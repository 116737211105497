import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injector } from '@angular/core';

import { TokenService } from '../services/token.service';
import { AppConfig } from '../configs/app.config';

@Injectable()
export class JwtauthService {
  private apiURL = '';
  private http_backend: HttpClient;
  myDetails;
  appConfig: AppConfig = new AppConfig();

  constructor(
    private injector: Injector, 
    private http: HttpClient,
    private backend_handler: HttpBackend,
    private tokenSer: TokenService,
    ) {
    this.apiURL = this.injector.get('API_URL');
    this.http_backend = new HttpClient(backend_handler);
  }

  login(data) {
    return this.http_backend.post(`${this.apiURL}login`, data);
  }

  logout() {
    let loginUri = '/login';
    if ( this.is_venodor_site() ) {
      loginUri = 'vendor'+loginUri;
    }
    localStorage.removeItem('myDetails');
    this.tokenSer.logout();
    if ( this.sessionExp ) {
      localStorage.setItem('session_expired', 'yes');
    }
    if ( ! this.tokenSer.isValid() ) {
      window.location.replace(loginUri);
      return;
    }
    this.http_backend.get(`${this.apiURL}logout`).subscribe(
      data => {
        window.location.replace(loginUri);
      },
      error => {
        window.location.replace(loginUri);
      }
    );
  }

  isSuperUser() {
    this.myDetails = this.getMyDetails();
    if ( this.myDetails && this.myDetails.rolesCode.includes('sup-admin') )
      return true;
    return false;
  }

  sapEnabled() {
    this.myDetails = this.getMyDetails();
    return this.myDetails.sapEnable;
  }

  is_venodor_site() {
    return (window.location.pathname.search('/vendor/') == 0);
  }

  isVenodor() {
    this.myDetails = this.getMyDetails();
    if ( this.myDetails && this.myDetails.vendor ) {
      return true;
    }
    return false;
  }

  venodorId() {
    this.myDetails = this.getMyDetails();
    return this.myDetails.vendor_id;
  }

  venodorAgreed() {
    this.myDetails = this.getMyDetails();
    return this.myDetails.privacy_policy == 'agreed';
  }

  alreadyShownReleaseNote() {
    return this.getMyDetails().alreadyShownReleaseNote;
  }
  
  setMyDetails(myDetails) {
    if ( typeof myDetails.projects_filter != 'undefined' ) {
      localStorage.setItem('projectListFilterData', myDetails.projects_filter);
      delete myDetails.projects_filter;
    }
    localStorage.setItem('myDetails', JSON.stringify(myDetails));
  }

  get_role() {
    let role = '';
    this.getMyDetails().rolesCode.forEach(element => {
      role = element;
    });
    return role;
  }

  getMyDetails() {
    this.myDetails = JSON.parse(localStorage.getItem('myDetails'));
    if (this.myDetails == null)
      this.updateMyDetails();
    return this.myDetails;
  }

  updateMyDetails() {
    let postData = {
      group: localStorage.getItem('userGroup'),
      releaseNoteVersion: this.appConfig.releaseNoteVersion
    }
    return this.http.post(`${this.apiURL}get_user_details`, postData)
  }

  external_storage_url = 'external_storage_url';
  get_external_storage_url(){
    if ( this.isVenodor() ) {
      return;
    }
    this.http.get(`${this.apiURL}config/view/3`).subscribe(
      data => {
        localStorage.setItem(this.external_storage_url, data['result'].value);
      },
      error => this.handleError(error)
    );
  }

  sessionExp: boolean = false;
  handleError(error){
    if (error.status == 401){
      if ( error.error.error && error.error.error.includes('Last user activity')){
        this.sessionExp = true;
      }
      this.logout();
    } else {
      return;
    }
  }

  forgotPassword(data) {
    return this.http_backend.post(`${this.apiURL}forgot-password`, data);
  }

  hasPermission(permission) {
    this.myDetails = this.getMyDetails();
    return (this.myDetails.permissions?.[permission] == 'Yes')
  }

  hasAPIAccess() {
    return ( ! this.hasPermission('freelancer') );
  }
}
