import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-email-meta-form-email',
  templateUrl: './email-meta-form-email.component.html',
  styleUrls: ['./email-meta-form-email.component.scss']
})
export class EmailMetaFormEmailComponent {
  @Input() emails;
  @Input() label: String;
  email: FormControl =  this.fb.control('');

  constructor(
    private fb: FormBuilder,
    private loaderSer: LoaderService,
    private lang: LanguageService,
    private helpSer: HelperService
  ) {
    //
  }

  ngOnInit() {
    //
  }

  getEmails() {
    return this.emails.value;
  }

  addEmail() {
    var email = this.email.value;
    var currentEmails = this.getEmails();
    this.helpSer.pushEmail(currentEmails, email);
  }

  removeEmail(ei) {
    if ( this.emails.value.length > 1 ) {
      return this.emails.value.splice(ei, 1);
    }
    this.loaderSer.notifySourceError.next(this.lang.getText('there_should_be_at_least_one_email_address'));
  }
}
