import { Component, Input, OnChanges, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { HelperService } from "src/app/services/helper.service";
import { LanguageService } from "src/app/services/language.service";

@Component({
  selector: 'app-email-meta',
  templateUrl: './email-meta.component.html',
  styleUrls: ['./email-meta.component.scss']
})
export class EmailMetaComponent implements OnChanges {
  @Input() form_data;
  form;
  loading: boolean = true;
  
  constructor(
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.loading = true;
    this.load_form();
  }

  ngOnChanges(changes) {
    this.load_form();
    if ( changes?.form_data?.currentValue ) {
      this.form.patchValue(changes?.form_data?.currentValue);
      this.loading = false;
    }
  }

  load_form() {
    if ( this.form ) {
      return;
    }
    this.form = this.fb.group({to: this.fb.control([]), cc: this.fb.control([]), from: this.fb.control([]), subject: ''});
    this.form.get('subject').valueChanges.subscribe(value => {
      this.form_data['subject'] = value;
    });
  }
}
