import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { SuggestImprovementComponent } from '../suggest-improvement/suggest-improvement.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {
  @ViewChild("helpModal") helpModal;
  @ViewChild("agreeModal") agreeModal;
  
  modalRef: BsModalRef;
  agreeModalRef: BsModalRef;
  apiURL = '';
  question;
  assistantResponse = [];
  thread_id = '';
  agree = false;
  description;
  //  = "Ask your Flowdesk Assistant any question about Flowdezk, about industry best practices, about terminology or about clients to speed up your project management. Simply type your question below in any language to get an instant reply.";

  constructor(
    public jwtAuth: JwtauthService,
    private modalService: BsModalService,
    private helpSer: HelperService,
    public lang: LanguageService,
    private injector: Injector,
    private http: HttpClient,
  ) { 
    this.apiURL = this.injector.get('API_URL');
  }

  ngOnInit() {
  }

  openAgreeModal() {
    if(this.jwtAuth.isVenodor()){
      this.openHelpModal();
      return;
    }
    this.getAssistantData();
    document.body.classList.add('agree-modal-opened');
    this.agreeModalRef = this.modalService.show(this.agreeModal, { 
      class: 'modal-lg agreeModal', 
      ignoreBackdropClick: true,
      keyboard: false, 
    });
  }
  openHelpModal() {
    document.body.classList.add('help-modal-opened');
    this.modalRef = this.modalService.show(this.helpModal, { 
      class: 'modal-lg helpModal', 
      ignoreBackdropClick: true,
      keyboard: false, 
    });
    this.closeAgreeModal();
  }

  @ViewChild('suggestImprovement') suggestImprovement: SuggestImprovementComponent;
  openSuggestImprovement() {
    this.suggestImprovement.openModal()
  }

  downUserManual() {
    let user_role = this.jwtAuth.get_role();
    if ( confirm(this.lang.getText('would_you_like_to_download_the_user_manual')) ) {
      this.helpSer.downUserManual(user_role)
    }
  }
  closeModal() {
    document.body.classList.remove('help-modal-opened');
    this.tempQuestion = "";
    this.assistantResponse = [];
    this.modalRef.hide();
  }

  closeAgreeModal(){
    document.body.classList.remove('agree-modal-opened');
    if(this.agreeModalRef)
      this.agreeModalRef.hide();
  }

  getAssistantData(){
    this.http.post(`${this.apiURL}chatgpt/getAssistantData`,[]).subscribe(
      data => {
        if(data['assistant_data']){
          this.description = data['assistant_data']['description'];
        }
      },
      error => this.handleError(error));
  }

  loading = false;
  tempQuestion = "";
  askChatGpt(){
    this.loading = true;
    let formData = {
      message: this.question,
      thread_id: this.thread_id
    }
    this.tempQuestion = this.question;
    this.question = '';
    this.http.post(`${this.apiURL}chatgpt/ask`, formData).subscribe(
      data => {
        this.tempQuestion = '';
        this.loading = false;
        this.thread_id = data['thread_id'];
        this.assistantResponse.push({
          question: formData.message,
          response: data['assistant_response']
        });
      },
      error => this.handleError(error)
    );
    return;
  }

  handleError(error) {
    this.loading = false;
    this.jwtAuth.handleError(error);
  }
}
