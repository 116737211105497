<div class="sticky-sidebar">
    <div class="main-page-sticky-sidebar">
        <div class="sidebar">
            <ul class="main-menu">
                <li class="d-flex align-items-center">
                    <div class="menu-item">
                        <span class="label">{{'configuration' | translate}}</span>
                    </div>
                </li>
                <li class="d-flex align-items-center mb-2" (click)="toggleMenu('general')"
                    [ngClass]="{'active-menu': activeMenu === 'general'}">
                    <div class="menu-item">
                        <span class="icon" *ngIf="activeMenu != 'general'"><img
                                src="/assets/svg/settings-normal.svg" /></span>
                        <span class="icon" *ngIf="activeMenu === 'general'"><img
                                src="/assets/svg/settings-active.svg" /></span>
                        <span class="label">{{ 'general_title' | translate }}</span>
                    </div>
                    <span class="arrow" [ngClass]="{'rotate-arrow': activeMenu === 'general'}"><i
                            class="fa fa-angle-down"></i></span>
                    <ul class="submenu" [class.active]="activeMenu === 'general'">
                        <li *ngFor="let menu of get_menus('general')"
                            [ngClass]="{'submenu-active': isSubmenuActive(menu.uri, 'general')}">
                            <a [routerLink]="menu.uri" class="btn">{{menu.lang_key| translate}}</a>
                        </li>
                    </ul>
                </li>
                <li class="d-flex align-items-center mb-2" (click)="toggleMenu('financial')">
                    <div class="menu-item">
                        <span class="icon" *ngIf="activeMenu != 'financial'"><img
                                src="/assets/svg/finance-normal.svg" /></span>
                        <span class="icon" *ngIf="activeMenu === 'financial'"><img
                                src="/assets/svg/finance-active.svg" /></span>
                        <span class="label">{{ 'financial_title' | translate }}</span>
                    </div>
                    <span class="arrow" [ngClass]="{'rotate-arrow': activeMenu === 'financial'}"><i
                            class="fa fa-angle-down"></i></span>
                    <ul class="submenu" [class.active]="activeMenu === 'financial'">
                        <li *ngFor="let menu of get_menus('financial')"
                            [ngClass]="{'submenu-active': isSubmenuActive(menu.uri, 'financial')}">
                            <a [routerLink]="menu.uri" class="btn">{{menu.lang_key| translate}}</a>
                        </li>
                    </ul>
                </li>
                <li class="d-flex align-items-center mb-2" (click)="toggleMenu('view')">
                    <div class="menu-item">
                        <span class="icon" *ngIf="activeMenu != 'view'"><img src="/assets/svg/view-normal.svg" /></span>
                        <span class="icon" *ngIf="activeMenu === 'view'"><img
                                src="/assets/svg/view-active.svg" /></span>
                        <span class="label">{{ 'view' | translate }}</span>
                    </div>
                    <span class="arrow" [ngClass]="{'rotate-arrow': activeMenu === 'view'}"><i
                            class="fa fa-angle-down"></i></span>
                    <ul class="submenu" [class.active]="activeMenu === 'view'">
                        <li *ngFor="let menu of get_menus('view')"
                            [ngClass]="{'submenu-active': isSubmenuActive(menu.uri, 'view')}">
                            <a [routerLink]="menu.uri" class="btn">{{menu.lang_key| translate}}</a>
                        </li>
                    </ul>
                </li>
                <li class="d-flex align-items-center mb-2" (click)="toggleMenu('project')">
                    <div class="menu-item">
                        <span class="icon" *ngIf="activeMenu != 'project'"><img
                                src="/assets/svg/project-managment-normal.svg" /></span>
                        <span class="icon" *ngIf="activeMenu === 'project'"><img
                                src="/assets/svg/project-managment-active.svg" /></span>
                        <span class="label">{{ 'project_management_title' | translate }}</span>
                    </div>
                    <span class="arrow" [ngClass]="{'rotate-arrow': activeMenu === 'project'}"><i
                            class="fa fa-angle-down"></i></span>
                    <ul class="submenu" [class.active]="activeMenu === 'project'">
                        <li *ngFor="let menu of get_menus('project')"
                            [ngClass]="{'submenu-active': isSubmenuActive(menu.uri, 'project')}">
                            <a [routerLink]="menu.uri" class="btn">{{menu.lang_key| translate}}</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="hasAPIAccess()" class="d-flex align-items-center mb-2" (click)="toggleMenu('sap')">
                    <div class="menu-item">
                        <span class="icon" *ngIf="activeMenu != 'sap'"><img
                                src="/assets/svg/api-connection-normal.svg" /></span>
                        <span class="icon" *ngIf="activeMenu === 'sap'"><img
                                src="/assets/svg/api-connection-active.svg" /></span>
                        <span class="label">{{ 'api_connections' | translate }}</span>
                    </div>
                    <span class="arrow" [ngClass]="{'rotate-arrow': activeMenu === 'sap'}"><i
                            class="fa fa-angle-down"></i></span>
                    <ul class="submenu" [class.active]="activeMenu === 'sap'">
                        <li *ngFor="let menu of get_menus('sap')"
                            [ngClass]="{'submenu-active': isSubmenuActive(menu.uri, 'sap')}">
                            <a [routerLink]="menu.uri" class="btn">{{menu.lang_key| translate}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>