import { Component, OnInit, Injector, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { timeout } from 'rxjs/operators';

import { TokenService } from 'src/app/services/token.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  apiURL;
  lang;
  modalRef;
  projectId;
  orderId;
  clientId;
  projectFinances;
  view;
  invoiceListForm: UntypedFormGroup;
  invoiceFA: UntypedFormArray = this.fb.array([]);
  bsConfig = {
    dateInputFormat: 'DD/MM/Y',
    rangeInputFormat: 'DD/MM/Y',
  }
  invoicing_emails;
  @Input() financial_dashboard;
  currencyCode;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private router: Router,
    private fb: UntypedFormBuilder,
    public helperService: HelperService,
    private modalService: BsModalService,
    public language: LanguageService
  ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
  }

  ngOnInit(
  ) {
    this.initInvoiceListForm();
  }

  initInvoiceListForm() {
    this.invoiceListForm = this.fb.group({
      invoices: this.invoiceFA
    });
  }

  splitDateAndTime(dateTime) {
    return this.helperService.splitDateAndTime(dateTime);
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }

  @ViewChild('invoiceHistoryModal', {static: true}) invoiceHistoryModal:TemplateRef<any>;
  openModal(projectId, orderId, invoicing_emails, view = null, clientId = null, currencyCode = null) {
    this.view = view;
    this.projectId = projectId;
    this.orderId = orderId;
    this.invoicing_emails = invoicing_emails;
    this.currencyCode = currencyCode;
    this.getProjectFinances(projectId)
    if (clientId != null) {
      this.clientId = clientId;
    }
    this.modalRef = this.modalService.show(
      this.invoiceHistoryModal,
      { class: 'modal-xl new-redesign-style', backdrop: 'static' }
    );
  }

  closeModal() {
    this.modalRef.hide();
    this.clearInvoiceData();
  }

  getProjectFinances(projectId) {
    let formData = {
      token: this.Token.get(),
      projectId: projectId,
      financial_dashboard: 1
    }
    this.changedProjects = [];
    this.http.post(`${this.apiURL}project-finance/getList`, formData).subscribe(
      invoices => {
        this.invoicePDF = [];
        this.projectFinances = invoices;
        this.invoiceFA.clear();
        this.projectFinances['invoice'].map((invoice) => {
          this.invoiceFA.push(this.fb.group({
            id: invoice.id,
            project_id: invoice.project_id,
            sent_at: this.splitDateAndTime(invoice.sent_at).date,
            paid_at: invoice.paid_at != null ? this.splitDateAndTime(invoice.paid_at).date : '',
            client_invoice_reminder_count: invoice.client_invoice_reminder_count
          }));
        })

        this.invoiceFA.valueChanges.subscribe(values => {
          setTimeout(() => { this.getChangedProjects() });
        });

        this.invoiceListForm.setControl('invoices', this.invoiceFA);
      },
      error => this.handleError(error)
    );
  }

  invoicePDF = [];
  @Output() sendInvoiceEvent: EventEmitter<any> = new EventEmitter();
  pfInvoiceDo(pfInvoice, method){
    if (!this.invoicePDF[pfInvoice.id]) {
      let formData = {
        token: this.Token.get(),
        projectFinanceID: pfInvoice.id
      };
      this.http.post(`${this.apiURL}project-finance/get`, formData)
      .subscribe(
        (data: any) => {
          this.invoicePDF[pfInvoice.id] = data;
          this.doInvoiceActions(pfInvoice, method)
        },
        error => {
          this.handleError(error);
        }
      );
    } else {
      this.doInvoiceActions(pfInvoice, method);
    }
  }

  doInvoiceActions(pfInvoice, method) {
    if (this.invoicePDF[pfInvoice.id].pdf != '') {
      let blob = this.helperService.base64ToBlob(this.invoicePDF[pfInvoice.id].pdf);
      if (method == 'down') {
        this.helperService.downloadFile(blob, pfInvoice.filename);
      } else if (method == 'view') {
        window.open(window.URL.createObjectURL(blob));
      } else if (method == 'send') {
        this.sendInvoiceEvent.emit({
          pfInvoice: this.invoicePDF[pfInvoice.id],
          pfInvoiceId:pfInvoice.id,
          projectData: {
            projectId: this.projectId,
            orderId: this.orderId,
            clientId: this.clientId
          }
        });
      } else if (method == 'send_reminder') { 
        let formData = this.Token.getAPITokenData();
        formData['projectId'] = pfInvoice.project_id;
        formData['invoiceReminderCount'] = pfInvoice.client_invoice_reminder_count + 1;
        formData['emails'] = this.invoicing_emails;
        formData['pfInvoiceId'] = pfInvoice.id;
        this.http.post(`${this.apiURL}financial-dashboard/project-invoice-reminder`, formData)
          .subscribe(
            data => {
              this.clearInvoiceData();
              this.closeModal();
              this.saveEvent.emit();
            },
            error => {
              this.handleError(error);
            }
          );
      }
    } else {
      alert(this.lang.getText('invoice_file_not_available_regenerate_invoice_warning'));
    }
  }

  changedProjects = [];
  isDirtyprojectsForm = false;
  getChangedProjects() {
    // let changedProjects = [];
    switch (this.view) {
      case 'billable_to_client':
        this.changedProjects = this.invoiceListForm.value.invoices.filter((invoice, index) => {
          return invoice.sent_at != null && invoice.sent_at != '';
        });
        break;
      case 'payable_by_client':
        this.changedProjects = this.invoiceListForm.value.invoices.filter((invoice, index) => {
          return invoice.paid_at != null && invoice.paid_at != '';
        });
        break;
      case 'financially_closed':
        this.changedProjects = this.invoiceListForm.value.invoices.filter((invoice, index) => {
          return (invoice.sent_at != null && invoice.sent_at != '') ||
            (invoice.paid_at != null && invoice.paid_at != '');
        });
        break;
      default:
        this.changedProjects = this.invoiceListForm.value.invoices.filter((invoice, index) => {
          return (invoice.sent_at != null && invoice.sent_at != '') ||
            (invoice.paid_at != null && invoice.paid_at != '');
        });
        break;
    }

    this.isDirtyprojectsForm = false;
    if (this.changedProjects.length > 0) {
      this.isDirtyprojectsForm = true;
    }
  }

  @Output() saveEvent: EventEmitter<any> = new EventEmitter();
  saveInvoices() {
    let postData = this.Token.getAPITokenData();
    postData['view'] = this.view;
    postData['changedProjects'] = this.changedProjects;
    let proGerReq = this.http.post(`${this.apiURL}financial-dashboard/projects/update`, postData)
    .pipe(
      timeout(60 * 1000)
    )
    .subscribe(
      data => {
        this.clearInvoiceData();
        this.closeModal();
        this.saveEvent.emit();
      },
      error => {
        this.handleError(error);
      }
    );
  }

  clearInvoiceData() {
    this.isDirtyprojectsForm = false;
    this.changedProjects = [];
    this.invoiceFA.clear();
  }
}
