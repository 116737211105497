<div class="row justify-content-md-center new-redesign-style">
  <div class="col-md-12 mt-3 mb-3 ps-5 pe-5">
    <div class="tab-content row justify-content-md-center">
      <div class="col-md-12 mb-3 ps-5 pe-5">
        <div class="header mt-4 mb-4 d-flex justify-content-between">
          <div class="d-flex align-items-center" [ngStyle]="{'width' : '53%'}">
            <h2>{{'vendors' | translate}}</h2>
            <div class="client-actions ps-2 ms-2 lign-items-center client-actions d-flex">
              <button class="btn btn-circle" routerLink="/vend/create" title="{{language.getText('create-vendor')}}">
                <img src="/assets/svg/create-client.svg">
              </button>
              <button class="btn btn-circle" (click)="downloadImport()" title="{{lang.getText('download_import_template')}}">
                <img src="/assets/svg/download-file.svg">
              </button>
              <button class="btn btn-circle" (click)="openUploadVendorDataModal(uploadVendorDataModal)" title="{{lang.getText('import_vendor_data')}}">
                <img src="/assets/svg/upload-file.svg">
              </button>
              <div class="d-inline-block form-check form-switch ms-2 pe-2 mt-2">
                <label class="d-flex align-items-center">
                  <input class="form-check-input me-2" type="checkbox" [(ngModel)]="vendorUploadInvoice" (change)="setVendorUploadInvoice()">
                  {{lang.getText('allow_vendors_to_upload_invoices')}}
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end" [ngStyle]="{'width' : '47%'}">
            <button class="btn btn-primary" [disabled]="isVendorSaving" (click)="vendorSaveChanges()">
              {{lang.getText('save_changes')}}
            </button>
            <div [hidden]="!hasXTMAccess" *ngIf="vendorMapType == 'xtm'" class="xtm-connections-container for_web_only">
              <div class="tab-container d-flex">
                <div style="max-width: 100%; overflow-y: auto;">
                  <!-- #firstScrollbar style="max-width: 100%;" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: true, scrollingThreshold: 50}" [scrollIndicators]="true" -->
                  <ul #widgetsContent role="tablist" class="nav nav-tabs" aria-label="Tabs">
                    <li class="nav-item" [ngClass]="selectedConnection == connectionIndex ? 'active' : ''" *ngFor="let connection of xtmConFgList; let connectionIndex=index;" (click)="selectXTMConnection(connectionIndex)">
                      <a class="nav-link" [ngClass]="selectedConnection == connectionIndex ? 'active' : ''" role="tab">
                        <span>{{ connection.connection_client_details.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div *ngIf="vendorMapType == 'phrase'" class="xtm-connections-container for_web_only">
              <div class="tab-container d-flex">
                <div style="max-width: 100%; overflow-y: auto;">
                  <ul #widgetsContent role="tablist" class="nav nav-tabs" aria-label="Tabs">
                    <li class="nav-item" [ngClass]="selectedPhraseConnection == connectionIndex ? 'active' : ''" *ngFor="let connection of phraseConFgList; let connectionIndex=index;" (click)="selectPhraseConnection(connectionIndex)">
                      <a class="nav-link" [ngClass]="selectedPhraseConnection == connectionIndex ? 'active' : ''" role="tab">
                        <span>{{ connection.connection_client_details.name }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-layout for_web_only">
          <div class="row row-secondary mt-2 mb-2 p-2">
            <div class="name_info_head text-start ps-5" [ngClass]="{'col-3': vendorMapType !== null, 'col-5 ps-5': vendorMapType === null}">{{lang.getText('name')}}</div>
            <div class="name_info_head text-start" [ngClass]="{'col-3': vendorMapType !== null, 'col-5': vendorMapType === null}">{{lang.getText('email')}}</div>
            <div [hidden]="!hasXTMAccess" class="col-4 text-start" *ngIf="vendorMapType == 'xtm'">{{lang.getText('xtm_user_id')}}</div>
            <!-- <div class="col-3 row">
              <div class="text-start">{{lang.getText('xtm_user_id')}}</div>
              <div class="col-8 text-start">
                {{lang.getText('xtm_user_display_name')}}
              </div>
            </div> -->
            <div class="col-4 text-start" *ngIf="vendorMapType == 'phrase'">{{lang.getText('phrase_provider_id')}}</div>
            <div class="col-2 name_info_head action-column">
              {{lang.getText('actions')}}
            </div>
          </div>
        </div>
        <div *ngIf="vendors">
          <form [formGroup]="VendorDataForm">
            <div formArrayName="vendor">
              <div class="row content row-primary mb-2 p-1" *ngFor="let vendor of vendorsFormArr.controls; let i=index">
                <div class="name text-start ps-5" [ngClass]="vendorMapType != null ? 'col-md-3 col-lg-3 col-sm-12' : 'col-md-5 col-lg-5 col-sm-12'"
                  [formGroupName]="i">
                  <input formControlName="id" type="hidden" value="{{vendor.id}}" />
                  <div class="hide_mobile">{{lang.getText('name')}}</div>
                  {{vendor.controls.name.value}}
                </div>
                <div class="email text-start" [ngClass]="vendorMapType != null ? 'col-md-3 col-lg-3 col-sm-12' : 'col-md-5 col-lg-5 col-sm-12'" [formGroupName]="i">
                  <div class="hide_mobile">{{lang.getText('email')}}</div>
                  {{vendor.controls.email.value}}
                </div>
                <div [hidden]="!hasXTMAccess" class="col-md-4 col-sm-12  ps-0" *ngIf="vendorMapType == 'xtm'">
                  <div class="main_tab_content hide_mobile">
                    <div class="tab-container">
                      <div style="max-width: 100%; overflow-y: auto;">
                        <!-- [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: true}" [scrollIndicators]="true" -->
                        <ul role="tablist" class="nav nav-tabs" aria-label="Tabs">
                          <li class="nav-item" [ngClass]="selectedConnection == connectionIndex ? 'active' : ''" *ngFor="let connection of xtmConFgList; let connectionIndex=index;" (click)="selectXTMConnection(connectionIndex)">
                            <a class="nav-link" [ngClass]="selectedConnection == connectionIndex ? 'active' : ''" role="tab">
                              <span>{{ connection.connection_client_details.name }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div [hidden]="!hasXTMAccess" [formGroupName]="i">
                    <div formArrayName="xtm_data" >
                      <div *ngFor="let connection of xtmConFgList; let connectionIndex = index;">
                        <div class="row" *ngIf="connection.isSelected" [formGroupName]="connectionIndex">
                          <input type="hidden" formControlName="id" value="" />
                          <div class="col-lg-12 col-md-12 col-sm-12 text-start">
                            <div class="hide_mobile">{{lang.getText('xtm_user_id')}}</div>
                            <input class="form-control" [ngClass]="{'error-border': (errors && errors[i+'.xtm_user_id'])}"
                              type="text" formControlName="xtm_user_id" />
                          </div>
                          <!-- <div class="col-lg-8 col-md-8 col-sm-12 text-start">
                            <div class="hide_mobile">{{lang.getText('xtm_user_display_name')}}</div>
                            <input class="form-control"
                              [ngClass]="{'error-border': (errors && errors[i+'.xtm_user_display_name'])}"
                              type="text" formControlName="xtm_user_display_name" />
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12" *ngIf="vendorMapType == 'phrase'">
                  <div class="main_tab_content hide_mobile">
                    <div class="tab-container">
                      <div style="max-width: 100%; overflow-y: auto;">
                        <ul role="tablist" class="nav nav-tabs" aria-label="Tabs">
                          <li class="nav-item" [ngClass]="selectedPhraseConnection == connectionIndex ? 'active' : ''" *ngFor="let connection of phraseConFgList; let connectionIndex=index;" (click)="selectPhraseConnection(connectionIndex)">
                            <a class="nav-link" [ngClass]="selectedPhraseConnection == connectionIndex ? 'active' : ''" role="tab">
                              <span>{{ connection.connection_client_details.name }}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div [formGroupName]="i" *ngIf="vendorMapType == 'phrase'">
                    <div formArrayName="phrase_data" >
                      <div *ngFor="let connection of phraseConFgList; let connectionIndex = index;">
                        <div class="row" *ngIf="connection.isSelected" [formGroupName]="connectionIndex">
                          <input type="hidden" formControlName="id" value="36" />
                          <div class="col-lg-12 col-md-4 col-sm-12 ps-0">
                            <div class="hide_mobile">{{lang.getText('phrase_provider_id')}}</div>
                            <input class="form-control" [ngClass]="{'error-border': (errors && errors[i+'.xtm_user_id'])}"
                              type="text" formControlName="phrase_provider_id" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 action text-start">
                  <div class="hide_mobile">{{lang.getText('actions')}}</div>
                  <a class="btn btn-circle-sm btn-circle pt-2 me-1"
                    routerLink="/vend/profile/{{vendor.controls.id.value}}" title="{{lang.getText('edit_profile')}}">
                    <!-- <i class="fa fa-pencil color-primary" aria-hidden="true"></i> -->
                    <img src="/assets/svg/vendor-profile-icon.svg">
                  </a>
                  <a class="btn btn-circle-sm btn-circle pt-2 me-1"
                    routerLink="/vend/edit/{{vendor.controls.id.value}}" title="{{lang.getText('edit_settings')}}">
                    <!-- <i class="fa fa-cog color-primary" aria-hidden="true"></i> -->
                    <img src="/assets/svg/settings-icon.svg">
                  </a>
                  <button (click)="delete(vendor.controls.id.value)"
                    class="btn btn-circle-sm btn-circle pb-2" title="{{lang.getText('delete')}}">
                    <!-- <i class="fa fa-trash color-secondary" aria-hidden="true"></i> -->
                    <img src="/assets/svg/delete-icon.svg">
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #uploadVendorDataModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{lang.getText('upload_vendor_data')}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <span>{{lang.getText('upload_vendor_popup_long_text')}}</span>
      {{lang.getText('upload_popup_long_text')}}</p>
    <form class="modal-body" [formGroup]="uploadVendorDataForm">
      <div>
        <input type="radio" value="false" name="keep_duplicate" id="keep_1" formControlName="keep_duplicate">
        <label for="keep_1">&nbsp;&nbsp;{{lang.getText('overwrite_duplicate_data')}}</label><br>
        <input type="radio" value="true" name="keep_duplicate" id="keep_2" formControlName="keep_duplicate">
        <label for="keep_2">&nbsp;&nbsp;{{lang.getText('keep_duplicate_data')}}</label>
      </div>
      <input #importFile type="file" name="import_file" class="form-control hidden" formControlName="import_file"
        accept=".xls" (change)="importFileChange($event.target.files)">
      <div class="col-sm-12 text-end">
        <button class="btn btn-primary btn-indent" (click)="closeModal()">{{lang.getText('cancel')}}</button>
        <button class="btn btn-primary btn-indent" *ngIf="!showUploadBtn"
          (click)="importFile.click()">{{lang.getText('ready_to_upload_the_populated_template')}}</button>
        <button class="btn btn-primary btn-indent" *ngIf="showUploadBtn"
          (click)="uploadVendorData()">{{lang.getText('upload')}}</button>
      </div>
    </form>
  </div>
</ng-template>

<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content" [ngClass]="status">
      <div class="modal-header text-center">
        <em class="fa fa-check" style="padding-top: 4px;"></em> &nbsp;
        {{message}}
        <button type="button" class="close" aria-label="Close" (click)="closeModalDialog()"><span
            aria-hidden="true">&times;</span></button>
      </div>
    </div>
  </div>
</div>
