import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-phrase',
  templateUrl: './phrase.component.html',
  styleUrls: ['./phrase.component.scss']
})
export class PhraseComponent {
  apiURL = env['API_URL'];
  lang;
  languages = [];
  @Input() assignJobs;
  @Input() projectID;
  @Input() projectData;
  @Input() vendors;
  @Input() clientSelected;
  @Input() isJobProjectCreated;
  @Input() allVendorsAssigned;
  @Input() isPhraseProjectCreated;
  @Input() phrase_pre_translate;
  @Input() phraseProjectId;
  @Input() phraseCreateResponse;
  @Input() jobs;
  @Input() phraseCheckedList;
  @Input() checkedListFiles;
  @Input() sources;
  @Input() targets;
  @Input() connectionId;
  @Input() selectedPraseTemplate;
  @Input() phraseTemplates;
  @Input() phraseJobfilesForm;
  @Input() connectors;
  @Input() currentConnector;
  @Input() checklistFiles;
  @Input() phraseChecklist;
  @Input() phraseMasterSelected;
  @Input() phraseTransaltionMemories;
  @Input() selectedDueDate;
  @Input() selectedDueTime;
  @Input() assignPopupPhraseData;
  @Output() closeModal: EventEmitter<Object> = new EventEmitter();
  @Output() reloadProject: EventEmitter<Object> = new EventEmitter();
  phraseConnectionID;
  orderID;
  combinedDueDateTime;
  phraseJobTargetLanguage: any[][] = [];
  phraseSourceLanguage = ''
  phrasePrioritizeWorkflowAssignment: boolean = false;
  bsConfig = {
    dateInputFormat: 'DD/MM/Y',
    rangeInputFormat: 'DD/MM/Y',
    minDate : new Date(new Date().setDate(new Date().getDate()))
  };
  loading: boolean = false;
  loadingCreateProject: boolean = false;

  constructor(
    private http: HttpClient,
    public language: LanguageService,
    private fb: FormBuilder,
    private loaderSer: LoaderService
  ) {
    this.lang = language;
  }
  
  ngAfterViewInit() {
    if ( this.assignJobs ) {
      this.loadOnlyAssignJobPopup();
      return;
    }
    this.loadPhrase();
  }

  async loadPhrase() {
    console.log('load phrase')
    let phraseConnectionResult = await this.setPhraseConnectionId();
    let phraseVendorResult = await this.checkPhraseVendor();
    if (phraseConnectionResult && phraseVendorResult && this.phraseTemplates.length == 0) {
      this.getPhraseTemplates();
    }
    this.phraseCheckedList = [];
  }

  loadOnlyAssignJobPopup() {
    this.alertNoVendor();
    let jobs = [];
    this.phraseConnectionID = this.assignPopupPhraseData.connection_id;
    this.phraseProjectId = this.assignPopupPhraseData.phrase_project_uid;

    this.assignPopupPhraseData.translations.forEach(trans => {
      let file = {
        path: trans.file_name,
        id: trans.project_file_id,
      }
      let job = this.createPhraseJob(file);

      let language = {
        id: trans.job_uid,
        full_name: trans.file_name,
        name: trans.file_name,
        source: trans.source_language,
        target: trans.target_language
      };
      (job.get('languages') as FormArray).push(this.initPhraseJobLanguage(language))
      jobs.push(job);
      this.jobs.push(job);
    })
  }

  createPhraseProject() {

    if (this.phraseConnectionID == null) {
      this.openModalDialog('failed',this.lang.getText('cannot_find_phrase_connection_for_this_client')+'!');
      return false;
    }
    if (!this.checkedListFiles.length) {
      this.openModalDialog('failed',this.lang.getText('please_select_or_upload_files_before_create_phrase_project')+'!');
      return false;
    }
    this.combinedDueDateTime = this.combineDateAndTime(new Date(this.selectedDueDate), new Date(this.selectedDueTime));
    let data = {
      phraseCheckedList : this.phraseCheckedList,
      projectData : this.projectData,
      referenceID: this.orderID,
      sourceLanguage: this.sources,
      targetLanguages: this.targets,
      dueDate: this.combinedDueDateTime,
      connectionId: this.connectionId,
      selectedClientId: this.clientSelected.id,
      selectedPraseTemplate: this.selectedPraseTemplate,
      template: this.phraseTemplates,
    }
    
    this.loadingCreateProject = true;
    this.http.post(`${this.apiURL}phrase/create-project/${this.phraseConnectionID}/${this.projectID}`, data).subscribe(
      (data: any) => {
        this.loadingCreateProject = false;
        this.openModalDialog('success', this.lang.getText('project_created_in_phrase'));
        this.isPhraseProjectCreated = true;
        this.phraseProjectId = data.phrase_project_uid;
        this.isPhraseProjectCreated = true;
        this.enableJobLanguageVendors();
      },
      error => {
        this.loadingCreateProject = false;
        if(error.status === 409){ // no phrase active plan
          // this.closeModal();
          this.closeModal.emit();
          return false;
        }
        this.phraseCreateResponse.emit({ status: 'Error' });
        if(error.status === 400){
          this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          //this.openModalDialog('failed',this.lang.getText('api_error_bad_request'));
        }
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        // setTimeout(() => {
        //   this.closeModalDialog();
        // }, 2 * 2000);
        // this.handleError(error)
        // this.closeModal();
        this.closeModal.emit();
      }
    );
  }
  
  prepareErrorMessage(error){
    let errorDetail = "";
    let errorStr = "";
    if(error['incorrectParameters'] && error['reason']) {
      errorDetail += error['reason'] + " - " + error['incorrectParameters']
    } else {
      error['details'].forEach(error => {
        errorDetail += error.name + " - " + error.value
      });
    }
    if(error.message) {
      errorStr += error.message
    }
    return errorStr + " Detail: " + errorDetail;
  }

  enableJobLanguageVendors() {
    this.jobs.controls.forEach(job => {
      let languages = job.get('languages') as FormArray;
      languages.controls.forEach(language => {
        language.get('vendor').enable();
      });
    });
  }

  //create phrase project start //
  combineDateAndTime(date: Date, time: Date): Date {
    // Extract the date part
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();

    // Extract the time part
    let hours = time.getHours();
    let minutes = time.getMinutes();
    let seconds = time.getSeconds();

    // Create a new Date object with the combined date and time
    return new Date(year, month, day, hours, minutes, seconds);
  }

  disable_assign_jobs: boolean = false;
  async assign_vendor_to_job() {
    let formData = this.phraseJobfilesForm.value;
    this.allVendorsAssigned = await this.checkVendors(formData);
    if ( ! this.allVendorsAssigned ) {
      this.openModalDialog('failed', this.lang.getText('assign_vendor_to_job'));
      return;
    }
    formData['phrase_project_uid'] = this.phraseProjectId;
    formData['selectedClientId'] = this.clientSelected.id;
    formData['project_id'] = this.projectID;
    formData['connection_id'] = this.phraseConnectionID;
    this.disable_assign_jobs = true;
    this.http.post(`${this.apiURL}phrase/assign-jobs`, formData).subscribe(
      (data: any) => {
        if (data.success) {
          this.openModalDialog('success', this.lang.getText('project_assigned_in_phrase'));
          this.closeModal.emit();
          this.reloadProject.emit(true);
        } else {
          this.closeModal.emit();
          var alerted = false;
          data['results'].forEach(result => {
            alerted = true;
            this.openModalDialog('failed', result.error.errorDescription);
          });
          if ( ! alerted ) {
            this.openModalDialog('failed', this.lang.getText('phrase_job_error'));
          }
        }
      },
      error => {
        this.disable_assign_jobs = false;
      }
    );
  }

  async onSubmitJob() {
    let formData = this.phraseJobfilesForm.value;
    this.allVendorsAssigned = await this.checkVendors(formData);
    if ( !this.allVendorsAssigned && ! this.phrasePrioritizeWorkflowAssignment ) {
        this.allVendorsAssigned = true;
        this.openModalDialog('failed', this.lang.getText('assign_vendor_to_job'));
        return;
      }
    formData['dueDate'] = this.combinedDueDateTime;
    formData['phraseProjectId'] = this.phraseProjectId;
    formData['pre_translate'] = this.phrase_pre_translate;
   formData['selectedClientId'] = this.clientSelected.id;
   formData['prioritizeWorkflowAssignment'] = this.phrasePrioritizeWorkflowAssignment;
   this.isJobProjectCreated = true;
    // console.log("formData", formData);
    // return false;
    this.loading = true;
    this.http.post(`${this.apiURL}phrase/create-and-analyze-job/${this.phraseConnectionID}/${this.projectID}`, formData).subscribe(
      (data: any) => {
        if (data.success) {
          this.allVendorsAssigned = true;
          setTimeout(() => {
            this.createJobAnalysis(data.jobsUUID);
          }, 5 * 1000);
        } else {
          this.loading = false;
          this.isJobProjectCreated = false;
          this.phraseCreateResponse.emit({ status: 'Error' });
          var alerted = false;
          data['results'].forEach(result => {
            alerted = true;
            this.openModalDialog('failed', result.error.errorDescription);
          });
          if ( ! alerted ) {
            this.openModalDialog('failed', this.lang.getText('phrase_job_error'));
          }
        }
      },
      error => {
        this.loading = false;
        this.isPhraseProjectCreated = false;
        this.isJobProjectCreated = false;
        this.allVendorsAssigned = true;
      }
    );
  }

  createJobAnalysis(jobsUUID:any) {
    let formData = {};
    formData['phraseProjectId'] = this.phraseProjectId;
    formData['pre_translate'] = this.phrase_pre_translate;
    formData['jobs_uuid'] = jobsUUID;
    this.http.post(`${this.apiURL}phrase/create-job-analyze/${this.phraseConnectionID}/${this.projectID}`, formData).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.success === true) {
          // console.log("phrase create job analyze response-------------", data);
          if (this.phrase_pre_translate) {
            this.preTranslateJobs(jobsUUID);
          }
          this.phraseCreateResponse.emit({ status: 'Created' });
          this.closeModalDialog();
          this.openModalDialog('success', this.lang.getText('project_started_in_phrase'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          // this.closeModal();
          this.closeModal.emit();
        } else {
          if (data.message == 'JOB_NOT_READY') {
            setTimeout(() => {
              this.createJobAnalysis(jobsUUID);
            }, 2 * 5000);
          } else {
            this.openModalDialog('failed', this.lang.getText('phrase_analyze_error')+'!');
          }
        }
      },
      error => {
        this.loading = false;
        this.isPhraseProjectCreated = false;
      }
    );
  }

  preTranslateJobs(jobsUUID: any) {
    return false;
    let formData = {};
    formData['phraseProjectUId'] = this.phraseProjectId;
    formData['pre_translate'] = this.phrase_pre_translate;
    formData['jobs_uuid'] = jobsUUID;
    this.http.post(`${this.apiURL}phrase/pre-translate-Jobs/${this.projectID}`, formData).subscribe(
      (data: any) => {
        // console.log("phrase pre translate Jobs response-------------", data);
      },
      error => {
        // this.isPhraseProjectCreated = false;
      }
    );
  }
  async checkVendors(formData: any) {
    this.allVendorsAssigned = true;
    for (let jobIndex = 0; jobIndex < formData.jobs.length; jobIndex++) {
      let job = formData.jobs[jobIndex];
      let languagesArray = this.jobs.at(jobIndex).get('languages') as FormArray;
      for (let jobLanguageIndex = 0; jobLanguageIndex < job.languages.length; jobLanguageIndex++) {
        let languageGroup = languagesArray.at(jobLanguageIndex) as FormGroup;
        let vendorControl = languageGroup.get('vendor');
        if (!vendorControl.value) {
          this.allVendorsAssigned = false;
        }
      }
    }
    return this.allVendorsAssigned;
  }

  removeLanguageFromPhraseJob(jobIndex, jobLanguageIndex) {
    let languagesArray = this.getJobLanguages(jobIndex);
    languagesArray.removeAt(jobLanguageIndex);
    if(languagesArray.length == 0) {
      this.jobs.removeAt(jobIndex);
    }
  }

  getJobLanguages(jobIndex: number): FormArray {
    return this.jobs.at(jobIndex).get('languages') as FormArray;
  }
  
  onChnageVendor(event: any, jobIndex: number = null, jobLanguageIndex: number = null): void {
    let selectedVendorId = event.target.value;
    let selectedVendor = this.vendors.find(vendor => vendor.vendor_id == selectedVendorId);  
    this.setVendorToJobLanguage(jobIndex, jobLanguageIndex, selectedVendor);
  }

  setVendorToJobLanguage(jobIndex, jobLanguageIndex, selectedVendor) {
    let languagesArray = this.jobs.at(jobIndex).get('languages') as FormArray;
    let languageGroup = languagesArray.at(jobLanguageIndex) as FormGroup;
    let vendorControl = languageGroup.get('vendor') as FormGroup;
    if (vendorControl) {
        if (vendorControl.value !== selectedVendor) {
            vendorControl.patchValue(selectedVendor);
        }
    } else {
        console.error("Vendor control is undefined");
    }
  }

  generateLanguageIndex(jobLanguageIndex) {
    return String.fromCharCode(65 + jobLanguageIndex);
  }

  selectedVendor : any;
  addVendorToAllJobs() {
    if (this.selectedVendor == null || this.selectedVendor == undefined || this.selectedVendor == '') {
      this.openModalDialog('failed', this.lang.getText('select_vendor'));
      return;
    }
    let selectedVendor = this.vendors.find(vendor => vendor.vendor_id == this.selectedVendor);
    if (!selectedVendor) {
      this.openModalDialog('failed', this.lang.getText('selected_vendor_null_or_not_found'));
      return;
    }
    this.jobs.controls.forEach((job, jobIndex) => {
      let languages = job.get('languages') as FormArray;  
      languages.controls.forEach((language, jobLanguageIndex) => {
        this.setVendorToJobLanguage(jobIndex, jobLanguageIndex, selectedVendor);
      });
    });
  }
  
  noVendor: boolean;
  async checkPhraseVendor() {
    if (this.vendors.length > 0) {
      this.vendors.forEach(vendor => {
        if (vendor.phrase_provider_id == null || vendor.phrase_provider_id == '') {
          this.openModalDialog('failed', this.lang.getText('please_map_phrase_vendor_id_to_all_vendors_first'));
          // this.closeModal();
          this.closeModal.emit();
          return false;
        }
      });
      return true;
    } else {
      this.phrasePrioritizeWorkflowAssignment = true;
      this.noVendor = true;
      // this.openModalDialog('failed', 'Please '+ this.lang.getText('create-vendor') + ' or ' +this.lang.getText('add_vendor'));
      // this.closeModal();
      return true;
    }
  }

  alertNoVendor() {
    if ( this.vendors.length == 0 ) {
      this.openModalDialog('failed', 'Please '+ this.lang.getText('create-vendor') + ' or ' +this.lang.getText('add_vendor'));
      this.closeModal.emit();
      return true;
    }
  }

  async setPhraseConnectionId() {
    if (this.clientSelected.phrase_connection_details != null) {
      this.phraseConnectionID =  this.clientSelected.phrase_connection_details.id;
      return true;
    } else {
      this.openModalDialog('failed',this.lang.getTextWDS('please_add_the_connection_first_for', [this.connectors[this.currentConnector-1].name])+" "+this.clientSelected.name);
          this.closeModal.emit();
          // this.closeModal();
    }
    return false;
  }

  getPhraseTemplates() {
    this.http.get(`${this.apiURL}phrase/list-templates/${this.phraseConnectionID}`).subscribe(
      data => {
        let templates = data['content'];
        this.phraseTemplates = templates;
      },
      error => {
        console.log('error',error);  
      }
    );
  }
  
  onCreateCat(start_project = false) {
    console.log('create')
    // if (this.currentConnector == 3) {
      if(this.selectedDueDate == '' || this.selectedDueTime == null){
        this.openModalDialog('failed',this.lang.getText('please_select_due_date'));
        return false;
      }

      if (this.phraseCheckedList.length == 0) {
        this.openModalDialog('failed',this.lang.getText('please_select_language_combination')+'!');
        return false;
      }
      
      this.createPhraseProject();
    // }
  }
  
  filesSelected:boolean;
  isAllSelectedFiles() {
    this.filesSelected = this.checklistFiles.every(function(item:any) {
        return item.isSelected == true;
    })
    this.getCheckedItemListFiles();
  }

  getFileName(link) {
    return link.substring(link.lastIndexOf('/')+1);
  }

  checkUncheckAllFiles() {
    for (var i = 0; i < this.checklistFiles.length; i++) {
      this.checklistFiles[i].isSelected = this.filesSelected;
    }
    this.getCheckedItemListFiles();
  }

  selectLanguageForPhraseJob(event, item, ti) {
    console.log('select s & t')
    event.preventDefault();
    event.stopPropagation();
    if (event.target.checked) {
      let isPushAllowed = true;
      if (this.phraseSourceLanguage == '') {
        this.phraseSourceLanguage = item.source;
      } else {
        if (this.phraseSourceLanguage != item.source) {
          isPushAllowed = false;
          event.srcElement.checked = false;
          this.phraseChecklist[ti].isSelected = false;
          this.openModalDialog('failed', this.lang.getText('please_select_same_source_language_combinations')+'!');
        }
      }

      if (isPushAllowed) {
        this.phraseChecklist[ti].isSelected = true;
        this.phraseCheckedList.push(this.phraseChecklist[ti]);
        this.addLanguageToJobs(this.phraseChecklist[ti]);
      }
    } else {
      event.srcElement.checked = false;
      this.phraseCheckedList = this.phraseCheckedList.filter(checkedItem => {
        return checkedItem.id != item.id;
      });
      this.removeLanguageFromJobs(this.phraseChecklist[ti]);
    }
    this.isAllSelected();
  }

  removeLanguageFromJobs(uncheckedLanguage: any) {
  
    for (let i = this.jobs.controls.length - 1; i >= 0; i--) {
      const job = this.jobs.controls[i] as FormGroup;
      const languagesArray = job.get('languages') as FormArray;
      const languageIndex = languagesArray.controls.findIndex((langControl: FormGroup) =>
        langControl.get('full_name')?.value === uncheckedLanguage.full_name 
      );
  
      if (languageIndex !== -1) {
        const removedLanguage = languagesArray.controls[languageIndex].get('full_name')?.value; 
        languagesArray.removeAt(languageIndex);
      }
  
      if (languagesArray.length === 0) {
        this.jobs.removeAt(i);
      }
    }
  }
  
  addLanguageToJobs(recheckedLanguage: any) {
    for (let i = 0; i < this.jobs.controls.length; i++) {
      const job = this.jobs.controls[i] as FormGroup;
  
      const languagesArray = job.get('languages') as FormArray;
      if (!languagesArray) {
        continue; // Skip this iteration if languages array is not found
      }
  
      const languageAlreadyAdded = languagesArray.controls.some((langControl: FormGroup) =>
        langControl.get('full_name')?.value === recheckedLanguage.full_name
      );
  
      if (!languageAlreadyAdded) {
        const newLanguageControl = this.initPhraseJobLanguage(recheckedLanguage);
        languagesArray.push(newLanguageControl);
      } else {
      }
    }
  }

  isAllSelected() {
    // if (this.currentConnector == 3) {
      this.phraseMasterSelected = this.phraseChecklist.every(function (item: any) {
        return item.isSelected == true;
      });
      this.getPhraseCheckedItemList();
    // }
  }

  checkUncheckAllSourcePhrase(event) {
    for (var i = 0; i < this.phraseChecklist.length; i++) {
      let item = this.phraseChecklist[i];
      let isPushAllowed = true;
      if (this.phraseSourceLanguage == '') {
        this.phraseSourceLanguage = item.source;
      } else {
        if (this.phraseSourceLanguage != item.source) {
          isPushAllowed = false;
          event.srcElement.checked = false;
          item.isSelected = false;
          let checkboxElement = document.getElementById("tran-" + i) as HTMLInputElement;
          checkboxElement.checked = false;
        }
      }
      
      if (isPushAllowed && this.phraseMasterSelected) {
        this.phraseCheckedList.push(item);
        item.isSelected = this.phraseMasterSelected;
        this.addLanguageToJobs(this.phraseChecklist[i]);
      } else if (!this.phraseMasterSelected) {
        this.removeLanguageFromJobs(this.phraseChecklist[i]);
        item.isSelected = this.phraseMasterSelected;
      }
    }
  }



  addFileToPhraseJobList(item: any) {
    if (this.phraseCheckedList.length === 0) { 
      this.openModalDialog('failed', this.lang.getText('add_language_combination'));
      return;
    }
    let itemId = item.id;
    let checklistFile = this.checkedListFiles.find(i => i.id === itemId);
    if (!checklistFile) {
      return;
    }
    let existingJobIndex = this.jobs.controls.findIndex((job: FormGroup) => job.get('id')?.value === itemId);
    if (existingJobIndex === -1) {
      let newJob = this.createPhraseJob(checklistFile);
      this.jobs.push(newJob);
      let languagesArray = newJob.get('languages') as FormArray;
      for (let lang of this.phraseCheckedList) {
        let jobLanguage = this.initPhraseJobLanguage(lang);
        languagesArray.push(jobLanguage);       
      }
    }
  }

  removeFileFromPhraseJobList(item: any) {
    let index = this.jobs.controls.findIndex(control => control.get('id')?.value === item.id);
    if (index !== -1) {
         this.jobs.removeAt(index);
      }
  }

  createPhraseJob(file: any): FormGroup {
    return this.fb.group({
      id: file.id,
      file: file,
      fileName: this.getFileName(file.path),
      languages: this.fb.array([]),
    });
  }

  initPhraseJobLanguage(lang) {
    return this.fb.group({
      title: 'job'+ 1,
      id: lang.id,
      full_name: lang.full_name,
      name: lang.name,
      source: lang.source,
      target: lang.target,
      // vendor: {value: this.fb.control(''), disabled: !this.isPhraseProjectCreated}
      vendor:this.fb.control({value: '', disabled: !this.isPhraseProjectCreated})
    });
  }
  
  checkUncheckAll(event) {
    // else if (this.currentConnector == 3) {
      this.phraseCheckedList = [];
      this.checkUncheckAllSourcePhrase(event);
    // }
  }

  getCheckedItemListFiles(){
    this.checkedListFiles = [];
    for (var i = 0; i < this.checklistFiles.length; i++) {
      if (this.checklistFiles[i].isSelected) {
        this.checkedListFiles.push(this.checklistFiles[i]);
        if (this.currentConnector == 3) {
          this.addFileToPhraseJobList(this.checklistFiles[i]);
        }
      } else {
        if (this.currentConnector == 3) {
          this.removeFileFromPhraseJobList(this.checklistFiles[i]);
        }
      }
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }

  getPhraseCheckedItemList() {
    let jobTargetLanguages = [];
    this.phraseCheckedList = [];
    for (var i = 0; i < this.phraseChecklist.length; i++) {
      if(this.phraseChecklist[i].isSelected)
        this.phraseCheckedList.push(this.phraseChecklist[i]);
        jobTargetLanguages.push(this.phraseChecklist[i]);
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }

  onChangePhraseTemplate(event: any) { 
    if (event == null || event == undefined || event == "") {
      this.phraseTransaltionMemories = [];
      return;
    }
    this.getTemplateTransMemrories(event);
  }

  getTemplateTransMemrories(templateId: string) { 
    this.http.get(`${this.apiURL}phrase/templates-trans-memories/${templateId}/${this.phraseConnectionID}`).subscribe(
      data => {
        this.phraseTransaltionMemories = data['transMemories']
      },
      error => {
        console.log('error',error);  
      }
    );
  }

  display = 'none';
  status = '';
  message = '';
  openModalDialog(status,message){
    if ( status == 'success' ) {
      this.loaderSer.notifySourceSuccess.next(message);
    } else {
      this.loaderSer.notifySourceError.next(message);
    }
    this.display='block';
    this.status = status;
    this.message = message;
  }
  closeModalDialog(){
    this.display='none';
  }
}
