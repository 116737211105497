import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, ViewChild, OnInit, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import {LanguageService} from '../../../services/language.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-project-relation-action',
  templateUrl: './project-relation-action.component.html',
  styleUrls: ['./project-relation-action.component.scss']
})
export class ProjectRelationActionComponent implements OnInit {
  apiURL: String = env['API_URL'];
  lang;
  parent_project_id;
  linkedProjects;
  disable_link_projects_btn = true;
  selected_projects_count = 0;
  constructor(
    private fb: UntypedFormBuilder,
    private jwtAuth: JwtauthService,
    private token: TokenService,
    private http: HttpClient,
    private modalService: BsModalService,
    public helperSer: HelperService,
    public language: LanguageService
  ) {
    this.lang = language;
  }

  ngOnInit() {
    this.initForm();
    this.patchFormValue();
  }

  searchProjectsForm
  addProjectsForm
  initForm() {
    this.searchProjectsForm = this.fb.group({
      search_text: ''
    });
    this.addProjectsForm = this.fb.group({
      id: '',
      project_name: '',
      comment: '',
      status: '',
    });
  }

  patchFormValue() {
    this.searchProjectsForm = this.fb.group({
      search_text: ''
    });

    this.addProjectsForm.patchValue({
      id: '',
      project_name: '',
      comment: '',
      status: '',
    }, { emitEvent: false });
  }

  searchedProjects: any[] = [];
  findProjects() {
    this.selected_projects_count = 0;
    let formData = this.searchProjectsForm.value;
    formData['token'] = this.token.get();
    let linkedProjectIds = [];
    this.linkedProjects.forEach(project => {
      linkedProjectIds.push(project.related_project_id);
    });
    linkedProjectIds.push(this.parent_project_id);
    formData['linkedProjectIds'] = linkedProjectIds;
    this.http.post(`${this.apiURL}project-relation/find-projects`, formData).subscribe(
      data => {
        this.searchedProjects = data['projects'];
      },
      error => this.handleError(error)
    );
  }

  addSelectedProjects() {
    let selectedProjects = [];
    this.searchedProjects.forEach(project => {
      if (project.checked) {
        selectedProjects.push({
          'project_id': this.parent_project_id,
          'related_project_id': project.id,
          'comment': project.comment
        })
      }
    });

    let formData = {
      token: this.token.get(),
      selected_projects: selectedProjects
    }
    this.http.post(`${this.apiURL}project-relation/link`, formData).subscribe(
      data => {
        this.closeModal();
      },
      error => this.handleError(error)
    );
  }

  @ViewChild('searchProjectModal') searchProjectModal;
  modalRef: BsModalRef;
  openModal(parent_project_id, linkedProjects) {
    this.linkedProjects = linkedProjects;
    this.parent_project_id = parent_project_id;
    this.searchedProjects = [];
    this.modalRef = this.modalService.show(
      this.searchProjectModal,
      { class: 'modal-xl', backdrop: 'static' }
    );
  }

  @Output("projectLinkedSuccess") projectLinkedSuccess = new EventEmitter();
  closeModal() {
    this.searchProjectsForm.get('search_text').reset();
    this.selected_projects_count = 0;
    this.modalRef.hide();
    this.projectLinkedSuccess.emit({ parent_project_id : this.parent_project_id });
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
  }

  setSelectedProjectCount(isChecked : boolean) {
    // if (event.target.checked) {
    //   this.selected_projects_count++;
    // } else { 
    //   this.selected_projects_count--;
    // }

    // if (this.selected_projects_count > 0) {
    //   this.disable_link_projects_btn = false;
    // } else { 
    //   this.disable_link_projects_btn = true;
    // }
    if (isChecked) {
      this.selected_projects_count++;
    } else {
      this.selected_projects_count--;
    }
  
    if (this.selected_projects_count > 0) {
        this.disable_link_projects_btn = false;
      } else { 
        this.disable_link_projects_btn = true;
      }
  }

  toggleProjectChecked(project: any) {
    project.checked = !project.checked; 
    this.setSelectedProjectCount(project.checked);
  }
  

}
