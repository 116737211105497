<ng-template #createCATprojectPopup>
  <div class="modal-header">
    <h4 class="modal-title float-start">
      {{lang.getText('create_cat_project')}}
    </h4>
    <div *ngIf="loading" style="height:0px">
      <div class="lds-ellipsis" style="left: 100px;">
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
      </div>
    </div>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center text-danger">
      {{lang.getText(no_api_access_msg)}}
    </div> 
    <div class="row my-4" style="top: 15px;">
      <div class="col-12">
      <!-- <p>{{lang.getText('select_one_of_the_available_connectors')}}:</p> -->
      <select class="form-select form-control" [(ngModel)]="currentConnector" (change)="onChangeConnector()">
        <option value="0">{{lang.getText('select_one_of_the_available_connectors')}}</option>
        <option *ngFor="let connect of connectors;" [disabled]="connect.disabled" value="{{connect.id}}">{{connect.name}}</option>
      </select>
      </div>
    </div>

    <!-- Phrase Controls -->
    <ng-container *ngIf="currentConnector == 3">
      <div class="row my-4" style="top: 15px;">
        <div class="col-12">
          <!-- <p>{{lang.getText('project_templates')}}:</p>   -->
          <select class="form-select form-control"
            [disabled]="isPhraseProjectCreated"
            (change)="!isPhraseProjectCreated ? onChangePhraseTemplate($event.target.value) : false"
            [(ngModel)]="selectedPraseTemplate">
            <option value="">{{lang.getText('project_templates')}}</option>
            <option [value]="phraseTemplate.uid" *ngFor="let phraseTemplate of phraseTemplates;">{{phraseTemplate.templateName}}</option>
          </select>
        </div>
        <div class="col-12 ml-3 mt-3" *ngIf="phraseTransaltionMemories.length > 0 && currentConnector == 3">
          <p class="phrase-translation-memory">{{ lang.getText('phrase_transaltion_memories') }}:</p>  
          <div  class="phrase-translation-memory">
            <span *ngFor="let transMemories of phraseTransaltionMemories"> 
              <span *ngFor="let targetLanguage of transMemories.transMemory.targetLangs">
                <!-- <ng-container *ngIf="targetLanguage == language.phrase_code"> -->
                {{ transMemories.transMemory.sourceLang }} - {{ targetLanguage }}<br/>
                <!-- </ng-container> -->
              </span> 
              <br/>
            </span>
          </div>
        </div>          
      </div>  
      <div class="row my-4">
        <div class="col-md-4 row">
          <p>{{lang.getText('due_date')}}:</p>
          <input [(ngModel)]="selectedDueDate" class="form-control" type="text" bsDatepicker [bsConfig]="bsConfig" />
          <span class="input-group-addon"> <i class="icon-calendar"></i> </span>
        </div>
        <div class="col-md-4 row">
          <p>{{lang.getText('due_time')}}:</p>
          <timepicker [(ngModel)]="selectedDueTime" [showSpinners]="true" [showMeridian]="false"></timepicker>
        </div>
      </div> 
      <div class="language-combination-sec">
        <br>
        <div class="col-12 language-sec">
          <strong>{{lang.getText('select_the_language_combinations')}}:</strong>
        </div>
        <br>
        <div class="row row-secondary p-2">
          <div class="col-4"><label>{{lang.getText('source2')}}</label></div>
          <div class="col-4"><label>{{lang.getText('target2')}}</label></div>
          <div class="col-4">
            <input type="checkbox" id="phrase_language_select_all" name="phrase_language_select_all" value="Select All" 
            [(ngModel)]="phraseMasterSelected"
            [disabled]="isPhraseProjectCreated"
            (change)="!isPhraseProjectCreated ? checkUncheckAll($event) : false">
            <label for="phrase_language_select_all" class="ms-2">{{lang.getText('select_all')}}</label>
          </div>
        </div>
        <br>
        <div class="row row-primary p-2">
          <div class="col-4">
            <p *ngFor="let source of sources;">{{source.name}}</p>
          </div>
          <div class="col-4">
            <p *ngFor="let target of targets;">{{target.name}}</p>
          </div>
          <div class="col-4">
            <div *ngFor="let item of phraseChecklist; let ti = index;"> 
              <input type="checkbox" id="tran-{{ti}}" name="tran-{{ti}}" value="{{item.id}}" 
              [(ngModel)]="item.isSelected"
              [disabled]="isPhraseProjectCreated"
              (change)="!isPhraseProjectCreated ? selectLanguageForPhraseJob($event, item, ti) : false"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="select-files-sec">
        <br>
        <div class="row row-secondary p-2">
          <div class="col-8"><label>{{lang.getText('select_files_to_include')}}:</label></div>
          <div class="col-4">
            <input type="checkbox" value="Select All" 
            [(ngModel)]="filesSelected"
            [disabled]="isPhraseProjectCreated"
            (change)="!isPhraseProjectCreated ? checkUncheckAllFiles() : false">
            <label class="ms-2">{{lang.getText('select_all')}}</label>
          </div>
        </div>
        <br>
        <div class="row-primary">
          <div class="row p-2" *ngFor="let item of checklistFiles; let ti = index;">
            <div class="col-8">
              <p style="word-wrap: break-word;">{{getFileName(item.path)}}</p>
            </div>
            <div class="col-4">
              <input type="checkbox" id="file-{{ti}}" name="file-{{ti}}" value="{{item.id}}" 
              [(ngModel)]="item.isSelected"
              [disabled]="isPhraseProjectCreated"
              (change)="!isPhraseProjectCreated ? isAllSelectedFiles() : false"
              >
              <label class="ms-2">{{lang.getText('select')}}</label>
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary mt-4"
        [disabled]="isPhraseProjectCreated"
        (click)="!isPhraseProjectCreated ? onCreateCat() : false">
        {{lang.getText('create_project2')}}
      </button>
      <hr class="mt-4 mb-4" style="color:#d5d5d5;"/>
      <div class="container">
        <h5>{{lang.getText('assign_jobs')}}</h5>
        <h5 class="font-italic fw-500">{{ lang.getText('mass_assignment') }}:</h5>
        <div class="row mb-3 col-md-10 p-0 m-0 d-flex align-items-center">
          <label for="files" class="form-label col-md-3">{{lang.getText('assign_all_jobs_to')}} :</label>
          <div class="col-md-7">
            <select
              class="form-select form-control"
              [(ngModel)]="selectedVendor"
              [disabled]="!isPhraseProjectCreated">
              <option *ngFor="let vendor of vendors" [value]="vendor.vendor_id" >{{ vendor.contact }}</option>
            </select>
          </div>
          <button
            class="btn btn-primary col-md-2"
            [disabled]="!isPhraseProjectCreated"
            (click)="isPhraseProjectCreated ? addVendorToAllJobs(): false">
            {{lang.getText('confirm')}}
          </button>
        </div>
        <h5 class="font-italic fw-500">{{ lang.getText('individual_assignment') }}:</h5>
        <div [formGroup]="phraseJobfilesForm">
          <div formArrayName="jobs" *ngFor="let job of jobs.controls; let jobIndex = index" class="job-container">
            <div [formGroupName]="jobIndex">
              <p class="fw-500">{{ lang.getText('job') }} {{ jobIndex+1 }}</p><br>
              <p class="fw-500">{{ job.get('fileName').value }}</p><br>
              <div class="mb-3">
                <div formArrayName="languages" *ngFor="let language of job.get('languages').controls; let jobLanguageIndex = index">
                  <div [formGroupName]="jobLanguageIndex" class="row col-md-12 mb-3">
                    <p class="form-label fw-500 col-md-2">{{ lang.getText('job') }} {{ jobIndex+1 }}{{ generateLanguageIndex(jobLanguageIndex) }}</p>
                    <p class="form-label col-md-3 text-primary">{{ language.value.full_name }}</p>
                    <div class="col-md-5 d-flex align-items-center">
                      <p class="form-label mr-2">{{ lang.getText('assign') }}: </p> 
                      <select
                        class="form-select form-control ms-2"
                        [disabled]="!isPhraseProjectCreated"
                        formControlName="vendor"
                        (change)="isPhraseProjectCreated ? onChnageVendor($event, jobIndex, jobLanguageIndex) : false">
                        <option *ngFor="let vendor of vendors" [selected]="language.get('vendor').value.vendor_id == vendor.vendor_id ? 'selected' : ''" [value]="vendor.vendor_id">{{ vendor.contact }}</option>
                      </select>
                    </div>
                    <div class="col-md-2 d-flex align-items-center">
                      <button 
                        class="btn btn-circle-sm btn-circle me-2"
                        [disabled]="!isPhraseProjectCreated"
                        (click)="isPhraseProjectCreated ? removeLanguageFromPhraseJob(jobIndex, jobLanguageIndex): false">
                        <i aria-hidden="true" class="fa fa-trash color-secondary"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-4 mb-4" style="color:#d5d5d5;"/>
        </div>
        <div class="d-flex d-flex align-items-center gap-3">
          <button
            class="btn btn-primary"
            [disabled]="!isPhraseProjectCreated || isJobProjectCreated"
            (click)="isPhraseProjectCreated ? onSubmitJob() : false">
            {{lang.getText('analyze_and_assign_jobs')}}
          </button>
          <div class="form-check ml-3" *ngIf="false">
            <input type="checkbox" [disabled]="!isPhraseProjectCreated" class="form-check-input" id="pre-translate" [(ngModel)]="phrase_pre_translate">
            <label class="form-check-label" for="pre-translate">{{ lang.getText('pre_translate') }}?</label>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Trados Controls -->
    <ng-container *ngIf="currentConnector == 1">
      <div class="row my-4" style="top: 15px;">
        <div class="col-12">
        <!-- <p>{{lang.getText('location')}}:</p>   -->
        <select class="form-select form-control" (change)="onChangeLocation($event.target.value)" [(ngModel)]="selectedLocation">
          <option value="" disabled selected>{{lang.getText('location')}}</option>
          <option [value]="tradosFolder.id" *ngFor="let tradosFolder of tradosFolders;">{{tradosFolder.name}}</option>
        </select>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-12">
          <!-- <p>{{lang.getText('project_templates')}}:</p>   -->
          <select class="form-select form-control" (change)="onChangeTemplate($event.target.value)" [(ngModel)]="selectedTemplate">
            <option value="" disabled selected>{{lang.getText('project_templates')}}</option>
            <option [value]="tradosTemplate.id" *ngFor="let tradosTemplate of tradosTemplates;">{{tradosTemplate.name}}</option>
          </select>
        </div>
        <a class="col-12 mt-2" style="cursor: pointer; color: #56bfea;" (click)="toggleExtraOptionsShow()">{{lang.getText('not_defined_a_template')}}</a>
      </div>  

      <div class="row my-4" *ngIf="isShown">
        <div class="col-12">
          <!-- <p>{{lang.getText('file_processing_configuration')}}:</p> -->
          <select class="form-select form-control" [(ngModel)]="selectedFileType">
            <option value="" disabled selected>{{lang.getText('file_processing_configuration')}}</option>
            <option [value]="tradosFileProcessingConfig.id" *ngFor="let tradosFileProcessingConfig of tradosFileProcessingConfigs['items'];">{{tradosFileProcessingConfig.name}}</option>
          </select>
        </div>
      </div> 

      <div class="row my-4" *ngIf="isShown">
        <div class="col-12">
        <!-- <p>{{lang.getText('translation_engine')}}:</p> -->
        <select class="form-select form-control" [(ngModel)]="selectedTranslationEngine">
          <option value="" disabled selected>{{lang.getText('translation_engine')}}</option>
          <option [value]="tradosTranslationEngine.id" *ngFor="let tradosTranslationEngine of tradosTranslationEngines['items'];">{{tradosTranslationEngine.name}}</option>
        </select>
        </div>
      </div> 

      <div class="row my-4" *ngIf="isShown">
        <div class="col-12">
          <!-- <p>{{lang.getText('workflow')}}:</p> -->
          <select class="form-select form-control" [(ngModel)]="selectedWorkflow">
            <option value="" disabled selected>{{lang.getText('workflow')}}</option>
            <option [value]="tradosWorkflow.id" *ngFor="let tradosWorkflow of tradosWorkflows['items'];">{{tradosWorkflow.name}}</option>
          </select>
        </div>
      </div>
    </ng-container>

    <!-- XTM Controls -->
    <ng-container *ngIf="currentConnector == 2">
      <div class="row my-4">
        <div class="col-6">
          <p>{{lang.getText('workflow')}}:</p>
          <select class="form-select form-control" [(ngModel)]="xtmWorkflow">
            <option value="" *ngIf="xtmWorkflows.length > 0">{{ lang.getText('please_select_workflow') }}</option>
            <option value="" *ngIf="xtmWorkflows.length == 0">{{lang.getText('no_results')}}</option>
            <option [value]="workflow.id" *ngFor="let workflow of xtmWorkflows;">{{workflow.name}}</option>
          </select>
        </div>
        <div class="col-6">
          <p>{{ lang.getText('segment_locking_rule') }}:</p>
          <select class="form-select form-control" [(ngModel)]="xtmSegmentLockingRule">
            <option value="">{{ lang.getText('please_select_segment_locking_rule') }}</option>
            <option [value]="segmentLockingRule" *ngFor="let segmentLockingRule of segmentLockingRules;">{{segmentLockingRule}}</option>
          </select>
        </div>
      </div> 
    </ng-container>

    <ng-container *ngIf="currentConnector != 0 && currentConnector != 3">
      <div class="row my-4">
        <div class="col-md-4 row">
          <p>{{lang.getText('due_date')}}:</p>
          <input [(ngModel)]="selectedDueDate" class="form-control" type="text" bsDatepicker [bsConfig]="bsConfig" name="selectedDueDate" />
          <span class="input-group-addon"> <i class="icon-calendar"></i> </span>
        </div>
      </div> 
  
      <div class="language-combination-sec">
        <br>
        <div class="col-12 language-sec">
          <strong>{{lang.getText('select_the_language_combinations')}}:</strong>
        </div>
        <br>
        <div class="row row-secondary p-2">
          <div class="col-4"><label>{{lang.getText('source2')}}</label></div>
          <div class="col-4"><label>{{lang.getText('target2')}}</label></div>
          <div class="col-4">
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Select All" 
            [(ngModel)]="masterSelected"
            (change)="checkUncheckAll($event)">
            <label for="vehicle1" class="ms-2">{{lang.getText('select_all')}}</label>
          </div>
        </div>
        <br>
        <div class="row row-primary p-2">
          <div class="col-4">
            <p *ngFor="let source of sources;">{{source.name}}</p>
          </div>
          <div class="col-4">
            <p *ngFor="let target of targets;">{{target.name}}</p>
          </div>
          <div class="col-4">
            <div *ngFor="let item of checklist; let ti = index;"> 
              <input type="checkbox" id="tran-{{ti}}" name="tran-{{ti}}" value="{{item.id}}" 
              [(ngModel)]="item.isSelected"
              (change)="currentConnector == 2 ? setXTMSourceLanguage($event, item, ti) : isAllSelected()"
              >
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-4 mb-4" style="color:#d5d5d5;"/>
      <div class="select-files-sec">
        <br>
        <div class="row row-secondary p-2">
          <div class="col-8"><label>{{lang.getText('select_files_to_include')}}:</label></div>
          <div class="col-4">
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Select All" 
            [(ngModel)]="filesSelected"
            (change)="checkUncheckAllFiles()">
            <label for="vehicle1" class="ms-2">{{lang.getText('select_all')}}</label>
          </div>
        </div>
        <br>
        <div class="row row-primary p-2" *ngFor="let item of checklistFiles; let ti = index;">
          <div class="col-8">
            <p style="word-wrap: break-word;">{{getFileName(item.path)}}</p>
          </div>
          <div class="col-4">
            <div class="file-select-div"> 
              <input type="checkbox" id="file-{{ti}}" name="file-{{ti}}" value="{{item.id}}" 
              [(ngModel)]="item.isSelected"
              (change)="isAllSelectedFiles()"
              >
              <label class="ms-2">{{lang.getText('select')}}</label>
              <select class="form-select form-control role-select" [(ngModel)]="item.role">
                <option value="translatable">{{'translatable'}}</option>
                <option value="reference">{{'reference'}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    <br>
    <br>
    <ng-container *ngIf="currentConnector != 0 && currentConnector != 3">
      <div class="col-12 float-start">
        <button (click)="onCreateCat()" [disabled]="isXTMProjectCreated" class="btn btn-primary">{{lang.getText('create_cat_project')}}</button>
        <button (click)="onCreateCat(true)" [disabled]="isXTMProjectCreated" class="btn btn-primary ms-3">{{lang.getText('create_start_cat_project')}}</button>
      </div>
    </ng-container>
  </div>
</ng-template>


<ng-template #sendAdditionalFilesPopup>
  <div class="modal-header">
    <h4 class="modal-title float-start">
      {{lang.getText('send_additional_files')}}
    </h4>
    <div *ngIf="loading" style="height:0px">
      <div class="lds-ellipsis" style="left: 100px;">
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
      </div>
    </div>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <br>
    <div class="select-files-sec">
      <br>
      <div class="row">
        <div class="col-8"><strong>{{lang.getText('select_files_to_include')}}:</strong></div>
        <div class="col-4">
          <input type="checkbox" id="vehicle1" name="vehicle1" value="Select All" 
          [(ngModel)]="filesSelected"
          (change)="checkUncheckAllFiles()">
          <label for="vehicle1" class="ms-2">{{lang.getText('select_all')}}</label>
        </div>
      </div>
      <br>
      <div class="row" *ngFor="let item of checklistFiles; let ti = index;">
        <div class="col-8">
          <p style="word-wrap: break-word;">{{getFileName(item.path)}}</p>
        </div>
        <div class="col-4">
          <div class="file-select-div"> 
            <input type="checkbox" id="file-{{ti}}" name="file-{{ti}}" value="{{item.id}}" 
            [(ngModel)]="item.isSelected"
            (change)="isAllSelectedFiles()"
            >
            <select class="form-select form-control role-select" [(ngModel)]="item.role">
              <option value="translatable">{{'translatable'}}</option>
              <option value="reference">{{'reference'}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="float-start">
      <button (click)="startProject()" [disabled]="isXTMProjectStarted" class="btn btn-primary text-capitalize">{{lang.getText('start') + ' ' + lang.getText('project')}}</button>
    </div>
    <div class="float-end">
      <button (click)="sendAdditionalFiles()" [disabled]="isXTMFilesAdded" class="btn btn-primary">{{lang.getText('send_additional_files')}}</button>
    </div>
  </div>
</ng-template>

<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}" style="z-index: 9999;"> 
  <div class="modal-dialog" role="document">
    <div class="modal-content" [ngClass]="status">
      <div class="modal-header text-center;" style="white-space: pre-line;">
        <em class="fa fa-check" style="padding-top: 4px;"></em> &nbsp;
        {{message}}
        <button type="button" class="close" aria-label="Close" (click)="closeModalDialog()"><span
            aria-hidden="true">&times;</span></button>
      </div>
    </div>
  </div>
</div>

