export class configurationPageConfig {
    public menu_groups:Array<Object> = [
        {
            name: 'general',
            lang_key: 'general_title',
            menus: [
                {
                    lang_key: "company_setting",
                    uri: '/configure/company-settings',
                    sub_menus: [
                        { lang_key: "company_logo", uri: '/configure/company-settings/company-logo' }
                    ]
                },
                {lang_key : "data_retention_policy", uri: '/configure/data-retention-policy'},
                {lang_key : "consent_statement", uri: '/configure/vendor-subscription'},
                {lang_key : "user_manual", uri: '/configure/user-manual'},
            ],
        },
        {
            name: 'financial',
            lang_key: 'financial_title',
            menus: [
                {lang_key : "currencies", uri: '/configure/currency'},
                {lang_key : "invoice_model", uri: '/configure/invoice-model-v2/list' },
                {lang_key : "purchase_order_model", uri: '/configure/purchase-order-model' },
                {lang_key : "tax_descriptions", uri: '/configure/tax-desc'},
                {lang_key : "tax_rates", uri: '/configure/tax-rate'},
                {lang_key : "subscription", uri: '/configure/subscription'},
                {lang_key : "storage_space", uri: '/configure/storage-space'}
            ],
        },
        {
            name: 'project',
            lang_key: 'project_management_title',
            menus: [
                {lang_key : "languages", uri: '/configure/language'},
                {lang_key : "supplied_services",  uri: '/configure/supplier-services'},
                {lang_key : "specialization_codes", uri: '/configure/spec-code'},
                {lang_key : "additional_service_units", uri: '/configure/add-ser-unit'},
                {lang_key : "quality_checklists",  uri: '/configure/quality-checklist'},
                {lang_key : "heads_up_template",  uri: '/configure/heads-up-template'},
                {lang_key : "iso_documents",  uri: '/configure/iso-documentation'},
                {lang_key : "system_emails",  uri: '/configure/system-emails'}
            ],
        },
        {
            name: 'sap',
            lang_key: 'api_connections',
            menus: [
                {lang_key:'sap_sol_api', uri: '/configure/sap-connection'},
                {lang_key:'trados_api', uri: '/configure/trados'},
                {lang_key:'xtm_api', uri: '/configure/xtm-connection'},
                {lang_key:'phrase_api', uri: '/configure/phrase-connection'},
            ],
        },
        {
            name: 'view',
            lang_key: 'view',
            menus: [
                {lang_key:'status_bar_display_options', uri: '/configure/status-bar-display-settings'},
                {lang_key:'project_display_options', uri: '/configure/project-display-settings'},
            ]
        }
    ];

    get_group(select_group) {
        let temp_group:Object;
        this.menu_groups.forEach(group => {
            if (group['name'] == select_group) {
                temp_group = group;
            }
        });
        return temp_group;
    }
}
