import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { SuggestImprovementComponent } from '../suggest-improvement/suggest-improvement.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  apiURL = '';
  
  constructor(
    public jwtAuth: JwtauthService,
    private helpSer: HelperService,
    public lang: LanguageService,
    private injector: Injector,
  ) { 
    this.apiURL = this.injector.get('API_URL');
  }

  ngOnInit() {
  }

  @ViewChild('suggestImprovement') suggestImprovement: SuggestImprovementComponent;
  openSuggestImprovement() {
    this.suggestImprovement.openModal()
  }

  downUserManual() {
    let user_role = this.jwtAuth.get_role();
    if ( confirm(this.lang.getText('would_you_like_to_download_the_user_manual')) ) {
      this.helpSer.downUserManual(user_role)
    }
  }
  closeModal() {
    document.body.classList.remove('support-opened');
  }
}
