import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SummerNoteConfig } from 'src/app/configs/summer-note-config';
import { HelperService } from 'src/app/services/helper.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-generate-quote',
  templateUrl: './generate-quote.component.html',
  styleUrls: ['./generate-quote.component.scss']
})
export class GenerateQuoteComponent implements OnInit {
  apiURL = env['API_URL'];
  @Input() projectId;
  @Input() clientId;
  @Input() quoteSendStep;
  @Input() translationCount;
  @Input() displayBreakdownsFc: UntypedFormControl;
  @Output() afterSubmitQuote = new EventEmitter();
  summernoteConfig:SummerNoteConfig = new SummerNoteConfig();
  httpReq;
  email_meta_data;
  langCode;

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private helperSer: HelperService,
    private loaderService: LoaderService,
    public lang: LanguageService
  ) { }

  ngOnInit() {
    this.langCode = this.lang.getCode();
  }

  @ViewChild('modalTemp') modalTemp;
  modalRef: BsModalRef;
  openModal() {
    this.quoteSendStep = 1;
    this.modalRef = this.modalService.show(
      this.modalTemp,
      { class: 'modal-lg' }
    );
    this.getClientQuoteHTML();
  }

  closeModal() {
    this.modalRef.hide();
  }

  clientQuoteHTML
  getClientQuoteHTML() {
    this.loaderService.isLoadingSource.next(true);
    let formData = {
      projectId: this.projectId,
      langCode: this.langCode,
      displayBreakdowns: this.displayBreakDown()
    };
    this.httpReq = this.http.post(`${this.apiURL}project/quote/details`, formData)
      .subscribe(
        data => {
          this.loaderService.isLoadingSource.next(false);
          this.clientQuoteHTML = data['html'];
          this.email_meta_data = data['email_meta'];
          this.langCode = data['pdf_language']
        },
        error => {
          this.loaderService.isLoadingSource.next(false);
        }
      );
  }

  clientQuotePDF;
  getClientQuote() {
    let formData = {
      projectId: this.projectId,
      langCode: this.langCode,
      content: this.clientQuoteHTML
    };
    this.httpReq = this.http.post(`${this.apiURL}project/quote/perview`, formData)
      .subscribe(
        data => {
          this.clientQuotePDF = this.helperSer.urlObjPDF(data['pdfB64']);
        }
      );
  }

  submitQuote() {
    this.quoteSendStep = 3;
    let postData = {
      projectId:            this.projectId,
      clientQuoteHTML:      this.clientQuoteHTML,
      meta_data:            this.email_meta_data
    };
    this.httpReq = this.http.post(`${this.apiURL}project/quote/send`, postData).subscribe(
      data => {
        this.quoteSendStep = 4;
        setTimeout(()=>{
          this.afterSubmitQuote.emit('yes');
          this.closeModal();
        }, 2 * 1000);
      }
    );
  }

  displayBreakDown() {
    return this.displayBreakdownsFc.value;
  }

  showAlert() {
    if ( this.translationCount < 201 && !this.displayBreakDown()) {
      return;
    }
    if ( this.translationCount > 100 && this.translationCount < 201) {
      this.helperSer.alertIt(this.lang.getText('project_langu_combi_max_100_breakdown_alert'));
    }
    if ( this.translationCount > 200 ) {
      this.helperSer.alertIt(this.lang.getText('project_langu_combi_max_200_alert'));
    }
  }
  
  loading() {
    return this.httpReq && ! this.httpReq.isStopped
  }
}
