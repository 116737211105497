<div *ngIf="!assignJobs">
    <div class="row my-4" style="top: 15px;">
        <div class="col-12 row">
            <!-- <p>{{lang.getText('project_templates')}}:</p>   -->
            <select class="form-select form-control" [disabled]="isPhraseProjectCreated"
                (change)="!isPhraseProjectCreated ? onChangePhraseTemplate($event.target.value) : false"
                [(ngModel)]="selectedPraseTemplate">
                <option value="">{{lang.getText('project_templates')}}</option>
                <option [value]="phraseTemplate.uid" *ngFor="let phraseTemplate of phraseTemplates;">
                    {{phraseTemplate.templateName}}</option>
            </select>
        </div>
        <div class="col-12 ml-3 mt-3" *ngIf="phraseTransaltionMemories.length > 0 && currentConnector == 3">
            <p class="phrase-translation-memory">{{ lang.getText('phrase_transaltion_memories') }}:</p>
            <div class="phrase-translation-memory">
                <span *ngFor="let transMemories of phraseTransaltionMemories">
                    <span *ngFor="let targetLanguage of transMemories.transMemory.targetLangs">
                        <!-- <ng-container *ngIf="targetLanguage == language.phrase_code"> -->
                        {{ transMemories.transMemory.sourceLang }} - {{ targetLanguage }}<br />
                        <!-- </ng-container> -->
                    </span>
                    <br />
                </span>
            </div>
        </div>
    </div>
    <div class="row my-4">
        <div class="col-md-4 row">
            <p>{{lang.getText('due_date')}}:</p>
            <input [(ngModel)]="selectedDueDate" class="form-control" type="text" bsDatepicker [bsConfig]="bsConfig" />
            <span class="input-group-addon"> <i class="icon-calendar"></i> </span>
        </div>
        <div class="col-md-4 row">
            <p>{{lang.getText('due_time')}}:</p>
            <timepicker [(ngModel)]="selectedDueTime" [showSpinners]="true" [showMeridian]="false" [minuteStep]="5">
            </timepicker>
        </div>
    </div>
    <div class="language-combination-sec">
        <br>
        <div class="col-12 language-sec">
            <strong>{{lang.getText('select_the_language_combinations')}}:</strong>
        </div>
        <br>
        <div class="row row-secondary p-2">
            <div class="col-4"><label>{{lang.getText('source2')}}</label></div>
            <div class="col-4"><label>{{lang.getText('target2')}}</label></div>
            <div class="col-4">
                <input type="checkbox" id="phrase_language_select_all" name="phrase_language_select_all"
                    value="Select All" [(ngModel)]="phraseMasterSelected" [disabled]="isPhraseProjectCreated"
                    (change)="!isPhraseProjectCreated ? checkUncheckAll($event) : false">
                <label for="phrase_language_select_all" class="ms-2">{{lang.getText('select_all')}}</label>
            </div>
        </div>
        <br>
        <div class="row row-primary p-2">
            <div class="col-4">
                <p *ngFor="let source of sources;">{{source.name}}</p>
            </div>
            <div class="col-4">
                <p *ngFor="let target of targets;">{{target.name}}</p>
            </div>
            <div class="col-4">
                <div *ngFor="let item of phraseChecklist; let ti = index;">
                    <input type="checkbox" id="tran-{{ti}}" name="tran-{{ti}}" value="{{item.id}}"
                        [(ngModel)]="item.isSelected" [disabled]="isPhraseProjectCreated"
                        (change)="!isPhraseProjectCreated ? selectLanguageForPhraseJob($event, item, ti) : false">
                </div>
            </div>
        </div>
    </div>
    <div class="select-files-sec">
        <br>
        <div class="row row-secondary p-2">
            <div class="col-8"><label>{{lang.getText('select_files_to_include')}}:</label></div>
            <div class="col-4">
                <input type="checkbox" value="Select All" [(ngModel)]="filesSelected"
                    [disabled]="isPhraseProjectCreated"
                    (change)="!isPhraseProjectCreated ? checkUncheckAllFiles() : false">
                <label class="ms-2">{{lang.getText('select_all')}}</label>
            </div>
        </div>
        <br>
        <div class="row-primary">
            <div class="row p-2" *ngFor="let item of checklistFiles; let ti = index;">
                <div class="col-8">
                    <p style="word-wrap: break-word;">{{getFileName(item.path)}}</p>
                </div>
                <div class="col-4">
                    <input type="checkbox" id="file-{{ti}}" name="file-{{ti}}" value="{{item.id}}"
                        [(ngModel)]="item.isSelected" [disabled]="isPhraseProjectCreated"
                        (change)="!isPhraseProjectCreated ? isAllSelectedFiles() : false">
                    <label class="ms-2">{{lang.getText('select')}}</label>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-primary mt-4" [disabled]="isPhraseProjectCreated || loadingCreateProject"
        (click)="!isPhraseProjectCreated ? onCreateCat() : false">
        {{lang.getText('create_project2')}}
    </button>
    <app-loader [loading]="loadingCreateProject" [leftAlign]="true"></app-loader>
    <div class="container p-0">
        <div class="row mt-2 col-md-10 p-0 m-0 d-flex align-items-center">
            <label for="files" class="form-label col-md-3">{{'prioritize_workflow_assignment' | translate}}</label>
            <div class="col-md-7">
                <input type="checkbox" [value]="true" [(ngModel)]="phrasePrioritizeWorkflowAssignment"
                    [disabled]="!isPhraseProjectCreated || noVendor"
                    (change)="phrasePrioritizeWorkflowAssignment ? false : true">
            </div>
        </div>
    </div>
</div>
<div>
    <div *ngIf="!phrasePrioritizeWorkflowAssignment || assignJobs" class="container">
        <h5>{{lang.getText('assign_jobs')}}</h5>
        <h5 class="font-italic fw-500">{{ lang.getText('mass_assignment') }}:</h5>
        <div class="row mb-3 col-md-10 p-0 m-0 d-flex align-items-center">
            <label for="files" class="form-label col-md-3 ps-0">{{lang.getText('assign_all_jobs_to')}} :</label>
            <div class="col-md-7">
                <select class="form-select form-control" [(ngModel)]="selectedVendor"
                    [disabled]="!isPhraseProjectCreated">
                    <option *ngFor="let vendor of vendors" [value]="vendor.vendor_id">{{ vendor.contact }}</option>
                </select>
            </div>
            <button class="btn btn-primary col-md-2" [disabled]="!isPhraseProjectCreated"
                (click)="isPhraseProjectCreated ? addVendorToAllJobs(): false">
                {{lang.getText('confirm')}}
            </button>
        </div>
        <h5 class="font-italic fw-500">{{ lang.getText('individual_assignment') }}:</h5>
        <div [formGroup]="phraseJobfilesForm">
            <div formArrayName="jobs" *ngFor="let job of jobs.controls; let jobIndex = index" class="job-container">
                <div [formGroupName]="jobIndex">
                    <p class="fw-500">{{ lang.getText('job') }} {{ jobIndex+1 }}</p><br>
                    <p class="fw-500">{{ job.get('fileName').value }}</p><br>
                    <div class="mb-3">
                        <div formArrayName="languages"
                            *ngFor="let language of job.get('languages').controls; let jobLanguageIndex = index">
                            <div [formGroupName]="jobLanguageIndex" class="row col-md-12 mb-3">
                                <p class="form-label fw-500 col-md-2">{{ lang.getText('job') }} {{ jobIndex+1 }}{{
                                    generateLanguageIndex(jobLanguageIndex) }}</p>
                                <p class="form-label col-md-3 text-primary">{{ language.value.full_name }}</p>
                                <div class="col-md-5 d-flex">
                                    <p class="form-label mr-2 mt-1">{{ lang.getText('assign') }}: </p>
                                    <select class="form-select form-control ms-2"
                                        formControlName="vendor"
                                        (change)="isPhraseProjectCreated ? onChnageVendor($event, jobIndex, jobLanguageIndex) : false">
                                        <option *ngFor="let vendor of vendors"
                                            [selected]="language.get('vendor').value.vendor_id == vendor.vendor_id ? 'selected' : ''"
                                            [value]="vendor.vendor_id">{{ vendor.contact }}</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <button *ngIf="!assignJobs"
                                        class="btn btn-border-secondary btn-circle color-secondary me-2"
                                        [disabled]="!isPhraseProjectCreated"
                                        (click)="isPhraseProjectCreated ? removeLanguageFromPhraseJob(jobIndex, jobLanguageIndex): false">
                                        <i aria-hidden="true" class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <div class="d-flex">
            <button *ngIf="!assignJobs" class="btn btn-primary mb-3" [disabled]="!isPhraseProjectCreated || isJobProjectCreated"
                (click)="isPhraseProjectCreated ? onSubmitJob() : false">
                {{lang.getText('analyze_and_assign_jobs')}}
            </button>
            <button *ngIf="assignJobs" class="btn btn-primary mb-3" [disabled]="disable_assign_jobs"
                (click)="isPhraseProjectCreated ? assign_vendor_to_job() : false">
                {{lang.getText('assign_jobs')}}
            </button>
        </div>
    </div>
</div>
<div *ngIf="!assignJobs">
    <div *ngIf="phrasePrioritizeWorkflowAssignment" class="">
        <button class="btn btn-primary mb-3" [disabled]="!isPhraseProjectCreated || isJobProjectCreated" style="min-width: 7.8rem;"
            (click)="isPhraseProjectCreated ? onSubmitJob() : false">
            {{lang.getText('analyze_jobs')}}
        </button>
    </div>
    <div *ngIf="loading">
        <app-loader [leftAlign]="true"></app-loader>
    </div>
</div>
