import { Component, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { TranslatePipe } from 'src/app/modules/shared/pipes/translate.pipe';

@Component({
  selector: 'app-total-display',
  templateUrl: './total-display.component.html',
  styleUrls: ['./total-display.component.scss']
})
export class TotalDisplayComponent {
  lang;
  string_length;
  @Input() title;
  @Input() currencyCode;
  @Input() amount;
  @Input() type = 'badge-primary';
  @Input() length_check = 8;
  @Input() col_l = '8';
  @Input() col_r = '4';
  decimal: Number = 2;
  @Input() check_string_length = true;

  constructor(
    public helpSer: HelperService,
    private translatePipe: TranslatePipe
  ) {
  }

  ngOnInit(){
    this.amount = this.helpSer.roundOfToFractionStr(this.amount, this.decimal);
    this.string_length = this.amount.length//this.translatePipe.transform(this.title).length + this.amount.length;
  }
}
