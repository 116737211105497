import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UntypedFormGroup } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { JwtauthService } from "src/app/services/jwtauth.service";
import { LanguageService } from "src/app/services/language.service";
import { TokenService } from "src/app/services/token.service";
import { environment as env } from "src/environments/environment";

@Component({
  selector: "app-edit-project-description",
  templateUrl: "./edit-project-description.component.html",
  styleUrls: ["./edit-project-description.component.scss"],
})
export class EditProjectDescriptionComponent  {
  apiURL;
  favFilterRadioIpt = null;
  favorites: any[] = [];
  projectFilterForm: UntypedFormGroup;
  modalRef: BsModalRef;
  transVal;
  projectId;
  owners;
  statuses;
  lang;
  @ViewChild("editCommentDescription")
  editCommentDescription;
  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    public language: LanguageService,
    private changeDetect: ChangeDetectorRef
  ) {
    this.lang = language;
    this.apiURL = env["API_URL"];
  }

  openModal(projectId) {
    this.projectId = projectId;
    this.projectDetails = {
      discription: "",
      comments_details: "",
    };
    this.getProjectDetails(projectId);
    this.modalRef = this.modalService.show(
      this.editCommentDescription,
      Object.assign({}, { class: 'modal-lg new-redesign-style'  })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  projectDetails: any;
  getProjectDetails(id) {
    let postData = this.Token.getAPITokenData();
    postData["projectId"] = id;
    this.http.post(`${this.apiURL}project/view`, postData).subscribe(
      (data) => {
        this.projectDetails = data["result"];
      },
      (error) => this.handleError(error)
    );
    this.changeDetect.detectChanges();
  }

  getComments(comments) {
    // console.log(JSON.parse(comments));
    if (comments) {
      var comments = JSON.parse(comments);
      return comments.comments;
    } else {
      return "";
    }
  }

  updateDescription(descriptionData, CommentData, projectIdData) {
    let postData = {
      token: this.Token.get(),
      description: descriptionData,
      comments: CommentData,
    };
    let id = projectIdData;
    this.http
      .post(`${this.apiURL}project/update-mini/${id}`, postData)
      .subscribe(
        (data) => {
          this.updateProjectForm();
          this.closeModal();
        },
        (error) => {
          this.handleError(error);
        }
      );
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }

  @Output() updateData: EventEmitter<Object> = new EventEmitter();
  updateProjectForm() {
    this.updateData.emit(this.projectId);
  }
}
