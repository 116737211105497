<div *ngIf="!jwtAuth.isVenodor() && loggedIn()" class="inner_header">
<nav class="navbar navbar-expand navbar-light nav_header_top  wrapper_outer_main"  aria-label="menuBar">
  <a class="navbar-brand" routerLink="/project">
    <img class="FlowDezk Logo" src="{{ publicURL }}logo/brand-logo.webp" alt="logo" width="90" height="60" />
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsMain" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse justify-content-end" id="navbarsMain">
    <ul class="navbar-nav me-auto">
      <li class="nav-item active">
      </li>
      <li class="nav-item">
      </li>
    </ul>
    <div class="inline-block">
      <a class="navbar-brand">
        <img *ngIf="image" alt="Customer logo" class="customer-logo" src="{{ image }}" height="60" />
      </a>
    </div>
    <div class="my-2 my-md-0">
        <ul class="navbar-nav me-auto">
          <li class="nav-item user-button">
            <a class="nav-link" (click)="openLangPopup(true)">
              <div class="user-button-icon"></div>
            </a>
            <span class="fade" style="left: -74px; bottom: -44px;">{{ name }}</span>
          </li>
          <li class="nav-item logout-button">
            <a class="logout-btn nav-link" (click)="logoutMe()">
              <div class="logout-button-icon"></div>
            </a>
            <span class="fade" style="bottom: -44px; left: -40px;"> {{ lang.getText("logout") }} </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>

</div>




<div *ngIf="jwtAuth.isVenodor()" class="inner_header">
  <nav class="navbar navbar-expand navbar-light nav_header_top  wrapper_outer_main" aria-label="vendor menu" >
    <a class="navbar-brand" routerLink="/vendor/dashboard">
      <img class="FlowDezk Logo" src="{{ publicURL }}logo/brand-logo.webp" alt="logo" width="90" height="60" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsMain" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarsMain">
      <ul class="navbar-nav me-auto">
        <li class="nav-item active">
        </li>
        <li class="nav-item">
        </li>
      </ul>
      <div class="inline-block">
        <a class="navbar-brand">
         <img *ngIf="image" alt="Customer logo" class="customer-logo" src="{{ image }}" height="60" />
        </a>
      </div>
       <div class="my-2 my-md-0">
        <ul class="navbar-nav me-auto">
          <li class="nav-item user-button">
            <a class="nav-link" (click)="openLangPopup(true)">
              <img alt="User Image" src="../../assets/img/userIcon.png" class="_nav_icons">
            </a>
            <span class="fade">{{ name }}</span>
          </li>
          <li class="nav-item logout-button">
            <a class="logout-btn nav-link" (click)="logoutMe()">
               <img alt="Logout Image" src="../../assets/img/logoutIcon.png" class="_nav_icons">
            </a>
            <span class="fade"> {{ lang.getText("logout") }} </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  </div>

  <nav *ngIf="enable_storage_alery_sec()" class="storage-alert bc-nav wrapper_outer_main" aria-label="storage alert nav">
    <div class="message" *ngIf="usedStoragePer >= 90 && usedStoragePer < 100">
      {{lang.getText('storage_alert_at_90_in_header')}}
    </div>
    <div class="message" *ngIf="usedStoragePer >= 100">
      {{lang.getText('storage_alert_at_100_in_header')}}
    </div>
  </nav>

<nav *ngIf="!jwtAuth.isVenodor() && loggedIn()" class="bc-nav wrapper_outer_main" aria-label="breadcrumb">
  <ol class="breadcrumb bc-menu-title">
    <li class="breadcrumb-item bc-title-menu-item ">
      <a routerLink="/project" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{lang.getText('projects_dashboard')}}</a>
    </li>
    <li class="breadcrumb-item bc-title-menu-item">
        <a routerLink="/project/create/0" routerLinkActive="active">{{lang.getText('create_project')}}</a>
    </li>
    <li *ngIf="jwtAuth.isSuperUser()" class="breadcrumb-item bc-title-menu-item">
        <a routerLink="/reports" routerLinkActive="active">{{lang.getText('reporting')}}</a>
    </li>
    <li class="breadcrumb-item bc-title-menu-item">
      <a (click)="openDashboardSettingsPopup()" class="dashboard_settings">
        {{lang.getText('dashboard_settings')}}
      </a>
    </li>
    <li class="breadcrumb-item bc-title-menu-item ">
      <a routerLink="/financial-dashboard" routerLinkActive="active">{{lang.getText('financial_dashboard')}}</a>
    </li>
  </ol>
  <ol *ngIf="jwtAuth.isSuperUser()" class="breadcrumb bc-menu-title bc-menu-title-right">
    <li class="breadcrumb-item bc-title-menu-item ">
      <a routerLink="/comp-res-mana"  routerLinkActive="active">{{lang.getText('client/vendor')}}</a>
  </li>
  <li class="breadcrumb-item bc-title-menu-item">
    <a routerLink="/users-list" routerLinkActive="active">{{lang.getText('user')}}</a>
</li>
      <li class="breadcrumb-item bc-title-menu-item ">
          <a routerLink="/configure"  routerLinkActive="active"> {{lang.getText('configuration')}} </a>
      </li>
  </ol>
</nav>

<!-- Vendors Only Menu start -->
<nav *ngIf="jwtAuth.isVenodor() && loggedIn()" class="bc-nav wrapper_outer_main" aria-label="breadcrumb">
  <ol class="breadcrumb bc-menu-title">
    <li class="breadcrumb-item bc-title-menu-item">
        <a routerLink="/vendor/dashboard" routerLinkActive="active">{{lang.getText('my_projects')}}</a>
    </li>
    <li class="breadcrumb-item bc-title-menu-item ">
        <a routerLink="/vendor/profile" routerLinkActive="active">{{lang.getText('my_profile')}}</a>
    </li>
  </ol>
</nav>
<!-- Vendors Only Menu ends -->

<div *ngIf="show_screen_width_alert()" class="screen-width-alert bc-nav wrapper_outer_main">
  <div class="message">
    <span (click)="hide_screen_width_alert()" class="alert-close pull-right">
      <span class="close-icon">
        <img alt="Close Image" src="../../assets/img/alert-close-icon.svg" />
      </span>
      <div class="close-text">{{lang.getText('don_t_show_again')}}</div>
    </span>
    <span class="text"> {{ lang.getText("screen_width_restriction_message") }} </span>
  </div>
</div>

<ng-template #langPopup>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{lang.getText('welcome_back')}} <strong>{{name}}</strong></h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]='langForm'>
      <!-- <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <label class="col-auto col-form-label no-padding text-bold">{{lang.getText('display_language')}}</label>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          
          <div class="row no-padding mt-4">
            <div class="col-md-5">
              <label class="col-auto col-form-label no-padding">{{lang.getText('your_flowdezk_display_language')}}</label>
            </div>
            <div class="col-md-4">
              <select class="form-control" formControlName="lang">
                <option value="de">Deutsch (German)</option>
                <option value="en">English</option>
                <option value="es">Español (Spanish)</option>
                <option value="fr">Français (French)</option>
                <option value="it">Italiano (Italian)</option>
                <option value="nl">Nederlands (Dutch)</option>
                <option value="pt">Português (Portuguese)</option>
              </select>
            </div>
            <div class="col-md-3">
              <button type="button" class="btn btn-primary" (click)="enableLang()" style="margin-right: 10px;">{{lang.getText('change')}}</button>
              <button type="button" class="btn btn-primary " (click)="changeLang()">{{lang.getText('save')}}</button>
            </div>
          </div>
        </mat-expansion-panel>
        <hr>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <label class="col-auto col-form-label no-padding">{{lang.getText('multi_factor_authentication')}}</label>
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <app-account></app-account>
        </mat-expansion-panel>
        <hr>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <label class="col-auto col-form-label no-padding">{{lang.getText('project_labels')}}</label>
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <app-project-label ></app-project-label>
        </mat-expansion-panel>
        <hr>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <label class="col-auto col-form-label no-padding">{{lang.getText('support')}}</label>
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>
          <app-support ></app-support>
        </mat-expansion-panel>
      </mat-accordion> -->
      <div class="row no-padding">
        <div class="col-md-5">
          <label class="col-auto col-form-label no-padding">{{lang.getText('your_flowdezk_display_language')}}</label>
        </div>
        <div class="col-md-4">
          <select class="form-control" formControlName="lang">
            <option value="de">Deutsch (German)</option>
            <option value="en">English</option>
            <option value="es">Español (Spanish)</option>
            <option value="fr">Français (French)</option>
            <option value="it">Italiano (Italian)</option>
            <option value="nl">Nederlands (Dutch)</option>
            <option value="pt">Português (Portuguese)</option>
          </select>
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-primary" (click)="enableLang()">{{lang.getText('change')}}</button>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-12 no-padding">
          <button type="button" class="btn btn-primary " (click)="changeLang()">{{lang.getText('done')}}</button>
        </div>
      </div>
      <hr>
      <app-account></app-account>
    </form>
    <hr />
    <app-project-label ></app-project-label>
    <hr />
    <app-support ></app-support>
  </div>
<div>
</div>
</ng-template>
<footer>
  <div class="scroll-to-top" (click)="openHelpModal()">
    <div class="bs-tooltip-left help-tooltip tooltip show">
      <div class="tooltip-inner">
        {{lang.getText('hi_what_can_we_help')}}
      </div>
    </div>
    <img alt="Help Image" src="../../assets/img/help_icon.webp" />
  </div>
</footer>
<app-help-modal #helpModalCom></app-help-modal>
<app-release-notes-modal #releaseNotesModalCom (close)="closeReleaseNoteModal()"></app-release-notes-modal>
<app-dashboard-display-settings #dashboardDisplaySettingsCom></app-dashboard-display-settings>
<app-breadcrumb-menu></app-breadcrumb-menu>
