<ng-template #thisModal>
    <div class="modal-header">
       <h3 class="modal-heading text-center">{{lang.getText('new_features')}}</h3>
    </div>
    <div class="modal-body pr-3 pl-3">
        <div>
            <p>Welcome to release 4.0.1 of FlowDezk. This is what we bring you this time:</p>
            <br />
        </div>
        <ul class="row releasenotes-list ml-0 mr-0">
            <li><p>We’ve extended the look and feel of our new UX to a large number of screens and pop-ups. We’re phasing the older experience out in stages and we’re at 70% of that goal. We expect to complete this task in January.</p></li>
            <li><p>We’re also introducing version 2.0 of the XTM connector. You can now connect FlowDezk to any number of XTM customer accounts and create, start and complete projects from FlowDezk. Please note that XTM may charge for the API usage that this integration requires. On our side however XTM connection features will remain free.</p></li>
            <li><p>Most of the dropdowns in FlowDezk have now become auto-completion dropdowns. Just enter the first letters of the item you’re looking for and the dropdown will adapt accordingly.</p></li>
            <li><p>You can now combine several specialization (skill) filters when you search for vendors in FlowDezk.</p></li>
            <li><p><b>We’re also introducing a freelance version of FlowDezk. This single-user version without integrations/connectors and with reduced storage space is available at a reduced rate of EUR 25/month. It will allow freelancers to efficiently manage projects from several customers in a much more detailed way than in the vendor portal. If you know someone who’s interested, have them contact us at <a href="mailto:sales@flowdezk.com">sales@flowdezk.com</a>.</b></p></li>
        </ul>
        <div>
            <p>Of course we’re also introducing a number of bug fixes and smaller improvements with this release.</p><br />
            <p>For now enjoy this new release and don’t hesitate to create a support ticket from FlowDezk or by sending an e-mail to <a href="mailto:support@flowdezk.com">support@flowdezk.com</a> if you run into trouble or would like some advice on FlowDezk best practices.</p>
        </div>
    </div>
    <div class="modal-bottom px-3">
        <div class="d-flex justify-content-end">
            <div class="col-auto me-2">
                <button type="button" (click)="hideReleaseNote()" class="btn btn-primary mr-2">{{lang.getText('i_ve_read_this')}}</button>
            </div>
            <div class="col-auto">
                <button type="button" (click)="closeModal()" class="btn btn-secondary">{{lang.getText('remind_me_next_time')}}</button>
            </div>
        </div>
    </div>
</ng-template>
