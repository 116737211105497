import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-translation-snapshot',
  templateUrl: './translation-snapshot.component.html',
  styleUrls: ['./translation-snapshot.component.scss']
})
export class TranslationSnapshotComponent {
  wordMatchesA: any;
  wordMatchesB: any;
  languages: any;
  projectCurrencyName: any;
  data: any;
  lang: any;

  constructor(
    public language: LanguageService,
    public bsModalRef: BsModalRef
  ) {
    this.lang = language;
  }

  subTotal(wordCount: any, wordUnit: number) {
    return (wordCount * wordUnit).toFixed(1)
  }

  calculateTotalWordsCount() {
    let wordsCount = 0;
    if (this.data?.data[0]?.xtm_word_rate_match_snapshot && Array.isArray(this.data.data[0].xtm_word_rate_match_snapshot)) {
      this.data.data[0].xtm_word_rate_match_snapshot.map((wordRate) => {
        let count = parseFloat(wordRate.word_count);
        count = isNaN(count) || (wordRate.display && !wordRate.display) ? 0 : count;
        wordsCount += count;
      });
    }
    return wordsCount;
  }
  
  calculateWordsWeighted() {
    let wordsCount: number = 0;
    if (this.data?.data[0]?.xtm_word_rate_match_snapshot && Array.isArray(this.data.data[0].xtm_word_rate_match_snapshot)) {
      this.data.data[0].xtm_word_rate_match_snapshot.map((wordRate) => {
        wordsCount += ((wordRate.word_count / 100) * wordRate.percentage);
      })
      return Math.round(wordsCount);
    }
  }
}
