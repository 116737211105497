<ng-template #modalTemp>
    <div class="modal-header cliQuotePrevModal">
      <h1 class="modal-title pull-left">
          {{lang.getText('quote_preview')}}
      </h1>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div [ngClass]="{loading: ( loading() && quoteSendStep != 3 ) }" class="modal-body cliQuotePrevModalBody">
    <div [hidden]="quoteSendStep !== 1">
      <div class="pdf-editor">
        <textarea [(ngModel)]="clientQuoteHTML" [ngxSummernote]="summernoteConfig.configPDF"></textarea>
      </div>
      <div>
        <button class="btn btn-light preview-btn" (click)="getClientQuote()"> {{lang.getText('preview_pdf')}}</button>
      </div>
      <iframe *ngIf="clientQuotePDF" class="pdf-object" [src]="clientQuotePDF" type="application/pdf" title="Client Pdf"></iframe>
    </div>
    <div [hidden]="quoteSendStep !== 2" class="cliQuotePrevModal">
      <div class="col-md-12">
        <app-email-meta [(form_data)]="email_meta_data"></app-email-meta>
      </div>
    </div>
      <div [hidden]="quoteSendStep !== 3" class="alert alert-success">
        <strong>{{ 'sending' | translate }}</strong>
      </div>
      <div [hidden]="quoteSendStep !== 4" class="alert alert-success">
        <strong>{{ 'quote_sent' | translate }}</strong>
      </div>
      <div class="col-md-12 mt-3 d-flex gap-3">
        <button [hidden]="quoteSendStep !== 1" [disabled]="translationCount > 200" type="button" class="btn btn-primary" (click)="quoteSendStep = 2">
          <span aria-hidden="true">{{lang.getText('send_quote')}}</span>
        </button>
        <button [hidden]="quoteSendStep !== 1" type="button" class="btn btn-secondary" (click)="closeModal()">
          <span aria-hidden="true">{{lang.getText('edit_quote')}}</span>
        </button>
        <button [hidden]="quoteSendStep !== 2" type="button" class="btn btn-primary" (click)="submitQuote()">
          <span aria-hidden="true">{{lang.getText('send')}}</span>
        </button>
      </div>
    </div>
  </ng-template>
