<div class="email-section">
    <label>{{label | translate}}:</label>
    <span class="col-md-10" *ngFor="let email of getEmails(); let ei = index; let elast = last;">
        <span class="col-auto">
            {{ email }}
            <span class="fa fa-trash" title="{{'remove' | translate}}" (click)="removeEmail(ei)">
            </span>{{elast ? '' : ', '}}&nbsp;
        </span>
    </span>
    <div class="row">
        <div class="col-md-9">
            <input [formControl]="email" placeholder="{{'enter_email_address2' | translate}}" class="form-control form-control-sm" type="text" />
        </div>
        <div class="col-md-3">
            <span (click)="addEmail()" class="btn btn-sm btn-dark" title="Add">
                {{'add' | translate}}
            </span>
        </div>
    </div>
</div>
