<div *ngIf="projectId != 0" class="single_part_main">
  <input [id]="tag+'file-upload'" type="file" class="form-control hidden" (change)="uploadFileChange($event)" multiple/>
  <div class="single_part_inner" *ngIf="!tabName">
    <div class="main_head"
      (appOnceVisible)="getFiles(1);"
      >
      <p>{{lang.getText('file_management')}}</p>
    </div>
    <div class="container file-management-container">
      <div class="row py-3">
        <div class="col-md-5">
          <div class="form-check form-check-inline p-0">
            <!-- <button *ngIf="!readOnly" (click)="checkClaimed() ? enableUploadFile() : false" class="btn btn-primary me-2">{{lang.getText('upload_files')}}</button> -->
            <label [for]="tag+'file-upload'" class="custom-file-upload  btn btn-primary me-2">
              {{lang.getText('upload_files')}}
            </label>
            <!-- <input id="file-upload" type="file" class="form-control" (change)="uploadFileChange($event)" multiple/> -->
            <button (click)="checkClaimed() ? openModal(copyFilesModal) : false" class="btn btn-primary me-2">{{lang.getText('copy_files')}}</button>
          </div>
          <div class="form-check form-check-inline p-0">
            <a [href]=external_storage_url class="btn btn-warning text-white" target="_blank">{{lang.getText('external_storage_system')}}</a>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!readOnly && uploadFilesEnable" class="row">
        <div class="col-xl-8 col-lg-6 col-md-6">
          <input class="form-control" type="text" readonly placeholder="{{lang.getText('filename')}}">
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <input type="file" (change)="uploadFileChange($event)" class="btn form-control" multiple>
        </div>
      </div> -->
      <div *ngIf="files && files.length > 0">
        <div *ngFor="let progress of uploads">
          <div [hidden]="!progress.uploading" class="progress-bar-layout row">
            <div class="col-lg-9 progress">
                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width.%]="progress.progress"></div>
            </div>
            <div *ngIf="progress.done" class="col-lg-3 status">
                <span class="alert-success">{{lang.getText('done')}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row" *ngIf="files && form.get('files').controls.length > 0" >
        <div class="col-xl-12 col-lg-12">
          <div class="row float-end herizontal-buttons" [ngClass]="
          uploadFilesEnable
            ? 'file-upload-enable-button'
            : 'file-upload-enable'">
              <a *ngIf="delivery == 'ON'" (click)="checkClaimed() ? deliverFiles() : false" class="btn btn-primary del-sel-btn">{{lang.getText('deliver_selection')}}</a>
              <span *ngIf="downloadReq && !downloadReq.isStopped" class="download-indicator">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </span>
              <button [disabled]="downloadReq && !downloadReq.isStopped" (click)="checkClaimed() ? downloadFiles() : false" class="btn btn-primary down-sel-btn">{{lang.getText('download_selection')}}</button><br />
              <a *ngIf="!readOnly" (click)="checkClaimed() ? deleteFiles() : false" class="btn btn-primary del-sel-btn">{{lang.getText('delete_selection')}}</a>
          </div>
          <div class="col-md-4 float-end comment_wrapper" [ngClass]="
          uploadFilesEnable
            ? 'file-upload-enable'
            : 'file-upload-disable'
        "  *ngIf="isVendor">
            <label>{{lang.getText('vendor_comments')}}</label>
            <div class="comment_area">{{deliveryComment}}</div>
          </div>
        </div>
      </div> -->
      <div *ngIf="allFilesReq && !allFilesReq.closed" class="row">
        <div class="col-xl-5 col-lg-4 col-md-4">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
      <form *ngIf="form && files && files.length > 0" [formGroup]="form">
      <!-- <div class="row" *ngIf="form.get('files').controls.length > 0">
        <div class="col-xl-5 col-lg-4 col-md-4"><strong>{{lang.getText('file_name')}}</strong></div>
        <div class="col-xl-5 col-lg-4 col-md-4"><strong>{{lang.getText('date_time')}}</strong></div>
      </div> -->
      <div class="row">
        <div class="col-md-9">
          <div formArrayName="files" class="row" *ngFor="let file of form.get('files').controls; let i = index;">
            <div class="col-md-1">
              <div class="form-check form-check-inline float-end slect-file-checkbox">
                <input [formControlName]="i" type="checkbox" value="{{files[i].id}}" class="form-check-input file-checkbox" (change)="isAllSelected($event, i)" [checked]="i ? true : false">
              </div>
            </div>
            <div class="col-md-5 badge-empty py-2  me-1">
              <!-- <span class="mx-2">{{lang.getText('file_name')}} :</span> -->
              <span class="text-black">{{getFileName(files[i].path)}}</span>
            </div>
            <div class="col-md-3 badge-empty py-2  me-1">
              <!-- <span class="mx-2">{{lang.getText('date_time')}} :</span> -->
              <span class="text-black">{{files[i].created_at | date:'dd-MMM-y HH:mm':'local'}}</span>
            </div>

            <div class="col-md-2" style="white-space:nowrap;">
              <!-- <div class="form-check form-check-inline float-end slect-file-checkbox">
                <input [formControlName]="i" type="checkbox" value="{{files[i].id}}" class="form-check-input file-checkbox">
              </div> -->
              <!-- <a (click)="checkClaimed() ? viewFile(files[i].id) : false" class="btn btn-primary me-3 float-end">{{lang.getText('view')}}</a> -->
              <a (click)="checkClaimed() ? viewFile(files[i].id) : false" class="btn-circle btn-circle-action-icons me-1">
                <i class="fa fa-eye color-primary" aria-hidden="true"></i>
              </a>
              <a *ngIf="delivery == 'ON'" (click)="checkClaimed() ? deliverFile(files[i].id) : false" class="btn-circle btn-circle-action-icons">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z" fill="#18347b"/></svg>
              </a>
              <a (click)="checkClaimed() ? downloadFiles(files[i].id) : false" class="btn-circle btn-circle-action-icons me-1">
                <i class="fa fa-download color-primary" aria-hidden="true"></i>
              </a>
              <a (click)="checkClaimed() ? deleteFile(files[i].id) : false" class="btn-circle btn-circle-action-icons">
                <i class="fa fa-trash color-secondary" aria-hidden="true"></i>
              </a>
            </div>
            <div class="col-md-1">
              <span
                class="color-green delivered-status"
                *ngIf="files[i].delivered_at != null && displayDelivered"
                >
                {{lang.getText('delivered')}}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div [ngClass]="
          uploadFilesEnable
            ? 'file-upload-enable-button'
            : 'file-upload-enable'">
              <a *ngIf="delivery == 'ON'" (click)="checkClaimed() ? deliverFiles() : false" class="btn btn-primary del-sel-btn">{{lang.getText('deliver_selection')}}</a>
              <span *ngIf="downloadReq && !downloadReq.isStopped" class="download-indicator">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </span>
              
              <div class="mb-3">
                <button [disabled]="downloadReq && !downloadReq.isStopped" (click)="checkClaimed() ? downloadFiles() : false" class="btn btn-circle btn-circle-action-icons">
                  <i class="fa fa-lg fa-download color-primary" aria-hidden="true"></i>
                </button> {{lang.getText('download_selection')}}
              </div>
              <div>
                <a *ngIf="!readOnly" (click)="checkClaimed() ? deleteFiles() : false" class="btn btn-circle btn-circle-action-icons-lg">
                  <i class="fa fa-lg fa-trash color-secondary" aria-hidden="true"></i>
                </a> {{lang.getText('delete_selection')}}
              </div>
          </div>
          <div class="col-md-4 float-end comment_wrapper" [ngClass]="
          uploadFilesEnable
            ? 'file-upload-enable'
            : 'file-upload-disable'
        "  *ngIf="isVendor">
            <label>{{lang.getText('vendor_comments')}}</label>
            <div class="comment_area">{{deliveryComment}}</div>
          </div>
        </div>
      </div>
      
        <div class="row" *ngIf="vendorUploadedFiles.length != 0">
          <div class="col-12">
            <button class="btn btn-success">{{lang.getText('delivery_files')}}</button>
          </div>
        </div>
        <div class="row delivery-layout" *ngFor="let file of vendorUploadedFiles;">
          <div class="col-xl-5 col-lg-4 col-md-4">
            <p>{{getFileName(file.path)}}</p>
          </div>
          <div class="col-xl-2 col-lg-4 col-md-4"></div>
          <div class="col-xl-3 col-lg-4 col-md-3">
            <a
              style="margin-left: 10px;"
              (click)="checkClaimed() ? viewFile(file.id) : false"
              class="btn btn-primary me-3 float-end view-btn">
              {{lang.getText('view')}}
            </a>
          </div>
          <div class="col-xl-1 col-lg-2 col-md-3"></div>
        </div>

        <div class="p-3">
          <div class="row">
            <div class="col-md-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="select_all" name="select_all" value="Select All" 
                (change)="checkUncheckAllFiles($event)"
                [checked]="masterSelected ? true : false"> 
                <label class="form-check-label ms-2" for="select_all">{{lang.getText('select_all_files')}}</label>
              </div>
            </div>
          </div>  
        </div>
      </form>
      <div>
        <app-pagination
          theme = 'center-align-btn'
          (go_to_page) = "getFiles($event)"
          >
        </app-pagination>
      </div>
    </div>
  </div>


  <!-- CR38.02 - UI changes in delivery tab - START -->
  <div class="single_part_inner" *ngIf="tabName == 'delivery'">
    <div class="main_head" (appOnceVisible)="getFiles(1);"></div>
    <div class="container">
      <div class="row py-3">
        <div class="col-md-4 nospace">
          <div class="form-check form-check-inline p-0">
            <label [for]="tag+'file-upload'" class="custom-file-upload btn btn-primary me-2 m-0">
              {{lang.getText('upload_files')}}
            </label>
            <button (click)="checkClaimed() ? openModal(copyFilesModal) : false" class="btn btn-primary me-2">{{lang.getText('copy_files')}}</button>
          </div>
          <div class="form-check form-check-inline p-0">
            <a [href]=external_storage_url class="btn btn-warning text-white me-2" target="_blank">{{lang.getText('external_storage_system')}}</a>
          </div>
          <div class="form-check form-check-inline p-0">
            <button class="btn btn-sm me-2 ms-0 mt-0 check_all_files_as_delivered_btn"
              (click)="type == 'language' ? (translation_files_delivered == 1 ? undoDeliverFilesParent(fileIndex) : deliverFilesParent(fileIndex) ) : (services_delivered == 1 ? undoDeliverFilesSerParent(fileIndexSer) : deliverFilesSerParent(fileIndexSer)) "
              [ngClass]="type == 'language' ? (translation_files_delivered == 1 ? 'btn-disabled' : 'btn-success') : (services_delivered == 1 ? 'btn-disabled' : 'btn-success')">
              {{lang.getText('check_all_files_as_delivered')}}
              <span *ngIf="type == 'language' ?  translation_files_delivered == 1 : services_delivered == 1" class="color-success ps-1">
                <em class="fa fa-check" aria-hidden="true"></em>
              </span>
            </button>
          </div>
        </div>
        <div class="col-md-8 nospace">
          <form *ngIf="form && files && files.length > 0" [formGroup]="form">
            <div class="row">
              <div class="col-md-12 nospace">
                <div formArrayName="files" class="row d-flex align-items-center" *ngFor="let file of form.get('files').controls; let i = index;">
                  <!-- <div class="col-md-1">
                    <div class="form-check form-check-inline float-end slect-file-checkbox">
                      <input [formControlName]="i" type="checkbox" value="{{files[i].id}}" class="form-check-input file-checkbox" (change)="isAllSelected($event, i)" [checked]="i ? true : false">
                    </div>
                  </div> -->
                  <div class="col-md-5">
                    <div class="badge-empty py-2">
                      <span class="mx-1 color-disabled">{{lang.getText('file_name')}} :</span>
                      <span class="text-black">{{getFileName(files[i].path)}}</span>
                    </div>
                  </div>
                  <div class="col-md-4 nospace">
                    <div class="badge-empty py-2">
                      <span class="mx-1 color-disabled">{{lang.getText('date_time')}} :</span>
                      <span class="text-black">{{files[i].created_at | date:'dd-MMM-y HH:mm':'local'}}</span>
                    </div>
                  </div>
                  <div class="col-md-3 nospace text-center">
                    <a (click)="checkClaimed() ? viewFile(files[i].id) : false" class="btn-circle btn-circle-action-icons d-inline-block mb-1">
                      <i class="fa fa-eye color-primary" aria-hidden="true"></i>
                    </a>
                    <a *ngIf="delivery == 'ON'" (click)="checkClaimed() ? deliverFile(files[i].id) : false" class="btn-circle btn-circle-action-icons d-inline-block mb-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z" fill="#18347b"/></svg>
                    </a>
                    <a (click)="checkClaimed() ? downloadFiles(files[i].id) : false" class="btn-circle btn-circle-action-icons d-inline-block mb-1">
                      <i class="fa fa-download color-primary" aria-hidden="true"></i>
                    </a>
                    <a (click)="checkClaimed() ? deleteFile(files[i].id) : false" class="btn-circle btn-circle-action-icons d-inline-block mb-1">
                      <i class="fa fa-trash color-secondary" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row" *ngIf="vendorUploadedFiles.length != 0">
              <div class="col-12">
                <button class="btn btn-success">{{lang.getText('delivery_files')}}</button>
              </div>
            </div>
            <div class="row delivery-layout" *ngFor="let file of vendorUploadedFiles;">
              <div class="col-xl-5 col-lg-4 col-md-4">
                <p>{{getFileName(file.path)}}</p>
              </div>
              <div class="col-xl-2 col-lg-4 col-md-4"></div>
              <div class="col-xl-3 col-lg-4 col-md-3">
                <a
                  style="margin-left: 10px;"
                  (click)="checkClaimed() ? viewFile(file.id) : false"
                  class="btn btn-primary me-3 float-end view-btn">
                  {{lang.getText('view')}}
                </a>
              </div>
              <div class="col-xl-1 col-lg-2 col-md-3"></div>
            </div>
    
            <!-- <div class="p-3">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="select_all" name="select_all" value="Select All"  (change)="checkUncheckAllFiles($event)"
                    [checked]="masterSelected ? true : false"> 
                    <label class="form-check-label ms-2" for="select_all">{{lang.getText('select_all_files')}}</label>
                  </div> 
                </div>
              </div>  
            </div> -->
          </form>
          <div>
            <app-pagination
              theme = 'center-align-btn'
              (go_to_page) = "getFiles($event)"
              >
            </app-pagination>
          </div>
        </div>
      </div>
      <div>
        <div *ngFor="let progress of uploads">
          <div [hidden]="!progress.uploading" class="progress-bar-layout row">
            <div class="col-lg-9 progress">
                <div class="progress-bar progress-bar-striped" role="progressbar" [style.width.%]="progress.progress"></div>
            </div>
            <div *ngIf="progress.done" class="col-lg-3 status">
                <span class="alert-success">{{lang.getText('done')}}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="allFilesReq && !allFilesReq.closed" class="row">
        <div class="col-xl-5 col-lg-4 col-md-4">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>
  </div>
  <!-- CR38.02 - UI changes in delivery tab - END -->

</div>



<ng-template #copyFilesModal>
  <div *ngIf="!isModalOpen" class="row p-3">
    <h5 class="d-flex justify-content-center">{{lang.getText('loading')}}</h5>
  </div>
  <ng-container *ngIf="isModalOpen">
  <div class="modal-header" id="copyFilesModal">
    <h4>{{lang.getText('project_files')}}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
    
    <div class="col-md-12 text-center d-flex nav-tabs mt-2 mb-0 pb-0 copy-files-modal-tabs">
      <span
        *ngFor="let tag of tags;"
        class="nav-link"
        (click)="checkClaimed() ? diaplayTagFiles(tag.tag) : false"
        [ngClass]="tag.tag == copyActiveTag ? 'badge-primary active' : 'badge-secondary'">
        {{tag.name}}
      </span>
    </div><br>
    <div *ngIf="allFiles === null" class="row">
      <h5>{{lang.getText('loading')}}</h5>
    </div>
    <div id="copyFilesModal" class="modal-body pt-0 mt-0">
      <div class="row row-secondary mt-2 mb-2 for_web_only">
        <div class="col-7 text-start">{{lang.getText('file_name')}}</div>
        <div class="col-3 text-start">{{lang.getText('date_time')}}</div>
        <div class="col-2">
          <div class="align-items-center badge-empty d-flex justify-content-start mb-2 mt-2">
            <input type="checkbox" class="rounded-checkbox me-2" (change)="checkUncheckAll($event)"
            [checked]="isSelectedAll ? true : false" />
            <span *ngIf="!isSelectedAll"> {{ 'select_all' | translate }}</span>
            <span *ngIf="isSelectedAll"> {{ 'all_selected' | translate }}</span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="allFiles.length == 0">
        <div class="row content row-primary mb-2 p-1">
          <div class="col-12 text-center" colspan="4">{{ lang.getText('no_data_found') }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="allFiles.length > 0">
        <div *ngIf="allFiles && fileDetails" [formGroup]="copyForm">
          <div formArrayName="checkedFiles">
            <div *ngFor="let file of allFiles;let fi = index;">
              <!-- <tr
                *ngIf="copyFilesDisplayVendorLab(file)" class="file-details"
                [hidden]="file.upload_by != copyActiveTag || !file.fileExist">
                <td colspan="3">{{fileDetails[file.id]['name']}}</td>
              </tr>
              <tr [hidden]="file.upload_by != copyActiveTag || !file.fileExist">
                <td>{{getFileName(file.path)}}</td>
                <td> {{file.created_at | date:'d-MMM-y HH:mm':'local'}}  </td>
                <td>
                  <input [formControlName]="fi" type="checkbox" [value]="file.id" class="form-check-input" />
                </td>
              </tr> -->
              <div class="row content row-primary mb-2" *ngIf="copyFilesDisplayVendorLab(file)" [hidden]="hideInCopy(file)">
                <div class="col-12  text-start">
                  {{fileDetails[file.id]['name']}}
                </div>
              </div>
              <div class="row content row-primary mb-2" [hidden]="hideInCopy(file)">
                <div class="col-7  text-start">
                  {{getFileName(file.path)}}
                </div>
                <div class="col-3  text-start">
                  {{file.created_at | date:'d-MMM-y HH:mm':'local'}} 
                </div>
                <div class="col-2">
                  <div class="align-items-center badge-empty d-flex justify-content-start mb-2 mt-2">
                    <input [formControlName]="fi" type="checkbox" [value]="file.id" id="checkbox-{{fi}}" class="rounded-checkbox me-2" (change)="checkCheckBoxvalue($event, fi)" />
                    <span> {{ copyForm.get('checkedFiles').at(fi).value ? lang.getText('selected') : lang.getText('select') }}</span>
                  </div>                      
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    <div class="col-12">
      <button class="btn btn-primary claim-accept-button ps-4 pe-4 ms-0 border-radius-42 filter-drop-shadow" (click)="checkClaimed() ? copyFiles() : false">
        {{lang.getText('copy')}}
      </button>
    </div>
  </div>
</ng-container>
</ng-template>

<ng-template #deliverEmailsTemp>
  <div id="deliveryEmailsTempHead" class="modal-header" style="border-bottom: none;">
    <h2 class="modal-title">
      {{lang.getText('deliver_files_to')}}
    </h2>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div id="deliveryEmailsTempBody" class="modal-body pt-0">
    <div class="col-md-12">
      <form [formGroup]="deliveForm" class="col-md-12 no-padding">
        <app-email-meta [(form_data)]="email_meta"></app-email-meta>
        <div class="form-group row mt-3">
          <div class="col-md-3">
            <button class="btn btn-primary" type="button"
              (click)="checkClaimed() ? submitDeliver() : false"
              [disabled]="deliverReqActive"
              >
              {{'deliver' | translate}}
            </button>
          </div>
          <div class="col-md-3 no-padding">
            <div [hidden]="deliverReqActive == false" class="lds-ellipsis cus-lodder"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #claimedPopup>
  <div class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('confirm')}}</h4>
  </div>
  <div class="modal-body">
    <div class="col-sm-12">
      <h6>{{lang.getText('you_cant_edit_this_project')}}</h6>
    </div>
    <div class="do-transfer">
      <button class="btn btn-primary float-end" (click)="claimProject(false)" style="margin-left:10px">
        {{lang.getText('cancel')}}
      </button>
    </div>
    <div class="claim-accept">
      <button class="btn btn-primary float-end" (click)="claimProject(true)">
        {{lang.getText('claim')}}
      </button>
    </div>
  </div>
</ng-template>
