<div [hidden]="!isLoading" class="container-fluid loader">
  <div class="body">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</div>
<header class="main-menu">  
  <app-header></app-header>
</header>
<div *ngIf="lang_ser.is_data_loaded()" class="container-fluid redesign-bg" [ngClass]="{'new-redesign-style': newDesign, 'with-sticky-side-menu': displaySideMenu()}">
  <div class="d-flex">
    <div *ngIf="displaySideMenu()" class="left-col">
      <app-configure-side-menu></app-configure-side-menu>
    </div>
    <div [ngClass]="displaySideMenu() ? 'right-col' : 'col'">
      <router-outlet></router-outlet>
    </div>
  </div>  
</div>

<notifier-container></notifier-container>
<ng-template #notificationTemp let-notificationData="notification">
    <p type="notificationData.type"> {{ notificationData.message | translate }} </p>
</ng-template>
