<ng-template #thisPopup>
    <div class="modal-header">
        <h4 class="modal-title float-start">
        </h4>
        <div *ngIf="loading" style="height:0px">
            <div class="lds-ellipsis" style="left: 100px;">
                <div style="top: 15px;"></div>
                <div style="top: 15px;"></div>
                <div style="top: 15px;"></div>
                <div style="top: 15px;"></div>
            </div>
        </div>
        <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-phrase #phraseCom
            [assignJobs]="true"
            [projectID]="projectID"
            [projectData]="projectData"
            [clientSelected]="clientSelected"
            [vendors]="vendors"
            [phraseJobfilesForm]="phraseJobfilesForm"
            [jobs]="jobs"
            [assignPopupPhraseData]="phraseData"
            [isPhraseProjectCreated]=true
            (closeModal)="closeModal()"
            (reloadProject)="reloadProjectEmit()"
            >
        </app-phrase>
    </div>
</ng-template>
