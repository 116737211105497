import { Component, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PhraseComponent } from '../phrase/phrase.component';

@Component({
  selector: 'app-phrase-assign-jobs',
  templateUrl: './phrase-assign-jobs.component.html',
  styleUrls: ['./phrase-assign-jobs.component.scss']
})
export class PhraseAssignJobsComponent {
  @ViewChild('thisPopup') thisPopup;
  @ViewChild('phraseCom') phraseCom: PhraseComponent;
  projectID
  projectData
  languages
  orderID
  projectTranslations
  clientSelected
  vendors
  phraseData;
  modalRef: BsModalRef;
  loading:boolean = false;
  phraseJobfilesForm: FormGroup;
  @Output() reloadProject: EventEmitter<Object> = new EventEmitter();

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder
  ) {

  }

  openModal(projectID, projectData, phraseData, languages, orderID, clientSelected) {
    this.projectID = projectID;
    this.projectData = projectData;
    this.languages = languages;
    this.orderID = orderID;
    this.projectTranslations = projectData.translations;
    this.clientSelected = clientSelected;
    this.vendors = projectData.vendors;
    this.phraseData = phraseData;

    this.phraseJobfilesForm = this.fb.group({
      jobs: this.fb.array([])
    });
    // console.log(this.phraseJobfilesForm.get('jobs'))

    this.modalRef = this.modalService.show(
      this.thisPopup,
      { class: 'new-redesign-style modal-xl' }
    );
  }
  
  closeModal() {
    this.modalRef.hide();
  }

  get jobs(): FormArray {
    return this.phraseJobfilesForm.get('jobs') as FormArray;
  }

  reloadProjectEmit() {
    this.reloadProject.emit();
  }
}
