<div class="row justify-content-md-center new-redesign-style">
  <div class="col-md-12 mt-3 mb-3 ps-5 pe-5">
    <div class="tab-content row justify-content-md-center">
      <div class="col-md-12 mb-3 ps-5 pe-5">
        <div class="header mt-4 mb-4 d-flex">
          <h2>{{'create_user' | translate }}</h2>
          <div class="client-actions ps-2 ms-2"></div>
        </div>
        <form *ngIf="roles" [formGroup]='userCreateForm' (ngSubmit)="onSubmitUserCreateForm(createUserConfirm)">
          <div class="form-errors">
            <p class="error" *ngFor="let error of errors; let ei = index;">{{error}}</p>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="col-form-label">{{lang.getText('name')}}</label>
              <input type="text" class="form-control" formControlName='name' placeholder="{{lang.getText('enter_name')}}">
            </div>
            <div class="form-group col-md-6">
              <label class="col-form-label">{{lang.getText('email_address3')}}</label>
              <input type="email" class="form-control" formControlName='email' aria-describedby="emailHelp" placeholder="{{lang.getText('enter_email_address2')}}">
            </div>
          </div>
          <h3 class="mt-3 mb-3">{{lang.getText('roles')}}</h3>
          <div class="form-group">
            <div *ngFor="let role of roles; let i = index;" class="form-check form-switch pb-1">
              <input type="radio" 
                    class="form-check-input mr-2" 
                    id="role{{i}}" 
                    formControlName="role_id" 
                    [value]="role.id" 
                    name="role_id" 
                    [checked]="role.id === userCreateForm.get('role_id')?.value">
                <label class="form-check-label font-weight-bold ps-2" [style]="{'color' : 'black'}" for="role{{i}}">
                {{role.name}} 
                &nbsp;<img src="/assets/svg/info-icon.svg" [tooltip]="descriptions[role.code]">
                <!-- <em class="fa fa-info-circle" [style]="{'color' : 'var(--PrimaryBadgeColor)'}" [tooltip]="descriptions[role.code]" placement="right"></em> -->
              </label>
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary mt-2 ms-0 ps-4 pe-4 filter-drop-shadow">{{lang.getText('create')}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ng-template #createUserConfirm>
  <div class="modal-content">
    <div class="modal-header">
    </div>
    <div class="modal-body">
      <p>{{lang.getText('long_text17')}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeModal()">{{lang.getText('cancel')}}</button>
      <button type="button" class="btn btn-primary" (click)="createUser()">{{lang.getText('yes_i_accept_the_billing_increase')}}</button>
    </div>
  </div>
</ng-template> 
