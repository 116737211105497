<form [formGroup]="form" [hidden]="loading" class="email-table">
    <div class="col-md-12">
        <app-email-meta-form-email [emails]="form.get('from')" [label]="'sender_email'"></app-email-meta-form-email>
        <app-email-meta-form-email [emails]="form.get('cc')" [label]="'cc_email'"></app-email-meta-form-email>
        <app-email-meta-form-email [emails]="form.get('to')" [label]="'to_email'"></app-email-meta-form-email>
        <label>{{'subject_line' | translate}}</label>
        <div class="row">
            <div class="col-md-9">
                <input formControlName="subject" class="form-control" type="text" />
            </div>
        </div>
    </div>
</form>
<div *ngIf="loading">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</div>
