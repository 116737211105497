<div class="mt-4">
  <div *ngIf="detailsTFA !== null" class="layout_2fa">
    <div class="row auth d-flex align-items-center">
      <div class="col-md-4 col-label">
        <label class="col-auto col-form-label-h5"
          >{{lang.getText('multi_factor_authentication')}}</label>
      </div>
      <div class="col-md-4">
        <button
          *ngIf="!detailsTFA"
          (click)="generate2Fa(true)"
          class="btn btn-sm btn-primary border-radius-42 ps-4 pe-4"
        >
          {{lang.getText('enable')}} <i class="fa fa-lock ms-2" aria-hidden="true"></i>
        </button>
        <button
          *ngIf="detailsTFA"
          (click)="generate2Fa(false)"
          class="btn btn-secondary border-radius-42 ps-4 pe-4"
        >
          {{lang.getText('disable')}} <i class="fa fa-lock ms-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <br />
    <form *ngIf="authCodeURL" autocomplete="off">
      <div class="row">
        <div class="col-md-1">
        </div>
        <div class="col-md-3 me-2 qr-code">
        <div class="form-group">
          <div class="col-12 pe-0">
            <img [src]="authCodeURL" alt="authentication"/>
          </div>
        </div>
      </div>
      <div class="col-md-6 qr ms-2">
          <p>{{lang.getText('scan_qr_code_with_google_autheticator')}} </p>
        <div class="form-group">
          <label for="verify_code">{{lang.getText('verification_code')}}</label>
          <input
            type="text"
            name="verify_code"
            class="form-control mt-3 mb-3"
            [(ngModel)]="verificationCode"
          />
        </div>
        <div *ngIf="verifyCodeInval" class="alert alert-danger" role="alert">
          {{lang.getText('invalid_code')}}
        </div>
        <div class="form-group">
          <button (click)="submitVerificationCode()" class="btn btn-primary border-radius-42 ps-4 pe-4">
            {{lang.getText('verify')}}
          </button>
        </div>
      </div>
    </div>
    </form>
  </div>
</div>
