import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { UntypedFormBuilder, FormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrls: ['./users-list-page.component.scss']
})
export class UsersListPageComponent implements OnInit {
  private apiURL = '';
  users;
  totalUsers;
  clientsListForm;
  selectedClientCon;
  lang;
  hasCreateUserAccess;
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private bsModalService: BsModalService,
    private fb: UntypedFormBuilder,
    public language: LanguageService
    ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
    this.clientsListForm = this.fb.group({
      selectedClients: new UntypedFormArray([]),
    });
    this.selectedClientCon = this.clientsListForm.get('selectedClients') as UntypedFormArray;
  }

  ngOnInit() {
    this.hasCreateUserAccess = this.Jwtwis.hasPermission('create-user');
    this.getUsers();
    this.getClients();
  }

  delete(id){
    if ( ! window.confirm(this.lang.getText('are_you_sure_you_want_to_delete')) ) {
        return;
    }
    let formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}user/delete/${id}`, formData).subscribe(
      data => this.ngOnInit(),
      error => this.handleError(error)
    );
  }

  getUsers() {
    this.http.get(`${this.apiURL}users`).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  clients = [];
  getClients() {
    this.http.post(`${this.apiURL}client`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.clients = data['result'];
      },
      error => this.handleError(error)
    );
  }

  clientsOfUser = [];
  selectedUser;
  getClientsOfUser(id) {
    this.selectedUser = id;
    let formData;
    formData = this.Token.getAPITokenData();
    formData['userId'] = id;
    this.http.post(`${this.apiURL}clients-of-user`, formData).subscribe(
      data => {
        this.clientsOfUser = [];
        //this.selectedClientCon = new FormArray([]);
        while (this.selectedClientCon.length !== 0) {
          this.selectedClientCon.removeAt(0)
        }
        data['result'].map(val => {
          this.selectedClientCon.push(new UntypedFormControl(false));
        });
        this.clientsOfUser = data['result'];
      },
      error => this.handleError(error)
    );
  }

  selectedClient;
  addClientToUser() {
    if (this.selectedClient == null) return;
    let id = this.selectedUser;
    let formData = this.Token.getAPITokenData();
    formData['userId'] = id;
    formData['clientId'] = this.selectedClient;
    this.http.post(`${this.apiURL}add-client-to-user`, formData).subscribe(
      data => {
        this.getClientsOfUser(id)
      },
      error => this.handleError(error)
    );
  }

  removeClientFormUser() {
    let formData = this.Token.getAPITokenData();
    let id = this.selectedUser;
    formData['clientsId'] = this.clientsListForm.value.selectedClients
      .map((v, i) => v ? this.clientsOfUser[i].id : null )
      .filter(v => v !== null);;
    formData['userId'] = id;
    this.http.post(`${this.apiURL}remove-clients-from-user`, formData).subscribe(
      data => {
        this.getClientsOfUser(id);
      },
      error => this.handleError(error)
    );
  }

  bsModalRef: BsModalRef;
  openModalManageClientsOf(template: TemplateRef<any>, id, roleId) {
    this.getClientsOfUser(id)
    if (roleId != 3)
      return
    this.bsModalRef = this.bsModalService.show(
      template,
      Object.assign({}, { class: 'modal-lg new-redesign-style' })
    );
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  handleResponse(data) {
    this.users = data.result;
    this.totalUsers = data.total;
    // console.log(this.users);
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }
}
