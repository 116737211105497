import { Component, EventEmitter, Injector, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TokenService } from 'src/app/services/token.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { HelperService } from 'src/app/services/helper.service';
import { LanguageService } from 'src/app/services/language.service';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { ProjectService } from 'src/app/services/project.service';
import { PaginationComponent } from 'src/app/modules/shared/components/pagination/pagination.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslationSnapshotComponent } from 'src/app/modules/shared/components/translation-snapshot/translation-snapshot.component';

@Component({
  selector: 'app-project-client-language-translations',
  templateUrl: './project-client-language-translations.component.html',
  styleUrls: [
    './project-client-language-translations.component.scss',
    '../../project-main-tab-page.component.scss',
    '../../project-create-page.component.scss',
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class ProjectClientLanguageTranslationsComponent implements OnInit {
  lang;
  apiURL;
  loading = false;
  allowLinkingBathch = true;
  errors;
  saveProjectMsg = false;
  formEventStop = { emitEvent: false };
  display = 'none';
  status = '';
  message = '';

  @Input() projectEditDis;
  @Input() projectId;
  @Input() type;
  @Input() translationsFA;
  @Input() trans;
  @Input() trasnlation_pagination_data;
  @Input() wordMatchesA;
  @Input() wordMatchesB;
  @Input() projectEditForm;
  @Input() deliveryBatchesFA;
  @Input() checkClaimed;
  @Input() checkXTMData;
  @Input() checkPrhaseData;
  @Input() checkTradosData;
  @Input() showProjectProgress;
  @Input() showProjectJobProgress;
  @Input() languages;
  @Input() unsavedProject;
  @Input() quoteGoToLastPage: boolean;
  @Input() projectCurrencyName;
  @Output() unsavedProjectChange = new EventEmitter<Boolean>();
  @Output() mapProjectClientTransEvent: EventEmitter<any> = new EventEmitter();
  @Output() calculateTotalCostEvent: EventEmitter<any> = new EventEmitter();
  @Output() updateCATEvent: EventEmitter<any> = new EventEmitter();
  @Output() openImportCATModalEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteClientTranEvent: EventEmitter<any> = new EventEmitter();
  @Output() onSourceLanguageChangeEvent: EventEmitter<any> = new EventEmitter();
  @Input() translationsFAOrignal;
  @Output() copyThisResTranEvent: EventEmitter<any> = new EventEmitter();
  @Input() client_id;
  currency_id;
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    public helperSer: HelperService,
    public language: LanguageService,
    private modal_ser: BsModalService,
    private projectSer: ProjectService,
  ) {
    this.lang = language;
    this.apiURL = this.injector.get('API_URL');
  }

  ngOnInit() {
    this.currency_id = this.projectEditForm.get('currency_id').value;
    this.getPageOfQuoteLangTrans();
  }

  ngOnChanges() {
    if (this.quoteGoToLastPage) {
      this.getPageOfQuoteLangTrans();
    }
  }

  @ViewChild(PaginationComponent) pagination_com: PaginationComponent;
  paginateQuoteLangCurrentPage = 1;
  getPageOfQuoteLangTrans(page_no = null) {
    if ( page_no == null ) {
      page_no = this.paginateQuoteLangCurrentPage;
    }
    this.paginateQuoteLangCurrentPage = page_no;

    this.trasnlation_pagination_data.quote_page_loading = true;
    let formData = this.Token.getAPITokenData();
    formData['projectId'] = this.projectId;
    formData['page'] = page_no;
    formData['per_page'] = 5;
    this.http.post(`${this.apiURL}project/quoting-translations`, formData).subscribe(
      data => {
        this.pagination_com.generate(data['totalTrasnlationPages'], page_no)
        let tempUnsavedProject = this.unsavedProject;
        this.translationsFA.clear();
        this.trasnlation_pagination_data.quote_total_pages = data['totalTrasnlationPages'];
        this.trasnlation_pagination_data.quote_page_loading = false;
        this.trasnlation_pagination_data.quote_page = page_no;
        this.mapProjectClientTransEvent.emit({ translations: data['translations'], catAnalysis: data['catAnalysis'] });
        this.unsavedProjectChange.emit(tempUnsavedProject);
      },
      error => {
        this.loading = false;
        if (error.status == 401) {
          this.handleError(error)
        } else {
          this.openModalDialog('failed', error.error.error);
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
        }
      }
    );
  }

  updateCATtoAll(ti) {
    if ( ! confirm(this.lang.getText('are_you_sure')) ) {
      return;
    }
    let project_translate_id = this.translationsFA.at(ti).get('project_translate_id').value
    let formData = {
      token: this.Token.get(),
      project_id: this.projectId,
      project_translate_id: project_translate_id
    };

    this.http.post(`${this.apiURL}project/translation/copy-cat/client`, formData).subscribe(
      data => {
        if ( data['success'] ) {
          this.getPageOfQuoteLangTrans();
        }
      },
      error => {
        this.handleError(error)
      }
    );
  }

  updateCAT(type, translation, index) {
    this.updateCATEvent.emit({
      type: type,
      translation: translation,
      index: index
    })

    translation.controls['source_lang_id'].disable();
    translation.controls['target_lang_id'].disable();
    translation.controls['word_rate'].disable();
    translation.controls['comment'].disable();
  }

  openImportCATModal(vendor, ti) {
    this.openImportCATModalEvent.emit({
      vendor: vendor,
      ti: ti
    })
  }

  openModalDialog(status,message){
    this.display='block';
    this.status = status;
    this.message = message;
  }
  closeModalDialog(){
    this.display='none';
  }
  nonNumericValPress(event){
    if (event.key.match(/[^$,.\d]/)){
      alert(this.lang.getText('long_text11'));
      return false;
    }
  }

  /** 
   * commented the code because now we are directly taking the batch delivery date
   * from translation
   * 
   * in future also needs to delete this code
  */
  // getLinkedBatches(serviceType, id){
  //   let batches = [];
  //   this.deliveryBatchesFA.value.forEach(batch => {
  //     if ( batch.service_type_to == serviceType && batch.service_type_id == id ) {
  //       batches.push(batch);
  //     }
  //   });
  //   return batches;
  // }

  deleteClientTran(trans, index) {
    if ( !confirm(this.lang.getText('are_you_sure_you_want_to_delete_this_item')) )
      return;
    if (trans.value.project_translate_id != null && trans.value.project_translate_id != '') {
      this.projectSer.deleteTranslation(this.projectId, trans.value.project_translate_id).subscribe(
        data => {
          this.deleteClientTranFromArr(trans, index);
        },
        error => {
          this.handleError(error);
        }
      );
    } else {
      this.deleteClientTranFromArr(trans, index);
    }
  }

  deleteClientTranFromArr(trans, index) {
    trans.patchValue({ delete: 'yes' }, this.formEventStop);
    let trans_orignal = this.translationsFAOrignal.controls[(this.trasnlation_pagination_data.quote_per_page * (this.trasnlation_pagination_data.quote_page - 1)) + index]
    // trans_orignal.patchValue({ delete: 'yes' }, this.formEventStop);
    this.translationsFAOrignal.removeAt((this.trasnlation_pagination_data.quote_per_page * (this.trasnlation_pagination_data.quote_page - 1)) + index)
    this.deleteClientTranEvent.emit();
    this.projectEditForm.get('translations').removeAt(index);
    this.projectEditForm.get('deliveryBatches').controls.map(batch => {
      if ( batch.getRawValue().service_type_to == 'translation' 
        && batch.getRawValue().service_type_id == trans.getRawValue().project_translate_id ) {
          batch.patchValue({
            service_type_to: null,
            service_type_id: null,
            // description: ''
          });
      }
    });
  }

  displayThisResTran(i){
    this.showProjectProgress.display = false;
    this.translationsFA.controls.map((tranFb, ti) => {
      let display = false;
      if (i == ti) {
        display = true;
        tranFb.patchValue({ display: display }, this.formEventStop);
        this.enable_language_input(tranFb);
        this.enable_word_rate_input(tranFb);
        this.enable_comment_input(tranFb);
      }
    });
  }

  onSourceLanguageChange() {
    this.onSourceLanguageChangeEvent.emit();
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
    this.errors = error.error.error;
    this.saveProjectMsg = false;
  }

  nonNumericValPressDecimal(event){
    if (event.key.match(/[^$,.\d]/)){
      alert(this.lang.getText('long_text11_decimal'));
      return false;
    }
  }

  diaplayMeta(meta) {
    return [
      'sap_xtm_job_id',
      'sap_xtm_job_status_percent',
      'sap_xtm_job_steps',
      'sap_xtm_job_status',
      'sap_xtm_job_file_name',
      'sap_xtm_job_words_to_be_done',
    ].includes(meta.meta_key);
  }

  getInitialWordsCount(trans) {
    let translation_meta_values = trans.getRawValue().translation_meta_values
    let count = null;
    if ( trans.getRawValue().project_translate_id == '' || (translation_meta_values == null || translation_meta_values.length < 1) ) {
      return '';
    }

    translation_meta_values.forEach((meta) => {
      if ( meta.meta_key == 'sap_xtm_job_words_at_start' ) {
        count = meta.meta_value;
      }
    });
    if (parseInt(count)) {
      return '('+count+')';
    }
  }

  markTrasnlationFinanciallyClosed(checked, translation) {
    if (!this.checkXTMData) {
      this.changeTrasnlationtoFinanciallyClosed(translation, checked);
    } else {
      if (checked) {
        if (translation.get('project_finances_id').value == null) {
          if (!confirm(this.lang.getText('not_part_of_any_invoice_warning'))) {
            this.changeTrasnlationtoFinanciallyClosed(translation, false);
          }
        } else if (!confirm(this.lang.getText('are_you_sure_this_cannot_be_undone'))) {
          this.changeTrasnlationtoFinanciallyClosed(translation, false);
        }
      } else { // unchecked FinanciallyClosed checkbox
        alert(this.lang.getText('this_cannot_be_undone'));
        this.changeTrasnlationtoFinanciallyClosed(translation, true);
      }
    }
  }
  changeTrasnlationtoFinanciallyClosed(translation, value) {
    translation.patchValue({
      "financially_closed": value
    });
  }

  enable_comment_input(tran) {
    if (tran.value.display && this.checkTradosData && !this.checkXTMData && !this.checkPrhaseData) {
      tran.controls['comment'].enable();
    }
  }

  enable_word_rate_input(tran) {
    if (tran.value.display && !tran.value.financially_closed && this.checkTradosData && !this.checkXTMData && !this.checkPrhaseData) {
      tran.controls['word_rate'].enable();
    }
  }

  enable_language_input(tran) {
    if (this.checkTradosData && !this.checkXTMData && !this.checkPrhaseData && tran.value.display) {
      tran.controls['source_lang_id'].enable();
      tran.controls['target_lang_id'].enable();
    } 
  }

  copyThisResTran(index){
    this.copyThisResTranEvent.emit({
      index: index
    }) 
  }

  getLanguageName(language_id){
    var found = this.languages.find(function(item) { return item.id == language_id; });
    return found.name;
  }
  
  @ViewChild('select_translation_rate_modal') select_translation_rate_modal: TemplateRef<any>;
  modal_active
  selected_tr_for_rate
  select_rate(translation_fg) {
    this.selected_tr_for_rate = translation_fg;
    this.modal_active = this.modal_ser.show(
      this.select_translation_rate_modal,
      { class: 'modal-lg', backdrop: 'static' }
    );
  }

  apply_translation_rate(values) {
    this.selected_tr_for_rate.patchValue({
      source_lang_id: values.source_lang_id,
      target_lang_id: values.target_lang_id,
      word_rate: values.word_rate
    })
    this.close_modal();
  }

  close_modal() {
    this.modal_active.hide();
  }

  getTranslationSnapshot(i) {
    let data = {
      token: this.Token.get(),
      project_id: this.projectId,
    };
    let projectTranslateId = this.translationsFA.at(i).get('project_translate_id').value
    this.http.post(`${this.apiURL}xtm/get-translation-snapshot/${projectTranslateId}`, data).subscribe(
      res => {
        this.translationSnapshotModel(res);
      },
      error => {
        if(error.status === 400)  
          this.openModalDialog('failed',this.lang.getText('api_error_bad_request'));
        else if (error.status === 404) {
          this.openModalDialog('failed',error.error.error);
        } else{  
          this.openModalDialog('failed',error.error.error);
        }
        // this.handleError(error)
      });
  }

  @ViewChild('TranslationSnapshotComponent') TranslationSnapshotComponent: TranslationSnapshotComponent;
  translationSnapshotModelRef
  translationSnapshotModel(res) {
    const initialState = { 
      data: res, 
      wordMatchesA: this.wordMatchesA, 
      wordMatchesB: this.wordMatchesB, 
      languages: this.languages,
      projectCurrencyName: this.projectCurrencyName
    };
    this.translationSnapshotModelRef = this.modal_ser.show(
      TranslationSnapshotComponent,
      { class: 'new-redesign-style modal-xl', backdrop: 'static',  initialState: initialState }
    );
  }
}
