import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { configurationPageConfig } from 'src/app/configs/configuration.page.config';
import { JwtauthService } from 'src/app/services/jwtauth.service';

@Component({
  selector: 'app-configure-side-menu',
  templateUrl: './configure-side-menu.component.html',
  styleUrls: ['./configure-side-menu.component.scss']
})
export class ConfigureSideMenuComponent {
  activeMenu: string | null = null;
  conf_page;
  currentRouteURI: string = '';

  constructor(
    private authSer: JwtauthService,
    private router: Router
  ) {
    //
  }
  
  ngOnInit(): void {
    this.conf_page = new configurationPageConfig();
    this.router.events.subscribe(() => {
      this.currentRouteURI = this.router.url;
    });
  }
  get_menus(group) {
    return this.conf_page.get_group(group).menus;
  }

  toggleMenu(menu: string): void {
    this.activeMenu = this.activeMenu === menu ? null : menu;
  }

  isSubmenuActive(uri: string, groupMenu:string): boolean {
    return this.router.url.includes(uri);
  }
  
  hasAPIAccess() {
    return this.authSer.hasAPIAccess();
  }

  apply_new_design() {
    console.log(this.currentRouteURI)
    var enableDesign: boolean = false;
    var newDesignURIs = [ '/configure/company-settings', '/configure/currency', '/status-bar-display-settings', '/language', '/add-ser-unit', '/supplier-services'];
    newDesignURIs.forEach(uri => {
      if ( ! enableDesign && this.currentRouteURI.includes(uri) ) {
        enableDesign = true;
      }
    });
    return enableDesign;
  }
}
