<div class="modal-header">
    <h5 class="modal-title">{{ lang.getText('translation_snapshot_data') }}</h5>
    <button type="button" class="close" (click)="bsModalRef.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row" *ngFor="let trans of data.data; let i = index">
        <div class="row">
            <div class="col-md-2 col-sm-12">
                <span class="inner-label">{{ lang.getText('source') }} {{ lang.getText('language') }}</span>
                <select class="form-control" [(ngModel)]="trans.source_lang_id" disabled>
                    <option *ngFor="let language of languages" [value]="language.id">
                        {{ language.name }}
                    </option>
                </select>
            </div>
            <div class="col-md-2 col-sm-12">
                <span class="inner-label">{{ lang.getText('target') }} {{ lang.getText('language') }}</span>
                <select class="form-control" [(ngModel)]="trans.target_lang_id" disabled>
                    <option *ngFor="let language of languages" [value]="language.id">
                        {{ language.name }}
                    </option>
                </select>
            </div>
            <div class="col-md-2 col-sm-12">
                <span class="inner-label">{{ lang.getText('word_rate') }}</span>
                <input type="number" class="form-control" [(ngModel)]="trans.word_rate" readonly>
            </div>
            <div class="col-md-2 col-sm-12">
                <span class="inner-label">{{ lang.getText('description') }} {{ lang.getText('optional') }}</span>
                <input type="text" class="form-control" [(ngModel)]="trans.description" readonly>
            </div>
            <div class="col-md-3 col-sm-12 align-content-end">
                <div class="row md_rw sub-total-col badge-empty" style="margin: 0px;">
                    <div class="nospace text-center">
                        {{ lang.getText('subtotal') }}&nbsp;:&nbsp;
                        <span class="text-nowrap">
                            {{ trans.amount | myCurrency: projectCurrencyName }}
                        </span>
                        <input readonly type="hidden" min="0" class="form-control font-weight-bold">
                    </div>
                </div>
            </div>
        </div>

        <div class="single_part_main cat_analysis mt-4">
            <div class="single_part_inner">
                <div class="main_head">
                    <p>{{lang.getText('cat_analysis_combination')}}</p>
                </div>

                <div class="row nospace">
                    <div class="row nospace">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 nospace">
                            <div class="row">
                                <div class="col-md-3 col-sm-3"></div>
                                <div class="col-md-3 col-sm-3 text-center">
                                    <p class="title">{{lang.getText('words')}}</p>
                                </div>
                                <div class="col-md-3 col-sm-3 text-center">
                                    <p class="title">{{lang.getText('unit_rate')}}</p>
                                </div>
                                <div class="col-md-3 col-sm-3 text-center">
                                    <p class="title">{{lang.getText('subtotal')}}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3" *ngFor="let wordMatch of wordMatchesA; let i = index">
                                <div class="row"
                                    *ngFor="let transWordMatch of trans.xtm_word_rate_match_snapshot; let j = index">
                                    <ng-container *ngIf="transWordMatch.word_match_id == wordMatch.id">
                                        <div class="col-md-3 col-sm-3 nospace badge-secondary">
                                            <p>{{wordMatch.name}}</p>
                                        </div>
                                        <div class="col-md-3 col-sm-3">
                                            <input appShadowCheck type="number" min="0"
                                                [value]="transWordMatch.word_count"
                                                class="text-center form-control words" readonly>
                                        </div>
                                        <div class="col-md-3 col-sm-3">
                                            <input appShadowCheck type="number" min="0" readonly
                                                [value]="transWordMatch.rate"
                                                class="text-center form-control unit_rate">
                                        </div>
                                        <div class="col-md-3 col-sm-3">
                                            <input appShadowCheck type="number" min="0" readonly
                                                [value]="subTotal(transWordMatch.word_count, transWordMatch.rate)"
                                                class="text-center form-control sub_total">
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 nospace">
                            <div class="row">
                                <div class="col-md-3 col-sm-3"></div>
                                <div class="col-md-3 col-sm-3 text-center">
                                    <p class="title">{{lang.getText('words')}}</p>
                                </div>
                                <div class="col-md-3 col-sm-3 text-center">
                                    <p class="title">{{lang.getText('unit_rate')}}</p>
                                </div>
                                <div class="col-md-3 col-sm-3 text-center">
                                    <p class="title">{{lang.getText('subtotal')}}</p>
                                </div>
                            </div>
                            <div class="col-12 mb-3" *ngFor="let wordMatch of wordMatchesB; let i = index">
                                <div class="row"
                                    *ngFor="let transWordMatch of trans.xtm_word_rate_match_snapshot; let j = index">
                                    <ng-container *ngIf="transWordMatch.word_match_id == wordMatch.id">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-3 nospace badge-secondary">
                                                <p>{{wordMatch.name}}</p>
                                            </div>
                                            <div class="col-md-3 col-sm-3">
                                                <input appShadowCheck type="number" min="0"
                                                    [value]="transWordMatch.word_count"
                                                    class="text-center form-control words" readonly>
                                            </div>
                                            <div class="col-md-3 col-sm-3">
                                                <input appShadowCheck type="number" min="0" readonly
                                                    [value]="transWordMatch.rate"
                                                    class="text-center form-control unit_rate">
                                            </div>
                                            <div class="col-md-3 col-sm-3">
                                                <input appShadowCheck type="number" min="0" readonly
                                                    [value]="subTotal(transWordMatch.word_count, transWordMatch.rate)"
                                                    class="text-center form-control sub_total">
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pe-3">
                        <div class="col-sm-6 p-0">
                        </div>
                        <div class="col-sm-6 total_count_section p-0">
                            <div class="d-flex align-items-center gap-2 text-center d-flex align-items-center gap-2 text-center justify-content-around"
                                style="margin-top: 15px;">
                                <div class="col-sm-6 row badge-primary">
                                    <div>
                                        {{lang.getText('total_words')}}&nbsp;{{':'}}&nbsp;
                                        {{ calculateTotalWordsCount()}}
                                        <input readonly type="hidden" class="form-control">
                                    </div>
                                </div>
                                <div class="col-sm-6 row badge-primary">
                                    <div>
                                        {{lang.getText('weighed_words')}}&nbsp;{{':'}}&nbsp;
                                        {{ calculateWordsWeighted() }}
                                        <input readonly type="hidden" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
