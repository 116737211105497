<div class="row justify-content-md-center new-redesign-style">
  <div class="col-md-12 mt-3 mb-3 ps-5 pe-5">
    <div class="tab-content row justify-content-md-center">
      <div class="col-md-12 mb-3 ps-5 pe-5">
        <div class="header mt-4 mb-4 d-flex">
          <h2>{{'vendor' | translate}} {{'settings' | translate}}</h2>
          <div class="client-actions ps-2 ms-2"></div>
        </div>
        <form *ngIf="displayForm" [formGroup]='clientForm' (ngSubmit)="onSubmitClientForm()">
          <div class="form-errors">
            <p class="error" *ngFor="let error of errors; let ei = index;">{{error}}</p>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">{{lang.getText('name')}}</label>
                <input type="text" class="form-control" formControlName='name' placeholder="{{lang.getText('enter_name')}}">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">{{lang.getText('email_address')}}</label>
                <input type="email" class="form-control" formControlName='email' placeholder="{{lang.getText('enter_email_address2')}}">
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">{{lang.getText('accounting_software_reference_id')}}</label>
                <input type="text" class="form-control" formControlName='av_ref_id' placeholder="{{lang.getText('enter_accounting_software_reference_id')}}">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">{{lang.getText('address')}}</label>
                <input type="text" class="form-control" formControlName='address'>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-check ps-0 pb-1">
                <div class="form-check form-switch pe-2">
                  <label class="d-flex align-items-center">
                    <input class="form-check-input me-2" formControlName="send_reset_email" value="1" type="checkbox">
                    {{lang.getText('send_password_reset_link')}}
                  </label>
                </div>
              </div>
              <div class="form-check ps-0">
                <div class="form-check form-switch pe-2">
                  <label class="d-flex align-items-center">
                    <input class="form-check-input me-2" formControlName="mfa" value="1" type="checkbox" id="two_factor_id">
                    {{lang.getText('two_factor_authentication')}}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="single_part_main cat_analysis">
                  <div class="main_head">
                    <label class="col-form-label mb-2 mt-2">{{lang.getText('enable')}} {{lang.getText('iso_documents')}}</label>
                  </div>
                  <div class="row" formArrayName="isoDocs">
                    <div class="col-md-12 col-sm-12 nospace">
                      <div
                        *ngFor="let doc of isoDocsFa['controls']; let di = index;"
                        [formGroupName]="di"
                        >
                        <div class="form-check form-switch pe-2 pb-1">
                          <label class="d-flex align-items-center">
                            <input class="form-check-input me-2" formControlName="selected" [value]="doc.value.id" type="checkbox">
                            {{doc.value.title}}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button *ngIf="clientId" type="submit" class="btn btn-primary mt-3">{{lang.getText('update')}}</button>
          <button *ngIf="!clientId" type="submit" class="btn btn-primary mt-3">{{lang.getText('create')}}</button>
        </form>
      </div>
    </div>
  </div>
</div>
