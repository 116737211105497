import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, ViewChild, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, UntypedFormBuilder,FormArray,FormControl, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { LanguageService } from '../../../services/language.service';
import { HelperService } from 'src/app/services/helper.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-create-cat',
  templateUrl: './create-cat.component.html',
  styleUrls: [
    './create-cat.component.scss',
    '../../project-main-tab-page.component.scss',
    '../../project-create-page.component.scss' 
  ]
})
export class CreateCatComponent implements OnInit{
  apiURL = env['API_URL'];
  lang;
  languages = [];
  projectData = [];
  projectID;
  orderID;

  connectors = [
    { id: 1, name: "Trados Team/Enterprise", disabled:false },
    { id: 2, name: "XTM", disabled:false },
    { id: 3, name: "Phrase", disabled:false },
  ];
  sources = [];
  targets = [];

  tradosFolders = [];
  tradosFileProcessingConfigs = [];
  tradosTranslationEngines = [];
  tradosWorkflows = [];
  tradosTemplates = [];
  phraseTemplates = [];
  selectedPraseTemplate: string = '';
  selectedTemplate: string = '';
  selectedLocation: string = '';
  selectedFileType: string = '';
  selectedTranslationEngine: string = '';
  selectedWorkflow: string = '';
  selectedDueDate = '';
  selectedDueTime =  new Date();
  xtmSegmentLockingRule: string = '';
  xtmWorkflow;
  tag: string = 'quote-files';
  // selectedRole: string = 'translatable';
  bsConfig = {
    dateInputFormat: 'DD/MM/Y',
    rangeInputFormat: 'DD/MM/Y',
    minDate : new Date(new Date().setDate(new Date().getDate()))
  };
  bsTimeConfig = {
    withTimepicker: true,
    dateInputFormat: 'HH:mm:ss',
    minTime: new Date()
  };

  connectionId = null;
  phraseConnectionID = null;
  xtmWorkflows: any = [];
  phraseTransaltionMemories: any[] = [];
  dropdownOpen = false;
  languageDropdownOpen: any[] = [];
  phraseJobfilesForm: FormGroup;
  phrase_pre_translate = false;
  isXTMProjectCreated: boolean = false;
  isXTMProjectStarted: boolean = false;
  isXTMFilesAdded: boolean = false;
  isXTMFilesAddedButtonDisabled: boolean = false;
  
  constructor(
    private modalService: BsModalService,
    private http: HttpClient,
    private token: TokenService,
    private jwtAuth: JwtauthService,
    public language: LanguageService,
    private fb: UntypedFormBuilder,
    private helperSer: HelperService
  ) {
    this.lang = language;
    this.phraseJobfilesForm = this.fb.group({
      jobs: this.fb.array([])
    });
  }

  masterSelected:boolean;
  checklist:any;
  checkedList:any;
  phraseMasterSelected:boolean;
  phraseChecklist:any;
  phraseCheckedList:any;
  currentConnector = 0;
  segmentLockingRules = [
    "DISABLED",
    "ANY_100_MATCH",
    "APPROVED_100_MATCH",
    "XLIFFDOC_TRANSLATED",
    "XLIFFDOC_PROOFED",
    "XLIFFDOC_VALIDATED"
  ];
  clientSelected;

  vendors:any = [];
  selectedVendor : any;

  isPhraseProjectCreated = false;
  isJobProjectCreated = false;
  phraseProjectData = [];
  phraseProjectId;
  combinedDueDateTime;
  @Output() showLoader: EventEmitter<Object> = new EventEmitter();
  
  ngOnInit() {
    // return false;
  }

  getTradosTemplates(location_id) {
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-templates/${location_id}`,data).subscribe(
      data => {
        let templates = data['result']['items'];
        this.tradosTemplates = templates;
      },
      error => {
        // console.log('error',error);  
      }
    );
  }

  no_api_access_msg = "";
  getTradosFolders() {
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-folders`,data).subscribe(
      data => {
        if(data['lang_key'] != undefined && data['lang_key'] == "no_active_subscription_for_the_TRADOS"){
          this.no_api_access_msg = data['lang_key'];
          return;
        }
        let folders = data['result']['items'];
        let options = [];
        folders.forEach((folder)=>{
          if(!folder.hasParent){
            options.push({
              id: folder.id,
              name: "Root",
              depth: 1
            })
          }
          else{
            options.push({
              id: folder.id,
              name: this.extractPath(folder.path) + folder.name,
              depth: folder.path.length
            })
          }
        });
        options.sort((a, b) => a.depth - b.depth)
        this.tradosFolders = options;
      },
      error => {
        // console.log('error',error);  
        // this.handleError(error)
      }
    );
  }

  loading = false;
  onChangeTemplate(template_id){
    this.loading = true;
    // this.tradosFileProcessingConfigs = [];
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-template-related-data/${template_id}`,data).subscribe(
      data => {
        this.loading = false;
        this.tradosFolders['items'] = [data['result']['location']];
        this.tradosFileProcessingConfigs['items'] = [data['result']['fileProcessingConfiguration']];
        this.tradosTranslationEngines['items'] = [data['result']['translationEngine']];
        this.tradosWorkflows['items'] = [data['result']['workflow']];
        this.selectedLocation = data['result']['location']['id'];
        this.selectedFileType = data['result']['fileProcessingConfiguration']['id'];
        this.selectedTranslationEngine = data['result']['translationEngine']['id'];
        this.selectedWorkflow = data['result']['workflow']['id'];
      },
      error => {
        this.loading = false;
        // console.log('error',error);  
        // this.handleError(error)
      }
    );
    // this.selectedFileType = '';
    // this.selectedTranslationEngine = '';
    // this.selectedWorkflow = '';
    // this.getTradosFileProcessingConfigs(location_id);
    // this.getTradosTranslationEngine(location_id);
    // this.getTradosWorkflow(location_id);
  }

  onChangeLocation(location_id){
    this.selectedFileType = '';
    this.selectedTranslationEngine = '';
    this.selectedWorkflow = '';
    this.getTradosTemplates(location_id);
    this.getTradosFileProcessingConfigs(location_id);
    this.getTradosTranslationEngine(location_id);
    this.getTradosWorkflow(location_id);
  }

  getTradosFileProcessingConfigs(location_id) {
    this.loading = true;
    this.tradosFileProcessingConfigs = [];
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-file-processing-config/${location_id}`,data).subscribe(
      data => {
        this.loading = false;
        this.tradosFileProcessingConfigs = data['result'];
      },
      error => {
        this.loading = false;
        console.log('error',error);  
        // this.handleError(error)
      }
    );
  }

  getTradosTranslationEngine(location_id) {
    this.loading = true;
    this.tradosTranslationEngines = [];
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-translation-engine/${location_id}`,data).subscribe(
      data => {
        this.loading = false;
        this.tradosTranslationEngines = data['result'];
      },
      error => {
        this.loading = false;
        console.log('error',error);  
        // this.handleError(error)
      }
    );
  }

  getTradosWorkflow(location_id) {
    this.loading = true;
    this.tradosWorkflows = [];
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-workflow/${location_id}`,data).subscribe(
      data => {
        this.loading = false;
        this.tradosWorkflows = data['result'];
      },
      error => {
        this.loading = false;
        console.log('error',error);  
        // this.handleError(error)
      }
    );
  }

  @ViewChild('createCATprojectPopup') createCATprojectPopup;
  @ViewChild('sendAdditionalFilesPopup') sendAdditionalFilesPopup;
  modalRef: BsModalRef;
  projectTranslations;
  openModal(projectID, projectData, languages, orderID, clientSelected) {
    this.projectID = projectID;
    this.projectData = projectData;
    this.languages = languages;
    this.orderID = orderID;
    this.projectTranslations = projectData.translations;
    this.clientSelected = clientSelected;
    this.vendors = projectData.vendors;
    // this.setData();
    // this.getTradosTemplates();
    // this.getPhraseTemplates();
    // this.getTradosFolders();
    this.getFiles();
    this.modalRef = this.modalService.show(
      this.createCATprojectPopup,
      { class: 'new-redesign-style modal-xl' }
    );
  }

  openModalFilesOnly(projectID, projectData, languages, orderID, clientSelected, sendAdditionalFiles = false) {
    this.projectID = projectID;
    this.projectData = projectData;
    this.languages = languages;
    this.orderID = orderID;
    if(projectData.trados_data?.trados_project_id?.meta_value) {
      this.currentConnector = 1;
    } else if(projectData.xtm_data?.xtm_project_id) {
      this.currentConnector = 2;
      this.isXTMFilesAddedButtonDisabled = sendAdditionalFiles;
    }
    this.clientSelected = clientSelected;
    if(this.currentConnector == 2){
      this.setConnectionId();
      this.setData();
    }
    
    // this.getTradosTemplates();
    // this.getTradosFolders();
    this.getFiles();
    this.modalRef = this.modalService.show(
      this.sendAdditionalFilesPopup,
      { class: 'new-redesign-style modal-lg' }
    );
  }
  closeModal() {
    this.checklist = [];
    this.checkedList = [];
    // this.phraseChecklist = [];
    this.phraseCheckedList = [];
    this.checklistFiles = [];
    this.checkedListFiles = [];
    this.isPhraseProjectCreated = false;
    this.phraseProjectData = [];
    if (this.currentConnector != 1) { // for Trados project keeping current connector
      this.currentConnector = 0; // this value needed for start project function
    }
    this.xtmWorkflow = '';
    this.selectedDueDate = '';
    this.xtmSegmentLockingRule = '';
    this.selectedVendor = '';
    this.filesSelected = false;
    this.selectedDueTime = new Date();
    if (this.currentConnector == 3) {
      this.phraseMasterSelected = false;
      this.phrase_pre_translate = false;
      this.selectedPraseTemplate = '';
      this.phraseTransaltionMemories = [];
      for (var i = 0; i < this.phraseChecklist.length; i++) {
        this.phraseChecklist[i].isSelected = false;
      } 
      this.phraseJobfilesForm.reset();
      (this.phraseJobfilesForm.get('jobs') as FormArray).clear();
    }
    this.modalRef.hide();
  }

  async setData(){
    let source, target = null;
    let sourceData = [];
    let targetData = [];
    let translateData = [];
    this.projectTranslations.forEach(row => {
      source = this.getLanguageById(row['source_lang_id']);
      target = this.getLanguageById(row['target_lang_id']);
      sourceData.push({
        id: source.id,
        name: this.getCatLanguageCode(source),
        full_name: target.name,
      });
      targetData.push({
        id: target.id,
        name: this.getCatLanguageCode(target),
        full_name: target.name,
      });
      translateData.push({
        id: row['project_translate_id'],
        isSelected: false,
        source: this.getCatLanguageCode(source),
        target: this.getCatLanguageCode(target),
        full_name: target.name,
      });
      
    });
    this.sources = sourceData;
    this.targets = targetData;
    this.masterSelected = false;
    this.phraseMasterSelected = false;
    this.checklist = translateData;
    this.phraseChecklist = translateData;
    this.getCheckedItemList();
    this.getPhraseCheckedItemList();
  }

  getCatLanguageCode(lang) {
    if (this.currentConnector == 1) {
      return lang.trados_code;
    } else if (this.currentConnector == 2) {
      return lang.xtm_code;
    }else if (this.currentConnector == 3) {
      return lang.phrase_code;
    }
  }

  getCheckedItemList(){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected)
      this.checkedList.push(this.checklist[i]);
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }
 
  getPhraseCheckedItemList() {
    let jobTargetLanguages = [];
    this.phraseCheckedList = [];
    for (var i = 0; i < this.phraseChecklist.length; i++) {
      if(this.phraseChecklist[i].isSelected)
        this.phraseCheckedList.push(this.phraseChecklist[i]);
        jobTargetLanguages.push(this.phraseChecklist[i]);
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }

  isAllSelected() {
    if (this.currentConnector == 3) {
      this.phraseMasterSelected = this.phraseChecklist.every(function (item: any) {
        return item.isSelected == true;
      });
      this.getPhraseCheckedItemList();
    }
    if (this.currentConnector == 2) {
      this.masterSelected = this.checklist.every(function (item: any) {
        return item.isSelected == true;
      });
      this.getCheckedItemList();
    }
  }

  xtmSourceLanguage = '';
  setXTMSourceLanguage(event, item, ti) {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.checked) {
      let isPushAllowed = true;
      if (this.xtmSourceLanguage == '') {
        this.xtmSourceLanguage = item.source;
      } else {
        if (this.xtmSourceLanguage != item.source) {
          isPushAllowed = false;
          event.srcElement.checked = false;
          this.checklist[ti].isSelected = false;
          this.openModalDialog('failed',this.lang.getText('please_select_language_combination')+'!');
        }
      }

      if (isPushAllowed) {
        this.checklist[ti].isSelected = true;
        this.checkedList.push(this.checklist[ti]);
      }
    } else {
      event.srcElement.checked = false;
      this.checkedList = this.checkedList.filter(checkedItem => {
        return checkedItem.id != item.id;
      });
    }

    this.isAllSelected();
  }

  checkUncheckAllSourceXTM(event) {
    for (var i = 0; i < this.checklist.length; i++) {
      let item = this.checklist[i];
      let isPushAllowed = true;
      if (this.xtmSourceLanguage == '') {
        this.xtmSourceLanguage = item.source;
      } else {
        if (this.xtmSourceLanguage != item.source) {
          isPushAllowed = false;
          event.srcElement.checked = false;
          item.isSelected = false;
          let checkboxElement = document.getElementById("tran-" + i) as HTMLInputElement;
          checkboxElement.checked = false;
        }
      }
      
      if (isPushAllowed && this.masterSelected) {
        this.checkedList.push(item);
        item.isSelected = this.masterSelected;
      } else if(!this.masterSelected) {
        item.isSelected = this.masterSelected;
      }
    }
  }

  checkUncheckAllSourcePhrase(event) {
    for (var i = 0; i < this.phraseChecklist.length; i++) {
      let item = this.phraseChecklist[i];
      let isPushAllowed = true;
      if (this.phraseSourceLanguage == '') {
        this.phraseSourceLanguage = item.source;
      } else {
        if (this.phraseSourceLanguage != item.source) {
          isPushAllowed = false;
          event.srcElement.checked = false;
          item.isSelected = false;
          let checkboxElement = document.getElementById("tran-" + i) as HTMLInputElement;
          checkboxElement.checked = false;
        }
      }
      
      if (isPushAllowed && this.phraseMasterSelected) {
        this.phraseCheckedList.push(item);
        item.isSelected = this.phraseMasterSelected;
        this.addLanguageToJobs(this.phraseChecklist[i]);
      } else if (!this.phraseMasterSelected) {
        this.removeLanguageFromJobs(this.phraseChecklist[i]);
        item.isSelected = this.phraseMasterSelected;
      }
    }
  }

  checkUncheckAll(event) {
    if (this.currentConnector == 1) { // check/uncheck for trados
      for (var i = 0; i < this.checklist.length; i++) {
        this.checklist[i].isSelected = this.masterSelected;
      }
      this.getCheckedItemList();
    } else if (this.currentConnector == 2) { // check/uncheck for xtm
      this.checkedList = [];
      this.checkUncheckAllSourceXTM(event);
    } else if (this.currentConnector == 3) {
      this.phraseCheckedList = [];
      this.checkUncheckAllSourcePhrase(event);
    }
  }

  filesSelected:boolean;
  checklistFiles:any;
  checkedListFiles = [];
  getCheckedItemListFiles(){
    this.checkedListFiles = [];
    for (var i = 0; i < this.checklistFiles.length; i++) {
      if (this.checklistFiles[i].isSelected) {
        this.checkedListFiles.push(this.checklistFiles[i]);
        if (this.currentConnector == 3) {
          this.addFileToPhraseJobList(this.checklistFiles[i]);
        }
      } else {
        if (this.currentConnector == 3) {
          this.removeFileFromPhraseJobList(this.checklistFiles[i]);
        }
      }
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }

  isAllSelectedFiles() {
    this.filesSelected = this.checklistFiles.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemListFiles();
  }

  checkUncheckAllFiles() {
    for (var i = 0; i < this.checklistFiles.length; i++) {
      this.checklistFiles[i].isSelected = this.filesSelected;
    }
    this.getCheckedItemListFiles();
  }
  
  getLanguageById(langId) {
    let language = [];
    this.languages.forEach(lang => {
      if ( lang.id == langId )
        language = lang;
    });
    return language;
  }

  @Output() tradosCreateResponse: EventEmitter<Object> = new EventEmitter();
  @Output() xtmCreateResponse: EventEmitter<Object> = new EventEmitter();
  @Output() phraseCreateResponse: EventEmitter<Object> = new EventEmitter();
  
  onCreateCat(start_project = false) {
    if (this.currentConnector != 3) {
      if(this.selectedDueDate == ''){
        this.openModalDialog('failed',this.lang.getText('please_select_due_date'));
        return false;
      }
    }

    if (this.currentConnector == 3) {
      if(this.selectedDueDate == '' || this.selectedDueTime == null){
        this.openModalDialog('failed',this.lang.getText('please_select_due_date'));
        return false;
      }

      if (this.phraseCheckedList.length == 0) {
        this.openModalDialog('failed',this.lang.getText('please_select_language_combination')+'!');
        return false;
      }
      
      this.createPhraseProject();
    } else {
      if (this.checkedList.length == 0) {
        this.openModalDialog('failed',this.lang.getText('please_select_language_combination')+'!');
        return false;
      }
  
      // Trados specific errors
      if (this.currentConnector == 1) {
        if(this.selectedLocation == ''){
          this.openModalDialog('failed',this.lang.getText('please_select_location')+'!');
          return false;
        }
        if(this.selectedFileType == ''){
          this.openModalDialog('failed',this.lang.getText('please_select_file_processing_configuration')+'!');
          return false;
        }
        if(this.selectedTranslationEngine == ''){
          this.openModalDialog('failed',this.lang.getText('please_select_translation_engine')+'!');
          return false;
        }
        if(this.selectedWorkflow == ''){
          this.openModalDialog('failed',this.lang.getText('please_select_workflow')+'!');
          return false;
        }
        if ( ! this.checkedListFiles.length ) {
          if ( ! confirm(this.lang.getText('are_you_sure_want_continue_without_any_file')) ) {
            return false;
          }
        }
      }
  
      // XTM specific errors
      if (this.currentConnector == 2) {
        if (this.xtmWorkflow == '') {
          this.openModalDialog('failed', this.lang.getText('please_select_workflow') + '!');
          return false;
        }
  
        if (this.xtmSegmentLockingRule == '') {
          this.openModalDialog('failed', this.lang.getText('please_select_segment_locking_rule') + '!');
          return false;
        }
      }
      let length_error = false;
      this.checkedListFiles.forEach(row => {
          if(row.path.length > 200){
            length_error = true;
          }
      });
      if(length_error){
        this.openModalDialog('failed',this.lang.getText('path_file_name_length_error')+'!');
        return false;
      }
      
      if (this.currentConnector == 1) {
        this.createTradosProject(start_project);
      } else if (this.currentConnector == 2) {
        this.createXTMProject(start_project);
      }
      // formData['token'] = this.token.get();
    }
  }

  createTradosProject(start_project) {
    let data = {
      checkedList : this.checkedList,
      token : this.token.get(),
      projectData : this.projectData,
      orderID: this.orderID,
      templateID: this.selectedTemplate,
      locationID: this.selectedLocation,
      fileProcessingConfigID: this.selectedFileType,
      translationEngineID: this.selectedTranslationEngine,
      dueDate: this.helperSer.formateDPDate(this.selectedDueDate),
      workflowID: this.selectedWorkflow
    };
    this.http.post(`${this.apiURL}trados/create-cat/${this.projectID}`, data).subscribe(
      (data:any) => {
        // this.tradosCreateResponse.emit({ status: 'Created' });
        this.openModalDialog('success',this.lang.getText('project_created_in_trados'));
        // If files selected to upload
        if (this.checkedListFiles.length > 0) {
          this.addFilesToTrados(start_project);
        }
        // If no files selected to upload and 'create and start' button click
        else if (start_project && this.checkedListFiles.length == 0) {
          this.startProject(true);
        }
        else{
          this.tradosCreateResponse.emit({ status: 'Created' });
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal(); 
        }
      },
      error => {
        this.tradosCreateResponse.emit({ status: 'Error' });
        if(error.status === 400){
          this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          //this.openModalDialog('failed',this.lang.getText('api_error_bad_request'));
        }
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        // setTimeout(() => {
        //   this.closeModalDialog();
        // }, 2 * 2000);
        this.handleError(error)
        this.closeModal();
      }
    );
  }

  createXTMProject(start_project) {
    if (this.connectionId == null) {
      this.openModalDialog('failed',this.lang.getText('cannot_find_xtm_connection_for_this_client')+'!');
      return false;
    }
    let data = {
      checkedList : this.checkedList,
      projectData : this.projectData,
      referenceID: this.orderID,
      sourceLanguage: this.sources,
      targetLanguages: this.targets,
      workflowId: this.xtmWorkflow,
      dueDate: this.helperSer.formateDPDate(this.selectedDueDate),
      checkedListFiles: this.checkedListFiles,
      segmentLockingType: this.xtmSegmentLockingRule,
      connectionId: this.connectionId,
      selectedClientId: this.clientSelected.id
    }
    this.showLoader.emit(true);
    this.isXTMProjectCreated = true;
    this.http.post(`${this.apiURL}xtm/create-cat/${this.projectID}`, data).subscribe(
      (data: any) => {
        // this.xtmCreateResponse.emit({ status: 'Created' });
        this.showLoader.emit(false);
        this.openModalDialog('success', this.lang.getText('project_created_in_xtm'));
        setTimeout(() => {
          this.closeModalDialog();
        }, 2 * 1000);
        // If files selected to upload
        if (this.checkedListFiles.length > 0) {
          this.addFilesToXTM(start_project);
        }
        // If no files selected to upload and 'create and start' button click
        else if (start_project && this.checkedListFiles.length == 0) {
          this.startProject(true);
        }
        else{
          this.xtmCreateResponse.emit({ status: 'Created' });
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal(); 
        }
      },
      error => {
        this.isXTMProjectCreated = false;
        this.showLoader.emit(false);
        this.xtmCreateResponse.emit({ status: 'Error' });
        if(error.status === 400){
          this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));
        }
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        // setTimeout(() => {
        //   this.closeModalDialog();
        // }, 2 * 2000);
        this.handleError(error)
        this.closeModal();
      }
    );
  }

  //create phrase project start //

  combineDateAndTime(date: Date, time: Date): Date {
    // Extract the date part
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();

    // Extract the time part
    let hours = time.getHours();
    let minutes = time.getMinutes();
    let seconds = time.getSeconds();

    // Create a new Date object with the combined date and time
    return new Date(year, month, day, hours, minutes, seconds);
  }

  checkTemplateLanguages(templates: any[], formValue: any): boolean {
    const jobs = formValue.jobs;
    return templates.every(template => {
        const templateSourceLang = template.sourceLang;
        const templateTargetLangs = template.targetLangs;
        const sourceLangExists = jobs.some(job =>
            job.languages.some(language => language.source === templateSourceLang)
        );
        const targetLangsExist = templateTargetLangs.every(targetLang =>
            jobs.some(job =>
                job.languages.some(language => language.target === targetLang)
            )
        );
        return sourceLangExists && targetLangsExist;
    });
}

  createPhraseProject() {

    if (this.phraseConnectionID == null) {
      this.openModalDialog('failed',this.lang.getText('cannot_find_phrase_connection_for_this_client')+'!');
      return false;
    }
    if (!this.checkedListFiles.length) {
      this.openModalDialog('failed',this.lang.getText('please_select_or_upload_files_before_create_phrase_project')+'!');
      return false;
    }
    this.combinedDueDateTime = this.combineDateAndTime(new Date(this.selectedDueDate), new Date(this.selectedDueTime));
    let data = {
      phraseCheckedList : this.phraseCheckedList,
      projectData : this.projectData,
      referenceID: this.orderID,
      sourceLanguage: this.sources,
      targetLanguages: this.targets,
      dueDate: this.combinedDueDateTime,
      connectionId: this.connectionId,
      selectedClientId: this.clientSelected.id,
      selectedPraseTemplate: this.selectedPraseTemplate,
      template: this.phraseTemplates,
    }
    
    this.http.post(`${this.apiURL}phrase/create-project/${this.phraseConnectionID}/${this.projectID}`, data).subscribe(
      (data: any) => {
        this.openModalDialog('success', this.lang.getText('project_created_in_phrase'));
        this.isPhraseProjectCreated = true;
        this.phraseProjectId = data.phrase_project_uid;
        this.isPhraseProjectCreated = true;
        this.enableJobLanguageVendors();
      },
      error => {
        this.phraseCreateResponse.emit({ status: 'Error' });
        if(error.status === 400){
          this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          //this.openModalDialog('failed',this.lang.getText('api_error_bad_request'));
        }
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        // setTimeout(() => {
        //   this.closeModalDialog();
        // }, 2 * 2000);
        this.handleError(error)
        this.closeModal();
      }
    );
  }

  prepareErrorMessage(error){
    let errorDetail = "";
    let errorStr = "";
    if(error['incorrectParameters'] && error['reason']) {
      errorDetail += error['reason'] + " - " + error['incorrectParameters']
    } else {
      error['details'].forEach(error => {
        errorDetail += error.name + " - " + error.value
      });
    }
    if(error.message) {
      errorStr += error.message
    }
    return errorStr + " Detail: " + errorDetail;
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
  }

  display = 'none';
  status = '';
  message = '';
  openModalDialog(status,message){
    this.display='block';
    this.status = status;
    this.message = message;
  }
  closeModalDialog(){
    this.display='none';
  }

  // files;
  allFilesReq;
  async getFiles() {
    let formData = this.token.getAPITokenData();
    console.log('this.projectID;',this.projectID)
    formData['projectId'] = this.projectID;
    formData['tag'] = this.tag;

    this.allFilesReq = this.http.post(`${this.apiURL}project/file/get`,formData).subscribe(
      data => {
        let filesData = [];
        data['result']['files'].forEach(file => {
          if (file['upload_by'] == 0){
            // this.files.push(file);
            filesData.push({
              id:  file.id,
              isSelected:false,
              path: file.path,
              role: 'translatable',
              file_name: this.getFileName(file.path)
            });
          }
          // if (file['upload_by'] == 1)
          //   this.vendorUploadedFiles.push(file);
        });
        this.filesSelected = false;
        this.checklistFiles = filesData;  
      },
      error => this.handleError(error)
    );
  }
  getFileName(link) {
    return link.substring(link.lastIndexOf('/')+1);
  }

  sendAdditionalFiles(){
    if (this.checkedListFiles.length == 0) {
      this.openModalDialog('failed',this.lang.getText('please_select_files_xtm')+'!');
      return false;
    }
    let length_error = false;
    this.checkedListFiles.forEach(row => {
        if(row.path.length > 200){
          length_error = true;
        }
    });
    if(length_error){
      this.openModalDialog('failed',this.lang.getText('path_file_name_length_error')+'!');
      return false;
    }
    if (this.currentConnector == 1) {
      this.addFilesToTrados();
    } else if (this.currentConnector == 2) {
      this.addFilesToXTM();
    }
  }

  addFilesToTrados(start_project = false){
    console.log('this.checkedList',this.checkedList);
    console.log('this.checkedListFiles',this.checkedListFiles);
    // console.log('selectedRole',this.selectedRole);
    let data = {
      checkedList : this.checkedList,
      checkedListFiles : this.checkedListFiles,
      token : this.token.get(),
      projectData : this.projectData
    };
    // setTimeout(() => {
    //   this.openModalDialog('success',this.lang.getText('adding_files_to_trados'));
    // }, 2 * 1000);
    this.loading = true;
    this.http.post(`${this.apiURL}trados/add-files/${this.projectID}`, data).subscribe(
      res => {
        this.loading = false;
        if(res['error'].length > 0){
          let errors = "";
          res['error'].forEach(error => {
            if(error.message != "undefined")
            errors += error.message + '\n';
          });
          console.log('errors',errors);
          this.tradosCreateResponse.emit({ status: 'Error' });
          this.openModalDialog('failed',errors);  
          //return;
        }
        else{
          this.tradosCreateResponse.emit({ status: 'Created' });
          this.closeModalDialog();
          this.openModalDialog('success',this.lang.getText('project_files_added_to_trados'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal();
        }
        if(start_project){
          this.startProject();
        }
    },
    error => {
        this.tradosCreateResponse.emit({ status: 'Error' });
        if(error.status === 400)  
          this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        this.handleError(error)
        this.closeModal();
    });
  }

  addFilesToXTM(start_project = false){
    let data = {
      checkedList : this.checkedList,
      checkedListFiles : this.checkedListFiles,
      token : this.token.get(),
      projectData : this.projectData,
      connectionId: this.connectionId
    };
    this.loading = true;
    this.showLoader.emit(true);
    if (this.isXTMFilesAddedButtonDisabled) {
      this.isXTMFilesAdded = true;
    }
    this.http.post(`${this.apiURL}xtm/add-files/${this.projectID}`, data).subscribe(
      res => {
        this.loading = false;
        this.showLoader.emit(false);
        if (res['error'] && res['error'].length > 0) {
          this.isXTMFilesAdded = false;
          let errors = "";
          res['error'].forEach(error => {
            if(error.message != "undefined")
            errors += error.message + '\n';
          });
          console.log('errors',errors);
          this.xtmCreateResponse.emit({ status: 'Error' });
          this.openModalDialog('failed',errors);
        } else{
          this.closeModalDialog();
          this.openModalDialog('success',this.lang.getText('project_files_added_to_xtm'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          if(start_project){
            this.startProject();
          } else {
            this.xtmCreateResponse.emit({ status: 'Created' });
            this.closeModal();
          }
        }
    },
      error => {
      this.isXTMFilesAdded = false;  
      this.showLoader.emit(false);
      this.xtmCreateResponse.emit({ status: 'Error' });
      if(error.status === 400)  
        this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
      else {  
        this.openModalDialog('failed',error.error.error);
      }  
      this.handleError(error)
      this.closeModal();
    });
  }

  startProject(startWithoutFiles = false){
    if(this.currentConnector == 1) {
      this.startTradosProject(startWithoutFiles);
    } else if (this.currentConnector == 2) {
      this.startXTMProject(startWithoutFiles);
    }
  }

  startTradosProject(startWithoutFiles) {
    // setTimeout(() => {
    //   this.openModalDialog('success',this.lang.getText('starting_the_trados_project'));
    // }, 2 * 1000);
    let data = {
      token : this.token.get()
    };
    this.http.post(`${this.apiURL}trados/start-project/${this.projectID}`, data).subscribe(
      res => {
          this.tradosCreateResponse.emit({ status: 'Created' });
          this.closeModalDialog();
          this.openModalDialog('success',this.lang.getText('project_started_in_trados'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal();
      },
      error => {
          this.closeModalDialog();
          if(startWithoutFiles){
            this.tradosCreateResponse.emit({ status: 'startWithoutFiles' });  
          }
          this.tradosCreateResponse.emit({ status: 'Error' });
          if(error.status === 400)  
            this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          else {  
            this.openModalDialog('failed',error.error.error);
          }  
          this.handleError(error)
          this.closeModal();
      });
  }

  startXTMProject(startWithoutFiles){
    if(!startWithoutFiles) {
      setTimeout(() => {}, 10 * 1000);
    }
    let data = {
      token : this.token.get(),
      connectionId: this.connectionId
    };
    this.showLoader.emit(true);
    this.isXTMProjectStarted = true;
    this.http.post(`${this.apiURL}xtm/start-project/${this.projectID}`, data).subscribe(
      res => {
        this.generateXTMFiles();
      },
      error => {
        this.showLoader.emit(false);
        console.log(error);
        if(error?.error?.error?.reason && error?.error?.error?.reason == "Project is under analysis. Please wait for analysis end.") {
          this.startXTMProject(startWithoutFiles);
        } else {
          this.isXTMProjectStarted = false;
          this.closeModalDialog();
          if(startWithoutFiles){
            this.xtmCreateResponse.emit({ status: 'startWithoutFiles' });  
          }
          this.xtmCreateResponse.emit({ status: 'Error' });
          if(error.status === 400)  
            this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          else {  
            this.openModalDialog('failed',error.error.error);
          }  
          this.handleError(error)
          this.closeModal();
        }
      });
  }

  generateXTMFiles() {
    let data = {
      token : this.token.get(),
      connectionId: this.connectionId
    };
    this.http.post(`${this.apiURL}xtm/generate-files/${this.projectID}`, data).subscribe(
      res => {
        this.showLoader.emit(false);
        this.xtmCreateResponse.emit({ status: 'Created' });
        this.closeModalDialog();
        this.openModalDialog('success',this.lang.getText('project_started_in_xtm'));
        setTimeout(() => {
          this.closeModalDialog();
        }, 2 * 1000);
        this.closeModal();
      },
      error => {
        this.showLoader.emit(false);
        if(error?.error?.error && error?.error?.error == "jobs are under analysis, Please wait for analysis end") {
          this.generateXTMFiles();
        } else {
          this.isXTMProjectStarted = false;
          this.closeModalDialog();
          this.xtmCreateResponse.emit({ status: 'Error' });
          if(error.status === 400)  
            this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          else {  
            this.openModalDialog('failed',error.error.error);
          }  
          this.handleError(error)
          this.closeModal();
        }
      });
  }

  isShown: boolean = false ; // hidden by default
  toggleExtraOptionsShow() {
    this.isShown = ! this.isShown;
  }

  checkLanguageCode() {
    let source, target = null;
    let error = false;
    this.projectTranslations.forEach(row => {
      source = this.getLanguageById(row['source_lang_id']);
      target = this.getLanguageById(row['target_lang_id']);
      if(this.currentConnector == 1) {
        if(source && (source.trados_code == '' || source.trados_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_trados_code_into_language')+" "+source.name);
          error = true;
          return false;
        }
        if(target && (target.trados_code == '' || target.trados_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_trados_code_into_language')+" "+target.name);
          error = true;
          return false;
        }
      } else if(this.currentConnector == 2) {
        if(source && (source.xtm_code == '' || source.xtm_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_xtm_code_into_language')+" "+source.name);
          error = true;
          return false;
        }
        if(target && (target.xtm_code == '' || target.xtm_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_xtm_code_into_language')+" "+target.name);
          error = true;
          return false;
        }
      } else if(this.currentConnector == 3) {
        if(source && (source.phrase_code == '' || source.phrase_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_phrase_code_into_language')+" "+source.name);
          error = true;
          return false;
        }
        if(target && (target.phrase_code == '' || target.phrase_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_phrase_code_into_language')+" "+target.name);
          error = true;
          return false;
        }
      }
    });

    return error;
  }

  async onChangeConnector() {
    if(this.checkLanguageCode()) {
      this.closeModal();
    };
    this.xtmSourceLanguage = '';
    this.checkedList = [];
    this.isPhraseProjectCreated = false;
    this.phraseProjectData = [];
    await this.setData();
    this.masterSelected = false;
    this.checkedListFiles = [];
    await this.getFiles();
    this.filesSelected = false;
    if (this.currentConnector == 1) {
      this.getTradosFolders();
    } else if (this.currentConnector == 2) {
      this.phraseTemplates = [];
      this.phraseTransaltionMemories = [];
      // this.bsConfig["minDate"] = new Date(new Date().setDate(new Date().getDate() + 1));
      let result = this.setConnectionId();
      if (result && this.xtmWorkflows.length == 0) {
        this.getXTMWorkflows();
      }
    } else if (this.currentConnector == 3) {
      let phraseConnectionResult = await this.setPhraseConnectionId();
      let phraseVendorResult = await this.checkPhraseVendor();
      if (phraseConnectionResult && phraseVendorResult && this.phraseTemplates.length == 0) {
        this.getPhraseTemplates();
      }
    }
  }

  setConnectionId() {
    if (this.clientSelected.xtm_connection_details == null) {
      this.openModalDialog('failed',this.lang.getTextWDS('please_add_the_connection_first_for', [this.connectors[this.currentConnector-1].name])+" "+this.clientSelected.name);
      this.closeModal();
    } else if(this.clientSelected.xtm_connection_details && (this.clientSelected.xtm_connection_details.xtm_url == null || this.clientSelected.xtm_connection_details.xtm_url == '')) {
      this.openModalDialog('failed',this.lang.getTextWDS('please_add_the_connection_url_first_for', [this.connectors[this.currentConnector-1].name])+" "+this.clientSelected.name);
      this.closeModal();
    } else {
      this.connectionId = this.clientSelected.xtm_connection_details.id;
      return true;
    }
    return false;
  }

  async setPhraseConnectionId() {
    if (this.clientSelected.phrase_connection_details != null) {
      this.phraseConnectionID =  this.clientSelected.phrase_connection_details.id;
      return true;
    } else {
      this.openModalDialog('failed',this.lang.getTextWDS('please_add_the_connection_first_for', [this.connectors[this.currentConnector-1].name])+" "+this.clientSelected.name);
      this.closeModal();
    }
    return false;
  }

  async checkPhraseVendor() {
    if (this.vendors.length > 0) {
      this.vendors.forEach(vendor => {
        if (vendor.phrase_provider_id == null || vendor.phrase_provider_id == '') {
          this.openModalDialog('failed', this.lang.getText('please_map_phrase_vendor_id_to_all_vendors_first'));
          this.closeModal();
          return false;
        }
      });
      return true;
    } else {
      this.openModalDialog('failed', 'Please '+ this.lang.getText('create-vendor') + ' or ' +this.lang.getText('add_vendor'));
      this.closeModal();
      return false;
    }
  }

  getXTMWorkflows() {
    this.http.get(`${this.apiURL}xtm/get-workflows/${this.connectionId}`).subscribe(
      workflows => {
        this.xtmWorkflows = workflows;
      },
      error => this.handleError(error)
    );
  }

  getPhraseTemplates() {
    this.http.get(`${this.apiURL}phrase/list-templates/${this.phraseConnectionID}`).subscribe(
      data => {
        let templates = data['content'];
        this.phraseTemplates = templates;
      },
      error => {
        console.log('error',error);  
      }
    );
  }

  onChangePhraseTemplate(event: any) { 
    if (event == null || event == undefined || event == "") {
      this.phraseTransaltionMemories = [];
      return;
    }
    this.getTemplateTransMemrories(event);
  }

  getTemplateTransMemrories(templateId: string) { 
    this.http.get(`${this.apiURL}phrase/templates-trans-memories/${templateId}/${this.phraseConnectionID}`).subscribe(
      data => {
        this.phraseTransaltionMemories = data['transMemories']
      },
      error => {
        console.log('error',error);  
      }
    );
  }

  get jobs(): FormArray {
    return this.phraseJobfilesForm.get('jobs') as FormArray;
  }

  createPhraseJob(file: any): FormGroup {
    return this.fb.group({
      id: file.id,
      file: file,
      fileName: this.getFileName(file.path),
      languages: this.fb.array([]),
    });
  }

  addFileToPhraseJobList(item: any) {
    if (this.phraseCheckedList.length === 0) { 
      this.openModalDialog('failed', this.lang.getText('add_language_combination'));
      return;
    }
    let itemId = item.id;
    let checklistFile = this.checkedListFiles.find(i => i.id === itemId);
    if (!checklistFile) {
      return;
    }
    let existingJobIndex = this.jobs.controls.findIndex((job: FormGroup) => job.get('id')?.value === itemId);
    if (existingJobIndex === -1) {
      let newJob = this.createPhraseJob(checklistFile);
      this.jobs.push(newJob);
      let languagesArray = newJob.get('languages') as FormArray;
      for (let lang of this.phraseCheckedList) {
        let jobLanguage = this.initPhraseJobLanguage(lang);
        languagesArray.push(jobLanguage);       
      }
    }
  }

  initPhraseJobLanguage(lang) {
    return this.fb.group({
      title: 'job'+ 1,
      id: lang.id,
      full_name: lang.full_name,
      name: lang.name,
      source: lang.source,
      target: lang.target,
      // vendor: {value: this.fb.control(''), disabled: !this.isPhraseProjectCreated}
      vendor:this.fb.control({value: '', disabled: !this.isPhraseProjectCreated}, Validators.required)
    });
  }

  addVendorToAllJobs() {
    if (this.selectedVendor == null || this.selectedVendor == undefined || this.selectedVendor == '') {
      this.openModalDialog('failed', this.lang.getText('select_vendor'));
      return;
    }
    let selectedVendor = this.vendors.find(vendor => vendor.vendor_id == this.selectedVendor);
    if (!selectedVendor) {
      this.openModalDialog('failed', this.lang.getText('selected_vendor_null_or_not_found'));
      return;
    }
    this.jobs.controls.forEach((job, jobIndex) => {
      let languages = job.get('languages') as FormArray;  
      languages.controls.forEach((language, jobLanguageIndex) => {
        this.setVendorToJobLanguage(jobIndex, jobLanguageIndex, selectedVendor);
      });
    });
  }
  
  removeFileFromPhraseJobList(item: any) {
    let index = this.jobs.controls.findIndex(control => control.get('id')?.value === item.id);
    if (index !== -1) {
         this.jobs.removeAt(index);
      }
  }

  getJobLanguages(jobIndex: number): FormArray {
    return this.jobs.at(jobIndex).get('languages') as FormArray;
  }

  removeLanguageFromPhraseJob(jobIndex, jobLanguageIndex) {
    let languagesArray = this.getJobLanguages(jobIndex);
    languagesArray.removeAt(jobLanguageIndex);
    if(languagesArray.length == 0) {
      this.jobs.removeAt(jobIndex);
    }
  }

  generateLanguageIndex(jobLanguageIndex) {
    return String.fromCharCode(65 + jobLanguageIndex);
  }

  phraseJobTargetLanguage: any[][] = [];

  setVendorToJobLanguage(jobIndex, jobLanguageIndex, selectedVendor) {
    let languagesArray = this.jobs.at(jobIndex).get('languages') as FormArray;
    let languageGroup = languagesArray.at(jobLanguageIndex) as FormGroup;
    let vendorControl = languageGroup.get('vendor') as FormGroup;
    if (vendorControl) {
        if (vendorControl.value !== selectedVendor) {
            vendorControl.patchValue(selectedVendor);
        }
    } else {
        console.error("Vendor control is undefined");
    }
}


  onChnageVendor(event: any, jobIndex: number = null, jobLanguageIndex: number = null): void {
    let selectedVendorId = event.target.value;
    let selectedVendor = this.vendors.find(vendor => vendor.vendor_id == selectedVendorId);  
    this.setVendorToJobLanguage(jobIndex, jobLanguageIndex, selectedVendor);
  }
  
  getPhraseFileName(path: string): string {
    return path.split('/').pop();
  }

  enableJobLanguageVendors() {
    this.jobs.controls.forEach(job => {
      let languages = job.get('languages') as FormArray;
      languages.controls.forEach(language => {
        language.get('vendor').enable();
      });
    });
  }

  allVendorsAssigned : boolean = true;
  async checkVendors(formData: any) {
    for (let jobIndex = 0; jobIndex < formData.jobs.length; jobIndex++) {
      let job = formData.jobs[jobIndex];
      let languagesArray = this.jobs.at(jobIndex).get('languages') as FormArray;
      for (let jobLanguageIndex = 0; jobLanguageIndex < job.languages.length; jobLanguageIndex++) {
        let languageGroup = languagesArray.at(jobLanguageIndex) as FormGroup;
        let vendorControl = languageGroup.get('vendor');
        if (!vendorControl.value) {
          this.allVendorsAssigned = false;
        }
      }
    }
    return this.allVendorsAssigned;
  }

 async onSubmitJob() {
    let formData = this.phraseJobfilesForm.value;
    this.allVendorsAssigned = await this.checkVendors(formData);
    if (!this.allVendorsAssigned) {
        this.allVendorsAssigned = true;
        this.openModalDialog('failed', this.lang.getText('assign_vendor_to_job'));
        return;
      }
    formData['dueDate'] = this.combinedDueDateTime;
    formData['phraseProjectId'] = this.phraseProjectId;
    formData['pre_translate'] = this.phrase_pre_translate;
   formData['selectedClientId'] = this.clientSelected.id;
   this.isJobProjectCreated = true;
    // console.log("formData", formData);
    // return false;
    this.http.post(`${this.apiURL}phrase/create-and-analyze-job/${this.phraseConnectionID}/${this.projectID}`, formData).subscribe(
      (data: any) => {
        if (data.success) {
          this.allVendorsAssigned = true;
          setTimeout(() => {
            this.createJobAnalysis(data.jobsUUID);
          }, 5 * 1000);
        } else {
          this.isJobProjectCreated = false;
          this.phraseCreateResponse.emit({ status: 'Error' });
          this.openModalDialog('failed', this.lang.getText('phrase_job_error'));
        }
      },
      error => {
        this.isPhraseProjectCreated = false;
        this.isJobProjectCreated = false;
        this.allVendorsAssigned = true;
      }
    );
  }

  createJobAnalysis(jobsUUID:any) {
    let formData = {};
    formData['phraseProjectId'] = this.phraseProjectId;
    formData['pre_translate'] = this.phrase_pre_translate;
    formData['jobs_uuid'] = jobsUUID;
    this.http.post(`${this.apiURL}phrase/create-job-analyze/${this.phraseConnectionID}/${this.projectID}`, formData).subscribe(
      (data: any) => {
        if (data.success === true) {
          // console.log("phrase create job analyze response-------------", data);
          if (this.phrase_pre_translate) {
            this.preTranslateJobs(jobsUUID);
          }
          this.phraseCreateResponse.emit({ status: 'Created' });
          this.closeModalDialog();
          this.openModalDialog('success', this.lang.getText('project_started_in_phrase'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal();
        } else {
          if (data.message == 'JOB_NOT_READY') {
            setTimeout(() => {
              this.createJobAnalysis(jobsUUID);
            }, 2 * 5000);
          } else {
            this.openModalDialog('failed', this.lang.getText('phrase_analyze_error')+'!');
          }
        }
      },
      error => {
        this.isPhraseProjectCreated = false;
      }
    );
  }

  preTranslateJobs(jobsUUID: any) {
    return false;
    let formData = {};
    formData['phraseProjectUId'] = this.phraseProjectId;
    formData['pre_translate'] = this.phrase_pre_translate;
    formData['jobs_uuid'] = jobsUUID;
    this.http.post(`${this.apiURL}phrase/pre-translate-Jobs/${this.projectID}`, formData).subscribe(
      (data: any) => {
        // console.log("phrase pre translate Jobs response-------------", data);
      },
      error => {
        // this.isPhraseProjectCreated = false;
      }
    );
  }

  phraseSourceLanguage = '';
  selectLanguageForPhraseJob(event, item, ti) {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.checked) {
      let isPushAllowed = true;
      if (this.phraseSourceLanguage == '') {
        this.phraseSourceLanguage = item.source;
      } else {
        if (this.phraseSourceLanguage != item.source) {
          isPushAllowed = false;
          event.srcElement.checked = false;
          this.phraseChecklist[ti].isSelected = false;
          this.openModalDialog('failed', this.lang.getText('please_select_same_source_language_combinations')+'!');
        }
      }

      if (isPushAllowed) {
        this.phraseChecklist[ti].isSelected = true;
        this.phraseCheckedList.push(this.phraseChecklist[ti]);
        this.addLanguageToJobs(this.phraseChecklist[ti]);
      }
    } else {
      event.srcElement.checked = false;
      this.phraseCheckedList = this.phraseCheckedList.filter(checkedItem => {
        return checkedItem.id != item.id;
      });
      this.removeLanguageFromJobs(this.phraseChecklist[ti]);
    }
    this.isAllSelected();
  }

  removeLanguageFromJobs(uncheckedLanguage: any) {
  
    for (let i = this.jobs.controls.length - 1; i >= 0; i--) {
      const job = this.jobs.controls[i] as FormGroup;
      const languagesArray = job.get('languages') as FormArray;
      const languageIndex = languagesArray.controls.findIndex((langControl: FormGroup) =>
        langControl.get('full_name')?.value === uncheckedLanguage.full_name 
      );
  
      if (languageIndex !== -1) {
        const removedLanguage = languagesArray.controls[languageIndex].get('full_name')?.value; 
        languagesArray.removeAt(languageIndex);
      }
  
      if (languagesArray.length === 0) {
        this.jobs.removeAt(i);
      }
    }
  }
  
  

  addLanguageToJobs(recheckedLanguage: any) {
    for (let i = 0; i < this.jobs.controls.length; i++) {
      const job = this.jobs.controls[i] as FormGroup;
  
      const languagesArray = job.get('languages') as FormArray;
      if (!languagesArray) {
        continue; // Skip this iteration if languages array is not found
      }
  
      const languageAlreadyAdded = languagesArray.controls.some((langControl: FormGroup) =>
        langControl.get('full_name')?.value === recheckedLanguage.full_name
      );
  
      if (!languageAlreadyAdded) {
        const newLanguageControl = this.initPhraseJobLanguage(recheckedLanguage);
        languagesArray.push(newLanguageControl);
      } else {
      }
    }
  }

  extractPath(paths){
    let full_path_name = "";
    paths.slice().reverse().forEach((path)=>{
      full_path_name += (!path.hasParent ? "Root" : path.name) + ' / ';
    });
    return full_path_name;
  }
}
