<div class="mt-4">
  <div *ngIf="labels" class="">
    <div class="row auth">
      <div class="col-md-4 col-label">
        <label class="col-auto col-form-label-h5">{{lang.getText('my_project_labels')}}</label>
      </div>
      <div class="col-md-7">
        <ng-container>
        <div class="row labels">
          <div class="col-auto label" *ngFor="let label of labelFa.controls; let li=index;">
              <button class="btn btn-light btn-break btn-label" [ngClass]="isEditable && !isAddedNew ? 'ps-3 pe-2' : 'ps-4 pe-4'" [style.backgroundColor]="label.get('color').value" [hidden]="label.get('delete').value == 'yes' || label.get('is_new_add').value == true">
                {{label.get('name').value}}
                <a *ngIf="isEditable && !isAddedNew" class="btn btn-circle btn-circle-sm ms-3" (click)="editSpecificLabel(label, li)"
                  title="{{lang.getText('edit')}}">
                  <img src="/assets/svg/edit-white-icon.svg">
                </a>
              </button>
          </div>
        </div>
        <div class="row labels">
          <div class="col-4 label">
            <button class="btn btn-add btn-label" [style.backgroundColor]="'#18347b'" (click)="editLabel()" [disabled]="isAddedNew">
              {{lang.getText('edit_label')}}
            </button>
          </div>
          <div class="col-4 label">
            <button class="btn btn-add btn-label" [style.backgroundColor]="'#18347b'"
              (click)="addLabel('', '', '#F8515E', true)" [disabled]="labelFa.length > 9">
              {{lang.getText('add_label')}}
            </button>
          </div>
        </div>
      </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="labels" class="label-container">
  <form *ngIf="labelFg.enabled" [formGroup]="labelFg">
      <div formArrayName="labels">
        <div>
          <div class="label-picker-content">
            <ul class="label-picker-colors-view grid_ui_Color_label">
              <li class="" *ngFor="let label of getLabelsFa().controls; let li=index">
                <div class="row col-md-12">
                  <div class="col-md-4"> 
                  </div>
                  <ng-container *ngIf="li == currentSelectedIndex && !label.value.is_new_add">
                    <div class="d-flex align-items-center col-md-7 ps-4 ms-3">
                      <div class="badge-empty p-lable d-flex align-items-center" [formGroupName]="li"   [ngClass]="labelFg.enabled ? 'bg-box' :'full-bg-box'">
                        <input  type="color" value="label.value.color"  formControlName="color"
                          class="color-option-box color-box-0 p-0"  [style.borderColor]="label.value.color" />
                        <input #labelText class="editable-input-wrapper ms-3 color-option-input hover-class" type="text"
                          formControlName="name" />
                      </div>
                      <button (click)="deleteLabel(li)" class="btn fd-icon fd-delete fd-icon-sm ms-3 pb-1" title="{{lang.getText('delete')}}"> </button>
                    </div>
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="d-flex align-items-center justify-content-between col-md-7 mt-3 ps-3 ms-3">
                        <div class="form-group ps-3">
                          <button (click)="saveLabels()" class="btn btn-primary border-radius-42 pe-4 ps-4 me-3">
                            {{lang.getText('save')}}
                          </button>
                          <button (click)="cancelEdit(li)" class="btn btn-danger border-radius-42 pe-4 ps-4" [hidden]="label.value.delete == 'yes'">
                            {{lang.getText('cancel_edit')}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="isAddedNew && label.value.is_new_add && label.value.delete != 'yes'">
                    <div class="d-flex align-items-center col-md-7 ps-4 ms-3">
                      <div class="badge-empty p-lable d-flex align-items-center" [formGroupName]="li"   [ngClass]="labelFg.enabled ? 'bg-box' :'full-bg-box'">
                        <input  type="color" value="label.value.color"  formControlName="color"
                          class="color-option-box color-box-0 p-0"  [style.borderColor]="label.value.color" />
                        <input #labelText class="editable-input-wrapper ms-3 color-option-input hover-class" type="text"
                          formControlName="name" />
                      </div>
                      <button (click)="deleteLabel(li)" class="btn btn-circle btn-circle-sm ms-3 delete pb-1" title="{{lang.getText('delete')}}"> 
                        <img class="delete-img" src="/assets/svg/delete-icon.svg">
                      </button>
                    </div>
                  </ng-container>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isAddedNew">
        <div class="col-md-4"></div>
        <div class="d-flex align-items-center justify-content-between col-md-7 mt-3 ps-3 ms-3">
          <div class="form-group">
            <button (click)="saveLabels()" class="btn btn-primary border-radius-42 pe-4 ps-4 me-3">
              {{lang.getText('save')}}
            </button>
          </div>
        </div>
      </div>
      <!-- <div>
        <button *ngIf="labelFg.enabled" type="button" class="btn btn-primary border-radius-42 ps-4 pe-4" (click)="saveLabels()">
          {{lang.getText('apply')}}
        </button>
      </div> -->
  </form>
</div>
