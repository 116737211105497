import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './pipes/translate.pipe';
import { MyCurrencyPipe } from './pipes/myCurrency.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSummernoteModule } from 'ngx-summernote';
import { GenerateInvoiceComponent } from './components/generate-invoice/generate-invoice.component';
import { VendorInvoiceComponent } from './components/vendor-invoice/vendor-invoice.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { PoListComponent } from './components/po-list/po-list.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { OnceVisibleDirective } from './directives/once-visible.directive';
import { ShadowCheckDirective } from './directives/shadow-check.directive';
import { ClientTranslationRatesComponent } from './components/client-translation-rates/client-translation-rates.component';
import { BreadcrumbMenuComponent } from './components/breadcrumb-menu/breadcrumb.menu.component';
import { RouterModule } from '@angular/router';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslationSnapshotComponent } from './components/translation-snapshot/translation-snapshot.component';
import { ConfigureSideMenuComponent } from './components/configure-side-menu/configure-side-menu.component';
import { EmailMetaComponent } from './components/email-meta/email-meta.component';
import { EmailMetaFormEmailComponent } from './components/email-meta-form-email/email-meta-form-email.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    RouterModule,
    NgxSummernoteModule,
    NgSelectModule
  ],
  declarations: [
    TranslatePipe,
    MyCurrencyPipe,
    GenerateInvoiceComponent,
    VendorInvoiceComponent,
    InvoiceListComponent,
    PoListComponent,
    LoaderComponent,
    PaginationComponent,
    OnceVisibleDirective,
    ShadowCheckDirective,
    ClientTranslationRatesComponent,
    BreadcrumbMenuComponent,
    TooltipComponent,
    TooltipDirective,
    TranslationSnapshotComponent,
    ConfigureSideMenuComponent,
    EmailMetaComponent,
    EmailMetaFormEmailComponent,
    TranslationSnapshotComponent
  ],
  exports: [
    GenerateInvoiceComponent,
    VendorInvoiceComponent,
    InvoiceListComponent,
    PoListComponent,
    TranslatePipe,
    ClientTranslationRatesComponent,
    BreadcrumbMenuComponent,
    LoaderComponent,
    TooltipDirective,
    MyCurrencyPipe,
    PaginationComponent,
    NgSelectModule,
    OnceVisibleDirective,
    EmailMetaComponent,
    ClientTranslationRatesComponent,
    NgSelectModule,
    ConfigureSideMenuComponent
  ],
  providers: [
    BreadcrumbMenuComponent,
    TranslatePipe,
    TooltipDirective,
    LoaderComponent,
    PaginationComponent,
    MyCurrencyPipe,
    ShadowCheckDirective,
    ClientTranslationRatesComponent,
  ]
})
export class SharedModule { }
