<ng-template #thisModal>
    <div class="modal-header">
       <h3 class="modal-heading text-center">{{lang.getText('new_features')}}</h3>
    </div>
    <div class="modal-body pr-3 pl-3">
        <div>
            <p>Welcome to release 4.0.2 of FlowDezk. This is what we bring you this time:</p>
            <br />
        </div>
        <ul class="row releasenotes-list ml-0 mr-0">
            <li><p>We’re introducing our brand new <strong>FlowDezk Assistant</strong>. This new chat feature – which is available through the Help avatar in the lower right corner – will allow you to ask questions about FlowDezk in any language and get instant answers. This will save you the time otherwise spent on browsing through manuals looking for the correct way to use FlowDezk features. Please note that this is a beta version of our assistant and that it will get better over time.</p></li>
            <li><p>We’ve introduced a feature that many will appreciate – you can now <strong>skip the heads-up step</strong> and move straight into the confirmation step using the new Skip Heads-Up button.</p></li>
            <li><p>You can now also <strong>modify the sender address</strong> of invoices, purchase orders etc. This way you can make sure answers from clients and vendors are received in the desired inbox (like a shared inbox of a specific team for example).</p></li>
            <li><p>We’ve also extended the look and feel of our <strong>new UX</strong> to a few more screens, but we’re not there yet. Getting everything up to the same standard will take one or two additional releases.</p></li>
        </ul>
        <div>
            <p>Of course we’re also introducing a number of bug fixes and smaller improvements with this release.</p><br />
            <p>For now enjoy this new release and don’t hesitate to create a support ticket from FlowDezk or by sending an e-mail to <a href="mailto:support@flowdezk.com">support@flowdezk.com</a> if you run into trouble or would like some advice on FlowDezk best practices.</p>
        </div>
    </div>
    <div class="modal-bottom px-3">
        <div class="d-flex justify-content-end">
            <div class="col-auto me-2">
                <button type="button" (click)="hideReleaseNote()" class="btn btn-primary mr-2">{{lang.getText('i_ve_read_this')}}</button>
            </div>
            <div class="col-auto">
                <button type="button" (click)="closeModal()" class="btn btn-secondary">{{lang.getText('remind_me_next_time')}}</button>
            </div>
        </div>
    </div>
</ng-template>
