<ng-template #projectRelationListModal>
  <div class="new-redesign-style">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
        {{lang.getText('related_projects')}}
    </h4>
    <button type="button" class="close pull-right ps-2 pe-2 mb-5" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <button type="button" class="btn btn-primary ps-4 pe-4 border-radius-42 filter-drop-shadow" (click)="openProjectRelatationActionModal()">
      {{lang.getText('link_project')}}
    </button>
    <hr style="color: #6f6b6b;">
        <div class="row row-secondary mt-2 mb-2 p-2">
          <div class="col-md-5 text-start ps-5">{{ lang.getText('project') }}</div>
          <div class="col-md-3 text-start">  {{ lang.getText('comments2') }} </div>
          <div class="col-md-2 text-start">  {{ lang.getText('status') }}</div>
          <div class="col-md-2 text-start"> {{ lang.getText('actions') }}</div>
        </div>
        <ng-container *ngIf="this.projectRelations.length > 0">
          <div *ngFor="let project of this.projectRelations; let i=index" class="row content row-primary mb-2 ps-2 pe-2">
            <div class="col-md-5 col-sm-12 text-start ps-5">
              <a (click)="navigateToRelatedProject(this.parent_project_id, project.project_id, project.related_project_id)">{{ project.name }}</a>
            </div>
            <div class="col-md-3 col-sm-12 text-start">
              <span *ngIf="!project.isEditMode">{{ project.comment }}</span>
              <span *ngIf="project.isEditMode">
                <input type="text" class="form-control" [value]="project.comment" [(ngModel)]="comment" > 
              </span>
            </div>
            <div class="col-md-2 col-sm-12 text-start badge-empty badge-empty-padding mt-1 mb-1">
                  <span class="ms-3"> {{ lang.getText(project.status) }}<span [class]="project.status"></span></span>
            </div>
            <div class="col-md-2 col-sm-12 text-start">
              <ng-container *ngIf="!project.isEditMode">
                <a class="btn btn-circle btn-circle-sm me-2 ms-0" (click)="editProjectRelation(project.id, i)" title="{{ lang.getText('edit') }}">
                  <!-- <i class="fa fa-pencil  color-primary" aria-hidden="true"></i> -->
                  <img src="/assets/svg/edit-icon.svg">
                </a>
                <a class="btn btn-circle btn-circle-sm me-2 pb-2" (click)="unlinkProjectRelation(project.id, i)" title="{{ lang.getText('unlink') }}">
                  <!-- <i class="fa fa-chain-broken fa-rotate-90 color-secondary" aria-hidden="true"></i> -->
                  <img src="/assets/svg/unlink-icon.svg">
                </a>
              </ng-container>
              <ng-container *ngIf="project.isEditMode">
                <a class="btn btn-circle btn-circle-sm me-2 ms-0" (click)="updateProjectRelation(project.id, i)" title="{{ lang.getText('save') }}">
                  <i class="fa fa-save color-primary" aria-hidden="true"></i>
                </a>
                <a class="btn btn-circle btn-circle-sm me-2 ms-0" (click)="project.isEditMode = false" title="{{ lang.getText('cancel') }}">
                  <i class="fa fa-ban color-secondary" aria-hidden="true"></i>
                </a>
              </ng-container>
            </div>
          </div>
         </ng-container>
         <ng-container *ngIf="this.projectRelations.length == 0">
          <div class="row content row-primary mb-2 p-1">
            <div class="text-center">
              <p>{{ lang.getText('this_project_has') }} {{ project_relation_count }} {{ lang.getText('related_projects2') }}</p>
            </div>
          </div>
        </ng-container> 
  </div>
</div>
</ng-template>
<app-project-relation-action #openProjectRelatationActionCom (projectLinkedSuccess)="projectLinkedSuccess($event)"></app-project-relation-action>
