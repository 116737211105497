<div>
  <h5>{{lang.getText('support')}}</h5>
</div>
<div class="row">
<!-- <div class="row mt-4"> -->
  <div class="col-md-12">
    <div class="image-container">
      <div class="image-card" (click)="downUserManual()">
        <img 
          src="../../../assets/img/download-manual.svg"
          alt="Download Icon"
        />
        <p>{{ lang.getText("download_manual") }}</p>
      </div>
      <div class="image-card" (click)="openSuggestImprovement()">
        <img 
          src="../../../assets/img/suggest-improvement.svg"
          alt="suggest Icon"
        />
        <p>{{ lang.getText("suggest_improvement") }}</p>
      </div>
      <div class="image-card">
        <a href="mailto:support@flowdezk.com">
          <img (click)="closeModal()" src="../../../assets/img/create-support-ticket.svg" alt="Ticket Icon" />
          <p>{{ lang.getText("create_support_ticket") }}</p>
        </a>
      </div>
    </div>
    <!-- <img 
      src="../../../assets/img/help_avatar.png"
      class="help_avatar"
      alt="help_avatar"
    /> -->
  </div>
</div>
<app-suggest-improvement #suggestImprovement ></app-suggest-improvement>