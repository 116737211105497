<ng-template #modalTemp>
  <div class="modal-header">
    <h3 class="modal-title">{{lang.getText('invoice_details')}}</h3>
    <button (click)="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [ngClass]="{loading: ( loading() ) }" class="modal-body generate-invoice">
    <div *ngIf="invoiceForm" class="col-md-12 action-layout">
      <form [formGroup]="invoiceForm" class="col-md-12 no-padding">
        <div class="form-group w-100">
          <label class="bold">{{lang.getText('client_address')}}</label>
          <textarea formControlName=address class="form-control"></textarea>
        </div>
        <div class="form-group w-100 mt-2">
          <label class="bold">{{lang.getText('invoice_number')}}</label>
          <input type="text" formControlName=number class="form-control">
        </div>
        <div class="form-group w-100 mt-2">
          <label class="bold-new mt-3 mb-2">{{'decimal_separator' | translate}}</label>
        </div>
        <div class="form-group d-flex align-items-center">
          <div class="mt-2 mb-2 me-3 badge-empty col-md-2 form-group d-flex align-items-center">
            <input type="radio" formControlName="decimal_separator" value="." id="dot" class="form-check-input d-none">
            <label 
              class="form-check-label btn btn-circle btn-circle-sm me-2 ms-0" 
              [ngClass]="{
                'active': invoiceForm.get('decimal_separator').value === '.',
                'in-active': invoiceForm.get('decimal_separator').value !== '.'
              }" 
              for="dot">
              <i class="fa" [ngClass]="{'fa-check': invoiceForm.get('decimal_separator').value === '.'}"></i>
            </label>
            Dot
          </div>
          <div class="mt-2 mb-2 badge-empty col-md-2 form-group d-flex align-items-center">
            <input type="radio" formControlName="decimal_separator" value="," id="comma" class="form-check-input d-none">
            <label 
              class="form-check-label btn btn-circle btn-circle-sm me-2 ms-0" 
              [ngClass]="{
                'active': invoiceForm.get('decimal_separator').value === ',',
                'in-active': invoiceForm.get('decimal_separator').value !== ','
              }" 
              for="comma">
              <i class="fa" [ngClass]="{'fa-check': invoiceForm.get('decimal_separator').value === ','}"></i>
            </label>
            Comma
          </div>
        </div>
        <label class="bold-new mt-2 mb-2">{{lang.getText('tax_info')}}</label>
        <div class="form-row mt-3 mb-2">
          <div class="form-group col-12 mt-2 mb-2">
            <label>{{lang.getText('description_with_colon')}}</label>
            <select formControlName=taxDesc class="form-control">
              <option *ngFor="let desc of taxDescs" [value]=desc.name>{{desc.name}}</option>
            </select>
          </div>
          <div class="form-group col-12 mt-2 mb-2">
            <label>{{lang.getText('rate')}}</label>
            <select formControlName=taxRate class="form-control">
              <option *ngFor="let rate of taxRates" [value]=rate.rate>{{rate.rate}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" [checked]="showCurrencyDropdown" (change)="onChangeSecondCurrency()">
            <label class="form-check-label">{{'add_second_currency' | translate}}</label>
            <div *ngIf="showCurrencyDropdown">
              <select formControlName=secondCurrency class="form-control">
                <option *ngFor="let currency of currencies;" value="{{currency.id}}">{{currency.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <label class="bold mt-2 mb-2">{{lang.getText('include')}}</label> <br/>
        <div class="form-group">
          <div class="col-md-12 d-flex flex-row justify-content-between mt-2 pe-0 row">
            <div class="form-group col-auto d-flex flex-wrap"> 
              <div class="col-md-4 form-check form-switch pe-2 mb-3 me-3" formArrayName="includes" *ngFor="let include of includesFa['controls']; let i = index;">
                <label class="d-flex align-items-center w-100">
                  <input class="form-check-input me-3" [formControlName]=i [value]="getIncluds()[i].key" type="checkbox">
                  <span class="text-nowrap form-check-label">{{lang.getText(getIncluds()[i].langKey)}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        
        <!-- <div class="form-group">
          <label class="bold">{{lang.getText('include')}}</label> <br/>
            <div formArrayName="includes" *ngFor="let include of includesFa['controls']; let i = index;" class="form-check form-switch">
              <input type="checkbox" [formControlName]=i [value]="getIncluds()[i].key" class="form-check-input">
              <label class="form-check-label">{{lang.getText(getIncluds()[i].langKey)}}</label>
            </div>
          </div>   -->
      </form>
      <div>
        <div class="form-group my-4">
          <button (click)=generateInvoiceHTML() [disabled]="translationCount > 200" class="btn btn-primary ps-4 pe-4 border-radius-42">{{lang.getText('preview_invoice')}}</button>
        </div>
        <div *ngIf="invoiceHTML" class="form-group pdf-editor">
          <textarea [(ngModel)]="invoiceHTML" [ngxSummernote]="summernoteConfig.configPDF"></textarea>
        </div>
        <div *ngIf="invoiceHTML" class="form-group mt-4">
          <button class="btn btn-secondary preview-btn me-2 border-radius-42" (click)="generateInvoicePDF(false)"> {{lang.getText('preview_pdf')}}</button>
          <button (click)="generateInvoicePDF(true)" class="btn btn-primary border-radius-42">{{lang.getText('generate_invoice')}}</button>
        </div>
        <iframe *ngIf="invoicePDF" class="pdf-object border-radius-42" [src]="invoicePDF" type="application/pdf" title="invoicePDF" name="contents"></iframe>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #sendModalTemp>
  <div class="modal-header">
    <h1 class="modal-title">
      {{lang.getText('send_invoice')}}
    </h1>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <div [hidden]="invoiceSendStep !== 1">
    <h4> {{lang.getText('preview')}}</h4>
    <iframe *ngIf="pdfObjUrl" class="pdf-object" [src]="pdfObjUrl" type="application/pdf" title="pdfObjUrl"></iframe>
  </div>
  <div [hidden]="invoiceSendStep !== 2">
    <h4>{{lang.getText('send_to')}}</h4> 
    <div class="col-md-12">
      <div class="col-md-12 no-padding">
        <app-email-meta [(form_data)]="email_meta"></app-email-meta>
      </div>
    </div>
  </div>
    <div [hidden]="invoiceSendStep !== 3" class="alert alert-success">
      <strong>{{lang.getText('sending_invoice')}}</strong> 
    </div>
    <div [hidden]="invoiceSendStep !== 4" class="alert alert-success">
      <strong>{{ 'done' | translate }}</strong>
    </div>
    <div class="col-md-12 no-padding mt-3 pt-3">
      <button [hidden]="invoiceSendStep !== 1" type="button" class="btn btn-primary" (click)="invoiceSendStep = 2">
        <span aria-hidden="true">{{lang.getText('next')}}</span>
      </button>
      <button [hidden]="invoiceSendStep !== 2" type="button" class="btn btn-primary" (click)="sendInvoice()">
        <span aria-hidden="true">{{lang.getText('send')}}</span>
      </button>
    </div>
  </div>
</ng-template>
