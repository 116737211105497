import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SummerNoteConfig } from 'src/app/configs/summer-note-config';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-generate-quote',
  templateUrl: './generate-quote.component.html',
  styleUrls: ['./generate-quote.component.scss']
})
export class GenerateQuoteComponent implements OnInit {
  apiURL = env['API_URL'];
  @Input() projectId;
  @Input() orderId;
  @Input() clientId;
  @Input() quoteSendStep;
  @Input() translationCount;
  @Input() pMDetails;
  @Input() displayBreakdownsFc: UntypedFormControl;
  @Output() afterSubmitQuote = new EventEmitter();
  selectedClient;
  summernoteConfig:SummerNoteConfig = new SummerNoteConfig();
  quoteEmails;
  allQuoteEmails;
  httpReq;
  quoteEmailForm: UntypedFormGroup = this.fb.group({
    email: this.fb.control(''),
    subject: this.fb.control(''),
  })
  langCode;

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private token: TokenService,
    private helperSer: HelperService,
    private jwtwis: JwtauthService,
    private fb: UntypedFormBuilder,
    private loaderService: LoaderService,
    public lang: LanguageService
  ) { }

  ngOnInit() {
    this.langCode = this.lang.getCode();
  }

  @ViewChild('modalTemp') modalTemp;
  modalRef: BsModalRef;
  openModal(clientSelected) {
    this.selectedClient = clientSelected;
    this.quoteSendStep = 1;
    this.quoteEmails = [];
    this.allQuoteEmails = [];
    if (this.selectedClient && this.selectedClient.quoting_emails) {
      this.quoteEmails = this.selectedClient.quoting_emails.email;
      this.allQuoteEmails = this.selectedClient.quoting_emails.email;
    }
    if (this.pMDetails) {
      this.quoteEmails = [this.pMDetails.email].concat(this.quoteEmails);
      this.allQuoteEmails = [this.pMDetails.email].concat(this.allQuoteEmails);
    }
    if ( this.quoteEmails.length == 0 ) {
      this.quoteEmails.push(this.selectedClient.email)
    }
    this.quoteEmailForm.patchValue({subject: `Quote for ${this.orderId}`});
    this.modalRef = this.modalService.show(
      this.modalTemp,
      { class: 'modal-lg' }
    );
    this.getQuoteLanguage();
    this.getClientQuoteHTML();
  }

  closeModal() {
    this.modalRef.hide();
  }

  clientQuoteHTML
  getClientQuoteHTML() {
    this.loaderService.isLoadingSource.next(true);
    let formData = {
      token: this.token.get(),
      projectId: this.projectId,
      langCode: this.langCode,
      displayBreakdowns: this.displayBreakDown()
    };
    this.httpReq = this.http.post(`${this.apiURL}project/client-quote/getHTML`, formData)
      .subscribe(
        data => {
          this.loaderService.isLoadingSource.next(false);
          this.clientQuoteHTML = data;
        },
        error => {
          this.loaderService.isLoadingSource.next(false);
          this.handleError(error);
        }
      );
  }

  clientQuotePDF;
  getClientQuote() {
    this.selectedClient
    let formData = {
      token: this.token.get(),
      projectId: this.projectId,
      langCode: this.langCode,
      content: this.clientQuoteHTML
    };
    this.httpReq = this.http.post(`${this.apiURL}project/client-quote/get`, formData)
      .subscribe(
        data => {
          this.clientQuotePDF = this.helperSer.urlObjPDF(data['pdfB64']);
        },
        error => {
          this.handleError(error);
        }
      );
  }

  submitQuote(){
    this.quoteSendStep = 3;
    let postData = {
      token:                this.token.get(),
      projectId:            this.projectId,
      quote_email_subject:  this.quoteEmailForm.getRawValue().subject,
      clientQuoteHTML:      this.clientQuoteHTML,
      quote_emails:         this.quoteEmails
    };
    this.httpReq = this.http.post(`${this.apiURL}project/client-quote/send`, postData).subscribe(
      data => {
        this.quoteSendStep = 4;
        setTimeout(()=>{
          this.afterSubmitQuote.emit('yes');
          this.closeModal();
        }, 2 * 1000);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  addQuoteEmailIpt
  addQuoteEmail() {
    this.addQuoteEmailIpt = this.quoteEmailForm.get('email').value;
    this.helperSer.pushEmail(this.quoteEmails, this.addQuoteEmailIpt);
    this.helperSer.pushEmail(this.allQuoteEmails, this.addQuoteEmailIpt);
  }

  addQuoteEmailSubSave() {
    let subCon = this.quoteEmailForm.get('subject');
    subCon.enabled ? subCon.disable() : subCon.enable();
  }

  displayBreakDown() {
    return this.displayBreakdownsFc.value;
  }

  showAlert() {
    if ( this.translationCount < 201 && !this.displayBreakDown()) {
      return;
    }
    if ( this.translationCount > 100 && this.translationCount < 201) {
      this.helperSer.alertIt(this.lang.getText('project_langu_combi_max_100_breakdown_alert'));
    }
    if ( this.translationCount > 200 ) {
      this.helperSer.alertIt(this.lang.getText('project_langu_combi_max_200_alert'));
    }
  }
  
  loading() {
    return this.httpReq && ! this.httpReq.isStopped
  }

  handleError(error) {
    this.jwtwis.handleError(error);
  }

  getQuoteLanguage() {
    if (this.selectedClient.quoting_emails && this.selectedClient.quoting_emails.pdf_language) {
      this.langCode = this.selectedClient.quoting_emails.pdf_language;
    }
  }
}
