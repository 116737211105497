<ng-template #searchProjectModal>
  <div class="new-redesign-style">
    <div class="modal-header related-project-header">
      <div  class="col-md-12 row m-0 p-0">
        <div class="col-md-12 d-flex justify-content-between align-items-center m-0 p-0">
          <h5 class="modal-title">
            {{ lang.getText('search_project') }} 
          </h5>
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div> 
      </div>
    </div>
    
  <div class="modal-body">
    <div [formGroup]="searchProjectsForm" class="col-md-12 d-flex  align-items-center mt-2 mb-5 border-bottom ml-0 mr-0 ps-0 pe-0 pb-4">
      <div class="col-md-4 d-flex align-items-center">
        <input type="text" formControlName="search_text" class="form-control" placeholder="{{ lang.getText('enter_project_name') }}" />
      </div>
      <div class="col-md-2 d-flex align-items-center ps-4">
        <input (click)="findProjects()" class="btn btn-primary pe-4 ps-4 border-radius-42 filter-drop-shadow" type="button" value="{{lang.getText('search')}}" />
      </div>
    </div>

    <div class="row row-secondary mt-2 mb-2 p-2 project-list-content"  *ngIf="searchedProjects.length > 0">
      <div class="col-md-5 text-start">{{ lang.getText('project_name2') }}</div>
      <div class="col-md-5 text-start">  {{lang.getText('comments2')}}</div>
      <div class="col-md-2 text-start" (click)="!this.disable_link_projects_btn ? addSelectedProjects() : false" >  {{lang.getText('link')}}</div>
    </div>
    <ng-container *ngIf="searchedProjects.length > 0">
      <div class="scrollable-project-list">
        <div *ngFor="let project of searchedProjects;" class="row content row-primary mb-2 p-1 project-list-content">
          <div class="col-md-5 col-sm-12 text-start ">
            {{ project.name }}
          </div>
          <div class="col-md-5 col-sm-12 text-start">
            <input type="text" class="form-control" value="{{ project.comment }}" [(ngModel)]="project.comment">
          </div>
          <div class="col-md-2 col-sm-12 text-start">
            <div class="status d-flex align-items-center justify-content-md-center" (click)="toggleProjectChecked(project)">
              <!-- <i class="fa fa-check active" aria-hidden="true" *ngIf="project.checked"></i>
              <i class="fa fa-link in-active fa-rotate-90" aria-hidden="true" *ngIf="!project.checked"></i> -->
              <img src="/assets/svg/checked-icon.svg" *ngIf="project.checked">
              <img src="/assets/svg/link-icon.svg" *ngIf="!project.checked">
            </div>
            <input type="checkbox" value="{{ project.id }}" [(ngModel)]="project.checked" (change)="setSelectedProjectCount($event)" class="form-check-input d-none">
          </div>
        </div>
      </div>
      <div class="position-relative">
        <input (click)="!this.disable_link_projects_btn ? addSelectedProjects() : false" 
               class="btn btn-primary pe-4 ps-4 mt-3 mb-3 text-left save border-radius-42 filter-drop-shadow" 
               type="button" value="{{lang.getText('save')}}" />
      
        <span class="badge rounded-pill bg-success position-absolute top-0 start-0 translate-middle" *ngIf="selected_projects_count > 0">
          {{ selected_projects_count }}
        </span>
      </div>
      
      
    </ng-container>
    
  <ng-container *ngIf="searchedProjects.length == 0">
    <div class="row content row-primary mb-2 p-1">
      <p class="text-center">{{ lang.getText('no_matching_search_results') }}</p>
    </div>
  </ng-container>
  </div>
</div>
</ng-template>


<!-- <ng-template #searchProjectModal>
  <div class="new-redesign-style">
  <div class="modal-header related-project-header">
    <div [formGroup]='searchProjectsForm' class="col-md-12 row m-0 p-0">
      <div class="col-md-12 m-0 p-0 align-items-center">
        <h5 class="modal-title pull-left">
          {{ lang.getText('search_project') }}
        </h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="col-md-12 row m-0 p-0">
        <div class="col-md-4 m-0 p-0">
          <input type="text" formControlName="search_text" class="form-control" placeholder="{{ lang.getText('enter_project_name') }}" />
        </div>
        <br />
        <div class="col-md-12 m-0 p-0">
          <input (click)="findProjects()" class="btn btn-primary mt-3" type="button" value="{{lang.getText('search')}}" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <table class="table related-project related-project-table" aria-label="Project relation details">
      <thead role="rowgroup">
        <tr role="row">
          <th id="project-name" role="columnheader" class="col-md-5">{{ lang.getText('project_name2') }}</th>
          <th id="comments" role="columnheader" class="col-md-5">{{ lang.getText('comments2') }}</th>
          <th id="select-project" role="columnheader" class="col-md-2 text-center">
            <button (click)="!this.disable_link_projects_btn ? addSelectedProjects() : false" 
              type="button" 
              class="btn btn-primary btn-sm link-projet-btn"
              [disabled]="this.disable_link_projects_btn">
              {{ lang.getText('link_selected_projects') }}
            </button>
          </th>
        </tr>
      </thead>
      <tbody role="rowgroup">
        <ng-container *ngIf="searchedProjects.length > 0">
          <tr *ngFor="let project of searchedProjects;">
            <td class="col-md-5">{{ project.name }}</td>
            <td class="col-md-5">
              <input type="text" class="form-control" value="{{ project.comment }}" [(ngModel)]="project.comment">
            </td>
            <td class="col-md-2 text-center">
              <input type="checkbox" value="{{ project.id }}" [(ngModel)]="project.checked" (change)="setSelectedProjectCount($event)" class="form-check-input">
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="searchedProjects.length == 0">
          <tr>
            <td colspan="3" class="text-center">{{ lang.getText('no_matching_search_results') }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
</ng-template> -->

