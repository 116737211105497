<div [formGroup]="edit_form" class="row new-redesign-style">
    <!-- <div *ngIf="!is_project_page" class="col-12">
        <button type="button" (click)="add_t_rate()" class="btn btn-primary">{{ 'add_rate' | translate }}</button>
    </div> -->
  <div class="col-12">
    <div class="row row-secondary p-2">
      <div class="col-lg-2 text-start ps-5">{{ 'source' | translate }}</div>
      <div class="col-lg-2 text-start">{{ 'target' | translate }}</div>
      <div class="col-lg-2 text-start">{{ 'description' | translate }}</div>
      <div class="col-lg-2 text-start">{{ 'currency' | translate }}</div>
      <div class="col-lg-2 text-start">{{ 'rate' | translate }}</div>  
      <div class="col-lg-2 text-end icons pe-5">{{ 'actions' | translate }}</div>
    </div>
  </div>
  <div class="col-12 mt-2" *ngIf="rates == null">
    <div class="row row-primary mb-2 p-2">
      <div class="col-12 text-center">{{ 'no_records_found' | translate }}</div>
    </div>
  </div>
  <div formArrayName="t_rates" *ngFor="let t_rate of edit_form.get('t_rates')['controls']; let ri = index" class="col-12 mt-2">
    <div class="row row-primary mb-2 p-1" [formGroupName]="ri">
      <div *ngIf="t_rate.enabled" class="col-12">
        <div *ngFor="let error of validation_errors" class="text-danger text-start">{{error}}</div>
      </div>
      <div class="col-lg-2 text-start ps-5">
        <select formControlName="source_lang_id" class="form-control" [ngClass]="t_rate.get('source_lang_id').enabled ? 'enabled' : 'disabled'">
          <option *ngFor="let language of languages;" [value]="language.id">{{language.name}}</option>
        </select>
      </div>
      <div class="col-lg-2 text-start">
          <select formControlName="target_lang_id" class="form-control" [ngClass]="t_rate.get('target_lang_id').enabled ? 'enabled' : 'disabled'">
            <option *ngFor="let language of languages;" [value]="language.id">{{language.name}}</option>
          </select>
      </div>
      <div class="col-lg-2 text-start">
        <input formControlName="description" type="text" class="form-control" [ngClass]="t_rate.get('description').enabled ? 'enabled' : 'disabled'" />
      </div>
      <div class="col-lg-2 text-start">
        <select formControlName="currency_id" class="form-control" [ngClass]="t_rate.get('currency_id').enabled ? 'enabled' : 'disabled'">
          <option *ngFor="let currency of currencies;" [value]="currency.id">{{currency.name}}</option>
        </select>
      </div>
      <div class="col-lg-2 text-start">
        <input formControlName="word_rate" (keypress)="help_ser.non_numeric_val_alert($event)" type="number" min="0" class="form-control" [ngClass]="t_rate.get('word_rate').enabled ? 'enabled' : 'disabled'" />
      </div>
      <div class="col-lg-2 icons text-end pe-3">
        <ng-container *ngIf="!is_project_page">
          <button class="btn btn-circle btn-circle-sm me-2" *ngIf="t_rate.disabled" (click)="edit_t_rate(t_rate)" title="{{'edit' | translate}}">
            <!-- <i class="fa fa-pencil color-primary fa-lg" aria-hidden="true"></i>  -->
            <img src="/assets/svg/edit-icon.svg">
          </button>
          <button class="btn btn-circle btn-circle-sm me-2" *ngIf="t_rate.enabled" (click)="update_t_rate(t_rate)" title="{{'edit' | translate}}">
            <i class="fa fa-save color-primary fa-lg" aria-hidden="true"></i>
          </button>
          <button class="btn btn-circle btn-circle-sm pb-2" (click)="delete(ri)" title="{{'delete' | translate}}">
            <!-- <i class="fa fa-trash color-secondary fa-lg" aria-hidden="true"></i> -->
            <img src="/assets/svg/delete-icon.svg">
          </button>
        </ng-container>
        <ng-container *ngIf="is_project_page">
          <button (click)="select_t_rate(t_rate)" type="button" class="btn btn-primary">{{'select' | translate}}</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
