import { Component, OnInit, ViewChild, TemplateRef, EventEmitter,ViewEncapsulation, ElementRef} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { Injector } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, FormControl} from '@angular/forms';

import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';
import { HelperService } from '../services/helper.service'
import { FileUploadBuilderComponent } from './file-upload-builder.component';
import { SapDetailsComponent } from './sap-details/sap-details.component';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { AddVendorComponent } from './components/add-vendor/add-vendor.component';
import { VendorRatesComponent } from './components/vendor-rates/vendor-rates.component';
import {LanguageService} from '../services/language.service';
import { GenerateInvoiceComponent } from '../modules/shared/components/generate-invoice/generate-invoice.component';
import { SummerNoteConfig } from '../configs/summer-note-config';
import { CreateCatComponent } from './components/create-cat/create-cat.component';
import { ProjectRelationListComponent } from './components/project-relation-list/project-relation-list.component';
import { ProjectClientLanguageTranslationsComponent } from './components/project-client-language-translations/project-client-language-translations.component';
import { ProjectVendorLanguageTranslationsComponent } from './components/project-vendor-language-translations/project-vendor-language-translations.component';
import { GenerateQuoteComponent } from './components/generate-quote/generate-quote.component';
import { ProjectService } from '../services/project.service';
import { VendorInvoiceComponent } from '../modules/shared/components/vendor-invoice/vendor-invoice.component';
import { PoListComponent } from '../modules/shared/components/po-list/po-list.component';
import { InvoiceListComponent } from '../modules/shared/components/invoice-list/invoice-list.component';
import { FinanceBreakdownComponent } from './components/finance-breakdown/finance-breakdown.component';
import { VendorFinanceBreakdownComponent } from './components/vendor-finance-breakdown/vendor-finance-breakdown.component';
import { DeliveryBatchesComponent } from './components/delivery-batches/delivery-batches.component';
import { interval, Subscription } from 'rxjs';
import { map, mergeMap, switchMap } from "rxjs/operators";
import { DisplaySettingsService } from '../services/display-settings.service';
import { PaginationComponent } from 'src/app/modules/shared/components/pagination/pagination.component';
import { PhraseAssignJobsComponent } from './components/phrase-assign-jobs/phrase-assign-jobs.component';

@Component({
  selector: 'app-project-edit-page',
  templateUrl: './project-edit-page.component.html',
  styleUrls: [
    'project-create-page.component.scss',
    'project-main-tab-page.component.scss',
  ]
})

export class ProjectEditPageComponent implements OnInit {
  projectEditForm: UntypedFormGroup;
  initForm: boolean = false;
  apiURL = '';
  formData;
  currencies;
  errors;
  clientId;
  clientSelected;
  clients;
  clientsDropdownOptions;
  clientsDDConfig;
  currencyId;
  currencySelected;
  currencyDDConfig;
  supplierServices;
  project;
  projectId;
  commentDetails;
  languages;
  wordRateTypes = [];
  wordMatches;
  wordMatchesA;
  wordMatchesB;
  wordCount; wordUnit;
  translationsFA;
  translationsFAOrignal;
  financeLangTrans;
  catAnalysisFA;
  deliveryBatchesFA;
  additionalServicesFA;
  translationsFilesFA;
  vendorsFA;
  subCost;
  totalCost;
  allVendorsComments = [];
  additionalServicesTotalCost;
  formEventStop = {emitEvent: false};
  wordTypeRates;
  //addSerUnits = ['hours', 'days', 'lines', 'weeks']
  addSerUnits;
  hours = [];
  minutes = [0, 30];
  vendors; vendorsDD;
  @ViewChild(FileUploadBuilderComponent) fileUploadBuilder;
  @ViewChild(SapDetailsComponent) sapDetailsCompo;
  @ViewChild('vendorsComment') vendorsCommentTemp: TemplateRef<any>;
  vendorServices;
  qualityAssurances = null;
  catRateDecimalRound = 3;
  decimalRound = 2;
  projectDelivered = true;
  accountingClosed;
  projectEditDis = false;
  pStatuses;
  vendorCommentValue:string;
  quoteRejectedB = false;
  saving = false;
  createProject = false;
  showProjectProgress = {
    display: false,
    data: []
  }
  showXTMProjectProgress = {
    display: false,
    data: []
  }
  showPhraseProjectProgress = {
    display: false,
    data: []
  }
  loadingXTM = false;
  loadingPhrase = false;
  allowLinkingBathch = true;
  bsConfig = {
    dateInputFormat: 'DD/MM/Y',
    rangeInputFormat: 'DD/MM/Y',
  };
  commentsInstructionText;
  unsavedProject = false;
  redirectAfterSave = true;
  myDateYMD;
  quoteLastUpdated = '';
  clientName;
  orderId = '';
  sapData = {
    readOnly: true
  }
  sapEnabled:Boolean;
  lang;
  summernoteConfig:SummerNoteConfig = new SummerNoteConfig();
  projectClaimed = false;
  claimedPopupDisplayed = false;
  @ViewChild('claimedPopup') claimedPopup: TemplateRef<any>;
  showProjectJobProgress = {
    display: false,
    data: []
  }
  defaultLangCode;
  isSAPSolProject = false;
  project_relation_count = 0;
  trasnlation_pagination_data = {
    quote_per_page: 10,
    quote_page: 1,
    quote_total_pages:1,
    quote_page_loading: false,
    quote_show_hide_financially_closed: false,
    res_per_page: 10,
    res_page: 1,
    res_total_pages:1,
    res_page_loading: false,
    res_show_hide_financially_closed: false,
    delivery_per_page: 10,
    delivery_page: 1,
    delivery_total_pages: 1,
    delivery_page_loading: false,
    finance_per_page: 10,
    finance_page: 1,
    finance_total_pages: 1,
    trados_files_per_page: 50,
    trados_files_page: 1,
    trados_files_total_pages: 1,
    trados_files_loading: false,
    quote_batch_delivery_per_page: 10,
    quote_batch_delivery_page: 1,
    quote_batch_delivery_total_pages: 1,
    quote_batch_delivery_page_loading: false,
    xtm_files_per_page: 50,
    xtm_files_page: 1,
    xtm_files_total_pages: 1,
    xtm_files_loading: false,
    phrase_files_loading: false,
    phrase_files_page: 1,
    phrase_files_per_page: 50,
    phrase_files_total_pages: 1,
  }
  prevNextHttpReq;
  openedFileDeliverSecions: Array <number> = [];
  openedServicesFileDeliverSecions: Array <number> = [];
  currenciesDDConfig;
  is_freelancer: Boolean;

  @ViewChild(ProjectClientLanguageTranslationsComponent) projectClientLangTransCom : ProjectClientLanguageTranslationsComponent;
  @ViewChild(ProjectVendorLanguageTranslationsComponent) projectVendorLangTransCom : ProjectVendorLanguageTranslationsComponent;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public helperSer: HelperService,
    private projectSer: ProjectService,
    public language: LanguageService,
    public displaySettSer: DisplaySettingsService
    ) {
    this.summernoteConfig.config['height'] = '400px';
    this.apiURL = this.injector.get('API_URL');
    this.route.params.subscribe(params => {
      this.projectId =  params['id']
    });
    this.lang = language;
    if (this.projectId == 0)
      this.createProject = true;
    this.getFormMetaDetails();
    for(var i = 0; i < 24; i++){
      this.hours.push(i);
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }


  ngOnDestroy() {
    if (this.unsavedProject == false) return;
    if(this.projectClaimed){
      if ( confirm(this.lang.getText('do_you_want_to_save_all_changes')) ){
        this.redirectAfterSave = false;
        this.onSubmitProjectEditForm();
      }
    }

  }

  getFormMetaDetails(){
    this.getClients();
    if (this.createProject){
      //this.getCommentsInstructionText();
    }
    else {
      this.getProjectDetails();
      this.getInvoice('yes');
      this.checkClaimedProject();
      this.get_all_files();
    }
    this.getSupplierServices();
    this.getLanguages();
    this.getWordRateTypes();
    this.getWordMatches();
    this.getWordTypeRates();
    this.getVendors();
    this.getCurrencies();
    this.getAddSerUnits();
    //this.getPStatuses();
    this.getInitialOrder();
    this.getCurrentCurrency();
  }

  @ViewChild('delivery_batches_com') delivery_batches_com: DeliveryBatchesComponent;
  batch_si
  initProjectEditForm() {
    let unloaded =
      !this.clients ||
      !this.supplierServices ||
      !this.languages ||
      !this.wordRateTypes ||
      !this.wordMatches ||
      !this.vendors ||
      !this.currencies ||
      !this.addSerUnits ||
      this.initialOrderId === null ||
      this.currentCurrency == null ||
      !this.wordTypeRates

    if (this.createProject) {
      this.project_info_closed = false;
      //unloaded = unloaded || !this.commentsInstructionText;
    }
    else {
      unloaded = unloaded ||
        !this.project ||
        this.all_files == null
    }
    if ( unloaded ) {
      return;
    }
    if (this.createProject) {
      this.project = [];
      this.project.project_status_id = 0;
      this.project.services = [];
      this.project.qualityAssurances = [];
      this.project['catAnalysis'] = [];
      this.project['vendorTranslations'] = [];
    }
    else {
      this.getPMDetails();
      this.quoteLastUpdated = this.helperSer.formateDateObj(this.project['quoteLastUpdated']);
      this.orderId = this.project.id + this.initialOrderId;
    }
    this.quoteDisplayBreakdownsFc = this.fb.control(false)
    this.quoteDisplayBreakdownsFc.valueChanges.subscribe(val => {
      this.generateQuoteModal?.showAlert();
    });

    let dateTime = {date: 0, hour: 10, minute: 0};
    //const qAControls = this.qualityAssurances.map(qA => new FormControl( this.project.qualityAssurances.includes(qA.id )));
    let qAControls = [];
    this.projectEditForm = this.fb.group({
      bTz: - ( new Date().getTimezoneOffset() ),
      manual_status: '',
      name: '',
      request_date: '',
      ignore_warning: '',
      request_by: '',
      client_ref: '',
      client_comment: '',
      currency_id: '',
      currency_code: '',
      discription: '',
      owner: '',
      quote_issued_date: '',
      cost_centre: '',
      comments: '',
      volume_remaining: '',
      project_delivery: this.getDateTimeForm(dateTime),
      commit_delivery_only_at: this.getDateTimeForm(dateTime),
      services: this.fb.array(this.project.services),
      translations: new UntypedFormArray([]),
      translations_orignal: new UntypedFormArray([]),
      finance_translations: new UntypedFormArray([]),
      catAnalysis: new UntypedFormArray([]),
      additionalServices: new UntypedFormArray([]),
      translationsFiles: new UntypedFormArray([]),
      deliveryBatches: new UntypedFormArray([]),
      deliveryBatchesDeleted: new UntypedFormArray([]),
      batchesLeadTime: '',
      langTransTotalCost: '0.00',
      additionalServiceCost: 0,
      clientDiscount: 0,
      clientDiscountPercentage: 0,
      totalCost: 0,
      totalBeforeDiscount: '0.00',
      currencyAllVendors: '',
      vendors: new UntypedFormArray([]),
      vendorsCost: 0,
      qualityAssurances: new UntypedFormArray(qAControls),
      qa_comments: '',
      send_accounting: '',
      project_status_id: '',
      quote_rejected_at: '',
      submit_quote: '',
      quote_email_subject: '',
      cancel_quote: '',
      approve_quote: '',
      invoiceItCB: '',
      download_link: '',
      upload_link: '',
      delivered_at: '',
      invoiced_at: '',
      invoiced_at_cb: '',
      invoiced_paid_at: '',
      invoiced_paid_at_cb: '',
      quoteDisplayBreakdowns: this.quoteDisplayBreakdownsFc
    });
    this.initForm = true;
    this.catAnalysisFA = this.projectEditForm.get('catAnalysis') as UntypedFormArray;
    this.translationsFA = this.projectEditForm.get('translations') as UntypedFormArray;
    this.translationsFAOrignal = this.projectEditForm.get('translations_orignal') as UntypedFormArray;
    this.financeLangTrans = this.projectEditForm.get('finance_translations') as UntypedFormArray;
    
    this.additionalServicesFA = this.projectEditForm.get('additionalServices') as UntypedFormArray;
    this.deliveryBatchesFA = this.projectEditForm.get('deliveryBatches') as UntypedFormArray;
    this.translationsFilesFA = this.projectEditForm.get('translationsFiles') as UntypedFormArray;
    this.vendorsFA = this.projectEditForm.get('vendors') as UntypedFormArray;
    if (!this.createProject && this.project.manual_status == 0) {
      this.projectEditForm.get('project_status_id').valueChanges.subscribe(value => {
        let mStat = 0
        if (this.project.project_status_id != value)
          mStat = 1
        this.projectEditForm.patchValue({manual_status: mStat});
      });
    }
    this.translationsFA.valueChanges.subscribe(values => {
      // this.calculateTotalCost();
      this.calculateTotalCostOrignal();
    });
    this.additionalServicesFA.valueChanges.subscribe(values => {
      // this.calculateTotalCost();
      this.calculateTotalCostOrignal();
    });
    this.projectEditForm.get('clientDiscount').valueChanges.subscribe(value => {
      // this.calculateTotalCost();
      this.calculateTotalCostOrignal();
    });
    this.projectEditForm.get('clientDiscountPercentage').valueChanges.subscribe(value => {
      // this.calculateTotalCost();
      this.calculateTotalCostOrignal();
    });
    /*this.deliveryBatchesFA.valueChanges.subscribe(values => {
      //console.log(values)
    })*/

    if (this.createProject) {
      this.projectEditForm.patchValue({
        owner: (this.Jwtwis.getMyDetails()).name,
        currency_id: this.currentCurrency,
        currencyAllVendors: this.currentCurrency,
      });
      return;
    }
    else {
      this.getQualityAssurances();
    }

    if ( this.project.send_accounting || this.project.finance_closed )
      this.projectEditDis = true;
    this.accountingClosed = this.project.send_accounting;

    this.clients.filter(v => {
      if (v.id == this.project.client_id)
        this.clientSelected = v;
    })
    this.currencies.filter(v => {
      if (v.id == this.project.currency_id)
        this.currencySelected = v;
    })
    this.clientId = this.project.client_id;
    this.currencyId = this.project.currency_id;
    this.commentDetails = this.setComments_details();
    this.getHeadsUpTemplates(this.clientId);
    this.projectEditForm.patchValue({
      manual_status: this.project.manual_status,
      name: this.project.name,
      request_date: this.splitDateAndTime(this.project.request_date).date,
      ignore_warning: this.project.ignore_warning,
      request_by: this.commentDetails.request_by,
      client_ref: this.commentDetails.client_ref,
      client_comment: this.commentDetails.client_comment,
      discription: this.project.discription,
      owner: this.project.owner != null ? this.project.owner.name : '',
      //quote_issued_date: this.splitDateAndTime(this.project.quote_issued_date).date,
      quote_issued_date: this.splitDateAndTime(this.project.quote_issued_date).date,
      cost_centre: this.commentDetails.cost_centre,
      comments: this.commentDetails.comments,
      volume_remaining: this.project.volume_remaining,
      batchesLeadTime: this.commentDetails.batchesLeadTime,
      send_accounting: this.project.send_accounting,
      project_status_id: this.project.project_status_id,
      quote_rejected_at: this.project.quote_rejected_at,
      currency_id: this.project.currency_id,
      project_delivery: this.splitDateAndTime(this.project.project_delivery),
      commit_delivery_only_at: this.splitDateAndTime(this.project.commit_delivery_only_at),
      delivered_at: this.project.delivered_at,
      invoiced_at: this.splitDateAndTime(this.project.invoiced_at).date,
      invoiced_at_cb: this.splitDateAndTime(this.project.invoiced_at).date,
      invoiced_paid_at: this.splitDateAndTime(this.project.invoiced_paid_at).date,
      invoiced_paid_at_cb: this.splitDateAndTime(this.project.invoiced_paid_at).date,
      langTransTotalCost: this.project.langTransTotalCost,
      quoteDisplayBreakdowns: this.project.quote_display_breakdowns,
      qa_comments: this.project.qa_comments,
      currencyAllVendors: this.currencyId
    })

    /*
    this.project['translations'].map(v => {
      let fg = this.initTranslationFormOrignal(v.id)
      fg.patchValue({
        delivered: v.delivered,
        project_translate_id: v.id,
        source_lang_id: v.source_lang_id,
        target_lang_id: v.target_lang_id,
        word_rate: v.word_rate,
        amount: v.amount,
        invoiceable: (v.invoiceable ? true : false),
        comment: v.comment,
        translation_meta_values: v.translation_meta_values
      });
    });
    */
    this.trasnlation_pagination_data.finance_total_pages = Math.ceil(this.translationsFAOrignal.length / this.trasnlation_pagination_data.finance_per_page);
    
    this.project['additionalServices'].map(v => {
      let fg = this.initAdditionalServiceForm();
      fg.patchValue({
        delivered: v.delivered,
        pAsId: v.id,
        comment: v.comment,
        unit: v.add_service_unit_id,
        volume: v.volume,
        rate: this.helperSer.roundOfToFractionStr(v.rate, this.decimalRound),
        amount: 0,
        invoiceable: (v.invoiceable ? true : false),
        financially_closed: (v.financially_closed ? true : false),
      });
      this.updateProjectDelivered(v.delivered);
    });
    this.allowLinkingBathch = true;
    
    this.allVendorsComments = [];
    this.project['vendors'].map((projVal, pVi) => {
      let vForm = this.initVendorForm()
      let display = this.currentDisplayVendor == projVal.vendor_id ? true : false;
      vForm.patchValue({
        display: display,
        isUpdated: display,
        project_vendor_id: projVal.id,
        vendor_id: projVal.vendor_id,
        heads_up: projVal.heads_up,
        last_updated_at: this.splitDateAndTime(projVal.updated_at).date,
        heads_up_template_id: projVal.heads_up_temp_id,
        upload_link: projVal.upload_link,
        download_link: projVal.download_link,
        headsUpContent: projVal.heads_up_temp_content,
        confirm: projVal.confirm,
        delivered_at: projVal.delivered_at,
        created_at: this.splitDateAndTime(projVal.created_at).date,
        emails: projVal.vendor_emails,
        totalCost: projVal.amount,
        deliveryComments: projVal.comments,
        phrase_provider_id: projVal.vendor_phrase_details != null ? projVal.vendor_phrase_details.phrase_provider_id : null
      });
      if ( projVal.comments ) {
        this.allVendorsComments.push({
          details: this.getVendorDetails(projVal.vendor_id),
          comments: projVal.comments
        })
      }

      this.optimizeVendorDD();
      this.vendorsFA.controls[pVi].controls['vendor_id'].disable();
      
      let currentCurrencyID = projVal.currency_id;
      if(currentCurrencyID == 0){  currentCurrencyID = this.currentCurrency; }
      this.projectEditForm.patchValue({currencyAllVendors: currentCurrencyID}, this.formEventStop);
      this.vendorChange(projVal.vendor_id, pVi);
      let ti = 0;
      this.project['vendorTranslations'].map((venTraVal, venTrai ) => {
        if (this.vendorsFA.getRawValue()[pVi].vendor_id != venTraVal.vendor_id) return;

        this.initVendorTranslationFormOrignal(pVi);
        let vTranCons = this.vendorsFA.controls[pVi].controls.translations_orignal.controls
        let venTrnIn = (vTranCons.length)-1
        vTranCons[venTrnIn].patchValue({
          project_translate_id: venTraVal.id,
          source_lang_id: venTraVal.source_lang_id,
          target_lang_id: venTraVal.target_lang_id,
          word_rate: venTraVal.word_rate,
          client_translation_meta_values: venTraVal.client_translation_meta_values,
          amount: venTraVal.amount
        }, this.formEventStop)
        let venTranCombiFg = this.vendorsFA.at(pVi).get('translationsCombi').at(venTrnIn)
        this.project['vendorTranslationsCombi'].map((vTCVal) => {
          let fb = venTranCombiFg.controls
          this.updateVendorCombiValues(venTraVal.id, fb.catAnalysGroA, vTCVal);
          this.updateVendorCombiValues(venTraVal.id, fb.catAnalysGroB, vTCVal);
        })

        let vTServicesFa = venTranCombiFg.get('services') as UntypedFormArray;
        this.project['vendorTranCombiServices'].map((vTCSVal) => {
          if ( vTCSVal.project_translate_id == venTraVal.id ){
            vTServicesFa.push(this.fb.control(vTCSVal.vendor_tran_service_id));
          }
        });

        this.project['vendorTranBatches'].map((vTBatch) => {
          if ( vTBatch.project_tran_or_service_id == venTraVal.id ) {
            let fb = this.initVendorBatchForm(pVi, 'translations_orignal', ti)
            fb.patchValue({
              description: vTBatch.description,
              delivery_at: this.splitDateAndTime(vTBatch.delivery_at),
            }, this.formEventStop);
          }
        })
        ti++
      });
      let si = 0;
      this.project['vendorAdditionalServices'].map((venASVal, vasi) => {
        if (this.vendorsFA.getRawValue()[pVi].vendor_id != venASVal.vendor_id) return;
        let fb = this.initVendorAddiService(pVi);
        fb.patchValue({
          pAsId: venASVal.id,
          comment: venASVal.comment,
          unit: venASVal.add_service_unit_id,
          volume: venASVal.volume,
          rate: venASVal.rate,
          amount: this.helperSer.roundOfToFractionStr(venASVal.amount, this.decimalRound),
          project_finances_id: venASVal.project_finances_id,
          financially_closed: (venASVal.financially_closed == 1 ? true : false)
        }, this.formEventStop)
        this.project['vendorAddServiceBatches'].map((vTBatch) => {
          if ( vTBatch.project_tran_or_service_id == venASVal.id ) {
            let fb = this.initVendorBatchForm(pVi, 'additionalServices', si)
            fb.patchValue({
              description: vTBatch.description,
              delivery_at: this.splitDateAndTime(vTBatch.delivery_at),
            }, this.formEventStop);
          }
        })
        si++;
      });

      let vendorTransLength = this.vendorsFA.at(pVi).controls.translations_orignal.getRawValue().length;
      let total_trans_pages = Math.ceil(vendorTransLength / vForm.get('trans_per_page').value)
      vForm.patchValue({
        'total_trans_pages' : total_trans_pages
      })
      this.getPageOfFinanceVendorLangTrans(pVi);
    });
    setTimeout(()=>{
      this.paginateVendors();
    }, 500);

    let clientDiscount = 0;
    let clientDiscountPercentage = 0;
    if (this.project.is_cli_disc_percent == 1){
      clientDiscountPercentage = this.project.client_discount;
    } else {
      clientDiscount = this.project.client_discount;
    }
    this.projectEditForm.patchValue({
      clientDiscount: clientDiscount,
      clientDiscountPercentage: clientDiscountPercentage,
    }, this.formEventStop);

    this.projectEditForm.valueChanges.subscribe(val => {
      this.unsavedProject = true;
    })

    // this.calculateTotalCost();
    this.calculateTotalCostOrignal();
    // this.calculateVendorTotalCost();
    // this.calculateVendorTotalCostOrignal();
    this.update_financial_details();
    this.processClientDetails();
    setTimeout(()=>{
      this.projectEditForm.valueChanges.subscribe(data => this.checkClaimed())
    },500);
    // Check for different source in combinations
    this.onSourceLanguageChange();
    
    this.defaultLangCode = this.lang.getCode()
    this.checkXTMData();
    this.isSAPSolProject = this.project.type_of == 'sap' ? true : false;
    this.project_relation_count = this.project.project_relation_count + this.project.project_reverse_relation_count;

    this.getPageOfDeliveryLangTrans();
    this.getPageOfFinanceLangTrans();
    this.getPStatuses();
    if ( this.delivery_batches_com ) { // Used on reload of project, initially load ngAfterView
      this.delivery_batches_com.getBatches(1,'');
    }
  }

  mapProjectQuoteTrasnlations(event) {
    let translations = event.translations;
    let catAnalysis = event.catAnalysis;
    translations.map(v => {
      let fg = this.initTranslationForm(v.id, catAnalysis);
      let translateMetaValues = [];
      let jobStatus = null;
      if (v.translation_meta_values.length > 0) {
        let tmv = v.translation_meta_values[0];
        if (this.checkXTMData()) {
          let xtmJobId = {
            'project_translate_id': v.id,
            'meta_key': 'sap_xtm_job_id',
            'meta_value': v.xtm_job_id
          }
          translateMetaValues[0] = xtmJobId;
        }
        jobStatus = tmv.xtm_job_status;
        translateMetaValues[1] = {
          'project_translate_id': tmv.project_translate_id,
          'meta_key': 'sap_xtm_job_status',
          'meta_value': tmv.xtm_job_status
        }
        translateMetaValues[2] = {
          'project_translate_id': tmv.project_translate_id,
          'meta_key': 'sap_xtm_job_status_percent',
          'meta_value': tmv.xtm_job_status_percent
        }
        let percentage = tmv.xtm_job_status_percent;
        let progressColor = '';
  
        if(percentage >= 0 && percentage < 25){
          progressColor = 'red-bar';
        }
        if(percentage >= 25 && percentage < 75){
          progressColor = 'yellow-bar';
        }
        if(percentage >= 75 && percentage <= 100){
          progressColor = 'green-bar';
        }
        this.showProjectJobProgress.data[tmv.project_translate_id] = {
          'color': progressColor,
          'percentage': percentage
        };
        translateMetaValues[5] = {
          'project_translate_id': tmv.project_translate_id,
          'meta_key': 'sap_xtm_job_file_name',
          'meta_value': tmv.xtm_job_file_name
        }
        translateMetaValues[3] = {
          'project_translate_id': tmv.project_translate_id,
          'meta_key': 'sap_xtm_job_steps',
          'meta_value': tmv.xtm_job_steps
        }
        translateMetaValues[4] = {
          'project_translate_id': tmv.project_translate_id,
          'meta_key': 'sap_xtm_job_words_to_be_done',
          'meta_value': tmv.xtm_job_words_to_be_done
        }
      }
      fg.patchValue({
        delivered: v.delivered,
        project_translate_id: v.id,
        source_lang_id: v.source_lang_id,
        target_lang_id: v.target_lang_id,
        word_rate: v.word_rate,
        amount: v.amount,
        orignalamount: v.amount,
        invoiceable: (v.invoiceable ? true : false),
        comment: v.comment,
        translation_meta_values: translateMetaValues,
        financially_closed: (v.financially_closed == 1 ? true : false),
        jobStatus: jobStatus,
        project_finances_id: v.project_finances_id
      });

      if (!this.checkTradosData('alc') || this.checkXTMData() || this.checkPrhaseData() || !fg.get('display').value) {
        fg.controls['source_lang_id'].disable();
        fg.controls['target_lang_id'].disable();
      }

      if (this.checkXTMData() && jobStatus != 'FINISHED') { 
        fg.controls['financially_closed'].disable();
      }

      if (this.disable_word_rate_input(fg)) {
        fg.controls['word_rate'].disable();
      }

      if (this.disable_comment_input(fg)) {
        fg.controls['comment'].disable();
      }

      let batchesLinksArr = fg.get('batches_links') as UntypedFormArray
      v.batches_links.map(batch_link => {
        let bl = this.fb.group({
          requested_at: null
        })

        bl.patchValue({
          requested_at: batch_link.batch && batch_link.batch.requested_at ? batch_link.batch.requested_at : null
        })
        
        batchesLinksArr.push(bl);
      })
      this.updateProjectDelivered(v.delivered);
    });
    if (this.quoteGoToLastPage) {
      this.quoteGoToLastPage = false;
      this.addLanguageCombination();
      let index = this.translationsFA.length;
      this.scrollToTranslation('quote_trans_'+index);
    }
  }

  disable_word_rate_input(tran) {
    if ( !tran.value.display ) {
      return true;
    }
    if ( (tran.value.financially_closed && this.checkXTMData()) || this.checkPrhaseData()) {
      return true;
    }
    return null;
  }

  disable_comment_input(tran) {
    return tran.value.display ? null : true;
  }

  deleteVendor() {
    let vendorSelectedCount = 0;
    this.vendorsFA.controls.forEach(vendorFa => {
      if (vendorFa.getRawValue().selected)
        vendorSelectedCount++;
    });
    if (vendorSelectedCount == 0){
      alert(this.lang.getText('please_select_any_one_vendor'));
      return;
    }
    if ( !confirm(this.lang.getText('are_you_sure_to_delete_the_selection')) )
      return;
    this.vendorsFA.controls.forEach(vendorFa => {
      vendorFa.patchValue({delete: vendorFa.getRawValue().selected})
    });
    this.optimizeVendorDD()
    this.onSubmitProjectEditForm();
  }

  deletedeliverybatch(batch) {
    if ( !confirm(this.lang.getText('are_you_sure_you_want_to_delete_this_item')) )
      return;
      batch.patchValue({delete: 'yes'}, this.formEventStop);
  }

  deleteClientTran(event) {
    this.calculateTotalCost();
    this.calculateTotalCostOrignal();
    this.allowLinkingBathch = false;
  }

  deleteVendorTran(event) {
    // this.calculateVendorTotalCost();
    // this.calculateVendorTotalCostOrignal();
    this.update_financial_details();
    this.allowLinkingBathch = false;
  }

  deleteClientService(service) {
    if ( !confirm(this.lang.getText('are_you_sure_you_want_to_delete_this_item')) )
      return;
    service.patchValue({delete: 'yes'}, this.formEventStop);
    this.calculateTotalCost();
    this.allowLinkingBathch = false;
  }

  processClientDetails() {
    this.clients.forEach(client => {
      if ( this.clientId == client.id ) {
        this.clientName = client.name
        if (typeof this.projectEditForm.getRawValue().currencyAllVendors == 'undefined'){
          /*
          this.projectEditForm.patchValue({
            currency_id: client.currency_id,
            currencyAllVendors: client.currency_id,
          }, this.formEventStop)
          */
        }
      }
    })
  }

  updateVendorCombiValues(ptId, fg, vTCVal, venTraVal_financially_closed = false) {
    fg.controls.map(fb =>{
      if ( vTCVal.project_translate_id == ptId && vTCVal.word_match_id == fb.value.word_match_id )
        fb.patchValue({
          word_count: vTCVal.word_count,
          rate: vTCVal.rate,
          percentage: vTCVal.percentage,
          lockPercentage: true,
          amount: vTCVal.word_count*vTCVal.rate,
        }, this.formEventStop)
      if (venTraVal_financially_closed || this.checkXTMData()) {
        fb.controls['word_count'].disable();
      }
    });
  }

  updateProjectDelivered(delivered){
    /*
    if (delivered == 0)
      this.projectDelivered = false;
    */
  }

  isQualityAssurances(){
    if (this.createProject)
      return false;
    return this.projectEditForm.get('qualityAssurances').value.every( val => val === true );
  }

  splitDateAndTime(dateTime) {
    return this.helperSer.splitDateAndTime(dateTime);
    /*
    var d = new Date(dateTime);
    //console.log(dateTime, !isNaN(d.valueOf()))
    //console.log(dateTime, dateTime.split("0").length)
    if (
          dateTime == null ||
          isNaN(d.getTime()) ||
          dateTime.split("0").length > 14 ||
          isNaN(d.valueOf())
        )
      return { date: '', hour: '', minute: '' };
    //var t = dateTime.split(/[- :]/);
    let date = this.datePipe.transform(d, 'dd/MM/yyyy');
    /*
    let date = isNaN(d.getTime()) ? '' : (("0" + d.getDate()).slice(-2)+'/'+("0" + (d.getMonth()+1)).slice(-2)+'/'+d.getFullYear());
    */
    /*
    return {
      date: date,
      hour: d.getHours(),
      minute: d.getMinutes(),
    }
    */
  }

  initTranslationForm(ptId, catAnalysis) {
    let wordRate = 0.5;
    const fg = this.fb.group({
      catAnalysisGroA: this.initCatAnalysisGroForm(catAnalysis, this.wordMatchesA, ptId),
      catAnalysisGroB: this.initCatAnalysisGroForm(catAnalysis, this.wordMatchesB, ptId),
      is_cat_changed: false,
      words_count: new UntypedFormControl({ value: '', disabled: true }),
      weighted_count: new UntypedFormControl({ value: '', disabled: true }),
      delete: '',
      display: false,
      delivered: 0,
      project_translate_id: '',
      source_lang_id: new UntypedFormControl({ value: this.defSourceLang, disabled: this.projectEditDis }),
      target_lang_id: new UntypedFormControl({ value: this.defTargetLang, disabled: this.projectEditDis }),
      word_rate: new UntypedFormControl({ value: wordRate, disabled: this.projectEditDis }),
      amount: '',
      orignalamount: 0,
      invoiceable: true,
      comment: '',
      translation_meta_values: new UntypedFormControl(),
      financially_closed: new UntypedFormControl({ value: false, disabled: false }),
      jobStatus: null,
      project_finances_id: null,
      batches_links: new UntypedFormArray([])
    });

    let wordsCount = 0;
    wordsCount += (
      this.calculateWordsCatGro(fg.get('catAnalysisGroA')) +
      this.calculateWordsCatGro(fg.get('catAnalysisGroB')));
    fg.patchValue({ words_count: wordsCount }, this.formEventStop);

    let weightedCount = 0;
    let wordRateT = fg.get('word_rate').value;
    weightedCount += (
      this.calculateWordsWeightedCatGro(fg.get('catAnalysisGroA'), wordRateT) +
      this.calculateWordsWeightedCatGro(fg.get('catAnalysisGroB'), wordRateT));
    fg.patchValue({weighted_count: Math.round(weightedCount)}, this.formEventStop);    

    const key = this.translationsFA.length;
    fg.get('word_rate').valueChanges.subscribe(value => {
      this.changeWordRate(value, key);
    });

    this.translationsFA.push(fg);
    // if ( ptId == '' )
    //   this.displayThisResTran(key);
    if (ptId.length == 0)
      this.initCatMatchRateByClient(key);
    this.changeWordRate(wordRate, key);
    return fg;
  }

  initTranslationFormOrignal(ptId) {
    let wordRate = 0.5;
    const fg = this.fb.group({
      delete: '',
      display: ptId == '' ? true : false,
      delivered: 0,
      project_translate_id: '',
      source_lang_id: new UntypedFormControl({value: this.defSourceLang, disabled: this.projectEditDis || this.checkXTMData() || this.checkPrhaseData() }),
      target_lang_id: new UntypedFormControl({value: this.defTargetLang, disabled: this.projectEditDis || this.checkXTMData() || this.checkPrhaseData() }),
      word_rate: new UntypedFormControl({value: wordRate, disabled: this.projectEditDis || this.checkXTMData() || this.checkPrhaseData() }),
      amount: '',
      invoiceable: true,
      comment: '',
      translation_meta_values: new UntypedFormControl(),
      batches_links: new UntypedFormArray([])
    })

    this.translationsFAOrignal.push(fg);
    this.financeLangTrans.push(fg);

    this.catAnalysisFA.push(
      this.fb.group({
        catAnalysGroA: this.initCatAnalysisGroForm(this.project['catAnalysis'], this.wordMatchesA, ptId),
        catAnalysGroB: this.initCatAnalysisGroForm(this.project['catAnalysis'], this.wordMatchesB, ptId),
        project_translate_id: ptId,
        words_count: new UntypedFormControl({value: '', disabled: true}),
        weighted_count: new UntypedFormControl({value: '', disabled: true}),
      })
    );
    return fg;
  }

  initAdditionalServiceForm() {
    const fg = this.fb.group({
      delete: '',
      delivered: 0,
      pAsId: '',
      comment: new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      unit:   new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      volume: new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      rate:   new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      amount: 0,
      invoiceable: true,
      financially_closed: new UntypedFormControl({value: false, disabled: false}),
    })
    this.additionalServicesFA.push(fg);
    return fg;
  }

  getDateTimeForm(dateT) {
    return this.fb.group({
      date: new UntypedFormControl({value: dateT.date, disabled: this.projectEditDis}),
      hour: new UntypedFormControl({value: dateT.hour, disabled: this.projectEditDis}),
      minute: new UntypedFormControl({value: dateT.minute, disabled: this.projectEditDis}),
    })
  }

  changeWordRate(word_rate, key) {
    this.changeWordRateGroClient(this.translationsFA.controls.at(key), 'catAnalysisGroA', word_rate);
    this.changeWordRateGroClient(this.translationsFA.controls.at(key), 'catAnalysisGroB', word_rate);
    // this.calculateTotalCost()
    this.calculateTotalCostOrignal();
  }

  changeWordRateGroClient(fa, catGroStr, word_rate) {
    fa.controls[catGroStr].controls.map(fg => {
      const percentage = fg.getRawValue().percentage;
      if ( percentage != 0 )
        fg.patchValue({rate: this.calculateRateByPercentage(percentage, word_rate) }, this.formEventStop);
    });
  }
/*
  changeWordRateGro(fa, catGroStr, word_rate) {
    fa.controls[catGroStr].controls.map(fg => {
      for (var key = 0; key < this.wordTypeRates.length; key++) {
        const wordRate = this.wordTypeRates[key];
        if ( fg.value.word_match_id == wordRate.word_match_id ) {
          fg.patchValue({rate: this.calculateRateByPercentage(wordRate.amount, word_rate) }, this.formEventStop);
        }
      }
    });
    //this.translationsFA.controls[key].patchValue({amount: this.subCost}, this.formEventStop)
  }
*/
  calculateRateByPercentage(amount, rate) {
    return this.helperSer.roundOfToFraction(((amount/100) * rate), this.catRateDecimalRound);
  }

  initCatAnalysisGroForm(catAnalysis, arr, ptId) {
    const formArr = this.fb.array([])
    arr.forEach(obj => {
      let wordCount = 0; let wordUnit = 0;
      let percentage = 0; let display = 0;
      catAnalysis.map(v => {
        if (v.project_translate_id == ptId && v.word_match_id == obj.id){
          wordCount = v.word_count; wordUnit = v.rate;
          percentage = v.percentage; display = v.display;
        }
      });
      let fg = this.fb.group({
        display: display,
        percentage: percentage,
        word_match_id: obj.id,
        word_count: new UntypedFormControl({ value: wordCount, disabled: this.projectEditDis }),
        rate: wordUnit,
        lockPercentage: false,
        amount: (wordCount * wordUnit).toFixed(1),
      });
      if (!this.checkTradosData('alc') || this.checkXTMData() || this.checkPrhaseData()) {
        fg.controls['word_count'].disable();
      }
      formArr.push(fg);
    });
    return formArr;
  }

  calculateTotalCost() {
    this.totalCost = 0;
    this.translationsFA.controls.map((translationfb, translationKey) => {
      let subCost = 0;
      let wordsCount = 0;
      wordsCount += (
        this.calculateWordsCatGro(translationfb.controls.catAnalysisGroA) +
        this.calculateWordsCatGro(translationfb.controls.catAnalysisGroB));
      translationfb.patchValue({ words_count: wordsCount }, this.formEventStop);

      let weightedCount = 0;
      let tFA = this.translationsFA.controls[translationKey];
      let wordRateT = tFA.getRawValue().word_rate
      
      weightedCount += (
        this.calculateWordsWeightedCatGro(translationfb.controls.catAnalysisGroA, wordRateT) +
        this.calculateWordsWeightedCatGro(translationfb.controls.catAnalysisGroB, wordRateT));
      translationfb.patchValue({ weighted_count: Math.round(weightedCount) }, this.formEventStop);
      
      subCost = subCost + this.calculateCatGro(translationfb.controls.catAnalysisGroA);
      
      this.subCost = (subCost + this.calculateCatGro(translationfb.controls.catAnalysisGroB));
      tFA.patchValue({ amount: this.helperSer.roundOfToFraction(this.subCost, this.decimalRound).toFixed(this.decimalRound) }, this.formEventStop);        
      this.totalCost = this.totalCost + this.subCost;
    });
    // this.projectEditForm.patchValue({langTransTotalCost: this.helperSer.roundOfToFraction(this.totalCost, this.decimalRound).toFixed(this.decimalRound)},this.formEventStop)
    this.calculateTotalCostServicesDiscount();
  }

  calculateTotalCostOrignal() {
    this.calculateTotalCost();
    this.get_translations_amount(false);
  }

  translations_amount_http_req = null;
  get_translations_amount_loaded: boolean = false;
  total_translations_amount: number = 0;
  get_translations_amount(force) {
    if ( ! force && this.translations_amount_http_req && this.translations_amount_http_req.isStopped == false ) {
      return;
    }
    let formData = {
      token: this.Token.get(),
      project_id: this.projectId

    };
    this.translations_amount_http_req = this.http.post(`${this.apiURL}project/quoting-translations-total-amount`, formData).subscribe(
      data => {
        this.get_translations_amount_loaded = true;
        this.total_translations_amount = data['translations_amount'];
        this.projectEditForm.patchValue({ langTransTotalCost: this.helperSer.roundOfToFraction(this.total_translations_amount, this.decimalRound).toFixed(this.decimalRound) }, this.formEventStop)
        this.calculateTotalCostServicesDiscount();
      },
      error => {
        this.handleError(error);
      }
    );
  }

  calculateTotalCostServicesDiscount() {
    if ( ! this.get_translations_amount_loaded ) {
      return;
    }
    this.subCost = 0;
    this.additionalServicesTotalCost = 0;
    this.additionalServicesFA.controls.map(fb => {
      let cost = fb.getRawValue().volume * fb.getRawValue().rate;
      fb.patchValue({amount: cost.toFixed(this.decimalRound)}, this.formEventStop);
      if (!fb.getRawValue().invoiceable) {
        return;
      }
      this.subCost = this.subCost + cost;
      this.additionalServicesTotalCost = this.additionalServicesTotalCost + cost;
    })
    this.totalCost = this.total_translations_amount + this.subCost;
    let totalBeforeDiscount = this.totalCost;
    let discountAmt = this.projectEditForm.getRawValue().clientDiscount; // * 1
    let discountAmtPer = this.projectEditForm.getRawValue().clientDiscountPercentage; // * 1
    this.clientDiscountHidden = false;
    this.clientDiscountPercentageHidden = false;
    if ( discountAmt > 0 ){
      discountAmtPer = 0;
      if (discountAmt > this.totalCost) {
        alert(this.lang.getText('please_check_discount_amount'));
        discountAmt = 0;
        this.projectEditForm.patchValue({clientDiscount: 0}, this.formEventStop);
        return this.calculateTotalCostOrignal();
      }
      this.clientDiscountPercentageHidden = true;
      this.totalCost -= discountAmt;
    }
    if (discountAmtPer > 0) {
      if (discountAmtPer > 100) {
        alert(this.lang.getText('please_check_discount_percentage'));
        discountAmt = 0;
        this.projectEditForm.patchValue({clientDiscountPercentage: 0}, this.formEventStop);
        return this.calculateTotalCostOrignal();
      }
      this.clientDiscountHidden = true;
      let amt = ( this.totalCost * discountAmtPer ) / 100 ;
      this.totalCost -= amt;
    }
    this.projectEditForm.patchValue({additionalServiceCost: this.additionalServicesTotalCost.toFixed(this.decimalRound)}, this.formEventStop);
    this.projectEditForm.patchValue({
      totalBeforeDiscount: this.helperSer.roundOfToFractionStr(totalBeforeDiscount, this.decimalRound),
      totalCost: this.helperSer.roundOfToFraction(this.totalCost, this.decimalRound).toFixed(this.decimalRound)
    }, this.formEventStop);
  }
  clientDiscountHidden = false;
  clientDiscountPercentageHidden = false;

  calculateWordsCatGro(catGroFA) {
    let wordsCount: number = 0;
    catGroFA.controls.map((catfb) => {
      let values = catfb.getRawValue();
      let count = parseFloat(values.word_count);
      count = isNaN(count) || (values.display && !values.display) ? 0 : count;
      wordsCount += count;
    })
    return wordsCount;
  }

  calculateWordsWeightedCatGro(catGroFA, wordRate) {
    let wordsCount: number = 0;
    catGroFA.controls.map((catfb) => {
      let values = catfb.getRawValue();
      wordsCount += ( ( values.word_count / 100 ) * values.percentage );
    })
    return wordsCount;
  }

  calculateCatGro(catGroFA) {
    let subCost:number = 0;
    catGroFA.controls.map((catfb) => {
      let wordCont = catfb.getRawValue().word_count;
      wordCont *= 1;
      let amount = this.helperSer.roundOfToFraction(this.helperSer.multiply(wordCont, catfb.getRawValue().rate), this.decimalRound);
      catfb.patchValue({word_count: wordCont, amount: amount}, this.formEventStop);
      subCost += amount;
    })
    return subCost;
  }

  ngOnInit() {
    this.is_freelancer = this.Jwtwis.hasPermission('freelancer');
    this.getDisplayLabels();
  }

  currencySelectionChanged(id) {
    this.currencyId = id;
  }

  currencyChanged(val) {
    let currencyObj = this.getCurrencyById(this.currentCurrency)[0];
    alert(this.lang.getText('please_note_that') + ' '+currencyObj.name+' ' + this.lang.getText('long_text8'));
  }

  alertFileEnable() {
    alert(this.lang.getText('long_text9'))
  }

  clientsSelectionChanged(id) {
    if (this.project['client_id'] != id)
      this.unsavedProject = true;
    this.clientId = id;
    if (this.createProject)
      this.updateValueByClient();
    this.processClientDetails();
  }

  updateValueByClient() {
    let currencyId;
    let cliProjIns;
    this.clients.forEach(client => {
      if (this.clientId == client.id) {
        cliProjIns = client.project_instruction;
        this.currencies.filter(v => {
          if (v.id == client.currency_id) {
            currencyId = v.id;
            this.currencySelectionChanged(currencyId);
            this.currencySelected = v;
          }
        })
      }
    });
    /*
    if ( this.createProject )
      this.projectEditForm.patchValue({
        comments: cliProjIns,
        currency_id: currencyId,
        currencyAllVendors: currencyId,
      }, this.formEventStop);
    /*
    if ( this.createProject )
      this.projectEditForm.patchValue({
        currency_id: currencyId,
        currencyAllVendors: currencyId
      }, this.formEventStop)
    */
  }

  initCatMatchRateByClient(key) {
    this.clients.forEach(client => {
      if (this.clientId == client.id)
        this.enableMatchTypeByClient(client.client_match_types, key)
    });
  }

  enableMatchTypeByClient(cliMatTypes, key) {
    this.enableMatchTypeGroByClient('A', cliMatTypes, key);
    this.enableMatchTypeGroByClient('B', cliMatTypes, key);
  }

  enableMatchTypeGroByClient(catGro, cliMatTypes, key) {
    let catAnaFa = this.translationsFA.at(key)
    let wordRate = this.translationsFA.at(key).getRawValue().word_rate;
    catAnaFa.get('catAnalysisGro'+catGro).controls.forEach(catGroFA => {
      let wordMatchId = catGroFA.getRawValue().word_match_id;
      cliMatTypes.forEach(cliMatType => {
        if (cliMatType.word_match_id == wordMatchId){
          catGroFA.patchValue({
            display: cliMatType.display,
            rate: cliMatType.display ? this.calculateRateByPercentage(cliMatType.amount, wordRate) : 0,
            percentage: cliMatType.amount,
          },this.formEventStop);
        }
      });
    })
  }

  getProjectDetails() {
    this.formData = this.Token.getAPITokenData();
    this.formData['projectId'] = this.projectId;
    this.http.post(`${this.apiURL}project/view`, this.formData).subscribe(
      data => {
        this.project = data['result'];
        console.log('this.project------------------------', this.project);
        this.sapEnabled = this.Jwtwis.sapEnabled() && data['result']['client']['name'].includes('SAP');
        this.initProjectEditForm();
        setTimeout(function(){
          this.getPageOfQuoteLangTrans();
        }.bind(this), 2000)
      },
      error => this.handleError(error)
    );
  }

  getSupplierServices() {
    this.formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}get-supplier-services`, this.formData).subscribe(
      data => {
        this.vendorServices = data['result'];
        this.supplierServices = data['result'];
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  defSourceLang; defTargetLang;
  getLanguages() {
    this.formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}languages`, this.formData).subscribe(
      data => {
        this.languages = data['result'];
        this.defSourceLang = this.languages[0].id;
        this.defTargetLang = this.languages[1].id;
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  getClients() {
    this.http.post(`${this.apiURL}clients-with-emails`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.clients = data;
        this.clientsDDConfig = {
          displayKey: 'name',
          search: true,
        }
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  getWordRateTypes() {
    for (let i = 0; i <= 1000; i += 5)
      this.wordRateTypes.push(i/1000)
    this.initProjectEditForm();
  }

  getWordMatches() {
    this.formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}word-matches`, this.formData).subscribe(
      data => {
        this.wordMatches = data['result'];
        this.wordMatchesA = [];
        this.wordMatchesB = [];
        this.wordMatches.map(obj => {
          if (obj.group == 1){
            this.wordMatchesA.push(obj);
          }
          else
            this.wordMatchesB.push(obj);
          return obj;
        })
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  getWordTypeRates() {
    this.formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}word-type-rates`, this.formData).subscribe(
      data => {
        this.wordTypeRates = data['result'];
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  getQualityAssurances() {
    let postData = {
      clientId: this.project.client_id
    };
    this.http.post(`${this.apiURL}quality-assurances-of-client`, postData).subscribe(
      data => {
        // if ( this.qualityAssurances !== null ) { return; }
        let qAFa = (this.projectEditForm.get('qualityAssurances') as UntypedFormArray);
        if ( qAFa.length > 0 ) return;
        this.qualityAssurances = data['result'];
        this.qualityAssurances.forEach(qA => {
          const control = new UntypedFormControl(this.project.qualityAssurances.includes(qA.id));
          qAFa.push(control);
        });
        this.unsavedProject = false;
      },
      error => this.handleError(error)
    );
  }

  getCurrencies() {
    this.http.post(`${this.apiURL}currencies`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.currencies = data['result'];
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  getPStatuses() {
    if(this.project.customize_status_version == 2){
      this.getCustomizeStatuses();
      return;
    }
    this.http.post(`${this.apiURL}project/statuses`, this.Token.getAPITokenData()).subscribe(
      data => {
        console.log('results1',data['result']);
        this.pStatuses = data['result'];
      },
      error => this.handleError(error)
    );
  }

  getCustomizeStatuses() {
    let postData = this.Token.getAPITokenData();
    postData['id'] = this.projectId;
    this.http.post(`${this.apiURL}customize-statuses/getByProject`, postData).subscribe(
      data => {
        this.pStatuses = data['result'];
      },
      error => this.handleError(error)
    );
  }

  getAddSerUnits(){
    this.http.post(`${this.apiURL}additional-service-units`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.addSerUnits = data['result'];
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  headsUpClientTemplates:any = [];
  getHeadsUpTemplates(clientId){
    let formData = {
      token: this.Token.get(),
      clientId: clientId,
    };
    this.http.post(`${this.apiURL}heads-up-templates-for-client`, formData).subscribe(
      data => {
        this.headsUpClientTemplates = data;
      },
      error => this.handleError(error)
    );
  }

  initialOrderId;
  getInitialOrder() {
    this.http.post(`${this.apiURL}initial-order-id`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.initialOrderId = parseInt(data['result']['value']);
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  currentCurrency; //default currency
  getCurrentCurrency() {
    this.http.post(`${this.apiURL}current-currency`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.currentCurrency = (data['result']);
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  projectFinances;
  getProjectFinances() {
    this.http.post(`${this.apiURL}project-finance/getList`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.projectFinances = data;
      },
      error => this.handleError(error)
    );
  }

  vendorPOList = null;
  getPOHistory(pVId) {
  this.vendorPOList = null;
  let formData = {
      token:      this.Token.get(),
      projectId:  this.projectId,
      pVId:       pVId
    }
    this.http.post(`${this.apiURL}project-finance/getPOs`, formData).subscribe(
      data => {
        this.vendorPOList = [];
        Object(data).forEach((po, pi) => {
          po.filename = `PO-#${this.orderId}-${pi+1}.pdf`;
          this.vendorPOList.push(po);
        });
      },
      error => this.handleError(error)
    );
  }

  all_files = null
  get_all_files() {
    let formData = this.Token.getAPITokenData();
    formData['projectId'] = this.projectId;
    formData['tag'] = '';
    formData['orderUser'] = true;
    this.http.post(`${this.apiURL}project/file/get`, formData).subscribe(
      data => {
        this.all_files = data;
      },
      error => this.handleError(error)
    );
  }

  getComments_details() {
    return JSON.stringify({
      request_by: this.projectEditForm.get('request_by').value,
      client_ref: this.projectEditForm.get('client_ref').value,
      cost_centre: this.projectEditForm.get('cost_centre').value,
      comments: this.projectEditForm.get('comments').value,
      client_comment: this.projectEditForm.get('client_comment').value,
      batchesLeadTime: this.projectEditForm.get('batchesLeadTime').value,
    });
  }

  getCurrencyById(id){
    return this.currencies.filter(v=>{
      if (v.id == id)
        return v
    })
  }

  setComments_details() {
    try {
      return JSON.parse(this.project.comments_details);
    } catch (e) {
      return '';
    }
  }

  scrollToTranslation(elmentId) {
    let el = document.getElementById(elmentId);
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  quoteGoToLastPage = false;
  addLanguageCombination() {
    if (this.trasnlation_pagination_data.quote_total_pages > 1 && this.trasnlation_pagination_data.quote_total_pages != this.trasnlation_pagination_data.quote_page) {
      this.quoteGoToLastPage = true;
    } else {
      this.initTranslationForm('', []);
      this.translationsFA.at(this.translationsFA.length - 1).patchValue({ display: true }, this.formEventStop);
      this.allowLinkingBathch = false;
    }
  }

  addAdditionalService() {
    this.initAdditionalServiceForm();
    this.allowLinkingBathch = false;
  }

  getVendorServiceRoles(vendorId){
    let service_ids = [];
    let roles = '';
    this.project['vendors'].map((vendor) => {
      if ( vendor.vendor_id != vendorId ) { return };
      vendor['translations_services'].map(service => {
        service_ids.push(service.vendor_tran_service_id);
      })
    })
    service_ids.forEach(service_id => {
      roles = roles + this.getVendorRoleByServiceId(service_id).name + ', ';
    });
    return roles.slice(0, -2);
  }

  getVendorRoleByServiceId(id){
    let role;
    this.vendorServices.map(vs => {
      if ( vs.id != id ) return;
      role = vs;
    })
    return role;
  }

  calculateProfitPercentage() {
    let pEFV = this.projectEditForm.getRawValue();
    return pEFV.vendorsCost == 0 ? '100 %' : this.helperSer.roundOfToFraction(( (this.calculateProfitMargin() / pEFV.totalCost) * 100), this.decimalRound)+" %";
  }

  calculateProfitMargin() {
    let pEFV = this.projectEditForm.value;
    return this.helperSer.roundOfToFraction((pEFV.totalCost - pEFV.vendorsCost), this.decimalRound);
  }

  cost_details;
  vendors_cost_details;
  update_financial_details() {
    this.http.get(`${this.apiURL}project/${this.projectId}/get-cost-details`).subscribe(
      data => {
        this.cost_details = data;
        this.vendors_cost_details = data['vendors_cost_details']
        this.vendorsFA.controls.map((vendor_fg) => {
          let vendor_id = vendor_fg.getRawValue().vendor_id;
          let vendor_cost_details = this.vendors_cost_details[vendor_id];
          vendor_fg.patchValue({LangTransCost: this.helperSer.roundOfToFraction(vendor_cost_details['translations'], this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
          vendor_fg.patchValue({AddServicesCost: this.helperSer.roundOfToFraction(vendor_cost_details['services'], this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
          vendor_fg.patchValue({totalCost: this.helperSer.roundOfToFraction(vendor_cost_details['total'], this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
        });
        this.projectEditForm.patchValue({ vendorsCost: this.helperSer.roundOfToFraction(data['vendors_total_cost'], this.decimalRound).toFixed(this.decimalRound) }, this.formEventStop);
      }
    );
  }

  calculate_vendor_total_cost(vendor_index) {
    let vendorsCost = 0;
    this.vendorsFA.controls.map((venFa, vi) => {
      if ( vi != vendor_index ) {
        return;
      }
      let lTsCost = 0;
      let totalCost = 0;
      let assServicesCost = 0;
      let vTotalCost = this.project['venTransTotalCost'][venFa.get('vendor_id').value];
      vTotalCost = vTotalCost != undefined ? vTotalCost : 0;
      /*
      venFa.get('translations').controls.map((tcFg, tci) => {
        let wordsCount = 0;
        let subCost = 0;
        subCost = subCost + this.calculateCatGro(tcFg.controls.catAnalysisGroA)
        subCost = subCost + this.calculateCatGro(tcFg.controls.catAnalysisGroB)
        let tfg = venFa.get('translations').controls[tci]
        tfg.patchValue({amount: this.helperSer.roundOfToFraction(subCost, this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
        lTsCost = lTsCost + subCost;

        wordsCount =
          this.calculateWordsCatGro(tcFg.controls.catAnalysisGroA) +
          this.calculateWordsCatGro(tcFg.controls.catAnalysisGroB)

        let wordRate = tfg.getRawValue().word_rate;
        let weightedWordsCount =
          this.calculateWordsWeightedCatGro(tcFg.controls.catAnalysisGroA, wordRate) +
          this.calculateWordsWeightedCatGro(tcFg.controls.catAnalysisGroB, wordRate)
        venFa.get('translations').controls[tci].patchValue({
          words_count: wordsCount,
          weighted_count: this.helperSer.roundOfToFraction(weightedWordsCount, this.decimalRound),
          sub_total: this.helperSer.roundOfToFraction(subCost, this.decimalRound).toFixed(this.decimalRound),
        }, this.formEventStop);

      })
      venFa.patchValue({LangTransCost: this.helperSer.roundOfToFraction(vTotalCost, this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
      */
     
      let vendor_id = venFa.getRawValue().vendor_id;
      let vandror_translations_cost = this.vendors_cost_details[vendor_id]['translations'];

      venFa.get('additionalServices').controls.map((asFg) => {
        let subCost = 0;
        subCost = asFg.getRawValue().volume * asFg.getRawValue().rate;
        asFg.patchValue({amount: subCost.toFixed(this.decimalRound)}, this.formEventStop);
        assServicesCost = assServicesCost + subCost;
      });
      venFa.patchValue({AddServicesCost: assServicesCost.toFixed(this.decimalRound)}, this.formEventStop);

      venFa.patchValue({totalCost: this.helperSer.roundOfToFraction(vandror_translations_cost + assServicesCost, this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);

      /*
      totalCost = vTotalCost + assServicesCost;
      venFa.patchValue({totalCost: this.helperSer.roundOfToFraction(totalCost, this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
      vendorsCost = vendorsCost + totalCost;
      */
    })
    /*
    this.projectEditForm.patchValue({ vendorsCost: this.helperSer.roundOfToFraction(vendorsCost, this.decimalRound).toFixed(this.decimalRound) }, this.formEventStop);
    this.calculateVendorTotalCostOrignal();
    */
  }
  calculateVendorTotalCostOrignal() {
    let vendorsCost = 0;
    this.vendorsFA.controls.map((venFa) => {
      let lTsCost = 0;
      let totalCost = 0;
      let assServicesCost = 0;
      venFa.get('translationsCombi').controls.map((tcFg, tci) => {
        if (tcFg.getRawValue().delete == "yes")
          return;
        let wordsCount = 0;
        let subCost = 0;
        subCost = subCost + this.calculateCatGro(tcFg.controls.catAnalysGroA)
        subCost = subCost + this.calculateCatGro(tcFg.controls.catAnalysGroB)
        let tfg = venFa.get('translations_orignal').controls[tci]
        tfg.patchValue({amount: this.helperSer.roundOfToFraction(subCost, this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
        lTsCost = lTsCost + subCost;

        wordsCount =
          this.calculateWordsCatGro(tcFg.controls.catAnalysGroA) +
          this.calculateWordsCatGro(tcFg.controls.catAnalysGroB)

        let wordRate = tfg.getRawValue().word_rate;
        let weightedWordsCount =
          this.calculateWordsWeightedCatGro(tcFg.controls.catAnalysGroA, wordRate) +
          this.calculateWordsWeightedCatGro(tcFg.controls.catAnalysGroB, wordRate)
        venFa.get('translationsCombi').controls[tci].patchValue({
          words_count: wordsCount,
          weighted_count: this.helperSer.roundOfToFraction(weightedWordsCount, this.decimalRound),
          sub_total: this.helperSer.roundOfToFraction(subCost, this.decimalRound).toFixed(this.decimalRound),
        }, this.formEventStop);

      })
      venFa.patchValue({LangTransCost: this.helperSer.roundOfToFraction(lTsCost, this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
      venFa.get('additionalServices').controls.map((asFg) => {
        let subCost = 0;
        subCost = asFg.getRawValue().volume * asFg.getRawValue().rate;
        asFg.patchValue({amount: subCost.toFixed(this.decimalRound)}, this.formEventStop);
        assServicesCost = assServicesCost + subCost;
      });
      venFa.patchValue({AddServicesCost: assServicesCost.toFixed(this.decimalRound)}, this.formEventStop);
      totalCost = lTsCost + assServicesCost;
      venFa.patchValue({totalCost: this.helperSer.roundOfToFraction(totalCost, this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
      vendorsCost = vendorsCost + totalCost;
    })
    this.projectEditForm.patchValue({vendorsCost: this.helperSer.roundOfToFraction(vendorsCost, this.decimalRound).toFixed(this.decimalRound)}, this.formEventStop);
  }

  initVendorForm() {
    const fg = this.fb.group({
      display: true,
      selected: false,
      delete: false,
      show_page: false,
      isUpdated: false,
      emails: [],
      email_meta: '',
      project_vendor_id: '',
      addQuoteToVendorTemp: '',
      file_access: '',
      confirm: '',
      upload_link: '',
      download_link: '',
      delivered_at: null,
      heads_up: null,
      last_updated_at: '',
      vendor_id: '',
      address: '',
      contact: '',
      totalCost: 0,
      translations: new UntypedFormArray([]),
      translations_orignal: new UntypedFormArray([]),
      translations_paginated: new UntypedFormArray([]),
      translationsCombi: new UntypedFormArray([]),
      additionalServices: new UntypedFormArray([]),
      AddServicesCost: 0,
      LangTransCost: 0,
      heads_up_template_id: '',
      headsUpContent: '',
      created_at: '',
      pay_req_CB: false,
      deliverEmails: [],
      deliveryComments: null,
      current_trans_page: 1,
      trans_per_page: 12,
      total_trans_pages: 1,
      phrase_provider_id: null
    });
    fg.controls.heads_up_template_id.valueChanges.subscribe(id => {
      let content = '';
      this.headsUpClientTemplates.map(hedUpTemp=>{
        if (hedUpTemp.id == id )
          content = hedUpTemp.content;
      })
      fg.patchValue({
        headsUpContent: content,
      })
    })
    this.vendorsFA.push(fg);
    this.optimizeVendorDD();
    return fg;
  }

  getVendors() {
    let postData = {
      token: this.Token.get(),
      withReviewAvg: true
    }
    this.http.post(`${this.apiURL}vendors`, postData).subscribe(
      data => {
        this.vendors = data['result'];
        this.vendorsDD = this.vendors;
        this.initProjectEditForm();
      },
      error => this.handleError(error)
    );
  }

  pMDetails = null;
  getPMDetails() {
    if ( this.createProject || this.project.project_manager_id.length == 0) return;
    this.http.post(`${this.apiURL}user-details/`+this.project.project_manager_id, this.Token.getAPITokenData()).subscribe(
      data => {
        this.pMDetails = data['result'];
      },
      error => this.handleError(error)
    );
  }

  invoiceIt() {
    if ( this.projectEditForm.getRawValue().invoiceItCB == false )
      return;
    let formData = {
      projectId: this.projectId
    }
    this.http.post(`${this.apiURL}send-invoice`, formData).subscribe(
      data => {
        this.project['invoiced_at'] = data['time']
        // this.getProjectDetails();
        this.projectEditForm.patchValue({
          invoiced_at_cb: true,
          invoiced_at: this.splitDateAndTime(data['time']).date
        });
      },
      error => this.handleError(error)
    );
  }

  invoiceReceivedDateArr = [];
  vendorPayReq(vendor) {
    let vendValues = vendor.getRawValue();
    let vendorId = vendValues.vendor_id;
    let payReqCB = vendValues.pay_req_CB;
    if ( payReqCB == false )
      return;
    let formData = {
      projectId: this.projectId,
      vendorId: vendorId,
      projVenId: vendValues.project_vendor_id
    };
    this.http.post(`${this.apiURL}request-vendor-pay`, formData).subscribe(
      data => {
        let output = data['result'];
        this.project['vendors'].forEach((proVend, pvi) => {
          if (proVend.vendor_id == vendor.getRawValue().vendor_id) {
            this.project['vendors'][pvi].pay_req_at = data['time'];
          }
        });
        // this.getProjectDetails();
        this.invoiceReceivedDateArr[vendValues.project_vendor_id] = this.splitDateAndTime(data['time']).date;
      },
      error => this.handleError(error)
    );
  }

  vendorPayRequested(vendor) {
    let requested = false;
    if (typeof this.project['vendors'] == 'undefined')
      return requested;
    this.project['vendors'].forEach(proVend => {
      if (proVend.vendor_id == vendor.getRawValue().vendor_id &&
          proVend.pay_req_at != null) {
            requested = true;
      }
    });
    return requested;
  }

  vendorSearchVal = null;
  addVendor(venObj) {
    let vendorId = venObj.vendorId;
    this.vendorSearchVal = venObj.searchValues;
    let canAdd = true;
    this.vendorsFA.controls.forEach(vendor => {
      if (vendor.getRawValue().vendor_id == vendorId && canAdd)
        canAdd = false
    });
    if ( !canAdd ) {
      this.addVendorCom.closeModal();
      return;
    }

    canAdd = true;
    this.vendorsFA.controls.forEach(vendor => {
      if (vendor.getRawValue().vendor_id == '' && canAdd)
        canAdd = false
    });
    if (canAdd) {
      this.initVendorForm().patchValue({
        vendor_id: vendorId,
        isUpdated: true,
        show_page: true
      });
      this.displayThisVendor('');
      this.addVendorCom.closeModal();
      this.vendorChange(vendorId, ( this.vendorsFA.controls.length - 1 ) );
    }
    else
      alert(this.lang.getText('please_select_a_vendor_and_save_your_data_before_you_add_another_vendor'))
  }

  activeVendor
  confirmProjectToVendor(template: TemplateRef<any>, vendor) {
    this.activeVendor = vendor;
    this.modalRef = this.modalService.show(
      template,
      { class: 'modal-sm', backdrop: 'static' }
    );
  }

  @ViewChild('jobDeliverEmailsTemp') jobDeliverEmailsTemp: TemplateRef<any>;
  jobDeliverEmailIpt
  jobDeliverEmails = [];
  getJobDeliverEmails(vendor) {
    this.activeVendor = vendor;
    this.modalRef = this.modalService.show(
      this.jobDeliverEmailsTemp,
      { class: 'modal-md' }
    );
  }

  vendorChange(value, vi) {
    let addDetails = { address: '', email: '' }
    this.vendors.map(v => {
      if (v.id == value)
        addDetails = { address: v.address, email: v.email };
    })

    let fb = this.vendorsFA.controls[vi]
    fb.patchValue({
      address: addDetails.address.replace(/\n/g, " "),
      contact: addDetails.email,
    }, this.formEventStop);
    this.optimizeVendorDD();
    fb.controls['vendor_id'].disable();
  }

  optimizeVendorDD(){
    var removeVendors = [];
    this.vendors.forEach(ven => {
      this.vendorsFA.getRawValue().forEach(venFa => {
        if (ven.id == venFa.vendor_id && venFa.delete != true)
          removeVendors.push(ven.id)
      });
    });

    this.vendorsDD = this.vendors.filter(ven => {
      return removeVendors.includes(ven.id) ? false : ven;
    });
  }

  getVendorSearchLang(sorOrTar) {
    if ( this.vendorSearchVal != null && this.vendorSearchVal[sorOrTar] ){
      return this.vendorSearchVal[sorOrTar];
    }
    return false;
  }

  initVendorTranslationFormOrignal(vi) {
    let translationsFA = this.vendorsFA.controls[vi].get('translations_orignal') as UntypedFormArray;
    let key = translationsFA.length;
    let wordRate = 0.5;
    let source = this.getVendorSearchLang('source') ? this.getVendorSearchLang('source') : this.defSourceLang;
    let target = this.getVendorSearchLang('target') ? this.getVendorSearchLang('target') : this.defTargetLang;
    let fg = this.fb.group({
      delete: '',
      project_translate_id: '',
      source_lang_id: new UntypedFormControl({value: source, disabled: this.projectEditDis}),
      target_lang_id: new UntypedFormControl({value: target, disabled: this.projectEditDis}),
      word_rate: new UntypedFormControl({value: wordRate, disabled: this.projectEditDis}),
      amount: 0,
      batches: new UntypedFormArray([]),
      client_translation_meta_values: new UntypedFormControl()
    });
    translationsFA.push(fg);
    let services = this.fb.array([]);
    if ( this.projectEditDis ) {
      services.disable();
    }
    let fb = this.fb.group({
      services: services,
      catAnalysGroA: this.initCatAnalysisGroForm([], this.wordMatchesA, ''),
      catAnalysGroB: this.initCatAnalysisGroForm([], this.wordMatchesB, ''),
      words_count: new UntypedFormControl({value: '', disabled: true}),
      weighted_count: new UntypedFormControl({value: '', disabled: true}),
      sub_total: new UntypedFormControl({ value: '', disabled: true }),
      delete: ''
    });
    (this.vendorsFA.controls[vi].get('translationsCombi') as UntypedFormArray)
      .push(fb);
    this.changeVendorWordRateOrignal(wordRate, key, vi);
  }

  initVendorTranslationForm(vi, catAnalysis, ptId) {
    let translationsFA = this.vendorsFA.controls[vi].get('translations') as UntypedFormArray;
    let key = translationsFA.length;
    let wordRate = 0.5;
    let source = this.getVendorSearchLang('source') ? this.getVendorSearchLang('source') : this.defSourceLang;
    let target = this.getVendorSearchLang('target') ? this.getVendorSearchLang('target') : this.defTargetLang;
    let services = this.fb.array([]);
    if ( this.projectEditDis ) {
      services.disable();
    }
    let fg = this.fb.group({
      delete: '',
      project_translate_id: '',
      catAnalysisGroA: this.initCatAnalysisGroForm(catAnalysis, this.wordMatchesA, ptId),
      catAnalysisGroB: this.initCatAnalysisGroForm(catAnalysis, this.wordMatchesB, ptId),
      is_cat_changed: false,
      words_count: new UntypedFormControl({ value: '', disabled: true }),
      weighted_count: new UntypedFormControl({ value: '', disabled: true }),
      source_lang_id: new UntypedFormControl({value: source, disabled: this.projectEditDis}),
      target_lang_id: new UntypedFormControl({value: target, disabled: this.projectEditDis}),
      word_rate: new UntypedFormControl({value: wordRate, disabled: this.projectEditDis}),
      amount: 0,
      orignalamount: 0,
      batches: new UntypedFormArray([]),
      client_translation_meta_values: new UntypedFormControl(),
      services: services,
      sub_total: new UntypedFormControl({ value: '', disabled: true }),
      display: false,
      financially_closed: new UntypedFormControl({value: false, disabled: false}),
      project_finances_id: null,
      sap_xtm_job_id: null
    });
    translationsFA.push(fg);
    fg.get('word_rate').valueChanges.subscribe(value => {
      this.changeVendorWordRate(value, key, vi);
    });

    // let fb = this.fb.group({
    //   services: services,
    //   catAnalysisGroA: this.initCatAnalysisGroForm(catAnalysis, this.wordMatchesA, ptId),
    //   catAnalysisGroB: this.initCatAnalysisGroForm(catAnalysis, this.wordMatchesB, ptId),
    //   words_count: new FormControl({value: '', disabled: true}),
    //   weighted_count: new FormControl({value: '', disabled: true}),
    //   sub_total: new FormControl({ value: '', disabled: true }),
    //   orignalamount: new FormControl({ value: '', disabled: true }),
    // });
    // (this.vendorsFA.controls[vi].get('translationsCombi') as FormArray)
    //   .push(fb);
    this.changeVendorWordRate(wordRate, key, vi);
  }

  changeVendorWordRateOrignal(word_rate, key, vi) {
    let fa = this.vendorsFA.controls[vi].get('translationsCombi').controls[key];
    /*
    this.changeWordRateGro(fa, 'catAnalysGroA', word_rate)
    this.changeWordRateGro(fa, 'catAnalysGroB', word_rate)
    */
    //Used same rate applied for client
    this.initCatMatchRateForVendorByClient(fa, word_rate, key, 'catAnalysGro');
  }

  changeVendorWordRate(word_rate, key, vi) {
    let fa = this.vendorsFA.controls[vi].get('translations').controls[key];
    /*
    this.changeWordRateGro(fa, 'catAnalysGroA', word_rate)
    this.changeWordRateGro(fa, 'catAnalysGroB', word_rate)
    */
    //Used same rate applied for client
    this.initCatMatchRateForVendorByClient(fa, word_rate, key);
  }

  resGoToLastPage = false;
  addVenTrans = false;
  addVendorTranslation(vi) {
    if (this.trasnlation_pagination_data.res_total_pages > 1 && this.trasnlation_pagination_data.res_total_pages != this.trasnlation_pagination_data.res_page) {
      this.resGoToLastPage = true;
    } else {
      this.initVendorTranslationForm(vi, [], '');
      this.initVendorTranslationFormOrignal(vi);
      let translationsFA = this.vendorsFA.controls[vi].get('translations') as UntypedFormArray;
      translationsFA.at(translationsFA.length - 1).patchValue({ display: true }, this.formEventStop);
      this.addVenTrans = true;
    }
  }

  initCatMatchRateForVendorByClient(fa, word_rate, key, keyName = 'catAnalysisGro') {
    this.clients.forEach(client => {
      if (this.clientId == client.id)
        this.enableMatchTypeForVendorByClient(fa, word_rate, client.client_match_types, key, keyName)
    });
  }

  enableMatchTypeForVendorByClient(fa, word_rate, cliMatTypes, key, keyName) {
    this.enableMatchTypeGroForVendorByClient(fa, word_rate, 'A', cliMatTypes, key, keyName);
    this.enableMatchTypeGroForVendorByClient(fa, word_rate, 'B', cliMatTypes, key, keyName);
  }

  enableMatchTypeGroForVendorByClient(fa, word_rate, catGro, cliMatTypes, key, keyName) {
    let catAnaFa = fa;
    let wordRate = word_rate;
    catAnaFa.get(keyName + catGro).controls.forEach(catGroFA => {
      let wordMatchId = catGroFA.getRawValue().word_match_id;
      cliMatTypes.forEach(cliMatType => {
        if (cliMatType.word_match_id == wordMatchId){
          let percentage = catGroFA.value.lockPercentage ? catGroFA.value.percentage : cliMatType.amount;
          let objVal = {
            display: cliMatType.display,
            rate: cliMatType.display ? this.calculateRateByPercentage(percentage, wordRate) : 0,
            percentage: percentage,
          }
          catGroFA.patchValue(objVal, this.formEventStop);
        }
      });
    })
  }

  getVendorName(vendorId){
    let name;
    this.vendors.map(v => {
      if (v.id == vendorId)
        name = v.name;
    })
    return name;
  }

  getVendorDetails(vendorId){
    let details;
    this.vendors.map(v => {
      if (v.id == vendorId)
        details = v;
    })
    return details;
  }

  initVendorAddiService(vi) {
    let addiServiceFA = this.vendorsFA.controls[vi].get('additionalServices') as UntypedFormArray;
    const fg = this.fb.group({
      delete: '',
      pAsId: '',
      comment: new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      unit: new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      volume: new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      rate: new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      amount: 0,
      batches: new UntypedFormArray([]),
      project_finances_id: null,
      financially_closed: false
    })
    fg.valueChanges.subscribe(id => {
      this.calculate_vendor_total_cost(vi);
    });
    addiServiceFA.push(fg);
    return fg;
  }

  addVendorAddiService(vi){
    this.initVendorAddiService(vi);
  }

  initVendorBatchForm(vi, type, ti) {
    //let dateTime = this.splitDateAndTime('')
    let dateTime = {date: 0, hour: 10, minute: 0};
    const fa = this.vendorsFA.controls[vi].get(type).controls[ti].get('batches') as UntypedFormArray;
    const fg = this.fb.group({
      delete: '',
      project_translate_id: '',
      description: new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      delivery_at: this.getDateTimeForm(dateTime),
    })
    fa.push(fg);
    return fg;
  }

  addVendorBatch(vi, type, ti){
    this.initVendorBatchForm(vi, type, ti);
  }

  addCostToVendorMailTemplate(vendor) {
    vendor.patchValue({
      headsUpContent: vendor.getRawValue().headsUpContent+'<h2>'+this.lang.getText('request_amount')+ ':</h2><p>'+this.lang.getText('total_cost')+': '+vendor.getRawValue().totalCost+'</p>'
    })
  }

  currentDisplayVendor
  displayThisVendor(vendor_id) {
    this.currentDisplayVendor = vendor_id;
    this.trasnlation_pagination_data.res_page = 1;
    this.trasnlation_pagination_data.res_total_pages = 1;
    this.vendorsFA.controls.map((venFb, pvi) => {
      let display = false;
      if (venFb.getRawValue().vendor_id == vendor_id) {
        display = true;
        venFb.patchValue({isUpdated: true}, this.formEventStop);
      }
      venFb.patchValue({display: display}, this.formEventStop);
    });
  }

  displayThisResTran(i){
    this.showProjectProgress.display = false;
    this.translationsFA.controls.map((tranFb, ti) => {
      let display = false;
      if (i == ti)
        display = true;
        tranFb.patchValue({display: display}, this.formEventStop);
    });
  }

  resourcingTab = false
  switchStatus(val) {
    this.projectEditForm.patchValue({project_status_id: val}, this.formEventStop);
    if (val == 3){
      this.isQuoteRejectedVar = false;
      this.isQuoteApprovedVar = true;
      this.projectEditForm.patchValue({
        approve_quote: 'yes',
        quote_rejected_at: 'no',
      }, this.formEventStop);
      this.resourcingTab = true;
      this.onSubmitProjectEditForm();
    }
  }

  getLanguageById(langId) {
    let language = [];
    this.languages.forEach(lang => {
      if ( lang.id == langId )
        language = lang;
    });
    return language;
  }

  getWordMatchName(matchId, wordMatches) {
    let name = null;
    wordMatches.forEach(match => {
      if ( name == null && matchId == match.id )
        name = match.name
    });
    return name;
  }

  getAddSerUnitName(unitId) {
    let name = null;
    this.addSerUnits.forEach(unit => {
      if ( name == null && unitId == unit.id )
        name = unit.name
    });
    return name;
  }

  submitDeliver() {
    this.vendorsFA.controls.forEach(vend => {
      if (vend.value.display === true) {
        vend.patchValue({deliverEmails: this.jobDeliverEmails});
        this.jobDeliverEmails = [];
        this.closeModal();
      }
    });
    this.onSubmitProjectEditForm();
  }

  pushEmail(emails, ipt) {
    if ( !this.validEmail(ipt) ){
      return alert(this.lang.getText('please_enter_valid_email'));
    }
    emails.push(ipt);
    this.jobDeliverEmailIpt = '';
  }

  validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  submitQuote(){
    this.isQuoteRejectedVar = false;
    this.isQuoteApprovedVar = false;
    this.isQuoteIssuedVar = true;
  }
  
  cancelQuote(){
    if (confirm(this.lang.getText('cancel_project_warning'))) {
      this.projectEditForm.patchValue({cancel_quote: 'yes'});
      this.onSubmitProjectEditForm();
    }
  }

  isQuoteIssuedVar
  isQuoteIssued() {
    if ( this.isQuoteRejectedVar == null &&
        this.isQuoteApprovedVar == null &&
        this.project.project_status_id == 2 &&
        this.project.quote_rejected_at == null
         )
        return true;
    return (! (this.isQuoteApproved() || this.isQuoteRejected())) &&
      ( this.isQuoteIssuedVar == true )
  }

  isQuoteSent() {
    return this.isQuoteIssued();
  }

  isQuoteCanceled() {
    // console.log(this.project);
    if ( this.project['quote_canceled_at'] != null
         )
        return true;
    return (! (this.isQuoteApproved() || this.isQuoteRejected())) &&
      ( this.isQuoteIssuedVar == true )
  }


  quoteRejected() {
    this.isQuoteRejectedVar = true;
    this.isQuoteApprovedVar = false;
    this.quoteRejectedB = true;
    this.projectEditForm.patchValue({
      quote_rejected_at: 'yes',
      approve_quote: 'no',
    }, this.formEventStop);
    this.onSubmitProjectEditForm();
  }

  isQuoteApprovedVar
  isQuoteApproved() {
    if (this.isQuoteApprovedVar == null)
      return this.projectEditForm.getRawValue().quote_rejected_at == null
      && this.projectEditForm.getRawValue().project_status_id > 2

    return this.isQuoteApprovedVar;
  }

  isQuoteRejectedVar = null;
  isQuoteRejected() {
    if (this.createProject)
      return false;
    if (this.isQuoteRejectedVar == null)
      return this.projectEditForm.getRawValue().quote_rejected_at != null
    return this.isQuoteRejectedVar
  }

  modalRef: BsModalRef;
  schVendor;
  openModalWithClass(template: TemplateRef<any>, vInx) {
    this.formData = this.projectEditForm.getRawValue();
    this.formData = this.formateAllDate(this.formData);
    this.schVendor = this.formData.vendors[vInx];
    
    this.modalRef = this.modalService.show(
      template,
      { class: 'modal-xl' }
    );
    // this.modalRef = this.modalService.show(template);
    // Object.assign({}, { class: ' modal-lg' });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      { class: 'modal-xl', backdrop: 'static' }
    );
  }

  @ViewChild('addVendorCom') addVendorCom: AddVendorComponent;
  openAddVendorModal() {
    let transVal = this.projectEditForm.getRawValue().translations
    this.addVendorCom.openModal(transVal);
  }

  @ViewChild('vendorRatesCom') vendorRatesCom: VendorRatesComponent;
  openRatesModal(vendorId) {
    this.vendorRatesCom.openModal(vendorId);
  }

  @ViewChild('generateInvoiceCom') generateInvoiceModal: GenerateInvoiceComponent;
  openGenerateInvoiceModal() {
    let langCode = this.defaultLangCode;
    if (this.clientSelected.invoice_emails && this.clientSelected.invoice_emails.pdf_language) {
      langCode = this.clientSelected.invoice_emails.pdf_language;
    }
    this.generateInvoiceModal.openModal();
  }

  @ViewChild('generateQuoteCom') generateQuoteModal: GenerateQuoteComponent;
  quoteSendStep = 0;
  quoteDisplayBreakdownsFc: UntypedFormControl;
  openGenerateQuoteModal() {
    if ( !this.project || !this.project.project_manager_id ) {
      alert(this.lang.getText('long_text10'));
      return false;
    }
    this.generateQuoteModal.showAlert();
    if ( this.getTranslationCount() > 200 ) {
      return;
    }
    this.quoteSendStep = 1;
    this.generateQuoteModal.openModal();
    // this.onSubmitProjectEditForm();
  }

  @ViewChild('createCat') createCatCom: CreateCatComponent;
  openCreateCatModal() {
    if(this.unsavedProject && this.projectClaimed){
      if ( confirm(this.lang.getText('do_you_want_to_save_all_changes')) ){
        this.redirectAfterSave = false;
        this.onSubmitProjectEditForm();
      }
    }
    let projectData = this.formateAllDate(this.projectEditForm.getRawValue());
    // let source, target = null;
    // let error = false;
    // projectData.translations.forEach(row => {
    //   source = this.getLanguageById(row['source_lang_id']);
    //   target = this.getLanguageById(row['target_lang_id']);
    //   if(source && (source.trados_code == '' || source.trados_code == null)){
    //     this.openModalDialog('failed',this.lang.getText('please_map_trados_code_into_language')+" "+source.name);
    //     error = true;
    //     return false;
    //   }
    //   if(target && (target.trados_code == '' || target.trados_code == null)){
    //     this.openModalDialog('failed',this.lang.getText('please_map_trados_code_into_language')+" "+target.name);
    //     error = true;
    //     return false;
    //   }
    // });
    // if(!error)
    this.createCatCom.openModal(this.projectId,projectData,this.languages,this.orderId, this.clientSelected);
  }

  openCreateCatModalFilesOnly() {
    if(this.unsavedProject && this.projectClaimed){
      if ( confirm(this.lang.getText('do_you_want_to_save_all_changes')) ){
        this.redirectAfterSave = false;
        this.onSubmitProjectEditForm();
      }
    }
    let projectData = this.formateAllDate(this.projectEditForm.getRawValue());
    projectData.trados_data = this.project.trados_data;
    projectData.xtm_data = this.project.xtm_data;
    this.createCatCom.openModalFilesOnly(this.projectId,projectData,this.languages,this.orderId, this.clientSelected, true);
  }
  nonNumericValPress(event){
    if (event.key.match(/[^$,.\d]/)){
      alert(this.lang.getText('long_text11'));
      return false;
    }
  }

  nonNumericValPressDecimal(event){
    this.helperSer.non_numeric_val_alert(event);
  }

  closeModal() {
    this.modalRef.hide();
    this.claimedPopupDisplayed = false;
  }

  isDateWithTime(dat){
    // console.log(dat, dat.length)
    return ( dat == null || typeof dat.length == 'undefined' );
  }

  formateAllDate(fmData) {
    let deliverChanged = false;
    if ( deliverChanged == false && this.isDateWithTime(fmData.request_date) )
      deliverChanged = true;
    fmData.request_date = this.helperSer.formateDPDate(fmData.request_date);
    if ( deliverChanged == false && this.isDateWithTime(fmData.project_delivery.date) )
      deliverChanged = true;
    fmData.project_delivery.date = this.helperSer.formateDPDate(fmData.project_delivery.date);
    fmData.commit_delivery_only_at.date = this.helperSer.formateDPDate(fmData.commit_delivery_only_at.date);
    fmData.deliveryBatches.map(ele => {
      if ( deliverChanged == false && this.isDateWithTime(ele.requested_at.date) )
        deliverChanged = true;
      /*
      if ( deliverChanged == false && this.isDateWithTime(ele.commited_at.date) )
        deliverChanged = true;
      */
      ele.requested_at.date = this.helperSer.formateDPDate(ele.requested_at.date);
      // ele.commited_at.date = this.helperSer.formateDPDate(ele.commited_at.date);
      return ele;
    });
    fmData.vendors.map(ven => {
      ven.translations.map(tran => {
        tran.batches.map(batch => {
          if ( deliverChanged == false && this.isDateWithTime(batch.delivery_at.date))
            deliverChanged = true;
          batch.delivery_at.date = this.helperSer.formateDPDate(batch.delivery_at.date);
          return batch;
        })
        return tran;
      })

      ven.additionalServices.map(service => {
        service.batches.map(batch => {
          if ( deliverChanged == false && this.isDateWithTime(batch.delivery_at.date))
            deliverChanged = true;
          batch.delivery_at.date = this.helperSer.formateDPDate(batch.delivery_at.date);
          return batch;
        })
        return service;
      })
      return ven;
    })
    if (deliverChanged)
      fmData.ignore_warning = null;
    return fmData;
  }

  onSubmitProjectEditForm() {
    if ( ! this.checkClaimed() ) {
      return;
    }
    if (this.saving) return;
    this.errors=[];
    this.unsavedProject = false;
    this.formData = this.projectEditForm.getRawValue();
    this.formData = this.formateAllDate(this.formData);
    this.formData['langCode'] = this.lang.getCode();
    this.formData['token'] = this.Token.get();
    this.formData['client_id'] = this.clientId;
    //this.formData['currency_id'] = this.currencyId;
    this.formData['comments_details'] = this.getComments_details();
    this.formData['qualityAssurances'] = this.projectEditForm.value.qualityAssurances
     .map((v, i) => v ? this.qualityAssurances[i].id : null )
     .filter(v => v !== null);
    this.formData['projectId'] = this.projectId;
    // console.log(this.formData);
    console.log('Form saved')
    this.saving = true;
    this.http.post(`${this.apiURL}project/update/${this.projectId}`, this.formData).subscribe(
      data => this.handleResponse(data),
      error => {
        this.saving = false;
        this.handleError(error);
        //this.onSubmitProjectEditForm();
      }
    );
  }

  saveProjectMsg = false;
  handleResponse(data) {
    setTimeout(function(){
      this.saving = false;
    }.bind(this), 100)
    let scroll_position = window.pageYOffset;
    if ( this.quoteSendStep == 1 ) {
      // this.generateQuoteModal.openModal(this.clientSelected);
    }
    /* Get detils in project update API itself */
    this.project = data['result'];
    this.initProjectEditForm();
    this.saveProjectMsg = true;
    this.getPageOfQuoteLangTrans();
    /*
    if ( this.quoteSendStep == 3 ) {
      this.quoteSendStep = 1;
      this.closeModal();
    }
    */
    setTimeout(function(){
      this.saveProjectMsg = false;
    }.bind(this), 2000)
    /*
    console.log(data);
    //this.getFormMetaDetails();
    if ( this.redirectAfterSave )
      this.router.navigateByUrl('/project/page/1');
      */
    if ( this.createProject ) {
      this.router.navigateByUrl('/project/edit/'+data['id']);
    }

    window.scrollTo({
      top: scroll_position,
      left: 0,
    });
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
    this.errors = error.error.error;
    this.saveProjectMsg = false;
  }

  @ViewChild('getEmailsTemp') getEmailsTemp: TemplateRef<any>;
  getEmailsTempIpt
  getEmailsTempTitle;
  getEmailsTempBtn;
  getEmailsTempVend;
  getEmailsTempType;
  getEmailsTempSubIpt;
  getEmailsLoading: boolean;
  vendorEmailForm: UntypedFormGroup = this.fb.group({
    email: '',
    subject: '',
  });
  openEmailsTemp(vendor, title, btn, type) {
    if ( type == 'heads_up' && this.getVendorTranslationCount(vendor.getRawValue().vendor_id) > 50 ) {
      this.helperSer.alertIt(this.lang.getText('project_vendor_langu_combi_max_50_breakdown_alert'));
      return;
    }
    this.getEmailsTempVend = vendor;
    this.getEmailsTempTitle = title;
    this.getEmailsTempBtn = btn;
    this.getEmailsTempType = type;
    // let subject = `${type == 'heads_up' ? this.lang.getText('heads_up') : this.lang.getText('confirmation')} for ${this.orderId}`;
    //Since mail traslation is not stated, ignore this translation
    let subject = 'Confirmation';
    if ( type == 'heads_up' ) {
      subject = 'Heads-up'
    } else if ( type == 'cancel' ) {
      subject = 'Cancel';
    }
    subject = `${subject} for ${this.orderId}`;
    this.vendorEmailForm.get('subject').enable();
    this.vendorEmailForm.patchValue({subject: subject});

    this.get_vendor_emails(vendor, subject);

    this.modalRef = this.modalService.show(
      this.getEmailsTemp,
      { class: 'modal-md', backdrop: 'static' }
    );
  }
  
  vendor_email_meta
  get_vendor_emails(vendor, subject) {
    this.getEmailsLoading = true;
    console.log(vendor.value.project_vendor_id)
    let formData = {
      project_id: this.projectId,
      project_vendor_id: vendor.value.project_vendor_id,
      subject: subject
    };
    this.http.post(`${this.apiURL}project/vendor/emails`, formData)
      .subscribe(
        data => {
          this.vendor_email_meta = data['email_meta'];
          this.getEmailsLoading = false;
        }
      );
  }

  getEmailsTempSubmit() {
    // this.getEmailsTempVend.patchValue({emails: this.getEmasilsTempEmails}, this.formEventStop);
    if (this.getEmailsTempType == 'heads_up') {
      this.getEmailsTempVend.patchValue({heads_up: 'yes'}, this.formEventStop);
    } else if (this.getEmailsTempType == 'confirm') {
      this.getEmailsTempVend.patchValue({confirm: 'confirm'});
    } else if (this.getEmailsTempType == 'cancel') {
      this.getEmailsTempVend.patchValue({confirm: 'cancel'});
    }
    this.getEmailsTempVend.patchValue({
      email_meta: this.vendor_email_meta,
    }, this.formEventStop);
    this.onSubmitProjectEditForm();
    this.closeModal();
  }

  scrollable = localStorage.getItem('projectPageScrollable');
  changedScrollable(event) {
    if ( event.target.checked )
      this.scrollable = 'active';
    else
      this.scrollable = '';
    localStorage.setItem('projectPageScrollable', this.scrollable);
  }

  openImportCATModal(event, importCATAnaTemp) {
    let template: TemplateRef<any> = importCATAnaTemp;
    let vendor = event.vendor;
    let ti = event.ti;
    this.importCATVendorRow = vendor;
    this.importCATRow = ti;
    this.modalRef = this.modalService.show(
      template,
      { class: 'modal-xl new-redesign-style' }
    );
  }

  importCATAnaFormat = 'sdlStudio';
  importCATAnaFiles:FileList;
  importCATAnaBtnEn:Boolean = false;
  importCATFileName:string = '';
  importCATVendorRow;
  importCATRow;
  importCATAnaValiFail = false;
  uploadCATAnalysis() {
    let file: File = this.importCATAnaFiles[0];
    let formData:FormData = new FormData();
    formData.append('file', file);
    formData.append('projectId', this.projectId);
    formData.append('format', this.importCATAnaFormat);
    formData.append('token', this.Token.get());
    this.importCATAnaBtnEn = false;
    this.http.post(`${this.apiURL}parse-cat-file`, formData)
      .subscribe(
        data => {
          this.importCATAnaBtnEn = true;
          if ( this.importCATVendorRow !== null ) {
            this.updateVenCATAnaTo(data);
          } else {
            this.updateCATAnaTo(data);
          }
        },
        error => {
          this.importCATAnaValiFail = true;
          setTimeout(() => this.importCATAnaValiFail = false, 3000)
          this.importCATAnaBtnEn = true;
          this.handleError(error);
        }
      );
  }

  importCATAnaFileChange(event) {
    this.importCATFileName = event.target.files[0].name;
    this.importCATAnaFiles = event.target.files;
    this.importCATAnaBtnEn = ( this.importCATAnaFiles.length > 0 )
  }

  updateCATAnaTo(catData) {
    let catFa = this.translationsFA.at(this.importCATRow).controls;
    this.importCatData(catFa, catData);
    this.closeModal();
  }

  updateVenCATAnaTo(catData) {
    let venFa = this.vendorsFA.at(this.importCATVendorRow);
    this.importCatData(venFa.get('translations').at(this.importCATRow).controls, catData);
    this.closeModal();
  }

  copyVenCATtoAll() {
    return;
  }

  importCatData(catFa, catData) {
    catFa.catAnalysisGroA.controls.forEach((control, i) => {
      if ( control.value.display ) {
        control.patchValue({word_count: catData['a'][i]});
      }
    });
    catFa.catAnalysisGroB.controls.forEach((control, i) => {
      if ( control.value.display ) {
        control.patchValue({word_count: catData['b'][i]});
      }
    });
  }

  getInvoiceDate() {
    let postData = {
      token: this.Token.get(),
      projectId: this.projectId
    };
    this.http.post(`${this.apiURL}project/view`, postData).subscribe(
      data => {
        let project = data['result'];
        let invoicedAt = this.splitDateAndTime(project.invoiced_at).date
        this.projectEditForm.patchValue({
          invoiced_at: invoicedAt,
          invoiced_at_cb: invoicedAt,
        })
      },
      error => this.handleError(error)
    );
  }
  
  addDeliverySheduleHeadeup(vendor,id){
    let formData = this.Token.getAPITokenData();
    formData['project_id'] = id;
    formData['vendor_id'] = vendor.getRawValue().vendor_id;
    this.http.post(`${this.apiURL}vendor-delivery-schedule-info`, formData).subscribe(
      data => {
        vendor.patchValue({
          headsUpContent: vendor.getRawValue().headsUpContent + this.setDeliverySheduleHeadeup(data)
        });
        this.onSubmitProjectEditForm();
      },
      error => this.handleError(error)
    );
  }
  isProjectClaimed = false;
  checkClaimed(show=true){
    let cancel=this.projectEditForm.get('cancel_quote').value;
    if(!this.createProject && !this.projectClaimed && cancel != 'yes'){
      if(!this.claimedPopupDisplayed&&show){
        this.modalRef = this.modalService.show(this.claimedPopup,{backdrop:'static',keyboard:false, class: 'new-redesign-style'});
        this.claimedPopupDisplayed = true;
      }
      this.isProjectClaimed = false;
      return false;
    }
    this.isProjectClaimed = true;
    return true;
  }
  goToHome(){
    this.router.navigateByUrl('/project');
    this.closeModal();
  }
  claimThisProject(projectId) {
    let postData = this.Token.getAPITokenData();
    postData['projectId'] = projectId;
    this.http.post(`${this.apiURL}claim-new-project`, postData).subscribe(
      data => {
        this.projectClaimed = true;
        this.getFormMetaDetails();
        this.getPageOfQuoteLangTrans();
        this.closeModal();
      },
      error => this.handleError(error)
    );
  }
  

  invoicePDF
  getInvoice(process) {
    if (process == 'clear') {
      this.invoicePDF = null;
    }
    let formData = this.Token.getAPITokenData();
    formData['projectId'] = this.projectId;
    this.http.post(`${this.apiURL}invoice/get`, formData)
      .subscribe(
        data => {
          this.invoicePDF = data;
        },
        error => {
          this.handleError(error);
        }
      );
  }

  pfInvoiceDo(finance, method){
    let formData = {
      token: this.Token.get(),
      projectFinanceID: finance.id
    };
    this.http.post(`${this.apiURL}project-finance/get`, formData)
    .subscribe(
      (data: any) => {
        let blob = this.helperSer.base64ToBlob(data.pdf);
        if (method == 'down') {
          this.helperSer.downloadFile(blob, finance.filename);
        } else if ( method == 'view' ) {
          window.open(window.URL.createObjectURL(blob));
        }  
      },
      error => {
        this.handleError(error);
      }
    );
  }

  invoiceDo(method) {
    let blob = this.helperSer.base64ToBlob(this.invoicePDF.pdf);
    if (method == 'down') {
      this.helperSer.downloadFile(blob, 'invoice-'+this.invoicePDF.orderNumber);
    } else if ( method == 'view' ) {
      window.open(window.URL.createObjectURL(blob));
    } else if ( method == 'send' ) {
      let invoiceEmails = [];
      if (this.clientSelected.invoice_emails) {
        invoiceEmails = this.clientSelected.invoice_emails.email.slice();
      }
      if ( invoiceEmails.length == 0 ) {
        invoiceEmails.push(this.clientSelected.email)
      }
      this.generateInvoiceModal.openSendModal(this.invoicePDF.pdf, invoiceEmails,null);
    }
  }

  openVendorsCommentModal() {
    this.modalRef = this.modalService.show(this.vendorsCommentTemp);
  }

  isPaymentAPIAvil() {
    return true;
  }
  
  checkClaimedProject(){
    let postData = this.Token.getAPITokenData();
    postData['projectId'] = this.projectId;
    this.http.post(`${this.apiURL}check-project-claim-status`, postData).subscribe(
      data => {
        this.projectClaimed = data['result']['status'];
      },
      error => this.handleError(error)
    );
  }
  claimFromComponent(claim){
    claim == true ? this.claimThisProject(this.projectId) : this.goToHome();
  }
  
  setDeliverySheduleHeadeup(data){
    // console.log(data);
    const schedule = this.lang.getText("delivery_schedule") != undefined ? this.lang.getText("delivery_schedule") : "Delivery Schedule";
    let str = '<p><b>' + schedule + '</b></p>';
    const batch = this.lang.getText('delivery_batch') != undefined ? this.lang.getText('delivery_batch') : 'Delivery Batch';
    if(data['translations']!=undefined && data['translations'].length){
      let t = data['translations'];
      for( var i = 0; i < t.length; i++ ){
        str += '<p>' + t[i].source_lang + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp' + t[i].target_lang + '</p>';
        if(t[i].batches!=undefined && t[i].batches.length){
          let tb = t[i].batches;
          for( var j = 0; j < tb.length; j++ ){
            str += '<p>&nbsp;&nbsp;&nbsp;' +batch+ ' ' + (j + 1) + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp';
            if(tb[j]['date']!=undefined){
              str+=this.helperSer.formatDateTime(tb[j]['date']).replace('/', ' ')+':00';
            }
            if(tb[j]['description']!=undefined){
              str+='&nbsp;&nbsp;'+tb[j]['description'];
            }
            str += '</p>';
          }
        }
      }
    }
    if(data['services']!=undefined && data['services'].length){
      let s = data['services'];
      for( var i = 0; i < s.length; i++ ){
        str += '<p>' + s[i].comment + '</p>';
        if(s[i].batches != undefined && s[i].batches.length){
          let sb = s[i].batches;
          for( var j = 0; j < sb.length; j++ ){
            str += '<p>&nbsp;&nbsp;&nbsp;' +batch+ ' ' + (j + 1) + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp';
            if(sb[j]['date']!=undefined){
              str+=this.helperSer.formatDateTime(sb[j]['date']).replace('/', ' ')+':00';
            }
            if(sb[j]['description']!=undefined){
              str+='&nbsp;&nbsp;'+sb[j]['description'];
            }
            str += '</p>';
          }
        }
      }
    }
    return str;
  }

  parseOptgroupValue(val){
    return val.split("::");
  }

  getLinkedBatches(serviceType, id){
    let batches = [];
    this.deliveryBatchesFA.value.forEach(batch => {
      if ( batch.service_type_to == serviceType && batch.service_type_id == id ) {
        batches.push(batch);
      }
    });
    return batches;
  }

  getErrorMessage(error) {
    return error === "The word rate format is invalid." || error === "The Vendor word rate format is invalid." ? this.lang.getText('word_rate_decimal') : error
  }

  disableCreateCat = false;
  onSourceLanguageChange(){
    this.disableCreateCat = false;
    let transVal = this.projectEditForm.getRawValue().translations;
    transVal.forEach(tran => {
      if(transVal[0].source_lang_id != Number(tran.source_lang_id)){
        this.disableCreateCat = true;
        return false;
      }
    });
    return false;
  }

  loading = false;
  FilesToGetExportStatus = [];
  xtm_project_is_under_analysis = false;
  viewProjectProgress(action = '', showXTMFiles = false) {
    this.xtm_project_is_under_analysis = false;
    this.loading = true;
    this.showProjectProgress.display = true;
    this.translationsFA.controls.map((tranFb, ti) => {
      tranFb.patchValue({display: false}, this.formEventStop);
    });
    let data = [];
    if (this.project.type_of == 'trados') {
      this.loading = true;
      this.showProjectProgress.display = true;
      this.trasnlation_pagination_data.trados_files_loading = true;
      let trados_files_page = this.trasnlation_pagination_data.trados_files_page;
      if (action == 'prev') {
        trados_files_page--;
      } else if (action == 'next') {
        trados_files_page++;
      }
      let formData = this.Token.getAPITokenData();
      formData['projectId'] = this.projectId;
      formData['page'] = trados_files_page;
      formData['per_page'] = this.trasnlation_pagination_data.trados_files_per_page;
      this.http.post(`${this.apiURL}trados/view-project-progress/${this.projectId}`,formData).subscribe(
        (data:any) => {
          this.loading = false;
          let result = data['result'];
          let target_files = data['target_files'];

          if(target_files['data'] != undefined){
            target_files['data']['items'].forEach(file => {
              file.isSelected = false;
              let local_data = file['local_data'];
              if(local_data && local_data.export_id && !local_data.export_status){
                this.FilesToGetExportStatus.push({
                  trados_project_id: result.id,
                  trados_project_file_id: file.id,
                  file_version_id: file.latestVersion.id,
                  export_id : local_data.export_id,
                  file_name: file.name,
                  trados_file_id: local_data.id,
                });
              }
            });
          }
          this.trasnlation_pagination_data.trados_files_total_pages = data['total_pages'];
          this.trasnlation_pagination_data.trados_files_loading = false;
          this.trasnlation_pagination_data.trados_files_page = trados_files_page;

          this.showProjectProgress.data['target_files_data'] = target_files['data'];
          this.showProjectProgress.data['target_files_last_api_call_at'] = target_files['target_files_last_api_call_at'];
          this.filesSelected = false;
          this.checklistFiles =  target_files['data']['items'];  
  
          let completed = result.analytics.workloadStatistics.completed;
          let total = result.analytics.workloadStatistics.total;
          let percentage = 0;
          if(total!= 0){
            percentage = Math.round((completed / total) * 100);
          }
          if(percentage >= 0 && percentage < 25){
            this.showProjectProgress.data['color'] = 'red-bar';
          }
          if(percentage >= 25 && percentage < 75){
            this.showProjectProgress.data['color'] = 'yellow-bar';
          }
          if(percentage >= 75 && percentage <= 100){
            this.showProjectProgress.data['color'] = 'green-bar';
          }
          this.showProjectProgress.data['percentage'] = percentage;
          this.showProjectProgress.data['status'] = result.status;
          this.triggerExportStatusCall();
        },
        error => {
          this.loading = false;
          this.openModalDialog('failed', error.error.error);
          console.log('error',error);
          // this.handleError(error)
        }
      );
    } else if (this.checkXTMData()) {
      this.loadingXTM = true;
      this.showXTMProjectProgress.display = true;
      this.trasnlation_pagination_data.xtm_files_loading = true;
      let xtm_files_page = this.trasnlation_pagination_data.xtm_files_page;
      if (action == 'prev') {
        xtm_files_page--;
      } else if (action == 'next') {
        xtm_files_page++;
      }
      let formData = this.Token.getAPITokenData();
      formData['projectId'] = this.projectId;
      formData['page'] = xtm_files_page;
      formData['per_page'] = this.trasnlation_pagination_data.xtm_files_per_page;
      formData['show_files'] = showXTMFiles;
      formData['clientId'] = this.clientSelected.id;
      formData['action'] = action;
      let connectionId = '';
      if (this.clientSelected.xtm_connection_details != null) {
        connectionId = this.clientSelected.xtm_connection_details.id;
      }
      formData['connectionId'] = connectionId;
      this.http.post(`${this.apiURL}xtm/view-project-progress/${this.projectId}`,formData).subscribe(
        (result: any) => {
          this.handleXTMFilesResponse(result, showXTMFiles, xtm_files_page);
        },
        error => {
          this.loadingXTM = false;
          this.loading = false;
          if (error.error.error == 'project is under analysis') {
            this.xtm_project_is_under_analysis = true;
          } else {
            this.openModalDialog('failed', error.error.error);
          }
          console.log('error',error);
          // this.handleError(error)
        }
      );
    } else if (this.checkPrhaseData()) {
      this.loadingPhrase = true;
      this.showPhraseProjectProgress.display = true;
      this.trasnlation_pagination_data.phrase_files_loading = true;
      let phrase_files_page = this.trasnlation_pagination_data.phrase_files_page;
      if (action == 'prev') {
        phrase_files_page--;
      } else if (action == 'next') {
        phrase_files_page++;
      }
      let formData = this.Token.getAPITokenData();
      formData['projectId'] = this.projectId;
      formData['page'] = phrase_files_page;
      formData['per_page'] = this.trasnlation_pagination_data.phrase_files_per_page;
      formData['show_files'] = showXTMFiles;
      formData['clientId'] = this.clientSelected.id;
      formData['phraseProjectUid'] = this.project.phrase_data?.phrase_project_uid;
      let connectionId = '';
      if (this.clientSelected.phrase_connection_details != null) {
        connectionId = this.clientSelected.phrase_connection_details.id;
      }
      formData['connectionId'] = connectionId;
      this.http.post(`${this.apiURL}phrase/view-project-progress/${this.projectId}`,formData).subscribe(
        (result: any) => {
          let data = result.result;
          let phrase_project_data = result.phrase_project;
          this.project.phrase_data = phrase_project_data;
          this.loadingPhrase = false;
          this.loading = false;
          let percentage = data.xtm_project_status_percent;
          if (percentage >= 0 && percentage < 25) {
            this.showPhraseProjectProgress.data['color'] = 'red-bar';
          }
          if (percentage >= 25 && percentage < 75) {
            this.showPhraseProjectProgress.data['color'] = 'yellow-bar';
          }
          if (percentage >= 75 && percentage <= 100) {
            this.showPhraseProjectProgress.data['color'] = 'green-bar';
          }
          this.showPhraseProjectProgress.data['percentage'] = percentage;
          this.showProjectProgress.data['status'] = phrase_project_data.project_status;
          if(showXTMFiles) {
            this.showProjectProgress.data['percentage'] = percentage;
            this.showProjectProgress.data['status'] = phrase_project_data.project_status;
            let target_files = result.target_files;
            this.trasnlation_pagination_data.phrase_files_total_pages = target_files['total_pages'];
            this.trasnlation_pagination_data.phrase_files_loading = false;
            this.trasnlation_pagination_data.phrase_files_page = phrase_files_page;
  
            this.showProjectProgress.data['target_files_data'] = target_files['data'];
            this.showProjectProgress.data['target_files_last_api_call_at'] = result.target_files_last_api_call_at;
            this.filesSelected = false;
            this.checklistFiles =  target_files;
    
            // let completed = result.analytics.workloadStatistics.completed;
            // let total = result.analytics.workloadStatistics.total;
          }

        },
        error => {
          this.loadingPhrase = false;
          this.loading = false;
          const errorDescription = error.error.exception.errorDescription;
          if(!error.error.success  && errorDescription.includes('The object referenced by the field "projectUid"')) {
            this.openModalDialog('failed', this.lang.getText('project_not_found_in_phrase'));
          } else {
            this.openModalDialog('failed', error.error.error);
          }
          console.log('error',error);
          // this.handleError(error)
        }
      );
    }
  }
  handleXTMFilesResponse(result, showXTMFiles, xtm_files_page) {
    let data = result.statusData;
    this.project.xtm_data = data;
    this.loadingXTM = false;
    this.loading = false;
    let percentage = data.xtm_project_status_percent;
    if (percentage >= 0 && percentage < 25) {
      this.showXTMProjectProgress.data['color'] = 'red-bar';
    }
    if (percentage >= 25 && percentage < 75) {
      this.showXTMProjectProgress.data['color'] = 'yellow-bar';
    }
    if (percentage >= 75 && percentage <= 100) {
      this.showXTMProjectProgress.data['color'] = 'green-bar';
    }
    this.showXTMProjectProgress.data['percentage'] = percentage;
    this.showXTMProjectProgress.data['status'] = data.xtm_project_status;
    if(showXTMFiles) {
      this.showProjectProgress.data['percentage'] = percentage;
      this.showProjectProgress.data['status'] = data.xtm_project_status;
      let target_files = result.target_files;
      this.trasnlation_pagination_data.xtm_files_total_pages = target_files['total_pages'];
      this.trasnlation_pagination_data.xtm_files_loading = false;
      this.trasnlation_pagination_data.xtm_files_page = xtm_files_page;

      this.showProjectProgress.data['target_files_data'] = target_files['data'];
      this.showProjectProgress.data['target_files_last_api_call_at'] = new Date();
      this.filesSelected = false;
      this.checklistFiles =  target_files['data'];

      // let completed = result.analytics.workloadStatistics.completed;
      // let total = result.analytics.workloadStatistics.total;
    }
  }

  updateQuotingStatistics(){
    let data = [];
    if (this.project.type_of == 'trados') {
      this.showProjectProgress.display = false;
      this.loading = true;
      this.http.post(`${this.apiURL}trados/update-quoting-statistics/${this.projectId}`, this.Token.getAPITokenData()).subscribe(
        data => {
          this.loading = false;
          // console.log('enter',data);
          this.getProjectDetails();
          this.openModalDialog('success', this.lang.getText('trados_quoting_statistics_updated'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 2000);
        },
        error => {
          this.loading = false;
          console.log('error', error);
          this.openModalDialog('failed', error.error.error);
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          // this.handleError(error)
        }
      );
    } else if (this.checkXTMData()) {
      this.http.post(`${this.apiURL}xtm-connection/update-quoting-statics/${this.projectId}`, this.Token.getAPITokenData()).subscribe(
        data => {
          this.loadingXTM = false;
          // console.log('enter',data);
          this.getProjectDetails();
          this.openModalDialog('success', this.lang.getText('xtm_quoting_statistics_updated'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 2000);
        },
        error => {
          this.loadingXTM = false;
          console.log('error', error);
          this.openModalDialog('failed', error.error.error);
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          // this.handleError(error)
        }
      );
    } else if (this.checkPrhaseData()) {
      let formData = {};
      formData['selectedClientId'] = this.clientSelected.id;
      this.http.post(`${this.apiURL}phrase-connection/project-analysis/${this.projectId}`, formData).subscribe(
        data => {
          this.loadingPhrase = false;
          // console.log('enter',data);
          this.getProjectDetails();
          this.openModalDialog('success', this.lang.getText('phrase_quoting_statistics_updated'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 2000);
        },
        error => {
          this.loadingPhrase = false;
          console.log('error', error);
          const errorDescription = error.error.exception.errorDescription;
          if (errorDescription.includes('The object referenced by the field "projectUid"')) {
            this.openModalDialog('failed', this.lang.getText('project_not_found_in_phrase'));
          } else {
            this.openModalDialog('failed', error.error.error);
          }
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          // this.handleError(error)
        }
      );
    }
  }

  updateQuotingStatisticsWarning() { 
    this.openModalDialog('failed',this.lang.getText('sap_xtm_update_quoting_metrics_warning'));
  }

  display = 'none';
  status = '';
  message = '';
  openModalDialog(status,message){
    this.display='block';
    this.status = status;
    this.message = message;
  }
  closeModalDialog(){
    this.display='none';
  }

  tradosCreateResponse(response){
    if(response.status == 'Created' || response.status == 'startWithoutFiles' || response.status == 'Error' ){
      // this.disableCreateCat = true;
      this.getProjectDetails();
    }
  }
  xtmCreateResponse(response){
    if(response.status == 'Created' || response.status == 'startWithoutFiles' || response.status == 'Error' ){
      // this.disableCreateCat = true;
      this.getProjectDetails();
    }
  }

  phraseCreateResponse(response){
    if(response.status == 'Created'  || response.status == 'Error' ){
      // this.disableCreateCat = true;
      this.getProjectDetails();
    }
  }

  checkTradosData(type){
    let trados_data = this.project.trados_data;
      if(type == 'alc'){
        if(trados_data && trados_data.trados_project_id && trados_data.trados_project_id.meta_value){
          return false; 
        }
        return true;
      }
      if(type == 'ccp'){
        if(this.createProject || this.disableCreateCat || (trados_data && trados_data.trados_project_id && trados_data.trados_project_id.meta_value)){
          return false;
        }
        return true;
      }
      if(type == 'vpp'){
        if(trados_data && trados_data.trados_project_id && trados_data.trados_project_id.meta_value){
          return true;
        }
        return false;
      }
      if(type == 'uqs'){
        if(trados_data && trados_data.trados_project_id && trados_data.trados_project_id.meta_value &&  (trados_data.trados_project_status && trados_data.trados_project_status.meta_value != "inProgress")){
          return true;
        }
        return false;
      }
  }

  checkXTMData(){
    if (this.project.type_of == 'xtm') {
      return true;
    } else {
      return false;
    }
  }

  checkPrhaseData(){
    if (this.project.type_of == 'phrase') {
      return true;
    } else {
      return false;
    }
  }

  enableUpdateQuotingBtn() {
    return this.project.xtm_data.xtm_project_status == 'NOT_STARTED' ? true : false;
  }

  enableTradosUpdateQuotingBtn() {
    let trados_data = this.project.trados_data;
    if (trados_data.length > 0) {
      return (trados_data && trados_data.trados_project_status && trados_data.trados_project_status.meta_value == 'inProgress') ? true : false;
    }
    if (this.project.phrase_data) {
      const phraseProjectStatus = this.project.phrase_data.project_status == "COMPLETED";  
      const hasNonNewJobs = this.project.phrase_data?.translations?.some(translation => translation.job_status !== 'NEW');
      if (hasNonNewJobs || phraseProjectStatus) { 
          return false;
        } else {
          return true;
      }      
    }
  }
  
  filesSelected:boolean;
  checklistFiles = [];
  checkedListFiles = [];
  getCheckedItemListFiles(){
    this.checkedListFiles = [];
    for (var i = 0; i < this.checklistFiles.length; i++) {
      if(this.checklistFiles[i].isSelected)
      this.checkedListFiles.push(this.checklistFiles[i]);
    }
  }
  isAllSelectedFiles() {
    this.filesSelected = this.checklistFiles.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemListFiles();
  }
  checkUncheckAllFiles() {
    for (var i = 0; i < this.checklistFiles.length; i++) {
      this.checklistFiles[i].isSelected = this.filesSelected;
    }
    this.getCheckedItemListFiles();
  }
  listProjectTargetFiles(){
    this.loading = true;
    this.showProjectProgress.display = true;
    this.http.post(`${this.apiURL}trados/list-project-target-files/${this.projectId}`,this.Token.getAPITokenData()).subscribe(
      (data:any) => {
        this.loading = false;
        let target_files = data['target_files'];
        target_files['data']['items'].forEach(file => {
          file.isSelected = false;
        });
        this.showProjectProgress.data['target_files_data'] = target_files['data'];
        this.showProjectProgress.data['target_files_last_api_call_at'] = target_files['target_files_last_api_call_at'];
        this.filesSelected = false;
        this.checklistFiles =  target_files['data']['items'];   
      },
      error => {
        this.loading = false;
        console.log('error',error);
      }
    );
  }

  downloadCatFiles() {
    if(this.project.type_of == 'trados') {
      this.downloadTradosFiles();
    } else if(this.checkXTMData()) {
      this.downloadXTMFiles();
    } else if(this.checkPrhaseData()) {
      this.getPhraseFiles();
    }
  }

  downloadTradosFiles() {
    this.loading = true;
    this.showProjectProgress.display = true;
    console.log('this.checkedListFiles',this.checkedListFiles);
    let formData = {
      token: this.Token.get(),
      checkedListFiles : this.checkedListFiles,
    };
    this.http.post(`${this.apiURL}trados/download-files/${this.projectId}`,formData).subscribe(
      (data:any) => {
        this.loading = false;
        let result = data['result'];
        this.viewProjectProgress();
      },
      error => {
        this.loading = false;
        console.log('error',error);
      }
    );
  }

  downloadXTMFiles() {
    this.loading = true;
    this.showProjectProgress.display = true;
    let formData = {
      token: this.Token.get(),
      checkedListFiles : this.checkedListFiles,
      clientId: this.clientSelected.id
    };
    let connectionId = '';
    if (this.clientSelected.xtm_connection_details != null) {
      connectionId = this.clientSelected.xtm_connection_details.id;
    }
    formData['connectionId'] = connectionId;
    formData['page'] = this.trasnlation_pagination_data.xtm_files_page;
    formData['per_page'] = this.trasnlation_pagination_data.xtm_files_per_page;
    this.http.post(`${this.apiURL}xtm/download-files/${this.projectId}`,formData).subscribe(
      (data:any) => {
        this.loading = false;
        this.handleXTMFilesResponse(data, true, this.trasnlation_pagination_data.xtm_files_page);
      },
      error => {
        this.loading = false;
        console.log('error',error);
      }
    );
  }

  getPhraseFiles() {
    this.loading = true;
    this.showProjectProgress.display = true;
    let formData = {
      token: this.Token.get(),
      checkedListFiles : this.checkedListFiles,
      clientId: this.clientSelected.id
    };
    let connectionId = '';
    if (this.clientSelected.phrase_connection_details != null) {
      connectionId = this.clientSelected.phrase_connection_details.id;
    }
    formData['connectionId'] = connectionId;
    this.http.post(`${this.apiURL}phrase/get-file/${this.projectId}`,formData).subscribe(
      (data:any) => {
        this.loading = false;
        this.viewProjectProgress('', true);
      },
      error => {
        this.loading = false;
        console.log('error',error);
      }
    );
  }

  downloadCatFilesLocal() {
    if(this.project.type_of == 'trados') {
      this.downloadTradosFilesLocal();
    } else if(this.checkXTMData()) {
      this.downloadXTMFilesLocal();
    } else if(this.checkPrhaseData()) {
      this.downloadPhraseFilesLocal();
    }
  }

  downloadTradosFilesLocal(){
    this.loading = true;
    this.showProjectProgress.display = true;
    console.log('this.checkedListFiles',this.checkedListFiles);
    let formData = {
      token: this.Token.get(),
      checkedListFiles : this.checkedListFiles,
    };
    this.http.post(`${this.apiURL}trados/download-files-local/${this.projectId}`,formData).subscribe(
      (data:any) => {
        this.loading = false;
        window.open(data['url'], "_blank");
      },
      error => {
        this.loading = false;
        console.log('error',error);
      }
    );
  }

  downloadXTMFilesLocal() {
    this.loading = true;
    this.showProjectProgress.display = true;
    let formData = {
      token: this.Token.get(),
      checkedListFiles : this.checkedListFiles,
    };
    this.http.post(`${this.apiURL}xtm/download-files-local/${this.projectId}`,formData).subscribe(
      (data:any) => {
        this.loading = false;
        window.open(data['url'], "_blank");
      },
      error => {
        this.loading = false;
        console.log('error',error);
      }
    );
  }

  downloadPhraseFilesLocal() {
    this.loading = true;
    this.showProjectProgress.display = true;
    let formData = {
      token: this.Token.get(),
      checkedListFiles : this.checkedListFiles,
    };
    this.http.post(`${this.apiURL}phrase/download-files-local/${this.projectId}`,formData).subscribe(
      (data:any) => {
        this.loading = false;
        window.open(data['url'], "_blank");
      },
      error => {
        this.loading = false;
        console.log('error',error);
      }
    );
  }
  phraseFilesInProgress = false;
  completeProject(){
    let data = {
      token : this.Token.get()
    };
    if (this.project.type_of == 'trados') {
      let inProgress = false;
      for (var i = 0; i < this.checklistFiles.length; i++) {
        if(this.checklistFiles[i].status == 'inProgress'){
          inProgress = true;
        }
      }
      if(inProgress){
        if ( !confirm(this.lang.getText('some_files_in_this_project_are_still_in_progress') + '. ' + this.lang.getText('are_you_sure_you_want_to_complete_the_project')+'?') )
        return;
      }
      else{
        if ( !confirm(this.lang.getText('are_you_sure_you_want_to_complete_the_project')+'?') )
        return;
      }
      this.http.post(`${this.apiURL}trados/complete-project/${this.projectId}`, data).subscribe(
        res => {
          this.openModalDialog('success',this.lang.getText('project_completed_in_trados'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
        },
        error => {
          this.closeModalDialog();
          if(error.status === 400)  
            this.openModalDialog('failed',this.lang.getText('api_error_bad_request'));
          else {  
            this.openModalDialog('failed',error.error.error);
          }  
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 2000);
          this.handleError(error)
        });
    } else if (this.checkXTMData()) {
      let connectionId = '';
      if (this.clientSelected.xtm_connection_details != null) {
        connectionId = this.clientSelected.xtm_connection_details.id;
      }
      data['connectionId'] = connectionId;
      this.http.post(`${this.apiURL}xtm/complete-project/${this.projectId}`, data).subscribe(
        res => {
          this.openModalDialog('success',this.lang.getText('project_completed_in_xtm'));
          this.getProjectDetails();
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
        },
        error => {
          this.closeModalDialog();
          if(error.status === 400)  
            this.openModalDialog('failed',this.lang.getText('api_error_bad_request'));
          else {  
            this.openModalDialog('failed',error.error.error);
          }  
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 2000);
          this.handleError(error)
        });
    } else if (this.checkPrhaseData()) {
      for (var i = 0; i < this.checklistFiles.length; i++) {
        if(this.checklistFiles[i].job_status == 'NEW' || this.checklistFiles[i].job_status == 'Rejected' || this.checklistFiles[i].job_status == 'Accepted'){
          this.phraseFilesInProgress = true;
        }
      }
      this.projectCloseModalRef = this.modalService.show(this.projectCloseModalTemp);
    }
  }

  phraseCompleteProject() {
    let data = {
      token : this.Token.get(),
      clientId: this.clientSelected.id,
      jobFiles: this.checklistFiles,
    };
    this.http.post(`${this.apiURL}phrase/complete-project/${this.projectId}`, data).subscribe(
      res => {
        this.getProjectDetails();
        this.showProjectProgress.data['status'] = res['status'];
        this.openModalDialog('success',this.lang.getText('project_completed_in_phrase'));
        setTimeout(() => {
          this.closeModalDialog();
        }, 2 * 1000);
      },
      error => {
        this.closeModalDialog();
        if(error.status === 400)  
          this.openModalDialog('failed',this.lang.getText('api_error_bad_request'));
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        setTimeout(() => {
          this.closeModalDialog();
        }, 2 * 2000);
        this.handleError(error)
      });
  }
  
  @ViewChild('openProjectRelationsCom') openProjectRelationsCom: ProjectRelationListComponent;
  openRelatedProjectModal(parent_project_id) {
    this.openProjectRelationsCom.getProjectRelations(parent_project_id);
  }

  updateProjectRelationsCount(event) {
    this.project_relation_count = event.project_relation_count;
  }

  
  @ViewChild('finance_breakdown_com') finance_breakdown_com: FinanceBreakdownComponent;
  @ViewChild('vendor_finance_breakdown_com') vendor_finance_breakdown_com: VendorFinanceBreakdownComponent;
  
  updateCAT(event) {
    let type = event.type;
    let translation = event.translation;
    let index = event.index;
    let ti = event.ti;
    let formData = this.Token.getAPITokenData();
    if ( type == 'vendor' && this.vendorsFA.at(index).get('project_vendor_id').value == '' ) {
      this.onSubmitProjectEditForm();
      return;
    }
    formData['projectId'] = this.projectId;
    formData['translation'] = translation.value;
    formData['vendor_id'] = type == 'vendor' ? this.vendorsFA.at(index).get('vendor_id').value : '';
    formData['bTz'] = - ( new Date().getTimezoneOffset() );
    translation.patchValue({ 'display': false });
    this.http.post(`${this.apiURL}project/translation-update-cat`, formData).subscribe(
      data => {
        if (type == 'vendor'){
          //
          this.vendorsFA.at(index).get('translationsCombi').at(ti).get('catAnalysGroA').patchValue(translation.get('catAnalysisGroA').value);
          this.vendorsFA.at(index).get('translationsCombi').at(ti).get('catAnalysGroB').patchValue(translation.get('catAnalysisGroB').value);
          // this.calculateVendorTotalCost();
        } else {
          this.finance_breakdown_com.getTranslations(1);
        }
        this.get_translations_amount(true);
        if ( data['result'] == 'created' ) {
          translation.patchValue({project_translate_id: data['id']}, this.formEventStop);
        }
        translation.patchValue({is_cat_changed: false}, this.formEventStop);
      },
      error => {
        this.loading = false;
        if (error.status == 401) {
          this.handleError(error)
        } else {
          this.openModalDialog('failed', error.error.error);
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
        }
      }
    );
  }

  quoteUpdateCATWordRates(event, translationKey) {
    this.nonNumericValPress(event);
    
    let translationfb = this.translationsFA.at(translationKey);
    let totalCost = 0;
    let subCost = 0;
    let wordsCount = 0;
    wordsCount += (
      this.calculateWordsCatGro(translationfb.controls.catAnalysisGroA) +
      this.calculateWordsCatGro(translationfb.controls.catAnalysisGroB));

    let weightedCount = 0;
    
    let wordRateT = translationfb.controls.word_rate.value;
    this.changeWordRateGroClient(translationfb, 'catAnalysisGroA', wordRateT);
    this.changeWordRateGroClient(translationfb, 'catAnalysisGroB', wordRateT);
    weightedCount += (
      this.calculateWordsWeightedCatGro(translationfb.controls.catAnalysisGroA, wordRateT) +
      this.calculateWordsWeightedCatGro(translationfb.controls.catAnalysisGroB, wordRateT));
    
    subCost = subCost + this.calculateCatGro(translationfb.controls.catAnalysisGroA);
    this.subCost = (subCost + this.calculateCatGro(translationfb.controls.catAnalysisGroB));
  }

  updateChildTrasnFA: EventEmitter<boolean> = new EventEmitter();
  mapProjectResTrasnlations(event) {
    let pVi = event.pvi;
    let res_page = this.trasnlation_pagination_data.res_page -1;
    let ti = res_page == 1 ? 0 : (res_page * this.trasnlation_pagination_data.res_per_page);
    let venTras = event.venTras;
    venTras.map((venTraVal, venTrai) => {
      this.initVendorTranslationForm(pVi, venTraVal.translation_word_rates, venTraVal.id);
      let vTranCons = this.vendorsFA.controls[pVi].controls.translations.controls
      let venTrnIn = (vTranCons.length) - 1;
      let venTranCombiFg = this.vendorsFA.at(pVi).get('translations').at(venTrnIn)
      let translateMetaValues = [];
      if(venTraVal.client_translation_meta_values) {
        translateMetaValues[0] = {
          'project_translate_id': venTraVal.client_translation_meta_values.project_translate_id,
          'meta_key': 'sap_xtm_job_file_name',
          'meta_value': venTraVal.client_translation_meta_values.xtm_job_file_name
        }
      }
      vTranCons[venTrnIn].patchValue({
        project_translate_id: venTraVal.id,
        source_lang_id: venTraVal.source_lang_id,
        target_lang_id: venTraVal.target_lang_id,
        word_rate: venTraVal.word_rate,
        client_translation_meta_values: translateMetaValues,
        orignalamount: venTraVal.amount,
        financially_closed: (venTraVal.financially_closed == 1 ? true : false),
        project_finances_id: venTraVal.project_finances_id,
        sap_xtm_job_id: venTraVal.xtm_job_id
      }, this.formEventStop)

      if (this.res_disable_language_input(vTranCons[venTrnIn])) {
        vTranCons[venTrnIn].controls['source_lang_id'].disable();
        vTranCons[venTrnIn].controls['target_lang_id'].disable();
      }
      if (this.res_disable_word_rate_input(vTranCons[venTrnIn])) {
        vTranCons[venTrnIn].controls['word_rate'].disable();
      }

      this.initVendorTranslationFormOrignal(pVi);
      let vTranConsCombi = this.vendorsFA.controls[pVi].controls.translationsCombi.controls
      let venTraVal_financially_closed = venTraVal.financially_closed == 1 ? true : false;
      vTranConsCombi[venTrnIn].patchValue({
        project_translate_id: venTraVal.id,
        source_lang_id: venTraVal.source_lang_id,
        target_lang_id: venTraVal.target_lang_id,
        word_rate: venTraVal.word_rate,
        client_translation_meta_values: translateMetaValues,
        orignalamount: venTraVal.amount,
        financially_closed: venTraVal_financially_closed,
        project_finances_id: venTraVal.project_finances_id,
        sap_xtm_job_id: venTraVal.xtm_job_id
      }, this.formEventStop)

      venTraVal['translation_word_rates'].map((vTCVal) => {
        this.updateVendorCombiValues(venTraVal.id, vTranCons[venTrnIn].get('catAnalysisGroA'), vTCVal, venTraVal_financially_closed);
        this.updateVendorCombiValues(venTraVal.id, vTranCons[venTrnIn].get('catAnalysisGroB'), vTCVal, venTraVal_financially_closed);
      });
      let vTServicesFa = venTranCombiFg.get('services') as UntypedFormArray;
      venTraVal['vedor_services'].map((vTCSVal) => {
          vTServicesFa.push(this.fb.control(vTCSVal.vendor_tran_service_id));
      });

      venTraVal['vedor_batches'].map((vTBatch) => {
          let fb = this.initVendorBatchForm(pVi, 'translations', ti)
          fb.patchValue({
            project_translate_id: vTBatch.project_tran_or_service_id,
            description: vTBatch.description,
            delivery_at: this.splitDateAndTime(vTBatch.delivery_at),
          }, this.formEventStop);
      })
      ti++;
    });
    // this.calculateVendorTotalCost();
    this.updateChildTrasnFA.emit(this.vendorsFA.at(pVi));

    if (this.resGoToLastPage) {
      this.resGoToLastPage = false;
      this.addVendorTranslation(pVi);
      let translationFA = this.vendorsFA.controls[pVi].get('translations') as UntypedFormArray;
      let index = translationFA.length
      this.scrollToTranslation('res_trans_'+index);
    }
  }

  res_disable_language_input(tran) {
    if ( !tran.value.display ) {
      return true;
    }
    return ( tran.value.financially_closed || this.checkXTMData ) ? true : null;
  }

  res_disable_word_rate_input(tran) {
    if ( !tran.value.display || tran.value.financially_closed ) {
      return true;
    }
    return null;
  }

  @ViewChild('paginationDeliveryCom') pagination_com: PaginationComponent;
  getPageOfDeliveryLangTrans(page_no = 1, transNaviaction = '') {
    let tempUnsavedProject = this.unsavedProject;
    this.trasnlation_pagination_data.delivery_page_loading = true;
    let delivery_page = this.trasnlation_pagination_data.delivery_page;
    if (transNaviaction == 'prev') {
      delivery_page--;
    } else if(transNaviaction == 'next') {
      delivery_page++;
    }
    let formData = this.Token.getAPITokenData();
    formData['projectId'] = this.projectId;
    formData['page'] = page_no;
    formData['per_page'] = this.trasnlation_pagination_data.delivery_per_page;
    this.http.post(`${this.apiURL}project/delivery-translations`, formData).subscribe(
      data => {
        this.pagination_com.generate(data['totalTrasnlationPages'], page_no)
        this.translationsFilesFA.clear();
        this.trasnlation_pagination_data.delivery_total_pages = data['totalTrasnlationPages'];
        data['translations'].map(v => {
          const fg = this.fb.group({
            project_translate_id: v.id,
            source_lang_id: new UntypedFormControl({ value: v.source_lang_id, disabled: this.projectEditDis }),
            target_lang_id: new UntypedFormControl({ value: v.target_lang_id, disabled: this.projectEditDis }),
            delivered: new UntypedFormControl({ value: v.delivered, disabled: this.projectEditDis }),
            word_rate: new UntypedFormControl({ value: v.word_rate, disabled: this.projectEditDis }),
            amount: new UntypedFormControl({ value: v.amount, disabled: this.projectEditDis }),
            comment: new UntypedFormControl({ value: v.comment, disabled: this.projectEditDis })
          });
          this.translationsFilesFA.push(fg);
          this.trasnlation_pagination_data.delivery_page_loading = false;
          this.trasnlation_pagination_data.delivery_page = delivery_page;
          this.unsavedProject = tempUnsavedProject;
        });
      },
      error => {
        this.loading = false;
        if (error.status == 401) {
          this.handleError(error)
        } else {
          this.openModalDialog('failed', error.error.error);
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
        }
      }
    );
  }

  getPageOfFinanceLangTrans(transNaviaction = '') {
    let tempUnsavedProject = this.unsavedProject;
    if (transNaviaction == 'prev') {
      this.trasnlation_pagination_data.finance_page--;
    } else if(transNaviaction == 'next') {
      this.trasnlation_pagination_data.finance_page++;
    }

    let startIndex = (this.trasnlation_pagination_data.finance_page - 1) * this.trasnlation_pagination_data.finance_per_page;
    let endIndex = startIndex + this.trasnlation_pagination_data.finance_per_page;
    this.financeLangTrans.clear();
    this.translationsFAOrignal.controls.slice(startIndex, endIndex).map((trans, index) => {
      this.financeLangTrans.push(trans);
      this.unsavedProject = tempUnsavedProject;
    })
  }

  getPageOfFinanceVendorLangTrans(pvi, transNaviaction = '') {
    let currentVendor = this.vendorsFA.at(pvi);
    let currentPage = currentVendor.get('current_trans_page').value;
    let transPerPage = currentVendor.get('trans_per_page').value;
    if (transNaviaction == 'prev') {
      currentPage--;
    } else if(transNaviaction == 'next') {
      currentPage++;
    }

    currentVendor.patchValue({
      'current_trans_page': currentPage
    })

    let startIndex = (currentPage - 1) * transPerPage;
    let endIndex = startIndex + transPerPage;
    currentVendor.get('translations_paginated').clear();
    currentVendor.controls.translations_orignal.controls.slice(startIndex, endIndex).map((trans, index) => {
      currentVendor.get('translations_paginated').push(trans);
    })
  }

  getPageOfQuoteLangTrans() {
    if ( typeof this.projectClientLangTransCom == 'undefined' ) {
      return;
    }
    this.projectClientLangTransCom.getPageOfQuoteLangTrans();
  }

  getPageOfResLangTrans(vi, transNaviaction) {
    this.projectVendorLangTransCom.getPageOfResLangTrans(transNaviaction);
  }

  getTranslationCount() {
    return this.project.translations.length;
  }

  getVendorTranslationCount($vendorId) {
    return this.project.vendorsTransTotal[$vendorId];
  }

  addAnalysisToHeadsUp(vendor) {
    if ( this.getVendorTranslationCount(vendor.getRawValue().vendor_id) > 50 ) {
      this.helperSer.alertIt(this.lang.getText('project_vendor_langu_combi_max_50_breakdown_alert'));
      return;
    }
    vendor.patchValue({addQuoteToVendorTemp: 'yes'}, this.formEventStop);
    this.onSubmitProjectEditForm()
  }

  deliverFiles(trans, index) {
    trans.patchValue({ delivered: 1 }, this.formEventStop);
    this.translationsFA.controls[index].patchValue({
      delivered: 1
    });
  }

  undoDeliverFiles(trans, index) {
    trans.patchValue({ delivered: 0 }, this.formEventStop);
    this.translationsFA.controls[index].patchValue({
      delivered: 0
    });
  }
  
  deliverFilesFromChild(event){
    let trans = this.translationsFilesFA.controls[event.index];
    this.deliverFiles(trans,event.index)
  }
  undoDeliverFilesFromChild(event){
    let trans = this.translationsFilesFA.controls[event.index];
    this.undoDeliverFiles(trans,event.index)
  }

  deliverFilesSer(service) {
    service.patchValue({ delivered: 1 }, this.formEventStop);
  }

  undoDeliverFilesSer(service) {
    service.patchValue({ delivered: 0 }, this.formEventStop);
  }

  deliverFilesSerFromChild(event){
    let service = this.additionalServicesFA.controls[event.index];
    this.deliverFilesSer(service)
  }
  undoDeliverFilesSerFromChild(event){
    let service = this.additionalServicesFA.controls[event.index];
    this.undoDeliverFilesSer(service)
  }


  showHideFinanciallyClosedJobs(type, action) {
    if (type == "quote") {
      this.trasnlation_pagination_data.quote_show_hide_financially_closed = action == "show" ? true : false;
      this.translationsFA.controls.map((tranFb, ti) => {
        tranFb.patchValue({ display: false }, this.formEventStop);
      });
    } else if (type == "res") {
      this.trasnlation_pagination_data.res_show_hide_financially_closed = action == "show" ? true : false;
    }
  }

  @ViewChild('generateVendorInvoiceCom') generateVendorInvoiceModal: VendorInvoiceComponent;
  openGeneratePOHTMLModal(vendor) {
    this.generateVendorInvoiceModal.generatePOModalWithParams(this.projectId, vendor.value.project_vendor_id, vendor.value.emails, this.getVendorTranslationCount(vendor.getRawValue().vendor_id), this.vendorPayRequested(vendor), this.createProject);
  }

  @ViewChild('invoiceListCom') invoiceListModal: InvoiceListComponent;
  invoiceEmails;
  openInvoiceHistoryModal() {
    this.clientId = this.project.client.id;
    this.invoiceEmails = this.project.client.invoicing_emails;
    this.invoiceListModal.openModal(this.projectId, this.orderId, this.invoiceEmails, null, this.clientId, this.getProjectCurrencyCode());
  }

  sendInvoiceEvent(event) {
    let pfInvoice = event.pfInvoice;
    let pfInvoiceId = event.pfInvoiceId;
    this.generateInvoiceModal.openSendModal(pfInvoice.pdf, pfInvoiceId);
  }

  @ViewChild('poListCom') poListModal: PoListComponent;
  openPOHistoryModal(projectId, projectVendorId) {
    this.poListModal.openModal(projectId, projectVendorId);
  }

  @ViewChild('successModal', { static: true }) successModalTemp: TemplateRef<any>;
  modalRef2;
  @ViewChild('projectCloseModal', { static: true }) projectCloseModalTemp: TemplateRef<any>;
  projectCloseModalRef;
  openModalSuccess() {
    this.modalRef2 = this.modalService.show(this.successModalTemp,  { class: 'modal-xl' });
  }

  closeModalSuccess() {
    this.modalRef2.hide();
  }

  closeProjectModal() {
    this.phraseFilesInProgress = false;
    this.projectCloseModalRef.hide();
  }

  confirmProjectCompletion() {
    this.phraseCompleteProject();
    this.projectCloseModalRef.hide();
  }
  
  markServiceFinanciallyClosed(checked, service) {
    if (!this.checkXTMData()) {
      this.changeServiceToFinanciallyClosed(service, checked);
    } else {
      if (checked) {
        if (service.get('project_finances_id').value == null) {
          if (!confirm(this.lang.getText('service_not_part_of_any_po_warning'))) {
            this.changeServiceToFinanciallyClosed(service, false);
          }
        } else if (!confirm(this.lang.getText('are_you_sure_this_cannot_be_undone'))) {
          this.changeServiceToFinanciallyClosed(service, false);
        }
      } else { // unchecked FinanciallyClosed checkbox
        alert(this.lang.getText('this_cannot_be_undone'));
        this.changeServiceToFinanciallyClosed(service, true);
      }
    }
  }

  changeServiceToFinanciallyClosed(service, value) {
    service.patchValue({
      "financially_closed": value
    });
  }
  
  prevProject(){
    this.prevNextHttpReq = this.projectSer.getPrevNextProjects(this.projectId).subscribe(
      data => {
        let result = data['result'];
        if(typeof result['previous_project_id'] == 'undefined' || result['previous_project_id'] == null){
          alert(this.lang.getText('no_next_projects'));
          return;
        }
        console.log(result)
        this.router.navigateByUrl('/project/edit/'+result['previous_project_id']);
      },
      error => {
        this.handleError(error)
      }
    );
  }

  nextProject(){
    this.prevNextHttpReq = this.projectSer.getPrevNextProjects(this.projectId).subscribe(
      data => {
        let result = data['result'];
        if(typeof result['next_project_id'] == 'undefined' || result['next_project_id'] == null){
          alert(this.lang.getText('no_previous_projects'));
          return;
        }
        this.router.navigateByUrl('/project/edit/'+result['next_project_id']);
      },
      error => {
        this.handleError(error)
      }
    );
  }

  loadingPrevNext() {
    return this.prevNextHttpReq && this.prevNextHttpReq.isStopped == false
  }
  financiallyCloseAllFinishedJobs(type, vendor_id = null) {
    let formData = this.Token.getAPITokenData();
    formData['project_id'] = this.projectId;
    formData['type'] = type;
    formData['vendor_id'] = vendor_id;
    let message = null;
    if (type == 'quote') {
      message = this.lang.getText('quote_financially_close_all_finished_jobs_warning');
    } else if(type == 'res') {
      message = this.lang.getText('res_financially_close_all_finished_jobs_warning');
    }
    if (!confirm(message)) {
      return false;
    }

    this.http.post(`${this.apiURL}project/financially-close-all-finished-jobs`, formData).subscribe(
      data => {
        if (type == 'quote') {
          this.projectClientLangTransCom.getPageOfQuoteLangTrans();
        } else if(type == 'res') {
          this.projectVendorLangTransCom.getPageOfResLangTrans();
        }
      },
      error => {
        this.loading = false;
        if (error.status == 401) {
          this.handleError(error)
        } else {
          this.openModalDialog('failed', error.error.error);
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
        }
      }
    );
  }
  
  archiveProject() {
    if (confirm(this.lang.getText('archive_this_project_warning'))) {
      this.http.post(`${this.apiURL}project/archive/${this.projectId}`, this.Token.getAPITokenData()).subscribe(
        data => {
          this.helperSer.alertIt(this.lang.getText('archive_this_project_success'));
        },
        error => this.handleError(error)
      );
    }
    return;
  }

  exportCallSubscriptions:Subscription[] = [];
  triggerExportStatusCall(){
    if(this.FilesToGetExportStatus.length > 0){
      this.FilesToGetExportStatus.forEach(element => {
         this.exportCallSubscriptions[element.trados_project_file_id] = 
          interval(10000).pipe(
            mergeMap(() => this.getTradosFileExportStatus(element))
          ).subscribe(data => {
            console.log('data',data);
          });
      });
    }
  }

  getTradosFileExportStatus(element) {
    let formData = {
      token: this.Token.get(),
      trados_project_id: element.trados_project_id,
      trados_project_file_id: element.trados_project_file_id,
      file_version_id: element.file_version_id,
      export_id: element.export_id,
      file_name: element.file_name,
      trados_file_id: element.trados_file_id
    };
    return this.http.post(`${this.apiURL}trados/getTargetFilePollingStatus`, formData)
      .pipe(map((res: any) => {
        if (res['result'] == 'success') {
          this.exportCallSubscriptions[element.trados_project_file_id].unsubscribe();
          this.checklistFiles.forEach((file, index) => {
            if (file.id === element.trados_project_file_id) {
              this.checklistFiles[index].local_data.export_status = 'completed';
              this.checklistFiles[index].available_in_s3 = 1;
            }
          });
        }
      }));
  }

  reloadInvoiceList(event) {
    this.invoiceListModal.closeModal();
    this.openInvoiceHistoryModal();
  }

  displayLabels = [];
  getDisplayLabels() {
    this.displaySettSer.getProjectDisplayLabels();
    this.displaySettSer.labelsObser.subscribe(labels => {
      this.displayLabels = labels;
    });
  }

  hideDisplayLabel(label) {
    return ! this.displayLabels.includes(label);
  }

  isAllLanguagesCombinationsSelected() {
    return this.translationsFA.controls.every(control=>{
      return control.getRawValue().invoiceable == true;
    });
  }

  selectAllLanguageCombinations(){
    this.translationsFA.controls.map(control=>{
      control.patchValue({invoiceable: true});
    });
  }

  isAllServicesSelected() {
    return this.additionalServicesFA.controls.every(control=>{
      return control.getRawValue().invoiceable == true;
    });
  }

  selectAllServices(){
    this.additionalServicesFA.controls.map(control=>{
      control.patchValue({invoiceable: true});
    });
  }

  copyThisService(service){
    const data = service.getRawValue();
    const fg = this.fb.group({
      delete: data.delete,
      delivered: data.delivered,
      pAsId: '',
      comment: new UntypedFormControl({value:data.comment,disabled: this.projectEditDis}),
      unit:   new UntypedFormControl({value:data.unit,disabled: this.projectEditDis}),
      volume: new UntypedFormControl({value:data.volume,disabled: this.projectEditDis}),
      rate:   new UntypedFormControl({value:data.rate,disabled: this.projectEditDis}),
      amount: data.amount,
      invoiceable: data.invoiceable,
      financially_closed: new UntypedFormControl({value:data.financially_closed,disabled: this.projectEditDis}),
    })
    console.log('fg',fg);
    this.additionalServicesFA.push(fg);

    // const data = service;
    // console.log('data',data);
    // data.patchValue({pAsId: ''});
    // this.additionalServicesFA.push(data);
    console.log('this.additionalServicesFA',this.additionalServicesFA);
  }

  deepCopyFormGroup(formGroup, formBuilder) {
    const newFormGroup = formBuilder.group({});
    
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof UntypedFormControl) {
        newFormGroup.addControl(key, new UntypedFormControl(control.value, control.validator, control.asyncValidator));
      } else if (control instanceof UntypedFormGroup) {
        newFormGroup.addControl(key, this.deepCopyFormGroup(control, formBuilder));
      } else if (control instanceof UntypedFormArray) {
        newFormGroup.addControl(key, this.deepCopyFormArray(control, formBuilder));
      }
    });
    
    return newFormGroup;
  }

  deepCopyFormArray(formArray, formBuilder) {
    const newFormArray = formBuilder.array([]);
    
    formArray.controls.forEach(control => {
      if (control instanceof UntypedFormControl) {
        newFormArray.push(new UntypedFormControl(control.value, control.validator, control.asyncValidator));
      } else if (control instanceof UntypedFormGroup) {
        newFormArray.push(this.deepCopyFormGroup(control, formBuilder));
      } else if (control instanceof UntypedFormArray) {
        newFormArray.push(this.deepCopyFormArray(control, formBuilder));
      }
    });
    
    return newFormArray;
  }

  copyThisResTran(event){
    let data = this.translationsFA.at(event.index);
    // .getRawValue();
    // console.log('data',data);
    // const fg = this.fb.group({
    //   catAnalysisGroA: data.catAnalysisGroA,
    //   catAnalysisGroB: data.catAnalysisGroB,
    //   is_cat_changed: data.is_cat_changed,
    //   words_count: new UntypedFormControl({ value: data.words_count, disabled: true }),
    //   weighted_count: new UntypedFormControl({ value: data.weighted_count, disabled: true }),
    //   delete: data.delete,
    //   display: data.display,
    //   delivered: data.delivered,
    //   project_translate_id: '',
    //   source_lang_id: new UntypedFormControl({ value: data.source_lang_id, disabled: this.projectEditDis }),
    //   target_lang_id: new UntypedFormControl({ value: data.target_lang_id, disabled: this.projectEditDis }),
    //   word_rate: new UntypedFormControl({ value: data.word_rate, disabled: this.projectEditDis }),
    //   amount: data.amount,
    //   orignalamount: data.orignalamount,
    //   invoiceable: data.invoiceable,
    //   comment: data.comment,
    //   translation_meta_values: new UntypedFormControl({value: data.translation_meta_values}),
    //   financially_closed: new UntypedFormControl({ value: data.financially_closed, disabled: false }),
    //   jobStatus: data.jobStatus,
    //   project_finances_id: data.jobStatus,
    //   batches_links: new UntypedFormArray(data.batches_links)
    // });
    const copiedFormGroup = this.deepCopyFormGroup(data, this.fb);
    copiedFormGroup.patchValue({
      "project_translate_id": ""
    });
    this.translationsFA.push(copiedFormGroup);
  }

  showLoader(event) {
    this.saving = event;
  }

  @ViewChild('paginationVendorCom') paginationVendor_com: PaginationComponent;
  paginateVendorsCurrentPage = 1;
  paginateVendors(page = null) {
    if ( page == null ) {
      page = this.paginateVendorsCurrentPage;
    }
    this.paginateVendorsCurrentPage = page;

    let per_page = 5;
    let totalPages = Math.ceil(this.vendorsFA.controls.length / per_page);
    this.vendorsFA.controls.forEach((vendorFa, index)  => {
      vendorFa.patchValue({show_page: false});
      if( (index >= (page - 1) * per_page ) && (index < page * per_page)){
        vendorFa.patchValue({show_page: true});
      }
    });
    console.log(this.vendorsFA.controls.length, page);
    this.paginationVendor_com.generate(totalPages, page);
  }

  getProjectCurrencyCode(){
    return this.project.currency_code;
  }

  getProjectVendorCurrencyCode(){
    let currency_code = '';
    if(this.project['vendors'] && this.project['vendors'].length > 0){
      currency_code = this.project['vendors'][0].currency_code;
    }
    if(currency_code == ''){
      currency_code = this.project.currency_code;
    }
    return currency_code;
  }

  project_info_closed: boolean = true;
  is_project_info_closed() {
    return ! this.createProject && this.project_info_closed
  }
  
  phrase_assign_jobs_disabled() {
    let assignedJobs = true;
    if ( ! this.project.phrase_data?.translations ) {
      return assignedJobs;
    }
    this.project.phrase_data.translations.forEach(trans => {
      if ( ! trans.vendor_id ) {
        assignedJobs = false;
      }
    });
    return assignedJobs;
  }

  @ViewChild('phraseAssignJobsCom') phraseAssignJobsCom: PhraseAssignJobsComponent;
  phrase_open_assign_jobs_popup() {
    let projectData = this.formateAllDate(this.projectEditForm.getRawValue());
    this.phraseAssignJobsCom.openModal(this.projectId, projectData, this.project.phrase_data, this.languages, this.orderId, this.clientSelected);
  }
}
