import { VendorListComponent } from './modules/vendor/pages/vendor-list/vendor-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsListPageComponent } from './projects-list-page/projects-list-page.component';
import { ProjectEditPageComponent } from './project-edit-page/project-edit-page.component';
import { UsersListPageComponent } from './users-list-page/users-list-page.component';
import { UserEditPageComponent } from './user-edit-page/user-edit-page.component';
import { UserCreatePageComponent } from './user-create-page/user-create-page.component';
import { CompanyResourceManagementComponent } from './company-resource-management/company-resource-management.component';
import { VendorActionsPageComponent } from './vendor-actions-page/vendor-actions-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent as venProfileCom } from './vendor-pages/profile/profile.component';
import { AccountComponent } from './modules/main/pages/account/account.component';
import { PermissionsService } from './services/permissions.service';

const appRoutes: Routes = [
  { 
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  { 
    path: 'vendor',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    children: [
      { path: 'project', component: ProjectsListPageComponent },
      { path: 'project/create/:id', component: ProjectEditPageComponent },
      { path: 'project/edit/:id', component: ProjectEditPageComponent },
      { path: 'profile', component: AccountComponent },
      /*
      { path: 'project/page/:pageNum', component: ProjectsListPageComponent },
      { path: 'project/page', component: ProjectsListPageComponent },
      */
      {
        path: 'users-list',
        children: [
          { path: '', component: UsersListPageComponent },
          { path: 'edit/:id', component: UserEditPageComponent },
          { path: 'create', component: UserCreatePageComponent },
        ],
      },
      {
        path: 'comp-res-mana',
        children: [
          { path: '', component: CompanyResourceManagementComponent }
        ],
      },
      {
        path: 'client',
        loadChildren: () => import('./modules/clients/client.module').then(m => m.ClientModule),
      },
      {
        path: 'vend',
        children: [
          { path: '', component: VendorListComponent },
          { path: 'edit/:id', component: VendorActionsPageComponent },
          { path: 'profile/:id', component: venProfileCom },
          { path: 'create', component: VendorActionsPageComponent },
          { path: ':type', component: VendorListComponent },
        ],
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'configure',
        loadChildren: () => import('./modules/configure/configure.module').then(m => m.ConfigureModule),
      },
      {
        path: 'financial-dashboard',
        loadChildren: () => import('./modules/financial-dashboard/financial-dashboard.module').then(m => m.FinancialDashboardModule),
      }
     ],
    canActivate: [PermissionsService],
    component: DashboardComponent
  },
  {
    path: '',
    loadChildren: () => import('./modules/vendor-portal/vendor-portal.module').then(m => m.VendorPortalModule),
    canActivate: [PermissionsService],
    component: DashboardComponent
  },
  {
    path: 'vendor',
    children: [
      { path: 'profile', component: venProfileCom },
    ],
    canActivate: [PermissionsService],
    component: DashboardComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
