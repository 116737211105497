<ng-template #addVendorModal>
  <div class="modal-header" style="border-bottom: none;">
    <h4 class="modal-title float-start">
      {{lang.getText('add_vendor')}}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body new-redesign-style">
    <div [hidden]="hide" class="container">
      <h5>{{lang.getText('general_parameters')}}</h5>
      <p class="f-w-500">{{lang.getText('long_text13')}}</p>
      <div class="form-errors vendor-form-errors">
        <p class="error" *ngFor="let error of vendorFormErrors; let ei = index;">{{error}}</p>
      </div>
      <br>
    </div>
    <div [formGroup]='vendorForm' class="container">
      <div [hidden]="hide">
        <div class="row">
          <div class="col-md-4">
            <!-- <label>{{lang.getText('source2')}}</label> -->
            <select formControlName="source" class="form-control color-disabled">
              <option value="" >{{lang.getText('source')}}</option>
              <option *ngFor="let lang of languages;" value="{{lang.id}}">{{lang.name}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <!-- <label>{{lang.getText('target2')}}</label> -->
            <select formControlName="target" class="form-control color-disabled">
              <option value="">{{lang.getText('target')}}</option>
              <option *ngFor="let lang of languages;" value="{{lang.id}}">{{lang.name}}</option>
            </select>
          </div>
          <div class="col-md-4">
            <!-- <label>{{lang.getText('specialization')}}</label> -->
            <ng-select
              class="form-control w-100 p-0 h-auto"
              [items]="specCodes"
              [clearable]="false"
              bindLabel="name" 
              bindValue="id"
              [multiple]="true"
              notFoundText="{{ lang.getText('no_data_found') }}"
              placeholder="{{lang.getText('specialization')}}" 
              formControlName="spec_codes"
            >
            </ng-select>
          </div>
          <div class="col-12">
            <br />
            <button (click)="addFavorite()" class="btn btn-primary">{{lang.getText('add_current_parameter_combination_to_favorites')}}</button>
          </div>
        </div>
        <br/>
        <h5 class="my-2">{{lang.getText('favorite_parameters_tick_a_box_to_select_for_search')}}</h5>
        <div class="container">
          <div class="row row-secondary p-2">
            <div class="col-md-3">
              <label>{{lang.getText('select_favorite')}}</label>
            </div>
            <div class="col-md-3">
              <label>{{lang.getText('source')}}</label>
            </div>
            <div class="col-md-3">
              <label>{{lang.getText('target')}}</label>
            </div>
            <div class="col-md-3">
              <label>{{lang.getText('specialization')}}</label>
            </div>
            <!-- <div class="col-md-5">
              <button
                (click)="vendorForm.patchValue({applyFormValue: false})"
                class="btn btn-primary float-end">
                {{lang.getText('deselect_favorite')}}
              </button>
            </div> -->
          </div>
          <div *ngFor="let fav of favorites" class="row row-primary mt-2 p-2">
            <div class="col-md-3 d-flex">
              <div class="form-check form-switch m-auto">
                <input type="radio" formControlName="applyFormValue" [value]="fav" (click)="applyFormValue(fav)" class="form-check-input"/>
              </div>
            </div>
            <div class="col-md-3">
              {{fav.source_language.name}}
            </div>
            <div class="col-md-3">
              {{fav.target_language.name}}
            </div>
            <div class="col-md-3">
              <span *ngFor="let spec of fav.specialization;let isLast=last">{{spec.specialization_code.name}}{{isLast ? '' : ', '}}</span>
            </div>
          </div>
        </div>
        <br />
      </div>  
      <!-- <h5>{{lang.getText('search_by_name')}}</h5> -->
      <div class="row">
        <div class="col-md-4">
          <input type="text" formControlName="name" class="form-control" placeholder="{{lang.getText('search_by_name')}}" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <input (click)="findVendors()" class="btn btn-primary" type="button" value="{{lang.getText('find_vendors')}}" />
          <button
            (click)="vendorForm.patchValue({applyFormValue: false, source:'', target: '', spec_codes: ''})"
            class="btn btn-primary ms-3">
            {{lang.getText('deselect_favorite')}}
          </button>
        </div>
      </div>
    </div>
    <br />
    <div *ngIf="vendors.length > 0" class="container">
      <div class="col-md-12">
      <div class="row row-secondary p-2">
        <div class="col-2">{{lang.getText('name')}}</div>
        <div class="col-3">{{lang.getText('email')}}</div>
        <div class="col-3">{{lang.getText('review_score')}}</div>
        <div class="col-2"></div>
        <div class="col-2">
          <div class="form-check form-switch">
            <label class="form-check-label" for="">
              <input type="checkbox" class="form-check-input me-2" [(ngModel)]="masterSelected"
              (change)="checkUncheckAll($event)" name="m1"/>
              {{lang.getText('select_all')}}
            </label>
          </div>
        </div>
      </div>
      <div class="row row-primary p-1 mt-2" *ngFor="let vendor of vendors;let i = index;">
        <div class="col-2">{{vendor.vendor.name}}</div>
        <div class="col-3">{{vendor.vendor.email}}</div>
        <div class="col-3">
          <span class="score">
            <div class="score-wrap text-start">
              <span class="stars-active" [style.width.%]="( 100 * vendor.rating ) / 5">
                <i *ngFor="let rate of helperSer.ratingAry(); let ri = index;"
                  class="fa fa-star icon-c rating orange" aria-hidden="true"></i>
              </span>
              <span class="stars-inactive">
                <i *ngFor="let rate of helperSer.ratingAry(); let ri = index;" class="fa fa-star icon-c rating"
                  aria-hidden="true"></i>
              </span>
            </div>
          </span>
        </div>
        <div class="col-2">
          <a (click)="openRatesModal(vendor.vendor.id)" class="cur-pointer btn-circle btn-empty p-1">
            <!-- <u>{{lang.getText('rates')}}</u> -->
            <i class="fa fa-money icon-c rating orange p-1" aria-hidden="true"></i>
          </a>
          <a href="/vend/profile/{{vendor.vendor.id}}" target="_blank" class="cur-pointer btn-circle btn-empty p-1" >
            <!-- <u>{{lang.getText('display_all_details')}}</u> -->
            <i class="fa fa-user icon-c rating orange p-1" aria-hidden="true"></i>
          </a>
        </div>
        <div class="col-2 text-end">
          <div class="form-check form-switch float-end">
            <input type="checkbox" class="form-check-input" name="{{vendor.vendor.id}}" 
            (change)="isAllSelected($event, i)"
            [(ngModel)]="vendor.checked"/>
          </div>
        </div>
      </div>
      <div class="row col-3 mt-3">
        <button (click)="addSelectedVendors()" class="btn btn-primary">{{lang.getText('use_selected_vendors')}}</button>
      </div>
      </div>
      <!-- <table class="table table-border" aria-label="vendor-comp">
        <thead role="rowgroup">
          <tr role="row">
            <td role="">{{lang.getText('name')}}</td>
            <td role="columnheader">{{lang.getText('email')}}</td>
            <td role="columnheader">{{lang.getText('review_score')}}</td>
            <td role="columnheader"></td>
            <th id="columnht" role="columnheader"></th>
            <th id="columnhts" role="columnheader">
              <button (click)="addSelectedVendors()" class="btn btn-primary">{{lang.getText('use_selected_vendors')}}</button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vendor of vendors;">
            <td>
              {{vendor.vendor.name}}
            </td>
            <td>
              {{vendor.vendor.email}}
            </td>
            <td>
              <span class="score">
                <div class="score-wrap">
                  <span class="stars-active" [style.width.%]="( 100 * vendor.rating ) / 5">
                    <i *ngFor="let rate of helperSer.ratingAry(); let ri = index;"
                      class="fa fa-star icon-c rating orange" aria-hidden="true"></i>
                  </span>
                  <span class="stars-inactive">
                    <i *ngFor="let rate of helperSer.ratingAry(); let ri = index;" class="fa fa-star icon-c rating"
                      aria-hidden="true"></i>
                  </span>
                </div>
              </span>
            </td>
            <td>
              <a (click)="openRatesModal(vendor.vendor.id)" class="cur-pointer">
                <u>{{lang.getText('rates')}}</u>
              </a>
            </td>
            <td>
              <a href="/vend/profile/{{vendor.vendor.id}}" target="_blank" style="color: black;">
                <u>{{lang.getText('display_all_details')}}</u>
              </a>
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="vendor.checked" />
            </td>
          </tr>
        </tbody>
      </table> -->

    </div>
  </div>
</ng-template>
<app-vendor-rates #vendorRatesCom></app-vendor-rates>
